import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { ManifestSchema, ManifestUpdateInputSchema } from '@/zod';
import { manifestController } from './manifestController';
import { ManifestCreateDTOSchema } from './manifestModel';

extendZodWithOpenApi(z);

export const manifestRegistry = new OpenAPIRegistry();
export const manifestRouter: Router = express.Router();

manifestRegistry.register('Manifest', ManifestSchema);

manifestRegistry.registerPath({
    method: 'get',
    path: '/api/manifests',
    tags: ['Manifest'],
    responses: createApiResponse(z.array(ManifestSchema), 'Success'),
});

manifestRouter.get('/', isAuthenticated, manifestController.getAll);

const GetManifestOptionsSchema = z.object({
    query: z.object({
        originId: commonValidations.id,
        destinationId: commonValidations.id,
    }),
});

manifestRouter.get(
    '/booking-options',
    isAuthenticated,
    validateRequest(GetManifestOptionsSchema),
    manifestController.getBookingOptions,
);

manifestRouter.get(
    '/baggage-options',
    isAuthenticated,
    validateRequest(GetManifestOptionsSchema),
    manifestController.getBaggageOptions,
);

manifestRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    manifestController.getById,
);

const CreateSchema = z.object({
    body: ManifestCreateDTOSchema,
});

manifestRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    manifestController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: ManifestUpdateInputSchema,
});

manifestRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    manifestController.update,
);
