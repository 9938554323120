import { DeliveryMode, PaymentMode, ServiceType, Unit } from '@prisma/client';
import { z } from 'zod';

export interface LookupViewModel {
    value: string;
    label: string;
}

export const Lookup = {
    unit: {
        [Unit.piece]: 'Piece',
        [Unit.weight]: 'Weight',
        [Unit.fix]: 'Fixed',
    },
    paymentMode: {
        [PaymentMode.cod]: { label: 'COD', client: true },
        [PaymentMode.topay]: { label: 'TOPAY', client: null },
        [PaymentMode.due]: { label: 'DUE', client: true },
        [PaymentMode.paid]: { label: 'PAID', client: false },
    },
    serviceType: {
        [ServiceType.air]: 'By Air',
        [ServiceType.surface]: 'By Surface',
        [ServiceType.logistic]: 'Logistics',
    },
    deliveryMode: {
        [DeliveryMode.d2d]: 'Door to Door',
        [DeliveryMode.b2b]: 'Branch to Branch',
        [DeliveryMode.d2b]: 'Door to Branch',
        [DeliveryMode.b2d]: 'Branch to Door',
    },
};

export const LookupSchema = z.object({
    value: z.string(),
    label: z.string(),
    client: z.boolean().optional(),
});
