import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import {
    RoleCreateInputSchema,
    RoleSchema,
    RoleUpdateInputSchema,
} from '@/zod';
import { roleController } from './roleController';

extendZodWithOpenApi(z);

export const roleRegistry = new OpenAPIRegistry();
export const roleRouter: Router = express.Router();

roleRegistry.register('Role', RoleSchema);

roleRegistry.registerPath({
    method: 'get',
    path: '/api/roles',
    tags: ['Role'],
    responses: createApiResponse(z.array(RoleSchema), 'Success'),
});

roleRouter.get('/', isAuthenticated, roleController.getAll);

roleRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    roleController.getById,
);

const CreateSchema = z.object({
    body: RoleCreateInputSchema,
});

roleRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    roleController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: RoleUpdateInputSchema,
});

roleRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    roleController.update,
);
