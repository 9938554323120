import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { lookupController } from './lookupController';
import { LookupSchema } from './lookupModel';

extendZodWithOpenApi(z);

export const lookupRegistry = new OpenAPIRegistry();
export const lookupRouter: Router = express.Router();

lookupRegistry.register('Lookup', LookupSchema);

lookupRegistry.registerPath({
    method: 'get',
    path: '/api/lookups/units',
    tags: ['Lookup'],
    responses: createApiResponse(z.array(LookupSchema), 'Success'),
});

lookupRouter.get('/units', isAuthenticated, lookupController.getUnits);

lookupRegistry.registerPath({
    method: 'get',
    path: '/api/lookups/payment-modes',
    tags: ['Lookup'],
    responses: createApiResponse(z.array(LookupSchema), 'Success'),
});

lookupRouter.get(
    '/payment-modes',
    isAuthenticated,
    lookupController.getPaymentModes,
);

lookupRegistry.registerPath({
    method: 'get',
    path: '/api/lookups/service-types',
    tags: ['Lookup'],
    responses: createApiResponse(z.array(LookupSchema), 'Success'),
});

lookupRouter.get(
    '/service-types',
    isAuthenticated,
    lookupController.getServiceTypes,
);

lookupRegistry.registerPath({
    method: 'get',
    path: '/api/lookups/delivery-modes',
    tags: ['Lookup'],
    responses: createApiResponse(z.array(LookupSchema), 'Success'),
});

lookupRouter.get(
    '/delivery-modes',
    isAuthenticated,
    lookupController.getDeliveryModes,
);
