import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { type Role, type Prisma, PrismaClient } from '@prisma/client';

export class RoleService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all roles from the database
    async getAll(): Promise<ServiceResponse<Role[] | null>> {
        try {
            const list = await this.prisma.role.findMany({
                orderBy: { id: 'asc' },
            });

            if (!list || list.length === 0) {
                return ServiceResponse.failure(
                    'No role found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<Role[]>('Roles found', list);
        } catch (ex) {
            const errorMessage = `Error getting roles: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving roles.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a role by their ID
    async getById(id: number): Promise<ServiceResponse<Role | null>> {
        try {
            const role = await this.prisma.role.findUnique({ where: { id } });
            if (!role) {
                return ServiceResponse.failure(
                    'Role not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<Role>('Role found', role);
        } catch (ex) {
            const errorMessage = `Error finding role with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding role.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single role
    async create(
        data: Prisma.RoleCreateInput,
    ): Promise<ServiceResponse<Role | null>> {
        try {
            const role = await this.prisma.role.create({ data });
            return ServiceResponse.success<Role>(
                'Role Successfully Created',
                role,
            );
        } catch (ex) {
            const errorMessage = `Error creating a role:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating role.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a role by their ID
    async update(
        id: number,
        data: Prisma.RoleUpdateInput,
    ): Promise<ServiceResponse<Role | null>> {
        try {
            const role = await this.prisma.role.findUnique({ where: { id } });
            if (!role) {
                return ServiceResponse.failure(
                    'Role not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateRole = await this.prisma.role.update({
                where: {
                    id,
                },
                data,
            });

            return ServiceResponse.success<Role>(
                'Role successfully updated',
                updateRole,
            );
        } catch (ex) {
            const errorMessage = `Error updating role with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating role.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const roleService = new RoleService();
