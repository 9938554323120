import type { Request, RequestHandler, Response } from 'express';

import { productTypeService } from '@/api/productType/productTypeService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';
import { ProductTypeCreateDTOSchema } from './productTypeModel';

class ProductTypeController {
    public getAll: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await productTypeService.getAll();
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await productTypeService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = ProductTypeCreateDTOSchema.parse(req.body);
        const userId = req.user!.id;
        const serviceResponse = await productTypeService.create(data, userId);
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.ProductTypeUpdateInput;
        const serviceResponse = await productTypeService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const productTypeController = new ProductTypeController();
