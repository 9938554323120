import type { NextFunction, Request, Response } from 'express';
import createError from 'http-errors';

export default async function (
    req: Request,
    _res: Response,
    next: NextFunction,
) {
    if (!req.user) {
        const error = createError(401, 'Not authenticated!');
        return next(error);
    }
    return next();
}
