import type { Request, RequestHandler, Response } from 'express';

import { rateService } from '@/api/rate/rateService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';

class RateController {
    public getAll: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await rateService.getAll();
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await rateService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = req.body as Prisma.RateCreateInput;
        const serviceResponse = await rateService.create(data);
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.RateUpdateInput;
        const serviceResponse = await rateService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const rateController = new RateController();
