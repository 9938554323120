import compression from 'compression';
import cors from 'cors';
import express, { type Express } from 'express';
import helmet from 'helmet';
import { pino } from 'pino';

import { openAPIRouter } from '@/api-docs/openAPIRouter';
import authenticate from '@/common/middleware/authentication';
import errorHandler from '@/common/middleware/errorHandler';
import rateLimiter from '@/common/middleware/rateLimiter';
import requestLogger from '@/common/middleware/requestLogger';
import { env } from '@/common/utils/envConfig';
import routerConfig from '@/config/router';

const logger = pino({ name: 'server start' });
const app: Express = express();

// Set the application to trust the reverse proxy
app.set('trust proxy', true);

// Middlewares
app.use(express.json());
app.use(express.urlencoded({ extended: true }));
app.use(cors({ origin: env.CORS_ALLOWED_ORIGIN, credentials: true }));
app.use(
    compression({
        level: 9,
    }),
);
app.use(helmet());
app.use((_req, res, next) => {
    res.setHeader('Access-Control-Expose-Headers', 'token, refresh-token');
    next();
});

app.use(rateLimiter);

// Request logging
app.use(requestLogger);

// Auth
app.use(authenticate);

// Routes
routerConfig(app);

// Swagger UI
app.use(openAPIRouter);

// Error handlers
app.use(errorHandler());

export { app, logger };
