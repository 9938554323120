import { z } from 'zod';
import { commonValidations } from '../utils/commonValidation';

export interface BaseModel {
    id: number;
    name: string;
}

export const BaseSchema = z.object({
    id: z.number(),
    name: z.string(),
});

export const GetByIdSchema = z.object({
    params: z.object({ id: commonValidations.id }),
});

export const GetBySearchSchema = z.object({
    params: z.object({ query: z.string() }),
});
