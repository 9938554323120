import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import {
    BookingAction,
    BookingStatus,
    type Manifest,
    ManifestStatus,
    type MasterManifest,
    MasterManifestStatus,
    type Prisma,
    PrismaClient,
} from '@prisma/client';
import {
    type MansterManifestCreateDTO,
    MasterManifestDetailViewModel,
} from './masterManifestModel';

export class MasterManifestService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all Master Manifests from the database
    async getAll(
        locationId: number,
    ): Promise<ServiceResponse<MasterManifest[] | null>> {
        try {
            const cond: Prisma.MasterManifestWhereInput = {
                OR: [
                    {
                        originId: locationId,
                        status: { not: ManifestStatus.received },
                    },
                    {
                        destinationId: locationId,
                        status: ManifestStatus.dispatched,
                    },
                ],
            };

            const list = await this.prisma.masterManifest.findMany({
                include: {
                    origin: true,
                    destination: true,
                },
                where: cond,
                orderBy: { id: 'asc' },
            });

            return ServiceResponse.success<MasterManifest[]>(
                'Master Manifests found',
                list,
            );
        } catch (ex) {
            const errorMessage = `Error getting Master Manifests: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving Master Manifests.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a Master Manifest by their ID
    async getById(
        id: number,
    ): Promise<ServiceResponse<MasterManifestDetailViewModel | null>> {
        try {
            const masterManifest = await this.prisma.masterManifest.findUnique({
                include: {
                    origin: true,
                    destination: true,
                    manifests: {
                        include: {
                            origin: true,
                            destination: true,
                            bookings: {
                                include: {
                                    items: true,
                                },
                            },
                            baggages: {
                                include: {
                                    bookings: {
                                        include: {
                                            items: true,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                where: { id },
            });
            if (!masterManifest) {
                return ServiceResponse.failure(
                    'Master Manifest not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<MasterManifestDetailViewModel>(
                'Master Manifest found',
                new MasterManifestDetailViewModel(masterManifest),
            );
        } catch (ex) {
            const errorMessage = `Error finding Master Manifest with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding Master Manifest.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async getManifestOptions(
        originId: number,
        destinationId: number,
    ): Promise<ServiceResponse<Manifest[] | null>> {
        try {
            const manifests = await this.prisma.manifest.findMany({
                include: {
                    origin: true,
                    destination: true,
                },
                where: {
                    originId,
                    destinationId,
                    masterManifestId: null,
                },
            });
            return ServiceResponse.success<Manifest[]>(
                'Manifest(s) found',
                manifests,
            );
        } catch (ex) {
            const errorMessage = `Error finding manifest options with for originId ${originId} and destinationId ${destinationId}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding manifests.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single Master Manifest
    async create(
        data: MansterManifestCreateDTO,
        userId: number,
        locationId: number,
    ): Promise<ServiceResponse<MasterManifest | null>> {
        const yearPrefix = new Date().getFullYear().toString().slice(2);
        try {
            const lastMasterManifest =
                await this.prisma.masterManifest.findFirst({
                    where: {
                        masterManifestNo: {
                            startsWith: yearPrefix,
                        },
                    },
                    orderBy: {
                        masterManifestNo: 'desc',
                    },
                });

            const lastSeq = lastMasterManifest
                ? Number.parseInt(lastMasterManifest.masterManifestNo.slice(2))
                : 0;

            const nextSeq = lastSeq + 1;
            const masterManifestNo = `${yearPrefix}${nextSeq.toString().padStart(5, '0')}`;
            const masterManifest = await this.prisma.$transaction(
                async (tran) => {
                    const mmf = await tran.masterManifest.create({
                        include: {
                            manifests: {
                                include: {
                                    bookings: true,
                                    baggages: {
                                        include: {
                                            bookings: true,
                                        },
                                    },
                                },
                            },
                        },
                        data: {
                            ...data,
                            advance: data.advance || undefined,
                            createdById: userId,
                            masterManifestNo,
                            manifests: {
                                connect: data.manifests.map((id) => ({ id })),
                            },
                            details: {
                                create: data.manifests.map((manifestId) => ({
                                    manifestId,
                                })),
                            },
                            status: MasterManifestStatus.created,
                        },
                    });

                    await tran.manifest.updateMany({
                        where: {
                            id: { in: data.manifests },
                            status: 'created',
                        },
                        data: {
                            status: 'master',
                        },
                    });

                    const allBookings = mmf.manifests.flatMap((manifest) => [
                        ...manifest.bookings,
                        ...manifest.baggages.flatMap((bag) => bag.bookings),
                    ]);

                    await tran.booking.updateMany({
                        data: {
                            status: BookingStatus.master,
                        },
                        where: {
                            id: {
                                in: allBookings.map((b) => b.id),
                            },
                        },
                    });

                    await tran.bookingHistory.createMany({
                        data: allBookings.map((booking) => ({
                            bookingId: booking.id,
                            action: BookingAction.master,
                            refId: mmf.id,
                            refNo: mmf.masterManifestNo,
                            userId,
                            locationId,
                            customer: false,
                        })),
                    });

                    return mmf;
                },
            );
            return ServiceResponse.success<MasterManifest>(
                'Master Manifest Successfully Created',
                masterManifest,
            );
        } catch (ex) {
            const errorMessage = `Error creating a Master Manifest:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating Master Manifest.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Dispatch a Master Manifest by their ID
    async dispatch(
        id: number,
        userId: number,
        locationId: number,
    ): Promise<ServiceResponse<MasterManifest | null>> {
        try {
            const masterManifest = await this.prisma.masterManifest.findUnique({
                include: {
                    manifests: {
                        include: {
                            bookings: true,
                            baggages: {
                                include: {
                                    bookings: true,
                                },
                            },
                        },
                    },
                },
                where: { id },
            });
            if (!masterManifest) {
                return ServiceResponse.failure(
                    'Master Manifest not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const bookingIds = masterManifest.manifests.flatMap((manifest) => [
                ...manifest.bookings.map((booking) => booking.id),
                ...manifest.baggages.flatMap((baggage) =>
                    baggage.bookings.map((booking) => booking.id),
                ),
            ]);
            const updateMasterManifest = await this.prisma.$transaction(
                async (tran) => {
                    const mmf = await tran.masterManifest.update({
                        where: {
                            id,
                        },
                        data: {
                            status: MasterManifestStatus.dispatched,
                            dispatchedAt: new Date(),
                            dispatchedById: userId,
                        },
                    });

                    await tran.manifest.updateMany({
                        where: {
                            id: {
                                in: masterManifest.manifests.map(
                                    (manifest) => manifest.id,
                                ),
                            },
                        },
                        data: {
                            status: ManifestStatus.dispatched,
                        },
                    });

                    await tran.booking.updateMany({
                        where: {
                            id: {
                                in: bookingIds,
                            },
                        },
                        data: {
                            currentLocationId: null,
                        },
                    });

                    await tran.bookingHistory.createMany({
                        data: bookingIds.map((id) => ({
                            bookingId: id,
                            action: BookingAction.dispatch,
                            refId: mmf.id,
                            refNo: mmf.masterManifestNo,
                            userId,
                            locationId,
                            customer: false,
                        })),
                    });
                    return mmf;
                },
            );

            return ServiceResponse.success<MasterManifest>(
                'Master Manifest successfully dispatched',
                updateMasterManifest,
            );
        } catch (ex) {
            const errorMessage = `Error dispatching Master Manifest with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while dispatching Master Manifest.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Receive a Master Manifest by their ID
    async receive(
        id: number,
        userId: number,
        locationId: number,
    ): Promise<ServiceResponse<MasterManifest | null>> {
        try {
            const masterManifest = await this.prisma.masterManifest.findUnique({
                include: {
                    manifests: {
                        include: {
                            bookings: true,
                            baggages: {
                                include: {
                                    bookings: true,
                                },
                            },
                        },
                    },
                },
                where: { id },
            });
            if (!masterManifest) {
                return ServiceResponse.failure(
                    'Master Manifest not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const bookingIds = masterManifest.manifests.flatMap((manifest) => [
                ...manifest.bookings.map((booking) => booking.id),
                ...manifest.baggages.flatMap((baggage) =>
                    baggage.bookings.map((booking) => booking.id),
                ),
            ]);
            const updateMasterManifest = await this.prisma.$transaction(
                async (tran) => {
                    const mmf = await tran.masterManifest.update({
                        where: {
                            id,
                        },
                        data: {
                            status: MasterManifestStatus.received,
                            receivedById: userId,
                            receivedAt: new Date(),
                        },
                    });

                    await tran.manifest.updateMany({
                        where: {
                            id: {
                                in: masterManifest.manifests.map(
                                    (manifest) => manifest.id,
                                ),
                            },
                        },
                        data: {
                            status: ManifestStatus.received,
                        },
                    });
                    await tran.booking.updateMany({
                        where: {
                            id: {
                                in: bookingIds,
                            },
                        },
                        data: {
                            currentLocationId: masterManifest.destinationId,
                        },
                    });

                    await tran.bookingHistory.createMany({
                        data: bookingIds.map((id) => ({
                            bookingId: id,
                            action: BookingAction.arrive,
                            refId: mmf.id,
                            refNo: mmf.masterManifestNo,
                            userId,
                            locationId,
                            customer: false,
                        })),
                    });
                    return mmf;
                },
            );

            return ServiceResponse.success<MasterManifest>(
                'Master Manifest successfully received',
                updateMasterManifest,
            );
        } catch (ex) {
            const errorMessage = `Error receiving Master Manifest with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while receiving Master Manifest.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a Master Manifest by their ID
    async update(
        id: number,
        data: Prisma.MasterManifestUpdateInput,
    ): Promise<ServiceResponse<MasterManifest | null>> {
        try {
            const masterManifest = await this.prisma.masterManifest.findUnique({
                where: { id },
            });
            if (!masterManifest) {
                return ServiceResponse.failure(
                    'Master Manifest not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateMasterManifest =
                await this.prisma.masterManifest.update({
                    where: {
                        id,
                    },
                    data,
                });

            return ServiceResponse.success<MasterManifest>(
                'Master Manifest successfully updated',
                updateMasterManifest,
            );
        } catch (ex) {
            const errorMessage = `Error updating Master Manifest with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating Master Manifest.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const masterManifestService = new MasterManifestService();
