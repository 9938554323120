import type { Request, RequestHandler, Response } from 'express';

import { clientService } from '@/api/client/clientService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import {
    type ClientCreateDTO,
    ClientStatementFetchDTOSchema,
    type ClientUpdateDTO,
} from './clientModel';

class ClientController {
    public getAll: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await clientService.getAll();
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await clientService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public getStatement: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = ClientStatementFetchDTOSchema.parse(req.body);
        const serviceResponse = await clientService.getStatement(id, data);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const userId = req.user!.id;
        const data = req.body as ClientCreateDTO;
        const serviceResponse = await clientService.create(data, userId);
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as ClientUpdateDTO;
        const serviceResponse = await clientService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const clientController = new ClientController();
