import type { BaseModel } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { z } from 'zod';

export const LoginSchema = z.object({
    userName: z.string(),
    password: z.string(),
});

export type LoginViewModel = z.infer<typeof LoginSchema>;

export interface LoginResponse {
    token: string;
    refreshToken: string;
}

export class TokenClaim {
    id: number;
    email: string;
    name: string;
    userName: string;
    locationId: number;
    clientId: number | null;
    isAdmin: boolean;
    isClient: boolean;
    isOffice: boolean;
    location: BaseModel | null;

    constructor(user: {
        id: number;
        userName: string;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        name?: string;
        email: string;
        role?: {
            isAdmin: boolean;
        } | null;
        isAdmin?: boolean;
        locationId: number;
        location: BaseModel | null;
        clientId: number | null;
    }) {
        this.id = user.id;
        this.email = user.email;
        this.name =
            user.name ||
            [user.firstName, user.middleName, user.lastName]
                .filter((x) => x)
                .join(' ');
        this.locationId = user.locationId;
        this.location = user.location;
        this.email = user.email;
        this.isAdmin = user.isAdmin || user.role?.isAdmin === true;
        this.isClient = !!user.clientId;
        this.isOffice = !user.clientId;
        this.userName = user.userName;
        this.clientId = user.clientId;
    }
}

export const UserRegisterSchema = z.object({
    firstName: z.string().max(30),
    middleName: z.string().max(30).nullable().optional(),
    lastName: z.string().max(30),
    userName: z.string().max(150),
    locationId: z.number(),
    email: z.string().email(),
    phone: commonValidations.contact,
    password: z.string(),
    roleId: z.number(),
});

export type UserRegister = z.infer<typeof UserRegisterSchema>;
