import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import {
    BookingAction,
    type BookingDelivery,
    BookingDeliveryStatus,
    BookingStatus,
    type Delivery,
    DeliveryStatus,
    type Prisma,
    PrismaClient,
} from '@prisma/client';
import {
    DeliveryBookingOptionViewModel,
    type DeliveryCreateDTO,
    type DeliveryDeliverDTO,
    DeliveryDetailViewModel,
    DeliveryListViewModel,
    type DeliveryReturnDTO,
} from './deliveryModel';

export class DeliveryService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all deliveries from the database
    async getAll(
        locationId: number,
    ): Promise<ServiceResponse<DeliveryListViewModel[] | null>> {
        try {
            const list = await this.prisma.delivery.findMany({
                include: {
                    deliveredBy: true,
                    bookings: true,
                },
                where: {
                    deliveredBy: {
                        locationId,
                    },
                },
                orderBy: { id: 'desc' },
            });

            return ServiceResponse.success<DeliveryListViewModel[]>(
                'Deliveries found',
                list.map((delivery) => new DeliveryListViewModel(delivery)),
            );
        } catch (ex) {
            const errorMessage = `Error getting deliveries: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving deliveries.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a delivery by their ID
    async getById(
        id: number,
    ): Promise<ServiceResponse<DeliveryDetailViewModel | null>> {
        try {
            const delivery = await this.prisma.delivery.findUnique({
                include: {
                    deliveredBy: true,
                    bookings: {
                        include: {
                            booking: {
                                include: {
                                    origin: true,
                                    destination: true,
                                    items: true,
                                },
                            },
                        },
                    },
                },
                where: { id },
            });
            if (!delivery) {
                return ServiceResponse.failure(
                    'Delivery not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<DeliveryDetailViewModel>(
                'Delivery found',
                new DeliveryDetailViewModel(delivery),
            );
        } catch (ex) {
            const errorMessage = `Error finding delivery with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding delivery.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async getBookingOptions(
        locationId: number,
    ): Promise<ServiceResponse<DeliveryBookingOptionViewModel[] | null>> {
        try {
            const bookings = await this.prisma.booking.findMany({
                where: {
                    destinationId: locationId,
                    currentLocationId: locationId,
                    status: {
                        notIn: [BookingStatus.shipping, BookingStatus.received],
                    },
                },
                include: {
                    items: {
                        include: {
                            productType: true,
                        },
                    },
                    origin: true,
                    destination: true,
                    deliveries: true,
                },
            });
            return ServiceResponse.success<DeliveryBookingOptionViewModel[]>(
                'Booking(s) found',
                bookings.map(
                    (booking) => new DeliveryBookingOptionViewModel(booking),
                ),
            );
        } catch (ex) {
            const errorMessage = `Error finding baggage options with for locationId ${locationId}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding baggage.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single delivery
    async create(
        data: DeliveryCreateDTO,
        userId: number,
        locationId: number,
    ): Promise<ServiceResponse<Delivery | null>> {
        try {
            const delivery = await this.prisma.$transaction(async (tran) => {
                const delivery = await tran.delivery.create({
                    data: {
                        deliveredBy: {
                            connect: {
                                id: data.deliveredById,
                            },
                        },
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                        location: {
                            connect: {
                                id: locationId,
                            },
                        },
                        bookings: {
                            createMany: {
                                data: data.bookings.map((id) => ({
                                    bookingId: id,
                                })),
                            },
                        },
                    },
                });

                await tran.booking.updateMany({
                    data: {
                        status: BookingStatus.shipping,
                    },
                    where: {
                        id: { in: data.bookings },
                    },
                });

                await tran.bookingHistory.createMany({
                    data: data.bookings.map((id) => ({
                        bookingId: id,
                        action: BookingAction.ship,
                        refId: delivery.id,
                        refNo: null,
                        userId,
                        locationId,
                        customer: false,
                    })),
                });

                return delivery;
            });

            return ServiceResponse.success<Delivery>(
                'Delivery Successfully Created',
                delivery,
            );
        } catch (ex) {
            const errorMessage = `Error creating a delivery:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating delivery.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a delivery by their ID
    async update(
        id: number,
        data: Prisma.DeliveryUpdateInput,
    ): Promise<ServiceResponse<Delivery | null>> {
        try {
            const delivery = await this.prisma.delivery.findUnique({
                where: { id },
            });
            if (!delivery) {
                return ServiceResponse.failure(
                    'Delivery not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateDelivery = await this.prisma.delivery.update({
                where: {
                    id,
                },
                data,
            });

            return ServiceResponse.success<Delivery>(
                'Delivery successfully updated',
                updateDelivery,
            );
        } catch (ex) {
            const errorMessage = `Error updating delivery with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating delivery.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async deliver(
        id: number,
        data: DeliveryDeliverDTO,
        userId: number,
        locationId: number,
    ): Promise<ServiceResponse<BookingDelivery | null>> {
        try {
            const bookingDelivery =
                await this.prisma.bookingDelivery.findUnique({
                    include: {
                        booking: true,
                    },
                    where: { id },
                });

            if (!bookingDelivery)
                return ServiceResponse.failure(
                    'Booking Delivery not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );

            const updateDelivery = await this.prisma.$transaction(
                async (tran) => {
                    const updateBookingDelivery =
                        await tran.bookingDelivery.update({
                            include: {
                                delivery: {
                                    include: {
                                        bookings: true,
                                    },
                                },
                            },
                            where: {
                                id,
                            },
                            data: {
                                ...data,
                                status: BookingDeliveryStatus.delivered,
                                deliveredAt: new Date(),
                            },
                        });

                    const remaining =
                        updateBookingDelivery.delivery.bookings.some(
                            (bd) => bd.status === BookingDeliveryStatus.ofd,
                        );

                    await tran.booking.update({
                        where: {
                            id: bookingDelivery?.booking.id,
                        },
                        data: {
                            status: BookingStatus.received,
                        },
                    });

                    await tran.bookingHistory.create({
                        data: {
                            bookingId: id,
                            action: BookingAction.deliver,
                            refId: bookingDelivery.deliveryId,
                            refNo: null,
                            userId,
                            locationId,
                            customer: false,
                        },
                    });

                    if (!remaining)
                        await tran.delivery.update({
                            where: {
                                id: bookingDelivery.deliveryId,
                            },
                            data: {
                                status: DeliveryStatus.complete,
                            },
                        });

                    return updateBookingDelivery;
                },
            );

            return ServiceResponse.success<BookingDelivery | null>(
                'Delivery successfully updated',
                updateDelivery,
            );
        } catch (ex) {
            const errorMessage = `Error delivering a delivery:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while delivering a delivery.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async return(
        id: number,
        data: DeliveryReturnDTO,
        userId: number,
        locationId: number,
    ): Promise<ServiceResponse<BookingDelivery | null>> {
        try {
            const bookingDelivery =
                await this.prisma.bookingDelivery.findUnique({
                    include: {
                        booking: true,
                    },
                    where: { id },
                });

            if (!bookingDelivery)
                return ServiceResponse.failure(
                    'Booking Delivery not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );

            const updateDelivery = await this.prisma.$transaction(
                async (tran) => {
                    const updateBookingDelivery =
                        await tran.bookingDelivery.update({
                            include: {
                                delivery: {
                                    include: {
                                        bookings: true,
                                    },
                                },
                            },
                            where: {
                                id,
                            },
                            data: {
                                ...data,
                                status: BookingDeliveryStatus.returned,
                                deliveredAt: new Date(),
                            },
                        });

                    const remaining =
                        updateBookingDelivery.delivery.bookings.some(
                            (bd) => bd.status === BookingDeliveryStatus.ofd,
                        );

                    await tran.booking.update({
                        where: {
                            id: bookingDelivery?.booking.id,
                        },
                        data: {
                            status: BookingStatus.arrived,
                        },
                    });

                    await tran.bookingHistory.create({
                        data: {
                            bookingId: id,
                            action: BookingAction.return,
                            refId: bookingDelivery.deliveryId,
                            refNo: null,
                            userId,
                            locationId,
                            customer: false,
                        },
                    });

                    if (!remaining)
                        await tran.delivery.update({
                            where: {
                                id: bookingDelivery.deliveryId,
                            },
                            data: {
                                status: DeliveryStatus.complete,
                            },
                        });

                    return updateBookingDelivery;
                },
            );

            return ServiceResponse.success<BookingDelivery | null>(
                'Delivery successfully updated',
                updateDelivery,
            );
        } catch (ex) {
            const errorMessage = `Error delivering a delivery:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while delivering a delivery.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const deliveryService = new DeliveryService();
