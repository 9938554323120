import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import {
    BookingAction,
    BookingStatus,
    type Manifest,
    ManifestStatus,
    type Prisma,
    PrismaClient,
} from '@prisma/client';
import { BookingOptionViewModel } from '../booking/bookingModel';
import {
    ManifestBaggageOptionViewModel,
    type ManifestCreateDTO,
    ManifestDetailViewModel,
    ManifestListViewModel,
} from './manifestModel';

export class ManifestService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all manifests from the database
    async getAll(
        locationId: number,
    ): Promise<ServiceResponse<ManifestListViewModel[] | null>> {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 30);

        const cond: Prisma.ManifestWhereInput = {
            OR: [
                {
                    originId: locationId,
                    status: { not: ManifestStatus.received },
                    date: { gt: minDate },
                },
                {
                    destinationId: locationId,
                    status: ManifestStatus.dispatched,
                    date: { gt: minDate },
                },
            ],
        };
        try {
            const list = await this.prisma.manifest.findMany({
                include: {
                    origin: true,
                    destination: true,
                    bookings: {
                        include: {
                            items: true,
                        },
                    },
                    baggages: {
                        include: {
                            bookings: {
                                include: {
                                    items: true,
                                },
                            },
                        },
                    },
                },
                where: cond,
                orderBy: { id: 'asc' },
            });

            return ServiceResponse.success<ManifestListViewModel[]>(
                'Manifests found',
                list.map((manifest) => new ManifestListViewModel(manifest)),
            );
        } catch (ex) {
            const errorMessage = `Error getting manifests: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving manifests.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a manifest by their ID
    // TODO: ViewModel
    async getById(
        id: number,
    ): Promise<ServiceResponse<ManifestDetailViewModel | null>> {
        try {
            const bookingInclude = {
                include: {
                    origin: true,
                    destination: true,
                    items: {
                        include: {
                            productType: true,
                        },
                    },
                },
            };
            const manifest = await this.prisma.manifest.findUnique({
                include: {
                    origin: true,
                    destination: true,
                    bookings: bookingInclude,
                    baggages: {
                        include: {
                            bookings: bookingInclude,
                        },
                    },
                },
                where: { id },
            });
            if (!manifest) {
                return ServiceResponse.failure(
                    'Manifest not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<ManifestDetailViewModel>(
                'Manifest found',
                new ManifestDetailViewModel(manifest),
            );
        } catch (ex) {
            const errorMessage = `Error finding manifest with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding manifest.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async getBookingOptions(
        originId: number,
        destinationId: number,
    ): Promise<ServiceResponse<BookingOptionViewModel[] | null>> {
        try {
            const bookings = await this.prisma.booking.findMany({
                where: {
                    originId,
                    destinationId,
                    baggageId: null,
                    manifestId: null,
                },
                include: {
                    items: true,
                    origin: true,
                    destination: true,
                },
                orderBy: {
                    id: 'desc',
                },
            });
            return ServiceResponse.success<BookingOptionViewModel[]>(
                'Booking(s) found',
                bookings.map((booking) => new BookingOptionViewModel(booking)),
            );
        } catch (ex) {
            const errorMessage = `Error finding baggage options with for originId ${originId} and destinationId ${destinationId}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding baggage.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async getBaggageOptions(
        originId: number,
        destinationId: number,
    ): Promise<ServiceResponse<ManifestBaggageOptionViewModel[] | null>> {
        try {
            const baggages = await this.prisma.baggage.findMany({
                where: {
                    originId,
                    destinationId,
                    manifestId: null,
                },
                include: {
                    bookings: true,
                },
            });
            return ServiceResponse.success<ManifestBaggageOptionViewModel[]>(
                'Baggage(s) found',
                baggages.map(
                    (baggage) => new ManifestBaggageOptionViewModel(baggage),
                ),
            );
        } catch (ex) {
            const errorMessage = `Error finding baggage options with for originId ${originId} and destinationId ${destinationId}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding baggage.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single manifest
    async create(
        data: ManifestCreateDTO,
        userId: number,
        locationId: number,
    ): Promise<ServiceResponse<Manifest | null>> {
        const yearPrefix = new Date().getFullYear().toString().slice(2);
        try {
            const lastManifest = await this.prisma.manifest.findFirst({
                where: {
                    manifestNo: {
                        startsWith: yearPrefix,
                    },
                },
                orderBy: {
                    manifestNo: 'desc',
                },
            });

            const lastSeq = lastManifest
                ? Number.parseInt(lastManifest.manifestNo.slice(2))
                : 0;

            const nextSeq = lastSeq + 1;
            const manifestNo = `${yearPrefix}${nextSeq.toString().padStart(5, '0')}`;
            const manifest = await this.prisma.$transaction(async (tran) => {
                const mf = await tran.manifest.create({
                    include: {
                        baggages: {
                            include: {
                                bookings: true,
                            },
                        },
                        bookings: true,
                    },
                    data: {
                        date: data.date,
                        manifestNo,
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                        status: ManifestStatus.created,
                        origin: {
                            connect: { id: data.originId },
                        },
                        destination: {
                            connect: { id: data.destinationId },
                        },
                        bookings: {
                            connect: data.bookings.map((id) => ({ id })),
                        },
                        baggages: {
                            connect: data.baggages.map((id) => ({ id })),
                        },
                    },
                });

                const allBookings = [
                    ...mf.bookings,
                    ...mf.baggages.flatMap((bag) => bag.bookings),
                ];

                await tran.booking.updateMany({
                    where: {
                        id: {
                            in: allBookings.map((booking) => booking.id),
                        },
                    },
                    data: {
                        status: BookingStatus.manifest,
                    },
                });

                await tran.bookingHistory.createMany({
                    data: allBookings.map((booking) => ({
                        bookingId: booking.id,
                        action: BookingAction.manifest,
                        refId: mf.id,
                        refNo: mf.manifestNo,
                        userId,
                        locationId,
                        customer: false,
                    })),
                });
                return mf;
            });
            return ServiceResponse.success<Manifest>(
                'Manifest Successfully Created',
                manifest,
            );
        } catch (ex) {
            const errorMessage = `Error creating a manifest:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating manifest.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a manifest by their ID
    async update(
        id: number,
        data: Prisma.ManifestUpdateInput,
    ): Promise<ServiceResponse<Manifest | null>> {
        try {
            const manifest = await this.prisma.manifest.findUnique({
                where: { id },
            });
            if (!manifest) {
                return ServiceResponse.failure(
                    'Manifest not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateManifest = await this.prisma.manifest.update({
                where: {
                    id,
                },
                data,
            });

            return ServiceResponse.success<Manifest>(
                'Manifest successfully updated',
                updateManifest,
            );
        } catch (ex) {
            const errorMessage = `Error updating manifest with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating manifest.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const manifestService = new ManifestService();
