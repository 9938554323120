import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { type Prisma, PrismaClient, type Rate } from '@prisma/client';

export class RateService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all rates from the database
    async getAll(): Promise<ServiceResponse<Rate[] | null>> {
        try {
            const list = await this.prisma.rate.findMany({
                orderBy: { id: 'asc' },
            });

            return ServiceResponse.success<Rate[]>('Rates found', list);
        } catch (ex) {
            const errorMessage = `Error getting rates: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving rates.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a rate by their ID
    async getById(id: number): Promise<ServiceResponse<Rate | null>> {
        try {
            const rate = await this.prisma.rate.findUnique({ where: { id } });
            if (!rate) {
                return ServiceResponse.failure(
                    'Rate not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<Rate>('Rate found', rate);
        } catch (ex) {
            const errorMessage = `Error finding rate with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding rate.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single rate
    async create(
        data: Prisma.RateCreateInput,
    ): Promise<ServiceResponse<Rate | null>> {
        try {
            const rate = await this.prisma.rate.create({ data });
            return ServiceResponse.success<Rate>(
                'Rate Successfully Created',
                rate,
            );
        } catch (ex) {
            const errorMessage = `Error creating a rate:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating rate.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a rate by their ID
    async update(
        id: number,
        data: Prisma.RateUpdateInput,
    ): Promise<ServiceResponse<Rate | null>> {
        try {
            const rate = await this.prisma.rate.findUnique({ where: { id } });
            if (!rate) {
                return ServiceResponse.failure(
                    'Rate not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateRate = await this.prisma.rate.update({
                where: {
                    id,
                },
                data,
            });

            return ServiceResponse.success<Rate>(
                'Rate successfully updated',
                updateRate,
            );
        } catch (ex) {
            const errorMessage = `Error updating rate with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating rate.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const rateService = new RateService();
