import { authRouter } from '@/api/auth/authRouter';
import { baggageRouter } from '@/api/baggage/baggageRouter';
import { bookingRouter } from '@/api/booking/bookingRouter';
import { clientRouter } from '@/api/client/clientRouter';
import { deliveryRouter } from '@/api/delivery/deliveryRouter';
import { districtRouter } from '@/api/district/districtRouter';
import { healthCheckRouter } from '@/api/healthCheck/healthCheckRouter';
import { locationRouter } from '@/api/location/locationRouter';
import { lookupRouter } from '@/api/lookup/lookupRouter';
import { manifestRouter } from '@/api/manifest/manifestRouter';
import { masterManifestRouter } from '@/api/masterManifest/masterManifestRouter';
import { productTypeRouter } from '@/api/productType/productTypeRouter';
import { rateRouter } from '@/api/rate/rateRouter';
import { userRouter } from '@/api/user/userRouter';
import { roleRouter } from '@/api/role/roleRouter';
import { statementRouter } from '@/api/statement/statementRouter';
import type { Express } from 'express';

const routerConfig = (app: Express) => {
    app.use('/health-check', healthCheckRouter);
    app.use('/api/users', userRouter);
    app.use('/api/auth', authRouter);
    app.use('/api/locations', locationRouter);
    app.use('/api/districts', districtRouter);
    app.use('/api/product-types', productTypeRouter);
    app.use('/api/lookups', lookupRouter);
    app.use('/api/clients', clientRouter);
    app.use('/api/rates', rateRouter);
    app.use('/api/bookings', bookingRouter);
    app.use('/api/baggages', baggageRouter);
    app.use('/api/manifests', manifestRouter);
    app.use('/api/master-manifests', masterManifestRouter);
    app.use('/api/deliveries', deliveryRouter);
    app.use('/api/roles', roleRouter);
    app.use('/api/statements', statementRouter);
};

export default routerConfig;
