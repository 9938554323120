import type { Request, RequestHandler, Response } from 'express';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import { StatusCodes } from 'http-status-codes';
import { LoginSchema, type TokenClaim, UserRegisterSchema } from './authModel';
import { authService } from './authService';

class AuthController {
    public login: RequestHandler = async (req: Request, res: Response) => {
        const data = LoginSchema.parse(req.body);
        const serviceResponse = await authService.login(data);
        handleServiceResponse(serviceResponse, res);
    };

    public auth: RequestHandler = async (req: Request, res: Response) => {
        const serviceResponse = req.user
            ? ServiceResponse.success<TokenClaim>('Login Successful', req.user)
            : ServiceResponse.failure(
                  'Unauthorized',
                  null,
                  StatusCodes.UNAUTHORIZED,
              );
        handleServiceResponse(serviceResponse, res);
    };

    public register: RequestHandler = async (req: Request, res: Response) => {
        const data = UserRegisterSchema.parse(req.body);
        const serviceResponse = await authService.register(data);
        handleServiceResponse(serviceResponse, res);
    };

    public resetPassword: RequestHandler = async (
        _req: Request,
        res: Response,
    ) => {
        res.send('Not Implemented');
    };

    public setPassword: RequestHandler = async (
        _req: Request,
        res: Response,
    ) => {
        res.send('Not Implemented');
    };

    public validateResetToken: RequestHandler = async (
        _req: Request,
        res: Response,
    ) => {
        res.send('Not Implemented');
    };
}

export const authController = new AuthController();
