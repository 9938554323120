import { UnitSchema } from '@/zod';
import type { Unit } from '@prisma/client';
import { z } from 'zod';

export class ProductTypeListViewModel {
    id: number;
    name: string;
    unit: Unit;

    constructor(productType: {
        id: number;
        name: string;
        unit: Unit;
    }) {
        this.id = productType.id;
        this.name = productType.name;
        this.unit = productType.unit;
    }
}

export const ProductTypeCreateDTOSchema = z.object({
    name: z.string().max(100),
    unit: UnitSchema,
});

export type ProductTypeCreateDTO = z.infer<typeof ProductTypeCreateDTOSchema>;
