import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { type Client, PrismaClient } from '@prisma/client';
import { BookingStatementViewModel } from '../booking/bookingModel';
import type {
    ClientCreateDTO,
    ClientStatementFetchDTO,
    ClientUpdateDTO,
} from './clientModel';

export class ClientService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all clients from the database
    async getAll(): Promise<ServiceResponse<Client[] | null>> {
        try {
            const list = await this.prisma.client.findMany({
                orderBy: { id: 'asc' },
            });

            return ServiceResponse.success<Client[]>('Clients found', list);
        } catch (ex) {
            const errorMessage = `Error getting clients: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving clients.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a client by their ID
    async getById(id: number): Promise<ServiceResponse<Client | null>> {
        try {
            const client = await this.prisma.client.findUnique({
                where: { id },
            });
            if (!client) {
                return ServiceResponse.failure(
                    'Client not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<Client>('Client found', client);
        } catch (ex) {
            const errorMessage = `Error finding client with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding client.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async getStatement(
        id: number,
        data: ClientStatementFetchDTO,
    ): Promise<ServiceResponse<BookingStatementViewModel[] | null>> {
        try {
            const [fromDate, toDate] = data.params.dateRange;
            toDate.setDate(toDate.getDate() + 1);

            const client = await this.prisma.client.findUnique({
                include: {
                    bookings: {
                        include: {
                            origin: true,
                            destination: true,
                            items: {
                                include: {
                                    productType: true,
                                },
                            },
                        },
                        where: {
                            statementId: null,
                            bookingDate: {
                                gte: fromDate,
                                lt: toDate,
                            },
                        },
                    },
                },
                where: { id },
            });
            if (!client) {
                return ServiceResponse.failure(
                    'Client not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }

            return ServiceResponse.success<BookingStatementViewModel[]>(
                'Bookings found',
                client.bookings.map((b) => new BookingStatementViewModel(b)),
            );
        } catch (ex) {
            const errorMessage = `Error finding client with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding client.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single client
    async create(
        data: ClientCreateDTO,
        userId: number,
    ): Promise<ServiceResponse<Client | null>> {
        try {
            const client = await this.prisma.client.create({
                data: {
                    ...data,
                    locationId: undefined,
                    createdBy: {
                        connect: {
                            id: userId,
                        },
                    },
                    location: {
                        connect: {
                            id: data.locationId,
                        },
                    },
                    status: true,
                },
            });
            return ServiceResponse.success<Client>(
                'Client Successfully Created',
                client,
            );
        } catch (ex) {
            const errorMessage = `Error creating a client:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating client.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a client by their ID
    async update(
        id: number,
        data: ClientUpdateDTO,
    ): Promise<ServiceResponse<Client | null>> {
        try {
            const client = await this.prisma.client.findUnique({
                where: { id },
            });
            if (!client) {
                return ServiceResponse.failure(
                    'Client not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateClient = await this.prisma.client.update({
                where: {
                    id,
                },
                data: {
                    ...data,
                    locationId: undefined,
                    ...(data.locationId && {
                        location: {
                            connect: { id: data.locationId },
                        },
                    }),
                },
            });

            return ServiceResponse.success<Client>(
                'Client successfully updated',
                updateClient,
            );
        } catch (ex) {
            const errorMessage = `Error updating client with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating client.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const clientService = new ClientService();
