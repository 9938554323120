import { type BaseModel, BaseSchema } from '@/common/models/commonModel';
import { z } from 'zod';

export class UserListViewModel {
    id: number;
    userName: string;
    name: string;
    email: string | null;
    phone: string;
    status: boolean;
    role: BaseModel | null;
    location: BaseModel | null;

    constructor(user: {
        id: number;
        firstName: string | null;
        userName: string;
        middleName: string | null;
        lastName: string | null;
        email: string | null;
        phone: string;
        status: boolean;
        role: BaseModel | null;
        location: BaseModel | null;
    }) {
        this.id = user.id;
        this.userName = user.userName;
        this.name = [user.firstName, user.middleName, user.lastName]
            .filter((x) => x)
            .join(' ');
        this.email = user.email;
        this.phone = user.phone;
        this.status = user.status;
        this.role = user.role;
        this.location = user.location;
    }
}

export const UserListSchema = z.object({
    id: z.number(),
    firstName: z.string().nullable(),
    middleName: z.string().nullable(),
    lastName: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string(),
    status: z.boolean(),
    role: BaseSchema.nullable(),
    location: BaseSchema.nullable(),
});
