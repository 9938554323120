import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { type Prisma, PrismaClient, type ProductType } from '@prisma/client';
import {
    type ProductTypeCreateDTO,
    ProductTypeListViewModel,
} from './productTypeModel';

export class ProductTypeService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all productTypes from the database
    async getAll(): Promise<
        ServiceResponse<ProductTypeListViewModel[] | null>
    > {
        try {
            const list = await this.prisma.productType.findMany({
                orderBy: { id: 'asc' },
            });

            if (!list || list.length === 0) {
                return ServiceResponse.failure(
                    'No productType found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<ProductTypeListViewModel[]>(
                'ProductTypes found',
                list.map(
                    (productType) => new ProductTypeListViewModel(productType),
                ),
            );
        } catch (ex) {
            const errorMessage = `Error getting productTypes: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving productTypes.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a productType by their ID
    async getById(id: number): Promise<ServiceResponse<ProductType | null>> {
        try {
            const productType = await this.prisma.productType.findUnique({
                where: { id },
            });
            if (!productType) {
                return ServiceResponse.failure(
                    'ProductType not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<ProductType>(
                'ProductType found',
                productType,
            );
        } catch (ex) {
            const errorMessage = `Error finding productType with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding productType.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single productType
    async create(
        data: ProductTypeCreateDTO,
        userId: number,
    ): Promise<ServiceResponse<ProductType | null>> {
        try {
            const productType = await this.prisma.productType.create({
                data: {
                    ...data,
                    status: true,
                    createdBy: {
                        connect: {
                            id: userId,
                        },
                    },
                },
            });
            return ServiceResponse.success<ProductType>(
                'ProductType Successfully Created',
                productType,
            );
        } catch (ex) {
            const errorMessage = `Error creating a productType:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating productType.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a productType by their ID
    async update(
        id: number,
        data: Prisma.ProductTypeUpdateInput,
    ): Promise<ServiceResponse<ProductType | null>> {
        try {
            const productType = await this.prisma.productType.findUnique({
                where: { id },
            });
            if (!productType) {
                return ServiceResponse.failure(
                    'ProductType not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateProductType = await this.prisma.productType.update({
                where: {
                    id,
                },
                data,
            });

            return ServiceResponse.success<ProductType>(
                'ProductType successfully updated',
                updateProductType,
            );
        } catch (ex) {
            const errorMessage = `Error updating productType with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating productType.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const productTypeService = new ProductTypeService();
