import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { validateRequest } from '@/common/utils/httpHandlers';
import { UserSchema } from '@/zod';
import { authController } from './authController';
import { LoginSchema, UserRegisterSchema } from './authModel';

extendZodWithOpenApi(z);

export const authRegistry = new OpenAPIRegistry();
export const authRouter: Router = express.Router();

authRegistry.register('Auth', UserSchema);

authRouter.get('/', isAuthenticated, authController.auth);
authRouter.post('/login', authController.login);

authRegistry.registerPath({
    method: 'post',
    path: '/api/auth/login',
    tags: ['Auth'],
    request: {
        body: {
            content: {
                'application/json': {
                    schema: LoginSchema,
                },
            },
        },
    },
    responses: createApiResponse(LoginSchema, 'Success'),
});

const RegisterSchema = z.object({
    body: UserRegisterSchema,
});

authRouter.post(
    '/register',
    isAuthenticated,
    validateRequest(RegisterSchema),
    authController.register,
);

authRouter.put('/reset-password', authController.resetPassword); // change later
authRouter.post('/set-password', authController.setPassword);
authRouter.get('/validate-token/:token', authController.validateResetToken);
