import dotenv from 'dotenv';
import {
    url,
    bool,
    cleanEnv,
    email,
    host,
    num,
    port,
    str,
    testOnly,
} from 'envalid';

dotenv.config();

const webUrl = 'http://localhost:8000';

export const env = cleanEnv(process.env, {
    NODE_ENV: str({
        devDefault: testOnly('test'),
        choices: ['development', 'production', 'test'],
    }),
    HOST: host({ devDefault: testOnly('localhost') }),
    PORT: port({ devDefault: testOnly(3000) }),
    APP_NAME: str({ devDefault: testOnly('XenLogistics') }),
    WEB_URL: url({ devDefault: testOnly(webUrl) }),
    CORS_ALLOWED_ORIGIN: url({ devDefault: testOnly(webUrl) }),
    RATE_LIMIT_MAX_REQUESTS: num({ devDefault: testOnly(1000) }),
    RATE_LIMIT_WINDOW_MS: num({ devDefault: testOnly(1000) }),
    // Sentry
    SENTRY_DSN: url({ devDefault: testOnly('https://xxx.sentrio.io/key') }),

    // JWT
    JWT_SECRET_KEY: str({ devDefault: testOnly('C9UrOyc31WW6asWwk8sc') }),

    // Database
    DB_DIALECT: str({
        devDefault: testOnly('mysql'),
        choices: ['mysql', 'postgres', 'sqlite', 'sqlserver', 'cockroachdb'],
    }),
    DB_NAME: str({ devDefault: testOnly('courier_db') }),
    DB_USER: str(),
    DB_PASSWORD: str(),
    DB_HOST: str({ devDefault: testOnly('localhost') }),
    DB_PORT: port({ devDefault: testOnly(3306) }),
    DB_SSL: bool({ devDefault: testOnly(false) }),
    DATABASE_URL: str({
        devDefault: testOnly(
            'postgresql://user:password@localhost:5432/db_name?schema=public',
        ),
    }),

    // Email
    SMTP_HOST: str({ devDefault: testOnly('mail.xenith.com.np') }),
    SMTP_PORT: port({ devDefault: testOnly(465) }),
    SMTP_USER: email({ devDefault: testOnly('noreply@xenith.com.np') }),
    SMTP_PASSWORD: str(),
    DEFAULT_MAIL_SENDER: str({
        devDefault: testOnly('"Xenith Tech <noreply@xenith.com.np>"'),
    }),

    UPLOAD_PATH: str({ devDefault: testOnly('uploads') }),
    COMPANY_NAME: str({ devDefault: testOnly('Xenith Tech Private Limited') }),
    COMPANY_ADDRESS: str({
        devDefault: testOnly('Mid Baneshwor, Kathmandu - 10'),
    }),
    COMPANY_PAN: str({
        devDefault: testOnly('610490235'),
    }),
});
