import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { ClientSchema } from '@/zod';
import { clientController } from './clientController';
import {
    ClientCreateDTOSchema,
    ClientStatementFetchDTOSchema,
    ClientUpdateDTOSchema,
} from './clientModel';

extendZodWithOpenApi(z);

export const clientRegistry = new OpenAPIRegistry();
export const clientRouter: Router = express.Router();

clientRegistry.register('Client', ClientSchema);

clientRegistry.registerPath({
    method: 'get',
    path: '/api/clients',
    tags: ['Client'],
    responses: createApiResponse(z.array(ClientSchema), 'Success'),
});

clientRouter.get('/', isAuthenticated, clientController.getAll);

clientRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    clientController.getById,
);

const StatementSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: ClientStatementFetchDTOSchema,
});

clientRouter.post(
    '/:id/statement',
    isAuthenticated,
    validateRequest(StatementSchema),
    clientController.getStatement,
);

const CreateSchema = z.object({
    body: ClientCreateDTOSchema,
});

clientRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    clientController.create,
);

export const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: ClientUpdateDTOSchema,
});

clientRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    clientController.update,
);
