import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { ProductTypeSchema, ProductTypeUpdateInputSchema } from '@/zod';
import { productTypeController } from './productTypeController';
import { ProductTypeCreateDTOSchema } from './productTypeModel';

extendZodWithOpenApi(z);

export const productTypeRegistry = new OpenAPIRegistry();
export const productTypeRouter: Router = express.Router();

productTypeRegistry.register('ProductType', ProductTypeSchema);

productTypeRegistry.registerPath({
    method: 'get',
    path: '/api/productTypes',
    tags: ['ProductType'],
    responses: createApiResponse(z.array(ProductTypeSchema), 'Success'),
});

productTypeRouter.get('/', isAuthenticated, productTypeController.getAll);

productTypeRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    productTypeController.getById,
);

const CreateSchema = z.object({
    body: ProductTypeCreateDTOSchema,
});

productTypeRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    productTypeController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: ProductTypeUpdateInputSchema,
});

productTypeRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    productTypeController.update,
);
