import type { Request, RequestHandler, Response } from 'express';

import { roleService } from '@/api/role/roleService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';

class RoleController {
    public getAll: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await roleService.getAll();
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await roleService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = req.body as Prisma.RoleCreateInput;
        const serviceResponse = await roleService.create(data);
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.RoleUpdateInput;
        const serviceResponse = await roleService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const roleController = new RoleController();
