import type { Request, RequestHandler, Response } from 'express';

import { userService } from '@/api/user/userService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';

class UserController {
    public getAll: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await userService.getAll();
        handleServiceResponse(serviceResponse, res);
    };

    public getInCurrentLocation: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const locationId = req.user!.locationId;
        const serviceResponse = await userService.getInLocation(locationId);
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await userService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await userService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };
}

export const userController = new UserController();
