import type { BaseModel } from '@/common/models/commonModel';
import {
    BookingDeliveryStatus,
    type DeliveryStatus,
    type PaymentMode,
    type Prisma,
} from '@prisma/client';
import { z } from 'zod';
import { BookingOptionViewModel } from '../booking/bookingModel';
import { Lookup } from '../lookup/lookupModel';

export const MAX_IMAGE_SIZE = 5_000_000;
export const ACCEPTED_IMAGE_TYPES = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/webp',
];

export const DeliveryCreateDTOSchema = z.object({
    deliveredById: z.number(),
    bookings: z
        .array(z.number())
        .min(1, { message: 'At least one booking must be selected' }),
});

export type DeliveryCreateDTO = z.infer<typeof DeliveryCreateDTOSchema>;

export const DeliveryDeliverDTOSchema = z.object({
    receivedBy: z.string(),
});

export interface DeliveryDeliverDTO {
    receivedBy: string;
    podPath: string;
}

export const DeliveryReturnDTOSchema = z.object({
    comment: z.string(),
});

export type DeliveryReturnDTO = z.infer<typeof DeliveryReturnDTOSchema>;

export class DeliveryListViewModel {
    readonly id: number;
    readonly date: string;
    readonly deliveredBy: BaseModel;
    readonly bookingCount: number;
    readonly returnCount: number;
    readonly deliveredCount: number;
    readonly status: DeliveryStatus;
    constructor(
        delivery: Prisma.DeliveryGetPayload<{
            include: { deliveredBy: true; bookings: true };
        }>,
    ) {
        this.id = delivery.id;
        this.date = delivery.date.toISOString();
        this.deliveredBy = {
            id: delivery.deliveredBy.id,
            name: [
                delivery.deliveredBy.firstName,
                delivery.deliveredBy.middleName,
                delivery.deliveredBy.lastName,
            ]
                .filter((x) => x)
                .join(' '),
        };
        this.bookingCount = delivery.bookings.length;
        this.returnCount = delivery.bookings.filter(
            (booking) => booking.status === BookingDeliveryStatus.returned,
        ).length;
        this.deliveredCount = delivery.bookings.filter(
            (booking) => booking.status === BookingDeliveryStatus.delivered,
        ).length;
        this.status = delivery.status;
    }
}

class BookingDeliveryListViewModel {
    readonly id: number;
    readonly bookingId: number;
    readonly status: BookingDeliveryStatus;
    readonly booking: BookingOptionViewModel;
    constructor(
        bd: Prisma.BookingDeliveryGetPayload<{
            include: {
                booking: {
                    include: {
                        origin: true;
                        destination: true;
                        items: true;
                    };
                };
            };
        }>,
    ) {
        this.id = bd.id;
        this.bookingId = bd.bookingId;
        this.booking = new BookingOptionViewModel(bd.booking);
        this.status = bd.status;
    }
}

export class DeliveryDetailViewModel {
    readonly id: number;
    readonly date: string;
    readonly deliveredBy: BaseModel;
    readonly bookingCount: number;
    readonly returnCount: number;
    readonly deliveredCount: number;
    readonly status: DeliveryStatus;
    readonly bookings: BookingDeliveryListViewModel[];
    constructor(
        delivery: Prisma.DeliveryGetPayload<{
            include: {
                deliveredBy: true;
                bookings: {
                    include: {
                        booking: {
                            include: {
                                origin: true;
                                destination: true;
                                items: true;
                            };
                        };
                    };
                };
            };
        }>,
    ) {
        this.id = delivery.id;
        this.date = delivery.date.toISOString();
        this.deliveredBy = {
            id: delivery.deliveredBy.id,
            name: [
                delivery.deliveredBy.firstName,
                delivery.deliveredBy.middleName,
                delivery.deliveredBy.lastName,
            ]
                .filter((x) => x)
                .join(' '),
        };
        this.bookingCount = delivery.bookings.length;
        this.returnCount = delivery.bookings.filter(
            (booking) => booking.status === BookingDeliveryStatus.returned,
        ).length;
        this.deliveredCount = delivery.bookings.filter(
            (booking) => booking.status === BookingDeliveryStatus.delivered,
        ).length;
        this.status = delivery.status;
        this.bookings = delivery.bookings.map(
            (bd) => new BookingDeliveryListViewModel(bd),
        );
    }
}

export class DeliveryBookingOptionViewModel {
    readonly id: number;
    readonly consignmentNo: string;
    readonly senderName: string;
    readonly origin: BaseModel;
    readonly destination: BaseModel;
    readonly receiverName: string;
    readonly piece: number;
    readonly weight: number;
    readonly paymentMode: PaymentMode;
    readonly paymentModeName: string;
    readonly totAmt: number;
    readonly remarks: string | null;
    constructor(
        booking: Prisma.BookingGetPayload<{
            include: {
                origin: true;
                destination: true;
                items: true;
                deliveries?: true;
            };
        }>,
    ) {
        this.id = booking.id;
        this.consignmentNo = booking.consignmentNo;
        this.senderName = booking.senderName;
        this.origin = {
            id: booking.origin.id,
            name: booking.origin.name,
        };
        this.receiverName = booking.receiverName;
        this.destination = {
            id: booking.destination.id,
            name: booking.destination.name,
        };
        this.piece = booking.items.reduce((a, b) => a + b.piece, 0);
        this.weight = booking.items.reduce(
            (a, b) => a + b.weight.toNumber(),
            0,
        );
        this.paymentMode = booking.paymentMode;
        this.paymentModeName = Lookup.paymentMode[booking.paymentMode].label;
        this.totAmt = booking.totAmt.toNumber();
        this.remarks =
            booking.deliveries && booking.deliveries.length !== 0
                ? `Returned: ${booking.deliveries.map((bd) => bd.comment).join(' ')}`
                : null;
    }
}
