import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { BookingSchema } from '@/zod';
import { bookingController } from './bookingController';
import {
    BookingCommentDTOSchema,
    BookingCreateDTOSchema,
    BookingUpdateDTOSchema,
} from './bookingModel';

extendZodWithOpenApi(z);

export const bookingRegistry = new OpenAPIRegistry();
export const bookingRouter: Router = express.Router();

bookingRegistry.register('Booking', BookingSchema);

bookingRegistry.registerPath({
    method: 'get',
    path: '/api/bookings',
    tags: ['Booking'],
    responses: createApiResponse(z.array(BookingSchema), 'Success'),
});

bookingRouter.get('/', isAuthenticated, bookingController.getAll);

const TrackSchema = z.object({
    params: z.object({
        consignmentNo: z
            .string()
            .regex(/^[1-9][0-9]{8}$/, 'Invalid Consignment No'),
    }),
});

bookingRouter.get(
    '/track/:consignmentNo',
    isAuthenticated,
    validateRequest(TrackSchema),
    bookingController.track,
);

bookingRouter.get(
    '/booking-track/:consignmentNo',
    validateRequest(TrackSchema),
    bookingController.publicTrack,
);

bookingRouter.get(
    '/:id/history',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    bookingController.history,
);

bookingRouter.get(
    '/:id/public-history',
    validateRequest(GetByIdSchema),
    bookingController.publicHistory,
);

bookingRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    bookingController.getById,
);

const CommentSchema = z.object({
    body: BookingCommentDTOSchema,
});

bookingRouter.post(
    '/:id/comment',
    // isAuthenticated,
    validateRequest(CommentSchema),
    bookingController.comment,
);

const CreateSchema = z.object({
    body: BookingCreateDTOSchema,
});

bookingRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    bookingController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: BookingUpdateDTOSchema,
});

bookingRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    bookingController.update,
);
