import type { Request, RequestHandler, Response } from 'express';

import { deliveryService } from '@/api/delivery/deliveryService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';
import type {
    DeliveryCreateDTO,
    DeliveryDeliverDTO,
    DeliveryReturnDTO,
} from './deliveryModel';

class DeliveryController {
    public getAll: RequestHandler = async (req: Request, res: Response) => {
        const locationId = req.user!.locationId;
        const serviceResponse = await deliveryService.getAll(locationId);
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await deliveryService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public getBookingOptions: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const locationId = req.user!.locationId;
        const serviceResponse =
            await deliveryService.getBookingOptions(locationId);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = req.body as DeliveryCreateDTO;
        const userId = req.user!.id;
        const locationId = req.user!.locationId;
        const serviceResponse = await deliveryService.create(
            data,
            userId,
            locationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.DeliveryUpdateInput;
        const serviceResponse = await deliveryService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };

    public deliver: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data: DeliveryDeliverDTO = {
            receivedBy: req.body.receivedBy,
            podPath: req.file!.filename,
        };
        const { id: userId, locationId } = req.user!;
        const serviceResponse = await deliveryService.deliver(
            id,
            data,
            userId,
            locationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public return: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as DeliveryReturnDTO;
        const { id: userId, locationId } = req.user!;
        const serviceResponse = await deliveryService.return(
            id,
            data,
            userId,
            locationId,
        );
        handleServiceResponse(serviceResponse, res);
    };
}

export const deliveryController = new DeliveryController();
