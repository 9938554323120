import type { BaseModel } from '@/common/models/commonModel';
import type { Prisma } from '@prisma/client';
import { z } from 'zod';
import { BookingDetailViewModel } from '../booking/bookingModel';

export const BaggageCreateDTOSchema = z.object({
    originId: z.number(),
    destinationId: z.number(),
    bookings: z
        .array(z.number())
        .min(1, { message: 'At least one booking must be selected' }),
});

export type BaggageCreateDTO = z.infer<typeof BaggageCreateDTOSchema>;

export class BaggageDetailViewModel {
    readonly id: number;
    readonly baggageNo: string;
    readonly origin: BaseModel;
    readonly destination: BaseModel;
    readonly date: Date;
    readonly bookings: BookingDetailViewModel[];
    constructor(
        bgg: Prisma.BaggageGetPayload<{
            include: {
                origin: true;
                destination: true;
                bookings: {
                    include: {
                        origin: true;
                        destination: true;
                        items: {
                            include: {
                                productType: true;
                            };
                        };
                        client: true;
                    };
                };
            };
        }>,
    ) {
        this.id = bgg.id;
        this.baggageNo = bgg.baggageNo;
        this.origin = {
            id: bgg.origin.id,
            name: bgg.destination.name,
        };
        this.destination = {
            id: bgg.destination?.id,
            name: bgg.destination?.name,
        };
        this.date = bgg.createdAt;
        this.bookings = bgg.bookings.map(
            (booking) => new BookingDetailViewModel(booking),
        );
    }
}
