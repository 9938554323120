import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { type District, type Prisma, PrismaClient } from '@prisma/client';

export class DistrictService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all districts from the database
    async getAll(): Promise<ServiceResponse<District[] | null>> {
        try {
            const list = await this.prisma.district.findMany({
                orderBy: { id: 'asc' },
            });

            if (!list || list.length === 0) {
                return ServiceResponse.failure(
                    'No district found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<District[]>('Districts found', list);
        } catch (ex) {
            const errorMessage = `Error getting districts: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving districts.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a district by their ID
    async getById(id: number): Promise<ServiceResponse<District | null>> {
        try {
            const district = await this.prisma.district.findUnique({
                where: { id },
            });
            if (!district) {
                return ServiceResponse.failure(
                    'District not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<District>(
                'District found',
                district,
            );
        } catch (ex) {
            const errorMessage = `Error finding district with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding district.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single district
    async create(
        data: Prisma.DistrictCreateInput,
    ): Promise<ServiceResponse<District | null>> {
        try {
            const district = await this.prisma.district.create({ data });
            return ServiceResponse.success<District>(
                'District Successfully Created',
                district,
            );
        } catch (ex) {
            const errorMessage = `Error creating a district:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating district.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a district by their ID
    async update(
        id: number,
        data: Prisma.DistrictUpdateInput,
    ): Promise<ServiceResponse<District | null>> {
        try {
            const district = await this.prisma.district.findUnique({
                where: { id },
            });
            if (!district) {
                return ServiceResponse.failure(
                    'District not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateDistrict = await this.prisma.district.update({
                where: {
                    id,
                },
                data,
            });

            return ServiceResponse.success<District>(
                'District successfully updated',
                updateDistrict,
            );
        } catch (ex) {
            const errorMessage = `Error updating district with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating district.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const districtService = new DistrictService();
