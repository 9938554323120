import type { Request, RequestHandler, Response } from 'express';

import { baggageService } from '@/api/baggage/baggageService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';
import type { BaggageCreateDTO } from './BaggageModel';

class BaggageController {
    public getAll: RequestHandler = async (req: Request, res: Response) => {
        const locationId = req.user!.locationId;
        const serviceResponse = await baggageService.getAll(locationId);
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await baggageService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public getBookingOptions: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const originId = Number.parseInt(req.query.originId as string, 10);
        const destinationId = Number.parseInt(
            req.query.destinationId as string,
            10,
        );
        const serviceResponse = await baggageService.getBookingOptions(
            originId,
            destinationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = req.body as BaggageCreateDTO;
        const userId = req.user!.id;
        const locationId = req.user!.locationId;
        const serviceResponse = await baggageService.create(
            data,
            userId,
            locationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.BaggageUpdateInput;
        const serviceResponse = await baggageService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const baggageController = new BaggageController();
