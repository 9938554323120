import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { PrismaClient, type User } from '@prisma/client';
import { UserListViewModel } from './userModel';

export class UserService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all users from the database
    async getAll(): Promise<ServiceResponse<UserListViewModel[] | null>> {
        try {
            const users = await this.prisma.user.findMany({
                orderBy: { id: 'desc' },
                omit: {
                    password: true,
                },
                include: {
                    role: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                    location: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                },
                where: {
                    clientId: null,
                },
            });

            logger.info(users);

            if (!users || users.length === 0) {
                return ServiceResponse.failure(
                    'No Users found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<UserListViewModel[]>(
                'Users found',
                users.map((user) => new UserListViewModel(user)),
            );
        } catch (ex) {
            const errorMessage = `Error getting users: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving users.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves all users in a location
    async getInLocation(
        locationId: number,
    ): Promise<ServiceResponse<UserListViewModel[] | null>> {
        try {
            const users = await this.prisma.user.findMany({
                orderBy: { id: 'desc' },
                omit: {
                    password: true,
                },
                include: {
                    role: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                    location: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                },
                where: {
                    clientId: null,
                    locationId,
                },
            });

            logger.info(users);

            if (!users || users.length === 0) {
                return ServiceResponse.failure(
                    'No Users found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<UserListViewModel[]>(
                'Users found',
                users.map((user) => new UserListViewModel(user)),
            );
        } catch (ex) {
            const errorMessage = `Error getting users: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving users.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a single user by their ID
    async getById(id: number): Promise<ServiceResponse<User | null>> {
        try {
            const user = await this.prisma.user.findUnique({ where: { id } });
            if (!user) {
                return ServiceResponse.failure(
                    'User not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<User>('User found', user);
        } catch (ex) {
            const errorMessage = `Error finding user with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding user.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a user by their ID
    async update(id: number): Promise<ServiceResponse<User | null>> {
        try {
            const user = await this.prisma.user.findUnique({
                where: { id },
                include: {
                    role: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                    location: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                },
            });
            if (!user) {
                return ServiceResponse.failure(
                    'User not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<User>('User found', user);
        } catch (ex) {
            const errorMessage = `Error finding user with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding user.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const userService = new UserService();
