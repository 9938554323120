import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { MasterManifestSchema, MasterManifestUpdateInputSchema } from '@/zod';
import { masterManifestController } from './masterManifestController';
import { MasterManifestCreateDTOSchema } from './masterManifestModel';

extendZodWithOpenApi(z);

export const masterManifestRegistry = new OpenAPIRegistry();
export const masterManifestRouter: Router = express.Router();

masterManifestRegistry.register('MasterManifest', MasterManifestSchema);

masterManifestRegistry.registerPath({
    method: 'get',
    path: '/api/master-manifests',
    tags: ['MasterManifest'],
    responses: createApiResponse(z.array(MasterManifestSchema), 'Success'),
});

masterManifestRouter.get('/', isAuthenticated, masterManifestController.getAll);

const GetMasterManifestOptionsSchema = z.object({
    query: z.object({
        originId: commonValidations.id,
        destinationId: commonValidations.id,
    }),
});

masterManifestRouter.get(
    '/manifest-options',
    isAuthenticated,
    validateRequest(GetMasterManifestOptionsSchema),
    masterManifestController.getManifestOptions,
);

masterManifestRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    masterManifestController.getById,
);

const CreateSchema = z.object({
    body: MasterManifestCreateDTOSchema,
});

masterManifestRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    masterManifestController.create,
);

masterManifestRouter.post(
    '/:id/dispatch',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    masterManifestController.dispatch,
);

masterManifestRouter.post(
    '/:id/receive',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    masterManifestController.receive,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: MasterManifestUpdateInputSchema,
});

masterManifestRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    masterManifestController.update,
);
