import type { BaseModel } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import type { Prisma } from '@prisma/client';
import { z } from 'zod';
import { ManifestListViewModel } from '../manifest/manifestModel';

export const MasterManifestCreateDTOSchema = z.object({
    originId: z.number(),
    destinationId: z.number(),
    date: commonValidations.dateNullable.optional(),
    manifests: z
        .array(z.number())
        .min(1, { message: 'At least one Manifest must be selected' }),
    vehicleNo: z.string().max(20),
    driverName: commonValidations.name,
    mobileNo: commonValidations.contact,
    freight: commonValidations.amount,
    advance: commonValidations.amount.nullable().optional(),
    balance: commonValidations.amount,
});

export type MansterManifestCreateDTO = z.infer<
    typeof MasterManifestCreateDTOSchema
>;

export class MasterManifestDetailViewModel {
    readonly id: number;
    readonly masterManifestNo: string;
    readonly origin: BaseModel;
    readonly destination: BaseModel;
    readonly date: Date;
    readonly manifests: ManifestListViewModel[];
    readonly vehicleNo: string;
    readonly driverName: string;
    readonly mobileNo: string;
    readonly freight: number;
    readonly advance: number;
    readonly balance: number;
    constructor(
        masterManifest: Prisma.MasterManifestGetPayload<{
            include: {
                manifests: {
                    include: {
                        origin: true;
                        destination: true;
                        baggages: {
                            include: {
                                bookings: {
                                    include: {
                                        items: true;
                                    };
                                };
                            };
                        };
                        bookings: {
                            include: {
                                items: true;
                            };
                        };
                    };
                };
                origin: true;
                destination: true;
            };
        }>,
    ) {
        this.id = masterManifest.id;
        this.masterManifestNo = masterManifest.masterManifestNo;
        this.origin = {
            id: masterManifest.origin.id,
            name: masterManifest.origin.name,
        };
        this.destination = {
            id: masterManifest.destination.id,
            name: masterManifest.destination.name,
        };
        this.date = masterManifest.date;
        this.manifests = masterManifest.manifests.map(
            (manifest) => new ManifestListViewModel(manifest),
        );
        this.vehicleNo = masterManifest.vehicleNo;
        this.driverName = masterManifest.driverName;
        this.mobileNo = masterManifest.mobileNo;
        this.freight = masterManifest.freight;
        this.advance = masterManifest.advance;
        this.balance = masterManifest.balance;
    }
}
