import type { Request, RequestHandler, Response } from 'express';

import { locationService } from '@/api/location/locationService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import { logger } from '@/server';
import type { Prisma } from '@prisma/client';
import type { LocationUpdateDTO } from './locationRouter';

class LocationController {
    public getAll: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await locationService.getAll();
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        logger.warn(`by id: >>${req.params.id}`);
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await locationService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public getClients: RequestHandler = async (req: Request, res: Response) => {
        logger.warn(`clients by id: >>${req.params.id}`);
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await locationService.getClients(id);
        handleServiceResponse(serviceResponse, res);
    };

    public search: RequestHandler = async (req: Request, res: Response) => {
        const query = req.params.query as string;
        const serviceResponse = await locationService.search(query);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = req.body as Prisma.LocationCreateInput;
        const serviceResponse = await locationService.create(data);
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as LocationUpdateDTO;
        const serviceResponse = await locationService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const locationController = new LocationController();
