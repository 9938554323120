import type { Request, RequestHandler, Response } from 'express';

import { lookupService } from '@/api/lookup/lookupService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';

class LookupController {
    public getUnits: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await lookupService.getUnits();
        handleServiceResponse(serviceResponse, res);
    };
    public getPaymentModes: RequestHandler = async (
        _req: Request,
        res: Response,
    ) => {
        const serviceResponse = await lookupService.getPaymentModes();
        handleServiceResponse(serviceResponse, res);
    };
    public getServiceTypes: RequestHandler = async (
        _req: Request,
        res: Response,
    ) => {
        const serviceResponse = await lookupService.getServiceTypes();
        handleServiceResponse(serviceResponse, res);
    };
    public getDeliveryModes: RequestHandler = async (
        _req: Request,
        res: Response,
    ) => {
        const serviceResponse = await lookupService.getDeliveryModes();
        handleServiceResponse(serviceResponse, res);
    };
}

export const lookupController = new LookupController();
