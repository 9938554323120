export const formatter = {
    money: (val: number): string => {
        const fixed = val.toFixed(2);
        const [int, dec] = fixed.split('.');

        const formattedInt =
            int.length <= 3
                ? int
                : `${int.slice(0, -3).replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${int.slice(-3)}`;
        return `${formattedInt}.${dec}`;
    },
};
