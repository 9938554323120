import type { Request, RequestHandler, Response } from 'express';

import { statementService } from '@/api/statement/statementService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';
import { StatusCodes } from 'http-status-codes';
import { printStatement } from './pdf';
import { StatementCreateDTOSchema } from './statementModel';

class StatementController {
    public getAll: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await statementService.getAll();
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await statementService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = StatementCreateDTOSchema.parse(req.body);
        const userId = req.user!.id;
        const serviceResponse = await statementService.create(data, userId);
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.StatementUpdateInput;
        const serviceResponse = await statementService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };

    public print: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await statementService.getById(id);

        if (serviceResponse.success) {
            try {
                const binary = await printStatement(serviceResponse.data!);
                res.contentType('application/pdf');
                res.send(binary);
            } catch (ex) {
                res.status(StatusCodes.INTERNAL_SERVER_ERROR).send(
                    'An error has occured while generating Report',
                );
            }
        }

        // handleServiceResponse(serviceResponse, res);
    };
}

export const statementController = new StatementController();
