import { commonValidations } from '@/common/utils/commonValidation';
import type { Prisma } from '@prisma/client';
import { z } from 'zod';

export const ClientCreateDTOSchema = z.object({
    name: z
        .string()
        .max(150, { message: 'Name cannot be longer than 150 characters' }),
    contactPerson: z.string().max(150, {
        message: 'Contact Person cannot be longer than 150 characters',
    }),
    contactNo: z
        .string()
        .regex(/^[-+ 0-9]{5,15}$/, { message: 'Invalid Contact Number' }),
    email: z.string().email().optional(),
    address: z
        .string()
        .max(500, { message: 'Address cannot be longer than 150 characters' })
        .optional(),
    pan: z
        .string()
        .regex(/^[1-9][0-9]{8}$/, {
            message: 'PAN number should be 9 digit number',
        })
        .optional(),
    locationId: z.number().optional(),
});

export type ClientCreateDTO = z.infer<typeof ClientCreateDTOSchema>;

export const ClientUpdateDTOSchema = ClientCreateDTOSchema;

export type ClientUpdateDTO = z.infer<typeof ClientUpdateDTOSchema>;

export const ClientStatementFetchDTOSchema = z.object({
    params: z.object({
        dateRange: z.array(commonValidations.date).length(2),
    }),
});

export type ClientStatementFetchDTO = z.infer<
    typeof ClientStatementFetchDTOSchema
>;

export class ClientInfoViewModel {
    readonly id: number;
    readonly name: string;
    readonly address: string;
    readonly pan: string;

    constructor(
        client: Prisma.ClientGetPayload<{ include: { location: true } }>,
    ) {
        this.id = client.id;
        this.name = client.name;
        this.address = client.address;
        this.pan = client.pan;
    }
}
