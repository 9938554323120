import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import {
    DistrictCreateInputSchema,
    DistrictSchema,
    DistrictUpdateInputSchema,
} from '@/zod';
import { districtController } from './districtController';

extendZodWithOpenApi(z);

export const districtRegistry = new OpenAPIRegistry();
export const districtRouter: Router = express.Router();

districtRegistry.register('District', DistrictSchema);

districtRegistry.registerPath({
    method: 'get',
    path: '/api/districts',
    tags: ['District'],
    responses: createApiResponse(z.array(DistrictSchema), 'Success'),
});

districtRouter.get('/', isAuthenticated, districtController.getAll);

districtRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    districtController.getById,
);

const CreateSchema = z.object({
    body: DistrictCreateInputSchema,
});

districtRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    districtController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: DistrictUpdateInputSchema,
});

districtRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    districtController.update,
);
