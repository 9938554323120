import type { Request, RequestHandler, Response } from 'express';

import { manifestService } from '@/api/manifest/manifestService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';
import type { ManifestCreateDTO } from './manifestModel';

class ManifestController {
    public getAll: RequestHandler = async (req: Request, res: Response) => {
        const locationId = req.user!.locationId;
        const serviceResponse = await manifestService.getAll(locationId);
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await manifestService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public getBookingOptions: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const originId = Number.parseInt(req.query.originId as string, 10);
        const destinationId = Number.parseInt(
            req.query.destinationId as string,
            10,
        );
        const serviceResponse = await manifestService.getBookingOptions(
            originId,
            destinationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public getBaggageOptions: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const originId = Number.parseInt(req.query.originId as string, 10);
        const destinationId = Number.parseInt(
            req.query.destinationId as string,
            10,
        );
        const serviceResponse = await manifestService.getBaggageOptions(
            originId,
            destinationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = req.body as ManifestCreateDTO;
        const { id: userId, locationId } = req.user!;
        const serviceResponse = await manifestService.create(
            data,
            userId,
            locationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.ManifestUpdateInput;
        const serviceResponse = await manifestService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const manifestController = new ManifestController();
