import type { Request, RequestHandler, Response } from 'express';

import { masterManifestService } from '@/api/masterManifest/masterManifestService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';
import type { MansterManifestCreateDTO } from './masterManifestModel';

class MasterManifestController {
    public getAll: RequestHandler = async (req: Request, res: Response) => {
        const locationId = req.user!.locationId;
        const serviceResponse = await masterManifestService.getAll(locationId);
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await masterManifestService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public getManifestOptions: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const originId = Number.parseInt(req.query.originId as string, 10);
        const destinationId = Number.parseInt(
            req.query.destinationId as string,
            10,
        );
        const serviceResponse = await masterManifestService.getManifestOptions(
            originId,
            destinationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = req.body as MansterManifestCreateDTO;
        const { id: userId, locationId } = req.user!;
        const serviceResponse = await masterManifestService.create(
            data,
            userId,
            locationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public dispatch: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const { id: userId, locationId } = req.user!;
        const serviceResponse = await masterManifestService.dispatch(
            id,
            userId,
            locationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public receive: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const { id: userId, locationId } = req.user!;
        const serviceResponse = await masterManifestService.receive(
            id,
            userId,
            locationId,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.MasterManifestUpdateInput;
        const serviceResponse = await masterManifestService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const masterManifestController = new MasterManifestController();
