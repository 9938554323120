import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import {
    type Baggage,
    type Booking,
    BookingAction,
    BookingStatus,
    type Prisma,
    PrismaClient,
} from '@prisma/client';
import { type BaggageCreateDTO, BaggageDetailViewModel } from './BaggageModel';

export class BaggageService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all baggages from the database
    async getAll(
        locationId: number,
    ): Promise<ServiceResponse<Baggage[] | null>> {
        try {
            const list = await this.prisma.baggage.findMany({
                include: {
                    origin: true,
                    destination: true,
                },
                where: {
                    originId: locationId,
                },
                orderBy: { id: 'asc' },
            });

            return ServiceResponse.success<Baggage[]>('Baggages found', list);
        } catch (ex) {
            const errorMessage = `Error getting baggages: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving baggages.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a baggage by their ID
    async getById(
        id: number,
    ): Promise<ServiceResponse<BaggageDetailViewModel | null>> {
        try {
            const baggage = await this.prisma.baggage.findUnique({
                where: { id },
                include: {
                    origin: true,
                    destination: true,
                    bookings: {
                        include: {
                            origin: true,
                            destination: true,
                            items: {
                                include: {
                                    productType: true,
                                },
                            },
                            client: true,
                        },
                    },
                },
            });
            if (!baggage) {
                return ServiceResponse.failure(
                    'Baggage not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<BaggageDetailViewModel>(
                'Baggage found',
                new BaggageDetailViewModel(baggage),
            );
        } catch (ex) {
            const errorMessage = `Error finding baggage with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding baggage.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves the list of bookings available for baggage
    async getBookingOptions(
        originId: number,
        destinationId: number,
    ): Promise<ServiceResponse<Booking[] | null>> {
        try {
            const bookings = await this.prisma.booking.findMany({
                where: {
                    originId,
                    destinationId,
                    baggageId: null,
                    manifestId: null,
                    status: BookingStatus.booked,
                },
                include: {
                    items: true,
                    origin: true,
                    destination: true,
                },
            });
            if (!bookings) {
                return ServiceResponse.failure(
                    'Baggage not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<Booking[]>(
                'Baggage found',
                bookings,
            );
        } catch (ex) {
            const errorMessage = `Error finding baggage options with for originId ${originId} and destinationId ${destinationId}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding baggage.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single baggage
    async create(
        data: BaggageCreateDTO,
        userId: number,
        locationId: number,
    ): Promise<ServiceResponse<Baggage | null>> {
        const yearPrefix = new Date().getFullYear().toString().slice(2);
        try {
            const lastBaggage = await this.prisma.baggage.findFirst({
                where: {
                    baggageNo: {
                        startsWith: yearPrefix,
                    },
                },
                orderBy: {
                    baggageNo: 'desc',
                },
            });

            const lastSeq = lastBaggage
                ? Number.parseInt(lastBaggage.baggageNo.slice(2))
                : 0;

            const nextSeq = lastSeq + 1;
            const baggageNo = `${yearPrefix}${nextSeq.toString().padStart(7, '0')}`;

            const baggage = await this.prisma.$transaction(async (tran) => {
                const bgg = await tran.baggage.create({
                    data: {
                        ...data,
                        createdById: userId,
                        baggageNo,
                        bookings: {
                            connect: data.bookings.map((id) => ({ id })),
                        },
                    },
                });

                await tran.bookingHistory.createMany({
                    data: data.bookings.map((id) => ({
                        bookingId: id,
                        action: BookingAction.bag,
                        refId: bgg.id,
                        refNo: bgg.baggageNo,
                        userId,
                        locationId,
                        customer: false,
                    })),
                });
                return bgg;
            });
            return ServiceResponse.success<Baggage>(
                'Baggage Successfully Created',
                baggage,
            );
        } catch (ex) {
            const errorMessage = `Error creating a baggage:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating baggage.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a baggage by their ID
    async update(
        id: number,
        data: Prisma.BaggageUpdateInput,
    ): Promise<ServiceResponse<Baggage | null>> {
        try {
            const baggage = await this.prisma.baggage.findUnique({
                where: { id },
            });
            if (!baggage) {
                return ServiceResponse.failure(
                    'Baggage not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateBaggage = await this.prisma.baggage.update({
                where: {
                    id,
                },
                data,
            });

            return ServiceResponse.success<Baggage>(
                'Baggage successfully updated',
                updateBaggage,
            );
        } catch (ex) {
            const errorMessage = `Error updating baggage with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating baggage.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const baggageService = new BaggageService();
