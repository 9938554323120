import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema, GetBySearchSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { LocationCreateInputSchema, LocationSchema } from '@/zod';
import { locationController } from './locationController';

extendZodWithOpenApi(z);

export const locationRegistry = new OpenAPIRegistry();
export const locationRouter: Router = express.Router();

locationRegistry.register('Location', LocationSchema);

locationRegistry.registerPath({
    method: 'get',
    path: '/api/locations',
    tags: ['Location'],
    responses: createApiResponse(z.array(LocationSchema), 'Success'),
});

locationRouter.get('/', isAuthenticated, locationController.getAll);

locationRouter.get(
    '/search/:query',
    validateRequest(GetBySearchSchema),
    locationController.search,
);

locationRouter.get(
    '/:id/clients',
    validateRequest(GetByIdSchema),
    locationController.getClients,
);

locationRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    locationController.getById,
);

const CreateSchema = z.object({
    body: LocationCreateInputSchema,
});

locationRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    locationController.create,
);

const SubLocationDTOSchema = z.object({
    id: z.number().optional(),
    name: z.string(),
});

const LocationUpdateDTOSchema = z.object({
    name: z
        .string()
        .max(30, { message: 'Name cannot be longer than 30 characters' })
        .optional(),
    code: z
        .string()
        .max(3, { message: 'Code cannot be longer than 3 characters' })
        .optional(),
    contactName: z
        .string()
        .max(150, {
            message: 'Contact Name cannot be longer than 150 characters',
        })
        .optional(),
    contactNo: z
        .string()
        .regex(/^[-+ 0-9]{5,15}$/, { message: 'Invalid Contact Number' })
        .optional(),
    districtId: z.number().optional(),
    subLocations: z.array(SubLocationDTOSchema),
});

export type LocationUpdateDTO = z.infer<typeof LocationUpdateDTOSchema>;

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: LocationUpdateDTOSchema,
});

locationRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    locationController.update,
);
