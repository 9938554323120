import type { BaseModel } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import type { Prisma } from '@prisma/client';
import { z } from 'zod';
import { ClientInfoViewModel } from '../client/clientModel';

export const StatementCreateDTOSchema = z.object({
    clientId: z.number(),
    dateRange: z
        .array(commonValidations.date)
        .length(2, 'Requires a From date and a To Date'),
    bookings: z.array(z.number()),
});

export type StatementCreateDTO = z.infer<typeof StatementCreateDTOSchema>;

export class StatementListViewModel {
    readonly id: number;
    readonly createdAt: Date;
    readonly fromDate: Date;
    readonly toDate: Date;
    readonly client: BaseModel;
    readonly amount: number;
    readonly consignments: number;
    constructor(
        statement: Prisma.StatementGetPayload<{
            include: {
                client: true;
                bookings: true;
            };
        }>,
    ) {
        this.id = statement.id;
        this.createdAt = statement.createdAt;
        this.fromDate = statement.fromDate;
        this.toDate = statement.toDate;
        this.client = {
            id: statement.client.id,
            name: statement.client.name,
        };
        this.amount = statement.amount.toNumber();
        this.consignments = statement.bookings.length;
    }
}

export class StatementDetailViewModel {}

export class StatementPrintRowViewModel {
    readonly bookingDate: string;
    readonly consignmentNo: string;
    readonly serviceType: string;
    readonly destination: string;
    readonly receiverName: string;
    readonly product: string;
    readonly piece: number;
    readonly weight: number;
    readonly amount: number;
    readonly remarks: string | null;
    constructor(
        booking: Prisma.BookingGetPayload<{
            include: {
                destination: true;
                origin: true;
                items: {
                    include: {
                        productType: true;
                    };
                };
            };
        }>,
    ) {
        this.bookingDate = booking.bookingDate.toISOString().substring(0, 10);
        this.consignmentNo = booking.consignmentNo;
        this.serviceType =
            booking.serviceType[0].toUpperCase() +
            booking.serviceType.substring(1);
        this.destination = booking.destination.name;
        this.receiverName = booking.receiverName;
        const products = booking.items.map((x) => ({
            piece: x.piece,
            product: x.productType.name,
        }));
        if (products.length === 1) {
            this.product = products[0].product;
        } else {
            this.product = products
                .map((x) => `${x.piece} ${x.product}`)
                .join(', ');
        }
        this.piece = booking.items.reduce((acc, item) => acc + item.piece, 0);
        this.weight = booking.items.reduce(
            (acc, item) => acc + item.weight.toNumber(),
            0,
        );
        this.amount = booking.totAmt.toNumber();
        this.remarks = booking.instruction;
    }
}

export class StatementPrintViewModel {
    readonly id: number;
    readonly fromDate: Date;
    readonly toDate: Date;
    readonly bookings: StatementPrintRowViewModel[];
    readonly client: ClientInfoViewModel;
    readonly createdBy: string;
    constructor(
        statement: Prisma.StatementGetPayload<{
            include: {
                bookings: {
                    include: {
                        destination: true;
                        origin: true;
                        items: {
                            include: {
                                productType: true;
                            };
                        };
                    };
                };
                client: {
                    include: {
                        location: true;
                    };
                };
                createdBy: true;
            };
        }>,
    ) {
        this.id = statement.id;
        this.fromDate = statement.fromDate;
        this.toDate = statement.toDate;
        this.client = new ClientInfoViewModel(statement.client);
        this.createdBy = [
            statement.createdBy.firstName,
            statement.createdBy.middleName,
            statement.createdBy.lastName,
        ].join(' ');
        this.bookings = statement.bookings.map(
            (booking) => new StatementPrintRowViewModel(booking),
        );
    }
}
