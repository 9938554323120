import type { TokenClaim } from '@/api/auth/authModel';
import { logger } from '@/server';
import jwt, { type JwtPayload, type SignOptions } from 'jsonwebtoken';
import { env } from './envConfig';

export interface TokenPayload extends JwtPayload {
    id: number;
    email: string;
    userName: string;
    locationId: number;
    clientId: number | null;
}

export const generateToken = (data: TokenClaim, expiresIn = '1h') => {
    const options: SignOptions = {
        expiresIn,
    };
    logger.info(expiresIn);

    return jwt.sign({ ...data }, env.JWT_SECRET_KEY, options);
};

export const verifyToken = (token: string) =>
    jwt.verify(token, env.JWT_SECRET_KEY) as TokenPayload;
