import type { BaseModel } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import {
    DeliveryModeSchema,
    PaymentModeSchema,
    ServiceTypeSchema,
    UnitSchema,
} from '@/zod';
import {
    BookingAction,
    type DeliveryMode,
    type PaymentMode,
    type Prisma,
    type ServiceType,
    type Unit,
} from '@prisma/client';
// import type { number } from '@prisma/client/runtime/library';
import { z } from 'zod';
import { Lookup } from '../lookup/lookupModel';

export const BookingItemCreateDTOSchema = z.object({
    productTypeId: z.number(),
    desc: z.string().max(150),
    piece: z.number(),
    weight: z.number(),
    unit: UnitSchema,
    // Calculate these on the back end later
    rate: z.number(),
    total: z.number(),
});

export const BookingCreateDTOSchema = z.object({
    clientId: z.number().optional(),
    bookingDate: commonValidations.dateNullable,
    paymentMode: PaymentModeSchema,
    serviceType: ServiceTypeSchema,
    deliveryMode: DeliveryModeSchema,
    originId: z.number(),
    destinationId: z.number(),
    senderName: commonValidations.name,
    senderContactNo: commonValidations.contact.optional(),
    senderEmail: z.string().email().nullable().optional(),
    senderPan: commonValidations.pan.optional(),
    receiverName: z.string().max(150),
    receiverContactNo: commonValidations.contact,
    receiverAddr: commonValidations.address.optional(),
    receiverPan: commonValidations.pan.optional(),
    refNo: z.string().max(10).optional(),
    invoiceDate: commonValidations.dateNullable.nullable().optional(),
    declaredValue: z.number().optional(),
    cod: z.number().nullable().optional(),
    instruction: z.string().max(250).optional(),
    items: z.array(BookingItemCreateDTOSchema),
    laborCharge: commonValidations.amount,
    pickupCharge: commonValidations.amount,
    deliveryCharge: commonValidations.amount,
    volumeCharge: commonValidations.amount,
    stCharge: commonValidations.amount,
    discount: commonValidations.amount,
    totAmt: commonValidations.amount,
});

export type BookingCreateDTO = z.infer<typeof BookingCreateDTOSchema>;

export const BookingItemUpdateDTOSchema = BookingItemCreateDTOSchema.extend({
    id: z.number().nullable().optional(),
});

export const BookingUpdateDTOSchema = BookingCreateDTOSchema.extend({
    items: z.array(BookingItemUpdateDTOSchema),
});

export type BookingUpdateDTO = z.infer<typeof BookingUpdateDTOSchema>;

export class BookingItemDetailViewModel {
    readonly id: number;
    readonly productTypeId: number;
    readonly productType: BaseModel;
    readonly desc: string;
    readonly piece: number;
    readonly weight: number;
    readonly unit: Unit;
    readonly rate: number;
    readonly total: number;
    constructor(
        item: Prisma.BookingItemGetPayload<{
            include: {
                productType: true;
            };
        }>,
    ) {
        this.id = item.id;
        this.productTypeId = item.productTypeId;
        this.productType = item.productType;
        this.desc = item.desc;
        this.piece = item.piece;
        this.weight = item.weight.toNumber();
        this.unit = item.unit;
        // TODO: set to zero when need to hide
        this.rate = item.rate.toNumber();
        this.total = item.total.toNumber();
    }
}

export class BookingDetailViewModel {
    readonly id: number;
    readonly consignmentNo: string;
    readonly bookingDate: Date;
    readonly paymentMode: PaymentMode;

    readonly refNo: string | null;
    readonly invoiceDate: Date | null;
    readonly declaredValue: number | null;
    readonly instruction: string | null;

    readonly serviceType: ServiceType;
    readonly serviceTypeName: string;

    readonly deliveryMode: DeliveryMode | null;
    readonly deliveryModeName: string | null;

    readonly senderName: string;
    readonly senderContactNo: string | null;
    readonly senderPan: string | null;
    readonly receiverName: string;
    readonly receiverContactNo: string | null;
    readonly receiverAddr: string | null;
    readonly receiverPan: string | null;
    readonly items: BookingItemDetailViewModel[];

    readonly piece: number;
    readonly weight: number;

    readonly originId: number;
    readonly origin: BaseModel & { contactNo: string };
    readonly destinationId: number;
    readonly destination: BaseModel & { contactNo: string };
    readonly clientId: number | null;
    readonly client: (BaseModel & { address: string | null }) | null;
    readonly laborCharge: number | null;
    readonly pickupCharge: number | null;
    readonly deliveryCharge: number | null;
    readonly volumeCharge: number | null;
    readonly stCharge: number | null;
    readonly discount: number | null;
    readonly otherCharge: number | null;
    readonly totAmt: number | null;
    constructor(
        booking: Prisma.BookingGetPayload<{
            include: {
                origin: true;
                destination: true;
                items: {
                    include: {
                        productType: true;
                    };
                };
                client: true;
            };
        }>,
    ) {
        this.id = booking.id;
        this.consignmentNo = booking.consignmentNo;
        this.bookingDate = booking.bookingDate;
        this.paymentMode = booking.paymentMode;
        this.refNo = booking.refNo;
        this.invoiceDate = booking.invoiceDate;
        this.declaredValue = booking.declaredValue?.toNumber() || null;
        this.instruction = booking.instruction;

        this.serviceType = booking.serviceType;
        this.serviceTypeName = Lookup.serviceType[this.serviceType];

        this.deliveryMode = booking.deliveryMode;
        this.deliveryModeName = booking.deliveryMode
            ? Lookup.deliveryMode[booking.deliveryMode]
            : null;

        this.senderName = booking.senderName;
        this.senderContactNo = booking.senderContactNo;
        this.senderPan = booking.senderPan;
        this.receiverName = booking.receiverName;
        this.receiverContactNo = booking.receiverContactNo;
        this.receiverAddr = booking.receiverAddr;
        this.receiverPan = booking.receiverPan;
        this.items = booking.items.map(
            (
                item: Prisma.BookingItemGetPayload<{
                    include: {
                        productType: true;
                    };
                }>,
            ) => new BookingItemDetailViewModel(item),
        );

        this.piece = booking.items.reduce((acc, item) => acc + item.piece, 0);
        this.weight = booking.items.reduce(
            (acc, item) => acc + item.weight.toNumber(),
            0,
        );

        this.originId = booking.originId;
        this.origin = {
            id: booking.origin.id,
            name: booking.origin.name,
            contactNo: booking.origin.contactNo,
        };
        this.destinationId = booking.destinationId;
        this.destination = {
            id: booking.destination.id,
            name: booking.destination.name,
            contactNo: booking.destination.contactNo,
        };
        this.clientId = booking.clientId;
        this.client = booking.client
            ? {
                  id: booking.client.id,
                  name: booking.client.name,
                  address: booking.client.address,
              }
            : null;

        // TODO: hide
        this.laborCharge = booking.laborCharge.toNumber();
        this.pickupCharge = booking.pickupCharge.toNumber();
        this.deliveryCharge = booking.deliveryCharge.toNumber();
        this.volumeCharge = booking.volumeCharge.toNumber();
        this.stCharge = booking.stCharge.toNumber();

        this.otherCharge =
            booking.laborCharge
                .plus(booking.pickupCharge)
                .plus(booking.deliveryCharge)
                .plus(booking.volumeCharge)
                .plus(booking.stCharge)
                .toNumber() || null;

        this.discount = booking.discount.toNumber();
        this.totAmt = booking.totAmt.toNumber();
    }
}

export class BookingOptionViewModel {
    readonly id: number;
    readonly consignmentNo: string;
    readonly senderName: string;
    readonly origin: BaseModel;
    readonly destination: BaseModel;
    readonly receiverName: string;
    readonly piece: number;
    readonly weight: number;
    readonly paymentMode: PaymentMode;
    readonly paymentModeName: string;
    readonly totAmt: number;
    constructor(
        booking: Prisma.BookingGetPayload<{
            include: {
                origin: true;
                destination: true;
                items: true;
            };
        }>,
    ) {
        this.id = booking.id;
        this.consignmentNo = booking.consignmentNo;
        this.senderName = booking.senderName;
        this.origin = {
            id: booking.origin.id,
            name: booking.origin.name,
        };
        this.receiverName = booking.receiverName;
        this.destination = {
            id: booking.destination.id,
            name: booking.destination.name,
        };
        this.piece = booking.items.reduce((a, b) => a + b.piece, 0);
        this.weight = booking.items.reduce(
            (a, b) => a + b.weight.toNumber(),
            0,
        );
        this.paymentMode = booking.paymentMode;
        this.paymentModeName = Lookup.paymentMode[booking.paymentMode].label;
        this.totAmt = booking.totAmt.toNumber();
    }
}

type CommentHistoryType = Prisma.BookingCommentGetPayload<{
    include: {
        createdBy: {
            include: {
                location: true;
            };
        };
    };
}>;

type BookingHistoryType = Prisma.BookingHistoryGetPayload<{
    include: {
        user: true;
        location: true;
    };
}>;

export class BookingHistoryViewModel {
    readonly id: number;
    readonly date: Date;
    readonly action: BookingAction;
    readonly refId: number | null;
    readonly refNo: string | null;
    readonly location: BaseModel | null;
    readonly user: BaseModel | null;
    readonly comment: string | null;
    readonly customer: boolean;

    constructor(hist: CommentHistoryType);

    constructor(hist: BookingHistoryType);

    constructor(hist: BookingHistoryType | CommentHistoryType) {
        if ('action' in hist) {
            this.id = hist.id;
            this.date = hist.date;
            this.action = hist.action;
            this.refId = hist.refId;
            this.refNo = hist.refNo;
            this.location = hist.location
                ? {
                      id: hist.location.id,
                      name: hist.location.name,
                  }
                : null;
            this.user = hist.user
                ? {
                      id: hist.user.id,
                      name: [
                          hist.user.firstName,
                          hist.user.middleName,
                          hist.user.lastName,
                      ]
                          .filter((x) => x)
                          .join(' '),
                  }
                : null;
            this.comment = hist.comment;
            this.customer = hist.customer;
        } else {
            this.id = hist.id;
            this.date = hist.createdAt;
            this.action = BookingAction.comment;
            this.refId = hist.id;
            this.refNo = null;
            this.location = hist.createdBy
                ? {
                      id: hist.createdBy?.location.id,
                      name: hist.createdBy?.location.name,
                  }
                : null;
            this.user = hist.createdBy
                ? {
                      id: hist.createdBy?.id,
                      name: [
                          hist.createdBy.firstName,
                          hist.createdBy.middleName,
                          hist.createdBy.lastName,
                      ]
                          .filter((x) => x)
                          .join(' '),
                  }
                : null;
            this.comment = hist.comment;
            this.customer = !!hist.createdBy?.clientId || !hist.createdBy;
        }
    }
}

export const BookingCommentDTOSchema = z.object({
    comment: z.string().max(1000),
});

export type BookingCommentDTO = z.infer<typeof BookingCommentDTOSchema>;

export class BookingStatementViewModel {
    readonly id: number;
    readonly consignmentNo: string;
    readonly bookingDate: Date;
    readonly receiverName: string;
    readonly destination: BaseModel;
    readonly serviceType: ServiceType;
    readonly product: string;
    readonly piece: number;
    readonly weight: number;
    readonly totAmt: number;
    readonly remarks: string | null;
    constructor(
        booking: Prisma.BookingGetPayload<{
            include: {
                origin: true;
                destination: true;
                items: {
                    include: {
                        productType: true;
                    };
                };
            };
        }>,
    ) {
        this.id = booking.id;
        this.consignmentNo = booking.consignmentNo;
        this.bookingDate = booking.bookingDate;
        this.receiverName = booking.receiverName;
        this.destination = {
            id: booking.destination.id,
            name: booking.destination.name,
        };
        this.serviceType = booking.serviceType;
        this.product = booking.items
            .map((item) => item.productType.name)
            .join(', ');
        this.piece = booking.items.reduce((acc, item) => acc + item.piece, 0);
        this.weight = booking.items.reduce(
            (acc, item) => acc + item.weight.toNumber(),
            0,
        );
        this.totAmt = booking.totAmt.toNumber();
        this.remarks = booking.instruction;
    }
}
