import path from 'node:path';
import { env } from '@/common/utils/envConfig';
import { formatter } from '@/common/utils/formatter';
// import { dateHelper } from '@/helpers';
import PdfPrinter from 'pdfmake';
import type { TDocumentDefinitions } from 'pdfmake/interfaces';
import type { StatementPrintViewModel } from './statementModel';

function createPdfBinary(pdfDoc: TDocumentDefinitions): Promise<Buffer> {
    return new Promise((resolve, reject) => {
        const fontDescriptors = {
            Lato: {
                normal: path.join(
                    __dirname,
                    '..',
                    '..',
                    'fonts',
                    '/Lato/Lato-Regular.ttf',
                ),
                bold: path.join(
                    __dirname,
                    '..',
                    '..',
                    'fonts',
                    '/Lato/Lato-Bold.ttf',
                ),
                italics: path.join(
                    __dirname,
                    '..',
                    '..',
                    'fonts',
                    '/Lato/Lato-Italic.ttf',
                ),
            },
            Segoe: {
                normal: path.join(__dirname, '..', '..', '/fonts/Segoe-UI.ttf'),
                bold: path.join(
                    __dirname,
                    '..',
                    '..',
                    'fonts',
                    '/Segoe-UI/Segoe-UI-Bold.ttf',
                ),
                italics: path.join(
                    __dirname,
                    '..',
                    '..',
                    'fonts',
                    '/Segoe-UI/Segoe-UI-Italic.ttf',
                ),
                bolditalics: path.join(
                    __dirname,
                    '..',
                    '..',
                    'fonts',
                    '/Segoe-UI/Segoe-UI-Bold-Italic.ttf',
                ),
            },
        };
        const printer = new PdfPrinter(fontDescriptors);

        const doc = printer.createPdfKitDocument(pdfDoc);

        const chunks: Uint8Array[] = [];

        doc.on('data', (chunk) => {
            chunks.push(chunk);
        });
        doc.on('end', () => {
            try {
                const result = Buffer.concat(chunks);
                // callback(`data:application/pdf;base64,${result.toString('base64')}`);
                resolve(result);
            } catch (err) {
                reject(err as Error);
            }
        });
        doc.end();
    });
}

const CONSIGNEE_MAX_LENGTH = 20;

export const printStatement = async (
    statement: StatementPrintViewModel,
): Promise<Buffer | null> => {
    try {
        const rows = statement.bookings;
        const data = rows.map((row, i) => [
            { text: i + 1, alignment: 'right' },
            row.bookingDate,
            row.consignmentNo,
            row.serviceType,
            { text: row.destination, noWrap: true },
            {
                text:
                    row.receiverName.length > CONSIGNEE_MAX_LENGTH
                        ? `${row.receiverName.slice(0, CONSIGNEE_MAX_LENGTH)}...`
                        : row.receiverName,
                noWrap: true,
            },
            row.product,
            { text: row.piece, alignment: 'right' },
            { text: row.weight.toFixed(2), alignment: 'right' },
            { text: formatter.money(row.amount), alignment: 'right' },
            { text: row.remarks, noWrap: true },
        ]);

        const columns = [
            { header: 'S.No.', width: 'auto' },
            { header: 'Date', width: 'auto' },
            { header: 'CN No', width: 'auto' },
            { header: 'Service', width: 'auto' },
            { header: 'Destination', width: 'auto' },
            { header: 'Consignee', width: 'auto' },
            { header: 'Product', width: 'auto' },
            { header: 'Piece', width: 'auto' },
            { header: 'Weight', width: 'auto' },
            { header: 'Amount', width: 'auto' },
            { header: 'Remarks', width: 'auto' },
        ];

        const REPORT_TITLE = 'Statement';
        const dd: TDocumentDefinitions = {
            pageOrientation: 'portrait',

            footer: (currentPage, pageCount) => ({
                text: `Page ${currentPage} of ${pageCount}`,
                alignment: 'right',
                margin: [10, 10, 10, 10],
                fontSize: 8,
            }),
            content: [
                {
                    text: env.COMPANY_NAME,
                    style: 'companyName',
                },
                {
                    text: env.COMPANY_ADDRESS,
                    style: 'companyInfo',
                },
                {
                    text: `PAN: ${env.COMPANY_PAN}`,
                    style: 'companyInfo',
                },
                {
                    text: REPORT_TITLE,
                    style: 'reportTitle',
                },
                {
                    columns: [
                        {
                            stack: [
                                {
                                    text: statement.client.name,
                                    style: 'clientName',
                                    // pageBreak: 'before',
                                },
                                {
                                    text: statement.client.address,
                                    style: 'clientInfo',
                                    // pageBreak: 'before',
                                },
                                {
                                    text: `PAN: ${statement.client.pan}`,
                                    style: 'clientInfo',
                                    // pageBreak: 'before',
                                },
                            ],
                        },
                        {
                            stack: [
                                {
                                    text: `Statement No: ${statement.id}`,
                                    style: 'reportInfo',
                                    // pageBreak: 'before',
                                },
                                {
                                    text: `Date: ${new Date().toISOString().substring(0, 10)}`,
                                    style: 'reportInfo',
                                    // pageBreak: 'before',
                                },
                                {
                                    text: `Period: ${statement.fromDate.toISOString().substring(0, 10)} - ${statement.toDate.toISOString().substring(0, 10)}`,
                                    style: 'reportInfo',
                                    // pageBreak: 'before',
                                },
                            ],
                        },
                    ],
                },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        // dontBreakRows: true,
                        // keepWithHeaderRows: 1,
                        // TODO: Define columns separately
                        widths: columns.map((col) => col.width),
                        body: [
                            columns.map((col) => ({
                                text: col.header,
                                style: 'tableHeader',
                                noWrap: true,
                            })),
                            ...data,
                            [
                                {
                                    text: 'Total',
                                    colSpan: 9,
                                    style: 'tableHeader',
                                    alignment: 'right',
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: formatter.money(
                                        statement.bookings.reduce(
                                            (a, b) => a + b.amount,
                                            0,
                                        ),
                                    ),
                                    style: 'tableHeader',
                                    alignment: 'right',
                                },
                                {},
                            ],
                        ],
                    },
                    layout: {
                        hLineWidth: () => 1,
                        vLineWidth: () => 0,
                        hLineColor: (i) =>
                            i === 0 || i === 1 ? 'black' : 'gray',
                        vLineColor: (i, node) =>
                            i === 0 || i === node.table.widths?.length
                                ? 'black'
                                : 'gray',
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // paddingLeft: function(i, node) { return 4; },
                        // paddingRight: function(i, node) { return 4; },
                        // paddingTop: function(i, node) { return 2; },
                        // paddingBottom: function(i, node) { return 2; },
                        // fillColor: function (rowIndex, node, columnIndex) { return null; }
                    },
                },
                {
                    margin: [0, 10, 0, 0],
                    columns: [
                        {
                            alignment: 'center',
                            stack: [
                                {
                                    text: '______________________________',
                                    style: 'signatureLine',
                                },
                                {
                                    text: `Prepared By: ${statement.createdBy}`,
                                    style: 'signature',
                                },
                            ],
                        },
                        {
                            alignment: 'center',
                            stack: [
                                {
                                    text: '______________________________',
                                    style: 'signatureLine',
                                },
                                { text: 'Checked By', style: 'signature' },
                            ],
                        },
                        {
                            alignment: 'center',
                            stack: [
                                {
                                    text: '______________________________',
                                    style: 'signatureLine',
                                },
                                { text: 'Received By', style: 'signature' },
                            ],
                        },
                    ],
                },
            ],
            styles: {
                companyName: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center',
                    // margin: [0, 0, 0, 10],
                },
                companyInfo: {
                    fontSize: 9,
                    alignment: 'center',
                    margin: [0, 0, 0, 2],
                },
                reportTitle: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'center',
                    // margin: [0, 0, 0, 10],
                },
                companyAddr: {
                    fontSize: 10,
                },
                header: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 15, 0, 2], //    
                    // 󰁎 󰁞 󰁕 󰁆
                },
                clientName: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 0, 0, 1], //    
                },
                clientInfo: {
                    fontSize: 8,
                    margin: [0, 0, 0, 1], //    
                },
                reportInfo: {
                    fontSize: 8,
                    alignment: 'right',
                    margin: [0, 0, 0, 1], //    
                },
                filter: {
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 5],
                },
                tableExample: {
                    margin: [0, 5, 0, 15],
                    fontSize: 7,
                },
                tableHeader: {
                    bold: true,
                    fontSize: 8,
                    color: 'black',
                },
                signatureLine: {
                    color: 'gray',
                },
                signature: {
                    margin: [0, 2, 0, 0],
                    fontSize: 8,
                },
            },
            defaultStyle: {
                font: 'Lato',
                fontSize: 10,
                // alignment: 'justify'
            },
        };

        return await createPdfBinary(dd);
    } catch (ex) {
        console.log(ex);
        return null;
    }
};
