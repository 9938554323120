import type { Request, RequestHandler, Response } from 'express';

import { districtService } from '@/api/district/districtService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import type { Prisma } from '@prisma/client';

class DistrictController {
    public getAll: RequestHandler = async (_req: Request, res: Response) => {
        const serviceResponse = await districtService.getAll();
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await districtService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = req.body as Prisma.DistrictCreateInput;
        const serviceResponse = await districtService.create(data);
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as Prisma.DistrictUpdateInput;
        const serviceResponse = await districtService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };
}

export const districtController = new DistrictController();
