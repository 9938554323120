import { StatusCodes } from 'http-status-codes';

import type { BaseModel } from '@/common/models/commonModel';
import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { type Location, type Prisma, PrismaClient } from '@prisma/client';
import { LocationListViewModel } from './locationModel';
import type { LocationUpdateDTO } from './locationRouter';

export class LocationService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all locations from the database
    async getAll(): Promise<ServiceResponse<LocationListViewModel[] | null>> {
        try {
            const list = await this.prisma.location.findMany({
                orderBy: { id: 'asc' },
                include: {
                    district: true,
                },
            });

            if (!list || list.length === 0) {
                return ServiceResponse.failure(
                    'No location found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<LocationListViewModel[]>(
                'Locations found',
                list.map((l) => new LocationListViewModel(l)),
            );
        } catch (ex) {
            const errorMessage = `Error getting locations: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving locations.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a location by their ID
    async getById(id: number): Promise<ServiceResponse<Location | null>> {
        try {
            const location = await this.prisma.location.findUnique({
                where: { id },
                include: {
                    subLocations: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                },
            });
            if (!location) {
                return ServiceResponse.failure(
                    'Location not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<Location>(
                'Location found',
                location,
            );
        } catch (ex) {
            const errorMessage = `Error finding location with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding location.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves the clients of a location by location's ID
    async getClients(id: number): Promise<ServiceResponse<BaseModel[] | null>> {
        try {
            const clients = await this.prisma.client.findMany({
                where: { locationId: id },
            });
            return ServiceResponse.success<BaseModel[] | null>(
                'Clients found',
                clients,
            );
        } catch (ex) {
            const errorMessage = `Error finding location clients with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding clients.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Searches a location based on it's name or it's sub locations' name
    async search(query: string): Promise<ServiceResponse<BaseModel[] | null>> {
        try {
            const locations = await this.prisma.location.findMany({
                where: {
                    OR: [
                        {
                            name: {
                                // TODO: postgres, add mode: 'insensitive'
                                contains: query,
                            },
                        },
                        {
                            subLocations: {
                                some: {
                                    name: {
                                        // TODO: postgres, add mode: 'insensitive'
                                        contains: query,
                                    },
                                },
                            },
                        },
                    ],
                },
            });
            return ServiceResponse.success<BaseModel[] | null>(
                'Locations found',
                locations,
            );
        } catch (ex) {
            const errorMessage = `Error finding locations clients with query ${query}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding location.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single location
    async create(
        data: Prisma.LocationCreateInput,
    ): Promise<ServiceResponse<Location | null>> {
        try {
            const location = await this.prisma.location.create({ data });
            return ServiceResponse.success<Location>(
                'Location Successfully Created',
                location,
            );
        } catch (ex) {
            const errorMessage = `Error creating a location:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating location.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a location by their ID
    async update(
        id: number,
        data: LocationUpdateDTO,
    ): Promise<ServiceResponse<Location | null>> {
        try {
            const location = await this.prisma.location.findUnique({
                where: { id },
            });
            if (!location) {
                return ServiceResponse.failure(
                    'Location not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const subLocationIds = data.subLocations
                .map((s) => s.id || 0)
                .filter((id) => id !== 0);

            logger.debug(data.subLocations);
            const updateLocation = await this.prisma.location.update({
                where: {
                    id,
                },
                data: {
                    ...data,
                    districtId: undefined,
                    ...(data.districtId && {
                        district: {
                            connect: { id: data.districtId },
                        },
                    }),
                    updatedAt: new Date(),
                    subLocations: {
                        deleteMany: {
                            id: {
                                notIn: subLocationIds,
                            },
                        },
                        upsert: data.subLocations.map((subLocation) => ({
                            where: { id: subLocation.id || 0 },
                            create: { name: subLocation.name },
                            update: { name: subLocation.name },
                        })),
                    },
                },
            });

            return ServiceResponse.success<Location>(
                'Location successfully updated',
                updateLocation,
            );
        } catch (ex) {
            const errorMessage = `Error updating location with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating location.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const locationService = new LocationService();
