import {
    OpenAPIRegistry,
    OpenApiGeneratorV3,
} from '@asteasolutions/zod-to-openapi';

import { authRegistry } from '@/api/auth/authRouter';
import { districtRegistry } from '@/api/district/districtRouter';
import { healthCheckRegistry } from '@/api/healthCheck/healthCheckRouter';
import { locationRegistry } from '@/api/location/locationRouter';
import { userRegistry } from '@/api/user/userRouter';
import { productTypeRegistry } from '@/api/productType/productTypeRouter';
import { lookupRegistry } from '@/api/lookup/lookupRouter';
import { clientRegistry } from '@/api/client/clientRouter';
import { rateRegistry } from '@/api/rate/rateRouter';
import { bookingRegistry } from '@/api/booking/bookingRouter';
import { baggageRegistry } from '@/api/baggage/baggageRouter';
import { manifestRegistry } from '@/api/manifest/manifestRouter';
import { masterManifestRegistry } from '@/api/masterManifest/masterManifestRouter';
import { deliveryRegistry } from '@/api/delivery/deliveryRouter';
import { roleRegistry } from '@/api/role/roleRouter';
import { statementRegistry } from '@/api/statement/statementRouter';

export function generateOpenAPIDocument() {
    const registry = new OpenAPIRegistry([
        healthCheckRegistry,
        userRegistry,
        authRegistry,
        locationRegistry,
        districtRegistry,
        productTypeRegistry,
        lookupRegistry,
        clientRegistry,
        rateRegistry,
        bookingRegistry,
        baggageRegistry,
        manifestRegistry,
        masterManifestRegistry,
        deliveryRegistry,
        roleRegistry,
        statementRegistry,
    ]);
    const generator = new OpenApiGeneratorV3(registry.definitions);

    return generator.generateDocument({
        openapi: '3.0.0',
        info: {
            version: '1.0.0',
            title: 'Swagger API',
        },
        externalDocs: {
            description: 'View the raw OpenAPI Specification in JSON format',
            url: '/swagger.json',
        },
    });
}
