import type { Request, RequestHandler, Response } from 'express';

import { bookingService } from '@/api/booking/bookingService';
import { handleServiceResponse } from '@/common/utils/httpHandlers';
import { BookingStatus } from '@prisma/client';
import {
    BookingCommentDTOSchema,
    BookingCreateDTOSchema,
    type BookingUpdateDTO,
} from './bookingModel';

class BookingController {
    public getAll: RequestHandler = async (req: Request, res: Response) => {
        const locationId = req.user!.locationId;
        const serviceResponse = await bookingService.getAll(locationId);
        handleServiceResponse(serviceResponse, res);
    };

    public getById: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await bookingService.getById(id);
        handleServiceResponse(serviceResponse, res);
    };

    public create: RequestHandler = async (req: Request, res: Response) => {
        const data = BookingCreateDTOSchema.parse(req.body);

        const locationId = req.user!.locationId;
        const status = req.user!.isClient
            ? BookingStatus.requested
            : BookingStatus.booked;
        const serviceResponse = await bookingService.create(
            { ...data, status },
            req.user!.id,
            locationId,
            req.user?.isClient || false,
        );
        handleServiceResponse(serviceResponse, res);
    };

    public update: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const data = req.body as BookingUpdateDTO;
        const serviceResponse = await bookingService.update(id, data);
        handleServiceResponse(serviceResponse, res);
    };

    public track: RequestHandler = async (req: Request, res: Response) => {
        const consignmentNo = req.params.consignmentNo as string;
        const serviceResponse = await bookingService.track(consignmentNo);
        handleServiceResponse(serviceResponse, res);
    };

    public publicTrack: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const consignmentNo = req.params.consignmentNo as string;
        const serviceResponse = await bookingService.publicTrack(consignmentNo);
        handleServiceResponse(serviceResponse, res);
    };

    public history: RequestHandler = async (req: Request, res: Response) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await bookingService.history(id);
        handleServiceResponse(serviceResponse, res);
    };

    public publicHistory: RequestHandler = async (
        req: Request,
        res: Response,
    ) => {
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await bookingService.publicHistory(id);
        handleServiceResponse(serviceResponse, res);
    };

    public comment: RequestHandler = async (req: Request, res: Response) => {
        const data = BookingCommentDTOSchema.parse(req.body);

        const userId = req.user?.id || null;
        const id = Number.parseInt(req.params.id as string, 10);
        const serviceResponse = await bookingService.comment(id, data, userId);
        handleServiceResponse(serviceResponse, res);
    };
}

export const bookingController = new BookingController();
