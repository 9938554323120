import { StatusCodes } from 'http-status-codes';
import { type ZodIssue, z } from 'zod';

export interface ResponseError {
    field: string;
    message: string;
}

export class ServiceResponse<T = null> {
    readonly success: boolean;
    readonly message: string;
    readonly data: T;
    readonly statusCode: number;
    readonly count: number;
    readonly errors: ResponseError[];

    private constructor(
        success: boolean,
        message: string,
        data: T,
        statusCode: number,
        errors?: ResponseError[],
    ) {
        this.success = success;
        this.message = message;
        this.data = data;
        this.statusCode = statusCode;
        this.count = Array.isArray(data) ? data.length : data ? 1 : 0;
        this.errors = errors || [];
    }

    static success<T>(
        message: string,
        responseObject: T,
        statusCode: number = StatusCodes.OK,
    ) {
        return new ServiceResponse(true, message, responseObject, statusCode);
    }

    static failure<T>(
        message: string,
        responseObject: T,
        statusCode: number = StatusCodes.BAD_REQUEST,
        errors?: ZodIssue[],
    ) {
        const resErrors = errors?.map((e) => ({
            field: e.path.slice(1).join('.'),
            message: e.message,
        }));
        return new ServiceResponse(
            false,
            message,
            responseObject,
            statusCode,
            resErrors,
        );
    }
}

export const ServiceResponseSchema = <T extends z.ZodTypeAny>(dataSchema: T) =>
    z.object({
        success: z.boolean(),
        message: z.string(),
        responseObject: dataSchema.optional(),
        statusCode: z.number(),
    });
