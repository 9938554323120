import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import {
    RateCreateInputSchema,
    RateSchema,
    RateUpdateInputSchema,
} from '@/zod';
import { rateController } from './rateController';

extendZodWithOpenApi(z);

export const rateRegistry = new OpenAPIRegistry();
export const rateRouter: Router = express.Router();

rateRegistry.register('Rate', RateSchema);

rateRegistry.registerPath({
    method: 'get',
    path: '/api/rates',
    tags: ['Rate'],
    responses: createApiResponse(z.array(RateSchema), 'Success'),
});

rateRouter.get('/', isAuthenticated, rateController.getAll);

rateRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    rateController.getById,
);

const CreateSchema = z.object({
    body: RateCreateInputSchema,
});

rateRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    rateController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: RateUpdateInputSchema,
});

rateRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    rateController.update,
);
