import crypto from 'node:crypto';
import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { generateToken } from '@/common/utils/token';
import { logger } from '@/server';
import { Prisma, PrismaClient, type User } from '@prisma/client';
import { compare, hash } from 'bcrypt';
import {
    type LoginResponse,
    type LoginViewModel,
    TokenClaim,
    type UserRegister,
} from './authModel';

export class AuthService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    async register(data: UserRegister): Promise<ServiceResponse<User | null>> {
        let password: string | null = null;
        let passwordToken: string | null = null;
        let passwordTokenExpiration: Date | null = null;

        if (data.password) password = await hash(data.password, 10);
        else {
            passwordToken = crypto.randomBytes(32).toString('hex');
            passwordTokenExpiration = new Date(Date.now() + 3600000 * 24);
        }

        try {
            const user = await this.prisma.user.create({
                data: {
                    ...data,
                    password,
                    passwordToken,
                    passwordTokenExpiration,
                    locationId: undefined,
                    roleId: undefined,
                    status: true,
                    location: {
                        connect: {
                            id: data.locationId,
                        },
                    },
                    role: {
                        connect: {
                            id: data.roleId,
                        },
                    },
                },
            });
            return ServiceResponse.success<User>(
                'User Successfully Created',
                user,
            );
        } catch (ex) {
            if (ex instanceof Prisma.PrismaClientKnownRequestError) {
                console.log('exception', ex.code, ex.meta);
                if (ex.code === 'P2002')
                    return ServiceResponse.failure(
                        `A user with this ${ex.meta?.target} already exists.`,
                        null,
                        StatusCodes.INTERNAL_SERVER_ERROR,
                    );
            }
            const errorMessage = `Error creating a user:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating user.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async login(
        creds: LoginViewModel,
    ): Promise<ServiceResponse<LoginResponse | null>> {
        try {
            const user = await this.prisma.user.findUnique({
                include: {
                    role: {
                        select: {
                            id: true,
                            name: true,
                            isAdmin: true,
                        },
                    },
                    location: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                },
                where: {
                    userName: creds.userName,
                },
            });

            if (
                !user ||
                !user.password ||
                !(await compare(creds.password, user.password))
            ) {
                return ServiceResponse.failure(
                    'Invalid Login',
                    null,
                    StatusCodes.UNAUTHORIZED,
                );
            }

            const claims = new TokenClaim(user);

            return ServiceResponse.success<LoginResponse>('Login Successful', {
                token: generateToken(claims),
                refreshToken: generateToken(claims, '2h'),
            });
        } catch (ex) {
            const errorMessage = `Error getting users: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while trying to login.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a single user by their ID
    async update(id: number): Promise<ServiceResponse<User | null>> {
        try {
            const user = await this.prisma.user.findUnique({
                where: { id },
                include: {
                    role: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                    location: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                },
            });
            if (!user) {
                return ServiceResponse.failure(
                    'User not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<User>('User found', user);
        } catch (ex) {
            const errorMessage = `Error finding user with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding user.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const authService = new AuthService();
