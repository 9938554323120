import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import {
    type Booking,
    BookingAction,
    type BookingComment,
    BookingStatus,
    PrismaClient,
} from '@prisma/client';
import {
    type BookingCommentDTO,
    type BookingCreateDTO,
    BookingDetailViewModel,
    BookingHistoryViewModel,
    type BookingUpdateDTO,
} from './bookingModel';

export class BookingService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all bookings from the database
    async getAll(
        locationId: number,
    ): Promise<ServiceResponse<Booking[] | null>> {
        try {
            const list = await this.prisma.booking.findMany({
                include: {
                    origin: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                    destination: {
                        select: {
                            id: true,
                            name: true,
                        },
                    },
                },
                where: {
                    originId: locationId,
                    status: {
                        in: [BookingStatus.requested, BookingStatus.booked],
                    },
                },
                orderBy: { id: 'desc' },
            });

            return ServiceResponse.success<Booking[]>('Bookings found', list);
        } catch (ex) {
            const errorMessage = `Error getting bookings: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving bookings.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a booking by their ID
    async getById(
        id: number,
    ): Promise<ServiceResponse<BookingDetailViewModel | null>> {
        try {
            const booking = await this.prisma.booking.findUnique({
                where: { id },
                include: {
                    origin: true,
                    destination: true,
                    items: {
                        include: {
                            productType: true,
                        },
                    },
                    client: true,
                },
            });
            if (!booking) {
                return ServiceResponse.failure(
                    'Booking not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<BookingDetailViewModel>(
                'Booking found',
                new BookingDetailViewModel(booking),
            );
        } catch (ex) {
            const errorMessage = `Error finding booking with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding booking.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single booking
    async create(
        data: BookingCreateDTO & { status: BookingStatus },
        userId: number,
        locationId: number,
        customer: boolean,
    ): Promise<ServiceResponse<BookingDetailViewModel | null>> {
        const yearPrefix = new Date().getFullYear().toString().slice(2);
        try {
            const lastBooking = await this.prisma.booking.findFirst({
                where: {
                    consignmentNo: {
                        startsWith: yearPrefix,
                    },
                },
                orderBy: {
                    consignmentNo: 'desc',
                },
            });

            const lastSeq = lastBooking
                ? Number.parseInt(lastBooking.consignmentNo.slice(2))
                : 0;

            const nextSeq = lastSeq + 1;
            const consignmentNo = `${yearPrefix}${nextSeq.toString().padStart(7, '0')}`;

            const booking = await this.prisma.$transaction(async (tran) => {
                const book = await tran.booking.create({
                    data: {
                        ...data,
                        clientId: undefined,
                        originId: undefined,
                        destinationId: undefined,
                        consignmentNo,
                        status: BookingStatus.booked,
                        currentLocation: {
                            connect: {
                                id: data.originId,
                            },
                        },
                        origin: {
                            connect: {
                                id: data.originId,
                            },
                        },
                        destination: {
                            connect: {
                                id: data.destinationId,
                            },
                        },
                        client: data.clientId
                            ? {
                                  connect: {
                                      id: data.clientId,
                                  },
                              }
                            : undefined,
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                        items: {
                            create: data.items,
                        },
                    },
                    include: {
                        origin: true,
                        destination: true,
                        items: {
                            include: {
                                productType: true,
                            },
                        },
                        client: true,
                    },
                });

                await tran.bookingHistory.create({
                    data: {
                        bookingId: book.id,
                        refId: book.id,
                        refNo: book.consignmentNo,
                        action: customer
                            ? BookingAction.request
                            : BookingAction.book,
                        userId,
                        locationId,
                        customer,
                    },
                });

                return book;
            });
            return ServiceResponse.success<BookingDetailViewModel>(
                `Booking Successfully Created. Consignment No: ${booking.consignmentNo}`,
                new BookingDetailViewModel(booking),
            );
        } catch (ex) {
            const errorMessage = `Error creating a booking:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating booking.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a booking by their ID
    async update(
        id: number,
        data: BookingUpdateDTO,
    ): Promise<ServiceResponse<Booking | null>> {
        try {
            const booking = await this.prisma.booking.findUnique({
                where: { id },
            });
            if (!booking) {
                return ServiceResponse.failure(
                    'Booking not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }

            const itemIds = data.items.map((i) => i.id || 0);
            const updateBooking = await this.prisma.booking.update({
                where: {
                    id,
                },
                data: {
                    ...data,
                    clientId: undefined,
                    originId: undefined,
                    destinationId: undefined,
                    origin: {
                        connect: {
                            id: data.originId,
                        },
                    },
                    destination: {
                        connect: {
                            id: data.destinationId,
                        },
                    },
                    client: data.clientId
                        ? {
                              connect: {
                                  id: data.clientId,
                              },
                          }
                        : undefined,
                    items: {
                        deleteMany: {
                            id: {
                                notIn: itemIds,
                            },
                        },
                        upsert: data.items.map((item) => ({
                            where: { id: item.id || 0 },
                            create: {
                                ...item,
                                id: undefined,
                                productTypeId: undefined,
                                productType: {
                                    connect: {
                                        id: item.productTypeId,
                                    },
                                },
                            },
                            update: {
                                ...item,
                                id: undefined,
                                productTypeId: undefined,
                                productType: {
                                    connect: {
                                        id: item.productTypeId,
                                    },
                                },
                            },
                        })),
                    },
                },
            });

            return ServiceResponse.success<Booking>(
                'Booking successfully updated',
                updateBooking,
            );
        } catch (ex) {
            const errorMessage = `Error updating booking with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating booking.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Tracks a booking by its Consignment No
    async track(
        consignmentNo: string,
    ): Promise<ServiceResponse<BookingDetailViewModel | null>> {
        try {
            const booking = await this.prisma.booking.findUnique({
                where: { consignmentNo },
                include: {
                    origin: true,
                    destination: true,
                    items: {
                        include: {
                            productType: true,
                        },
                    },
                    client: true,
                },
            });
            if (!booking) {
                return ServiceResponse.failure(
                    'Booking not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<BookingDetailViewModel>(
                'Booking found',
                new BookingDetailViewModel(booking),
            );
        } catch (ex) {
            const errorMessage = `Error finding booking with consignmentNo ${consignmentNo}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding booking.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Tracks a booking by its Consignment No
    async publicTrack(
        consignmentNo: string,
    ): Promise<ServiceResponse<BookingDetailViewModel | null>> {
        try {
            const booking = await this.prisma.booking.findUnique({
                where: { consignmentNo },
                include: {
                    origin: true,
                    destination: true,
                    items: {
                        include: {
                            productType: true,
                        },
                    },
                    client: true,
                },
            });
            if (!booking) {
                return ServiceResponse.failure(
                    'Booking not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<BookingDetailViewModel>(
                'Booking found',
                new BookingDetailViewModel(booking),
            );
        } catch (ex) {
            const errorMessage = `Error finding booking with consignmentNo ${consignmentNo}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding booking.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Tracks a booking by its Consignment No
    async history(
        id: number,
    ): Promise<ServiceResponse<BookingHistoryViewModel[] | null>> {
        try {
            const hist =
                (await this.prisma.bookingHistory.findMany({
                    include: {
                        user: true,
                        location: true,
                    },
                    where: { bookingId: id },
                })) || [];

            const comments =
                (await this.prisma.bookingComment.findMany({
                    include: {
                        createdBy: {
                            include: {
                                location: true,
                            },
                        },
                    },
                    where: { bookingId: id },
                })) || [];

            const list = [
                ...hist.map((h) => new BookingHistoryViewModel(h)),
                ...comments.map((c) => new BookingHistoryViewModel(c)),
            ].sort((a, b) => a.date.getTime() - b.date.getTime());

            return ServiceResponse.success<BookingHistoryViewModel[]>(
                'Booking found',
                list,
            );
        } catch (ex) {
            const errorMessage = `Error finding booking with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding booking.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Tracks a booking by its Consignment No
    async publicHistory(
        id: number,
    ): Promise<ServiceResponse<BookingDetailViewModel | null>> {
        try {
            const booking = await this.prisma.booking.findUnique({
                where: { id },
                include: {
                    origin: true,
                    destination: true,
                    items: {
                        include: {
                            productType: true,
                        },
                    },
                    client: true,
                },
            });
            if (!booking) {
                return ServiceResponse.failure(
                    'Booking not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<BookingDetailViewModel>(
                'Booking found',
                new BookingDetailViewModel(booking),
            );
        } catch (ex) {
            const errorMessage = `Error finding booking with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding booking.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Comment on a booking
    async comment(
        id: number,
        data: BookingCommentDTO,
        userId: number | null,
    ): Promise<ServiceResponse<BookingComment | null>> {
        try {
            const booking =
                (await this.prisma.booking.findUnique({
                    where: { id },
                })) || [];

            if (!booking) {
                return ServiceResponse.failure(
                    'User not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }

            const comment = await this.prisma.bookingComment.create({
                include: {
                    createdBy: {
                        include: {
                            location: true,
                        },
                    },
                },
                data: {
                    bookingId: id,
                    comment: data.comment,
                    createdById: userId,
                },
            });

            return ServiceResponse.success<BookingComment>(
                'Comment saved',
                comment,
            );
        } catch (ex) {
            const errorMessage = `Error finding booking with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding booking.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const bookingService = new BookingService();
