import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { UserSchema } from '@/zod';
import { userController } from './userController';
import { UserListSchema } from './userModel';

extendZodWithOpenApi(z);

export const userRegistry = new OpenAPIRegistry();
export const userRouter: Router = express.Router();

userRegistry.register('User', UserSchema);

userRegistry.registerPath({
    method: 'get',
    path: '/api/users',
    tags: ['User'],
    responses: createApiResponse(z.array(UserListSchema), 'Success'),
});

userRouter.get('/', isAuthenticated, userController.getAll);

userRegistry.registerPath({
    method: 'get',
    path: '/users/{id}',
    tags: ['User'],
    request: { params: z.object({ id: commonValidations.id }) },
    responses: createApiResponse(UserListSchema, 'Success'),
});

userRouter.get(
    '/current-location',
    isAuthenticated,
    userController.getInCurrentLocation,
);

userRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    userController.getById,
);
