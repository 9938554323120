import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { type Prisma, PrismaClient, type Statement } from '@prisma/client';
import {
    type StatementCreateDTO,
    StatementListViewModel,
    StatementPrintViewModel,
} from './statementModel';

export class StatementService {
    private prisma: PrismaClient;

    constructor() {
        this.prisma = new PrismaClient();
    }

    // Retrieves all statements from the database
    async getAll(): Promise<ServiceResponse<StatementListViewModel[] | null>> {
        try {
            const list = await this.prisma.statement.findMany({
                include: {
                    client: true,
                    bookings: true,
                },
                orderBy: { id: 'desc' },
            });

            return ServiceResponse.success<StatementListViewModel[]>(
                'Statements found',
                list.map((s) => new StatementListViewModel(s)),
            );
        } catch (ex) {
            const errorMessage = `Error getting statements: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving statements.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Retrieves a statement by their ID
    async getById(
        id: number,
    ): Promise<ServiceResponse<StatementPrintViewModel | null>> {
        try {
            const statement = await this.prisma.statement.findUnique({
                include: {
                    client: {
                        include: {
                            location: true,
                        },
                    },
                    bookings: {
                        include: {
                            origin: true,
                            destination: true,
                            items: {
                                include: {
                                    productType: true,
                                },
                            },
                        },
                    },
                    createdBy: true,
                },
                where: { id },
            });
            if (!statement) {
                return ServiceResponse.failure(
                    'Statement not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            return ServiceResponse.success<StatementPrintViewModel>(
                'Statement found',
                new StatementPrintViewModel(statement),
            );
        } catch (ex) {
            const errorMessage = `Error finding statement with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while finding statement.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Creates a single statement
    async create(
        data: StatementCreateDTO,
        userId: number,
    ): Promise<ServiceResponse<Statement | null>> {
        try {
            const [fromDate, toDate] = data.dateRange;
            const bookings = await this.prisma.booking.findMany({
                where: {
                    id: {
                        in: data.bookings,
                    },
                },
            });

            if (bookings.length !== data.bookings.length) {
                return ServiceResponse.failure(
                    'Booking Id list is incorrect',
                    null,
                    StatusCodes.BAD_REQUEST,
                );
            }

            const statement = await this.prisma.$transaction(async (tran) => {
                const statement = await tran.statement.create({
                    data: {
                        client: {
                            connect: {
                                id: data.clientId,
                            },
                        },
                        fromDate,
                        toDate,
                        amount: bookings.reduce(
                            (acc, booking) => acc + booking.totAmt.toNumber(),
                            0,
                        ),
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                    },
                });

                await tran.booking.updateMany({
                    data: {
                        statementId: statement.id,
                    },
                    where: {
                        id: {
                            in: data.bookings,
                        },
                    },
                });

                return statement;
            });

            return ServiceResponse.success<Statement>(
                'Statement Successfully Created',
                statement,
            );
        } catch (ex) {
            const errorMessage = `Error creating a statement:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while creating statement.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    // Updates a statement by their ID
    async update(
        id: number,
        data: Prisma.StatementUpdateInput,
    ): Promise<ServiceResponse<Statement | null>> {
        try {
            const statement = await this.prisma.statement.findUnique({
                where: { id },
            });
            if (!statement) {
                return ServiceResponse.failure(
                    'Statement not found',
                    null,
                    StatusCodes.NOT_FOUND,
                );
            }
            const updateStatement = await this.prisma.statement.update({
                where: {
                    id,
                },
                data,
            });

            return ServiceResponse.success<Statement>(
                'Statement successfully updated',
                updateStatement,
            );
        } catch (ex) {
            const errorMessage = `Error updating statement with id ${id}:, ${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while updating statement.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const statementService = new StatementService();
