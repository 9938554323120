import { TokenClaim } from '@/api/auth/authModel';
import { generateToken, verifyToken } from '@/common/utils/token';
import type { NextFunction, Request, Response } from 'express';

export default async function authenticate(
    req: Request,
    res: Response,
    next: NextFunction,
) {
    const authorization = req.headers.authorization || '';
    const refreshToken = (req.headers.refreshtoken as string) || '';

    req.user = null;

    if (!authorization.startsWith('Bearer ')) {
        return next();
    }

    const token = authorization.substring(7);

    let tokenData = null;
    try {
        tokenData = verifyToken(token);
    } catch (ex) {
        return next({ status: 401, message: 'Unauthorized' });
    }

    if (!tokenData) {
        return next({ status: 401, message: 'Unauthorized' });
    }

    const claims = tokenData as ITokenClaim;
    req.user = claims;

    const now = new Date();
    if (!tokenData.exp) return next({ status: 401, message: 'Unauthorized' });

    const exp = new Date(tokenData.exp * 1000);
    const difference = exp.getTime() - now.getTime();
    const minutes = Math.round(difference / 60000);

    if (refreshToken && minutes < 15) {
        const refreshTokenData = verifyToken(refreshToken);

        if (refreshTokenData.id === tokenData.id) {
            const newClaims = new TokenClaim(claims);
            const newToken = generateToken(newClaims);

            // TODO: environment variable
            const newRefreshToken = generateToken(newClaims, '2h');

            // Set response headers
            res.setHeader('token', newToken);
            res.setHeader('refresh-token', newRefreshToken);
        }
    }

    return next();
}
