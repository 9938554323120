import type { BaseModel } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import type { ManifestStatus, PaymentMode, Prisma } from '@prisma/client';
import { z } from 'zod';
import { BookingItemDetailViewModel } from '../booking/bookingModel';

export class ManifestBaggageOptionViewModel {
    readonly id: number;
    readonly baggageNo: string;
    readonly bookings: number;
    constructor(
        baggage: Prisma.BaggageGetPayload<{
            include: { bookings: true };
        }>,
    ) {
        this.id = baggage.id;
        this.baggageNo = baggage.baggageNo;
        this.bookings = baggage.bookings.length;
    }
}

export const ManifestCreateDTOSchema = z
    .object({
        originId: z.number(),
        destinationId: z.number(),
        date: commonValidations.dateNullable.optional(),
        bookings: z.array(z.number()),
        baggages: z.array(z.number()),
    })
    .refine((data) => data.bookings.length > 0 || data.baggages.length > 0, {
        message: 'At least one booking or one baggage must be selected',
        path: ['bookings', 'baggages'],
    });

export type ManifestCreateDTO = z.infer<typeof ManifestCreateDTOSchema>;

export class ManifestListViewModel {
    readonly id: number;
    readonly manifestNo: string;
    origin: BaseModel;
    destination: BaseModel;
    date: Date;
    baggageCount: number;
    bookingCount: number;
    weight: number;
    amount: number;
    status: ManifestStatus;
    constructor(
        manifest: Prisma.ManifestGetPayload<{
            include: {
                origin: true;
                destination: true;
                baggages: {
                    include: {
                        bookings: {
                            include: {
                                items: true;
                            };
                        };
                    };
                };
                bookings: {
                    include: {
                        items: true;
                    };
                };
            };
        }>,
    ) {
        this.id = manifest.id;
        this.manifestNo = manifest.manifestNo;
        this.origin = {
            id: manifest.origin.id,
            name: manifest.origin.name,
        };
        this.destination = {
            id: manifest.destination.id,
            name: manifest.destination.name,
        };
        // this.date = manifest.date.toISOString().split('T')[0];
        this.date = manifest.date;
        this.baggageCount = manifest.baggages.length;
        this.bookingCount = manifest.bookings.length;
        this.status = manifest.status;
        const allBookings = [
            ...manifest.bookings,
            ...manifest.baggages.flatMap((baggage) => baggage.bookings),
        ];
        this.weight = allBookings
            .flatMap((booking) => booking.items)
            .reduce((acc, item) => acc + item.weight.toNumber(), 0);
        this.amount = allBookings.reduce(
            (acc, booking) => acc + booking.totAmt.toNumber(),
            0,
        );
    }
}

export class ManifestDetailViewModel {
    readonly id: number;
    readonly manifestNo: string;
    origin: BaseModel;
    destination: BaseModel;
    date: Date;
    baggageCount: number;
    bookingCount: number;
    weight: number;
    amount: number;
    status: ManifestStatus;
    bookings: ManifestBooking[];
    baggages: ManifestBaggage[];
    constructor(
        manifest: Prisma.ManifestGetPayload<{
            include: {
                origin: true;
                destination: true;
                baggages: {
                    include: {
                        bookings: {
                            include: {
                                origin: true;
                                destination: true;
                                items: {
                                    include: {
                                        productType: true;
                                    };
                                };
                            };
                        };
                    };
                };
                bookings: {
                    include: {
                        origin: true;
                        destination: true;
                        items: {
                            include: {
                                productType: true;
                            };
                        };
                    };
                };
            };
        }>,
    ) {
        this.id = manifest.id;
        this.manifestNo = manifest.manifestNo;
        this.origin = {
            id: manifest.origin.id,
            name: manifest.origin.name,
        };
        this.destination = {
            id: manifest.destination.id,
            name: manifest.destination.name,
        };
        this.date = manifest.date;
        this.baggageCount = manifest.baggages.length;
        this.bookingCount = manifest.bookings.length;
        this.status = manifest.status;
        const allBookings = [
            ...manifest.bookings,
            ...manifest.baggages.flatMap((baggage) => baggage.bookings),
        ];
        this.weight = allBookings
            .flatMap((booking) => booking.items)
            .reduce((acc, item) => acc + item.weight.toNumber(), 0);
        this.amount = allBookings.reduce(
            (acc, booking) => acc + booking.totAmt.toNumber(),
            0,
        );
        this.bookings = manifest.bookings.map(
            (booking) => new ManifestBooking(booking),
        );
        this.baggages = manifest.baggages.map(
            (baggage) => new ManifestBaggage(baggage),
        );
    }
}

class ManifestBaggage {
    readonly id: number;
    readonly baggageNo: string;
    readonly bookings: ManifestBooking[];
    constructor(
        bag: Prisma.BaggageGetPayload<{
            include: {
                bookings: {
                    include: {
                        origin: true;
                        destination: true;
                        items: {
                            include: {
                                productType: true;
                            };
                        };
                    };
                };
            };
        }>,
    ) {
        this.id = bag.id;
        this.baggageNo = bag.baggageNo;
        this.bookings = bag.bookings.map(
            (booking) => new ManifestBooking(booking),
        );
    }
}

class ManifestBooking {
    readonly id: number;
    readonly consignmentNo: string;
    readonly senderName: string;
    readonly origin: BaseModel;
    readonly receiverName: string;
    readonly destination: BaseModel;
    readonly paymentMode: PaymentMode;
    readonly items: BookingItemDetailViewModel[];
    readonly piece: number;
    readonly weight: number;
    readonly totAmt: number;
    constructor(
        booking: Prisma.BookingGetPayload<{
            include: {
                origin: true;
                destination: true;
                items: {
                    include: {
                        productType: true;
                    };
                };
            };
        }>,
    ) {
        this.id = booking.id;
        this.consignmentNo = booking.consignmentNo;
        this.senderName = booking.senderName;
        this.origin = {
            id: booking.origin.id,
            name: booking.origin.name,
        };
        this.receiverName = booking.receiverName;
        this.destination = {
            id: booking.destination.id,
            name: booking.destination.name,
        };
        this.paymentMode = booking.paymentMode;
        this.items = booking.items.map(
            (item) => new BookingItemDetailViewModel(item),
        );
        this.piece = booking.items.reduce((acc, item) => acc + item.piece, 0);
        this.weight = booking.items.reduce(
            (acc, item) => acc + item.weight.toNumber(),
            0,
        );
        this.totAmt = booking.totAmt.toNumber();
    }
}
