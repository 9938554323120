import { z } from 'zod';
import { Prisma } from '@prisma/client';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// DECIMAL
//------------------------------------------------------

export const DecimalJsLikeSchema: z.ZodType<Prisma.DecimalJsLike> = z.object({
  d: z.array(z.number()),
  e: z.number(),
  s: z.number(),
  toFixed: z.function(z.tuple([]), z.string()),
})

export const DECIMAL_STRING_REGEX = /^(?:-?Infinity|NaN|-?(?:0[bB][01]+(?:\.[01]+)?(?:[pP][-+]?\d+)?|0[oO][0-7]+(?:\.[0-7]+)?(?:[pP][-+]?\d+)?|0[xX][\da-fA-F]+(?:\.[\da-fA-F]+)?(?:[pP][-+]?\d+)?|(?:\d+|\d*\.\d+)(?:[eE][-+]?\d+)?))$/;

export const isValidDecimalInput =
  (v?: null | string | number | Prisma.DecimalJsLike): v is string | number | Prisma.DecimalJsLike => {
    if (v === undefined || v === null) return false;
    return (
      (typeof v === 'object' && 'd' in v && 'e' in v && 's' in v && 'toFixed' in v) ||
      (typeof v === 'string' && DECIMAL_STRING_REGEX.test(v)) ||
      typeof v === 'number'
    )
  };

/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const BaggageScalarFieldEnumSchema = z.enum(['id','baggageNo','createdAt','updatedAt','deletedAt','createdById','manifestId','originId','destinationId','currentLocationId']);

export const BookingCommentScalarFieldEnumSchema = z.enum(['id','comment','createdAt','updatedAt','deletedAt','bookingId','createdById']);

export const BookingDeliveryScalarFieldEnumSchema = z.enum(['id','status','comment','deliveredAt','receivedBy','podPath','createdAt','updatedAt','deletedAt','bookingId','deliveryId']);

export const BookingHistoryScalarFieldEnumSchema = z.enum(['id','bookingId','date','action','refId','refNo','userId','locationId','comment','customer']);

export const BookingItemScalarFieldEnumSchema = z.enum(['id','desc','piece','weight','unit','rate','discount','total','createdAt','updatedAt','deletedAt','bookingId','productTypeId']);

export const BookingScalarFieldEnumSchema = z.enum(['id','bookingDate','consignmentNo','senderName','senderContactNo','senderEmail','senderPan','receiverName','receiverContactNo','receiverAddr','receiverPan','serviceType','deliveryMode','declaredValue','refNo','invoiceDate','instruction','paymentMode','otherCharge','tax','stCharge','totAmt','status','comment','createdAt','updatedAt','deletedAt','createdById','clientId','originId','destinationId','baggageId','manifestId','verifiedById','verifiedAt','cod','laborCharge','pickupCharge','deliveryCharge','volumeCharge','discount','statementId','currentLocationId']);

export const ClientScalarFieldEnumSchema = z.enum(['id','name','contactPerson','contactNo','email','address','pan','status','createdAt','updatedAt','deletedAt','locationId','createdById']);

export const DeliveryScalarFieldEnumSchema = z.enum(['id','date','locationId','status','createdAt','updatedAt','deletedAt','deliveredById','createdById']);

export const DistrictScalarFieldEnumSchema = z.enum(['id','name','createdAt','updatedAt','deletedAt']);

export const FiscalYearScalarFieldEnumSchema = z.enum(['id','nameEng','nameNep','curr','createdAt','updatedAt','deletedAt']);

export const GenBookingBarcodeScalarFieldEnumSchema = z.enum(['id','year','code','createdAt','updatedAt','deletedAt']);

export const LocationScalarFieldEnumSchema = z.enum(['id','name','code','contactName','contactNo','status','createdAt','updatedAt','deletedAt','districtId']);

export const ManifestScalarFieldEnumSchema = z.enum(['id','manifestNo','date','status','checkedBy','dispatchedAt','receivedAt','createdAt','updatedAt','deletedAt','currentLocationId','originId','destinationId','masterManifestId','createdById','dispatchedById','receivedById']);

export const MasterManifestDetailScalarFieldEnumSchema = z.enum(['createdAt','updatedAt','manifestId','masterManifestId']);

export const MasterManifestScalarFieldEnumSchema = z.enum(['id','masterManifestNo','date','vehicleNo','driverName','mobileNo','freight','advance','balance','status','checkedBy','dispatchedAt','receivedAt','createdAt','updatedAt','deletedAt','originId','destinationId','createdById','dispatchedById','receivedById']);

export const MonthScalarFieldEnumSchema = z.enum(['id','nameEng','nameNep','fyOrder','createdAt','updatedAt','deletedAt']);

export const ProductTypeScalarFieldEnumSchema = z.enum(['id','name','status','createdAt','updatedAt','deletedAt','createdById','unit']);

export const RateScalarFieldEnumSchema = z.enum(['id','rate','createdAt','updatedAt','deletedAt','originId','destinationId']);

export const RoleScalarFieldEnumSchema = z.enum(['id','code','name','desc','isAdmin','status','createdAt','updatedAt','deletedAt']);

export const StatementScalarFieldEnumSchema = z.enum(['id','fromDate','toDate','amount','createdAt','updatedAt','deletedAt','createdById','clientId']);

export const SubLocationScalarFieldEnumSchema = z.enum(['id','name','createdAt','updatedAt','deletedAt','locationId']);

export const UserScalarFieldEnumSchema = z.enum(['id','firstName','middleName','lastName','email','phone','userName','password','status','createdAt','updatedAt','deletedAt','locationId','roleId','clientId','passwordToken','passwordTokenExpiration']);

export const WardScalarFieldEnumSchema = z.enum(['id','nameEng','nameNep','createdAt','updatedAt','deletedAt']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const NullsOrderSchema = z.enum(['first','last']);

export const BaggageOrderByRelevanceFieldEnumSchema = z.enum(['baggageNo']);

export const BookingCommentOrderByRelevanceFieldEnumSchema = z.enum(['comment']);

export const BookingDeliveryOrderByRelevanceFieldEnumSchema = z.enum(['comment','receivedBy','podPath']);

export const BookingHistoryOrderByRelevanceFieldEnumSchema = z.enum(['refNo','comment']);

export const BookingItemOrderByRelevanceFieldEnumSchema = z.enum(['desc']);

export const BookingOrderByRelevanceFieldEnumSchema = z.enum(['consignmentNo','senderName','senderContactNo','senderEmail','senderPan','receiverName','receiverContactNo','receiverAddr','receiverPan','refNo','instruction','comment']);

export const ClientOrderByRelevanceFieldEnumSchema = z.enum(['name','contactPerson','contactNo','email','address','pan']);

export const DistrictOrderByRelevanceFieldEnumSchema = z.enum(['name']);

export const FiscalYearOrderByRelevanceFieldEnumSchema = z.enum(['nameEng','nameNep']);

export const LocationOrderByRelevanceFieldEnumSchema = z.enum(['name','code','contactName','contactNo']);

export const ManifestOrderByRelevanceFieldEnumSchema = z.enum(['manifestNo','checkedBy']);

export const MasterManifestOrderByRelevanceFieldEnumSchema = z.enum(['masterManifestNo','vehicleNo','driverName','mobileNo','checkedBy']);

export const MonthOrderByRelevanceFieldEnumSchema = z.enum(['nameEng','nameNep']);

export const ProductTypeOrderByRelevanceFieldEnumSchema = z.enum(['name']);

export const RoleOrderByRelevanceFieldEnumSchema = z.enum(['code','name','desc']);

export const SubLocationOrderByRelevanceFieldEnumSchema = z.enum(['name']);

export const UserOrderByRelevanceFieldEnumSchema = z.enum(['firstName','middleName','lastName','email','phone','userName','password','passwordToken']);

export const WardOrderByRelevanceFieldEnumSchema = z.enum(['nameEng','nameNep']);

export const DeliveryStatusSchema = z.enum(['active','complete']);

export type DeliveryStatusType = `${z.infer<typeof DeliveryStatusSchema>}`

export const BookingDeliveryStatusSchema = z.enum(['ofd','delivered','returned','cancelled']);

export type BookingDeliveryStatusType = `${z.infer<typeof BookingDeliveryStatusSchema>}`

export const ManifestStatusSchema = z.enum(['created','dispatched','received','master']);

export type ManifestStatusType = `${z.infer<typeof ManifestStatusSchema>}`

export const UnitSchema = z.enum(['piece','weight','fix']);

export type UnitType = `${z.infer<typeof UnitSchema>}`

export const MasterManifestStatusSchema = z.enum(['created','dispatched','received']);

export type MasterManifestStatusType = `${z.infer<typeof MasterManifestStatusSchema>}`

export const ServiceTypeSchema = z.enum(['air','surface','logistic']);

export type ServiceTypeType = `${z.infer<typeof ServiceTypeSchema>}`

export const DeliveryModeSchema = z.enum(['d2d','b2b','d2b','b2d']);

export type DeliveryModeType = `${z.infer<typeof DeliveryModeSchema>}`

export const PaymentModeSchema = z.enum(['cod','topay','due','paid']);

export type PaymentModeType = `${z.infer<typeof PaymentModeSchema>}`

export const BookingStatusSchema = z.enum(['requested','rejected','booked','manifest','master','received','forwarding','arrived','shipping','pod','rto']);

export type BookingStatusType = `${z.infer<typeof BookingStatusSchema>}`

export const BookingActionSchema = z.enum(['request','book','reject','bag','manifest','master','dispatch','arrive','statement','ship','deliver','return','rto','receive','comment']);

export type BookingActionType = `${z.infer<typeof BookingActionSchema>}`

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// BAGGAGE SCHEMA
/////////////////////////////////////////

export const BaggageSchema = z.object({
  id: z.number().int(),
  baggageNo: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().nullable(),
})

export type Baggage = z.infer<typeof BaggageSchema>

/////////////////////////////////////////
// BOOKING COMMENT SCHEMA
/////////////////////////////////////////

export const BookingCommentSchema = z.object({
  id: z.number().int(),
  comment: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  bookingId: z.number().int(),
  createdById: z.number().int().nullable(),
})

export type BookingComment = z.infer<typeof BookingCommentSchema>

/////////////////////////////////////////
// BOOKING DELIVERY SCHEMA
/////////////////////////////////////////

export const BookingDeliverySchema = z.object({
  status: BookingDeliveryStatusSchema,
  id: z.number().int(),
  comment: z.string().nullable(),
  deliveredAt: z.coerce.date().nullable(),
  receivedBy: z.string().nullable(),
  podPath: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  bookingId: z.number().int(),
  deliveryId: z.number().int(),
})

export type BookingDelivery = z.infer<typeof BookingDeliverySchema>

/////////////////////////////////////////
// BOOKING HISTORY SCHEMA
/////////////////////////////////////////

export const BookingHistorySchema = z.object({
  action: BookingActionSchema,
  id: z.number().int(),
  bookingId: z.number().int(),
  date: z.coerce.date(),
  refId: z.number().int().nullable(),
  refNo: z.string().nullable(),
  userId: z.number().int().nullable(),
  locationId: z.number().int().nullable(),
  comment: z.string().nullable(),
  customer: z.boolean(),
})

export type BookingHistory = z.infer<typeof BookingHistorySchema>

/////////////////////////////////////////
// BOOKING ITEM SCHEMA
/////////////////////////////////////////

export const BookingItemSchema = z.object({
  unit: UnitSchema,
  id: z.number().int(),
  desc: z.string(),
  piece: z.number().int(),
  weight: z.instanceof(Prisma.Decimal, { message: "Field 'weight' must be a Decimal. Location: ['Models', 'BookingItem']"}),
  rate: z.instanceof(Prisma.Decimal, { message: "Field 'rate' must be a Decimal. Location: ['Models', 'BookingItem']"}),
  discount: z.instanceof(Prisma.Decimal, { message: "Field 'discount' must be a Decimal. Location: ['Models', 'BookingItem']"}),
  total: z.instanceof(Prisma.Decimal, { message: "Field 'total' must be a Decimal. Location: ['Models', 'BookingItem']"}),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  bookingId: z.number().int().nullable(),
  productTypeId: z.number().int(),
})

export type BookingItem = z.infer<typeof BookingItemSchema>

/////////////////////////////////////////
// BOOKING SCHEMA
/////////////////////////////////////////

export const BookingSchema = z.object({
  serviceType: ServiceTypeSchema,
  deliveryMode: DeliveryModeSchema.nullable(),
  paymentMode: PaymentModeSchema,
  status: BookingStatusSchema,
  id: z.number().int(),
  bookingDate: z.coerce.date(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().nullable(),
  senderEmail: z.string().nullable(),
  senderPan: z.string().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().nullable(),
  receiverAddr: z.string().nullable(),
  receiverPan: z.string().nullable(),
  declaredValue: z.instanceof(Prisma.Decimal, { message: "Field 'declaredValue' must be a Decimal. Location: ['Models', 'Booking']"}).nullable(),
  refNo: z.string().nullable(),
  invoiceDate: z.coerce.date().nullable(),
  instruction: z.string().nullable(),
  otherCharge: z.instanceof(Prisma.Decimal, { message: "Field 'otherCharge' must be a Decimal. Location: ['Models', 'Booking']"}),
  tax: z.instanceof(Prisma.Decimal, { message: "Field 'tax' must be a Decimal. Location: ['Models', 'Booking']"}),
  stCharge: z.instanceof(Prisma.Decimal, { message: "Field 'stCharge' must be a Decimal. Location: ['Models', 'Booking']"}),
  totAmt: z.instanceof(Prisma.Decimal, { message: "Field 'totAmt' must be a Decimal. Location: ['Models', 'Booking']"}),
  comment: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().nullable(),
  manifestId: z.number().int().nullable(),
  verifiedById: z.number().int().nullable(),
  verifiedAt: z.coerce.date().nullable(),
  cod: z.instanceof(Prisma.Decimal, { message: "Field 'cod' must be a Decimal. Location: ['Models', 'Booking']"}).nullable(),
  laborCharge: z.instanceof(Prisma.Decimal, { message: "Field 'laborCharge' must be a Decimal. Location: ['Models', 'Booking']"}),
  pickupCharge: z.instanceof(Prisma.Decimal, { message: "Field 'pickupCharge' must be a Decimal. Location: ['Models', 'Booking']"}),
  deliveryCharge: z.instanceof(Prisma.Decimal, { message: "Field 'deliveryCharge' must be a Decimal. Location: ['Models', 'Booking']"}),
  volumeCharge: z.instanceof(Prisma.Decimal, { message: "Field 'volumeCharge' must be a Decimal. Location: ['Models', 'Booking']"}),
  discount: z.instanceof(Prisma.Decimal, { message: "Field 'discount' must be a Decimal. Location: ['Models', 'Booking']"}),
  statementId: z.number().int().nullable(),
  currentLocationId: z.number().int().nullable(),
})

export type Booking = z.infer<typeof BookingSchema>

/////////////////////////////////////////
// CLIENT SCHEMA
/////////////////////////////////////////

export const ClientSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  locationId: z.number().int().nullable(),
  createdById: z.number().int(),
})

export type Client = z.infer<typeof ClientSchema>

/////////////////////////////////////////
// DELIVERY SCHEMA
/////////////////////////////////////////

export const DeliverySchema = z.object({
  status: DeliveryStatusSchema,
  id: z.number().int(),
  date: z.coerce.date(),
  locationId: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  deliveredById: z.number().int(),
  createdById: z.number().int(),
})

export type Delivery = z.infer<typeof DeliverySchema>

/////////////////////////////////////////
// DISTRICT SCHEMA
/////////////////////////////////////////

export const DistrictSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
})

export type District = z.infer<typeof DistrictSchema>

/////////////////////////////////////////
// FISCAL YEAR SCHEMA
/////////////////////////////////////////

export const FiscalYearSchema = z.object({
  id: z.number().int(),
  nameEng: z.string(),
  nameNep: z.string(),
  curr: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
})

export type FiscalYear = z.infer<typeof FiscalYearSchema>

/////////////////////////////////////////
// GEN BOOKING BARCODE SCHEMA
/////////////////////////////////////////

export const GenBookingBarcodeSchema = z.object({
  id: z.number().int(),
  year: z.number().int(),
  code: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
})

export type GenBookingBarcode = z.infer<typeof GenBookingBarcodeSchema>

/////////////////////////////////////////
// LOCATION SCHEMA
/////////////////////////////////////////

export const LocationSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  districtId: z.number().int().nullable(),
})

export type Location = z.infer<typeof LocationSchema>

/////////////////////////////////////////
// MANIFEST SCHEMA
/////////////////////////////////////////

export const ManifestSchema = z.object({
  status: ManifestStatusSchema,
  id: z.number().int(),
  manifestNo: z.string(),
  date: z.coerce.date(),
  checkedBy: z.string().nullable(),
  dispatchedAt: z.coerce.date().nullable(),
  receivedAt: z.coerce.date().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  currentLocationId: z.number().int().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().nullable(),
  receivedById: z.number().int().nullable(),
})

export type Manifest = z.infer<typeof ManifestSchema>

/////////////////////////////////////////
// MASTER MANIFEST DETAIL SCHEMA
/////////////////////////////////////////

export const MasterManifestDetailSchema = z.object({
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  manifestId: z.number().int(),
  masterManifestId: z.number().int(),
})

export type MasterManifestDetail = z.infer<typeof MasterManifestDetailSchema>

/////////////////////////////////////////
// MASTER MANIFEST SCHEMA
/////////////////////////////////////////

export const MasterManifestSchema = z.object({
  status: MasterManifestStatusSchema,
  id: z.number().int(),
  masterManifestNo: z.string(),
  date: z.coerce.date(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number(),
  balance: z.number(),
  checkedBy: z.string().nullable(),
  dispatchedAt: z.coerce.date().nullable(),
  receivedAt: z.coerce.date().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().nullable(),
  receivedById: z.number().int().nullable(),
})

export type MasterManifest = z.infer<typeof MasterManifestSchema>

/////////////////////////////////////////
// MONTH SCHEMA
/////////////////////////////////////////

export const MonthSchema = z.object({
  id: z.number().int(),
  nameEng: z.string(),
  nameNep: z.string(),
  fyOrder: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
})

export type Month = z.infer<typeof MonthSchema>

/////////////////////////////////////////
// PRODUCT TYPE SCHEMA
/////////////////////////////////////////

export const ProductTypeSchema = z.object({
  unit: UnitSchema,
  id: z.number().int(),
  name: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  createdById: z.number().int(),
})

export type ProductType = z.infer<typeof ProductTypeSchema>

/////////////////////////////////////////
// RATE SCHEMA
/////////////////////////////////////////

export const RateSchema = z.object({
  id: z.number().int(),
  rate: z.instanceof(Prisma.Decimal, { message: "Field 'rate' must be a Decimal. Location: ['Models', 'Rate']"}),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  originId: z.number().int().nullable(),
  destinationId: z.number().int().nullable(),
})

export type Rate = z.infer<typeof RateSchema>

/////////////////////////////////////////
// ROLE SCHEMA
/////////////////////////////////////////

export const RoleSchema = z.object({
  id: z.number().int(),
  code: z.string(),
  name: z.string(),
  desc: z.string().nullable(),
  isAdmin: z.boolean(),
  status: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
})

export type Role = z.infer<typeof RoleSchema>

/////////////////////////////////////////
// STATEMENT SCHEMA
/////////////////////////////////////////

export const StatementSchema = z.object({
  id: z.number().int(),
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.instanceof(Prisma.Decimal, { message: "Field 'amount' must be a Decimal. Location: ['Models', 'Statement']"}),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int(),
})

export type Statement = z.infer<typeof StatementSchema>

/////////////////////////////////////////
// SUB LOCATION SCHEMA
/////////////////////////////////////////

export const SubLocationSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  locationId: z.number().int().nullable(),
})

export type SubLocation = z.infer<typeof SubLocationSchema>

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  id: z.number().int(),
  firstName: z.string().nullable(),
  middleName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().nullable(),
  clientId: z.number().int().nullable(),
  passwordToken: z.string().nullable(),
  passwordTokenExpiration: z.coerce.date().nullable(),
})

export type User = z.infer<typeof UserSchema>

/////////////////////////////////////////
// WARD SCHEMA
/////////////////////////////////////////

export const WardSchema = z.object({
  id: z.number().int(),
  nameEng: z.string(),
  nameNep: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
})

export type Ward = z.infer<typeof WardSchema>

/////////////////////////////////////////
// SELECT & INCLUDE
/////////////////////////////////////////

// BAGGAGE
//------------------------------------------------------

export const BaggageIncludeSchema: z.ZodType<Prisma.BaggageInclude> = z.object({
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  manifest: z.union([z.boolean(),z.lazy(() => ManifestArgsSchema)]).optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  currentLocation: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  bookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => BaggageCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const BaggageArgsSchema: z.ZodType<Prisma.BaggageDefaultArgs> = z.object({
  select: z.lazy(() => BaggageSelectSchema).optional(),
  include: z.lazy(() => BaggageIncludeSchema).optional(),
}).strict();

export const BaggageCountOutputTypeArgsSchema: z.ZodType<Prisma.BaggageCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => BaggageCountOutputTypeSelectSchema).nullish(),
}).strict();

export const BaggageCountOutputTypeSelectSchema: z.ZodType<Prisma.BaggageCountOutputTypeSelect> = z.object({
  bookings: z.boolean().optional(),
}).strict();

export const BaggageSelectSchema: z.ZodType<Prisma.BaggageSelect> = z.object({
  id: z.boolean().optional(),
  baggageNo: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  createdById: z.boolean().optional(),
  manifestId: z.boolean().optional(),
  originId: z.boolean().optional(),
  destinationId: z.boolean().optional(),
  currentLocationId: z.boolean().optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  manifest: z.union([z.boolean(),z.lazy(() => ManifestArgsSchema)]).optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  currentLocation: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  bookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => BaggageCountOutputTypeArgsSchema)]).optional(),
}).strict()

// BOOKING COMMENT
//------------------------------------------------------

export const BookingCommentIncludeSchema: z.ZodType<Prisma.BookingCommentInclude> = z.object({
  booking: z.union([z.boolean(),z.lazy(() => BookingArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const BookingCommentArgsSchema: z.ZodType<Prisma.BookingCommentDefaultArgs> = z.object({
  select: z.lazy(() => BookingCommentSelectSchema).optional(),
  include: z.lazy(() => BookingCommentIncludeSchema).optional(),
}).strict();

export const BookingCommentSelectSchema: z.ZodType<Prisma.BookingCommentSelect> = z.object({
  id: z.boolean().optional(),
  comment: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  bookingId: z.boolean().optional(),
  createdById: z.boolean().optional(),
  booking: z.union([z.boolean(),z.lazy(() => BookingArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// BOOKING DELIVERY
//------------------------------------------------------

export const BookingDeliveryIncludeSchema: z.ZodType<Prisma.BookingDeliveryInclude> = z.object({
  booking: z.union([z.boolean(),z.lazy(() => BookingArgsSchema)]).optional(),
  delivery: z.union([z.boolean(),z.lazy(() => DeliveryArgsSchema)]).optional(),
}).strict()

export const BookingDeliveryArgsSchema: z.ZodType<Prisma.BookingDeliveryDefaultArgs> = z.object({
  select: z.lazy(() => BookingDeliverySelectSchema).optional(),
  include: z.lazy(() => BookingDeliveryIncludeSchema).optional(),
}).strict();

export const BookingDeliverySelectSchema: z.ZodType<Prisma.BookingDeliverySelect> = z.object({
  id: z.boolean().optional(),
  status: z.boolean().optional(),
  comment: z.boolean().optional(),
  deliveredAt: z.boolean().optional(),
  receivedBy: z.boolean().optional(),
  podPath: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  bookingId: z.boolean().optional(),
  deliveryId: z.boolean().optional(),
  booking: z.union([z.boolean(),z.lazy(() => BookingArgsSchema)]).optional(),
  delivery: z.union([z.boolean(),z.lazy(() => DeliveryArgsSchema)]).optional(),
}).strict()

// BOOKING HISTORY
//------------------------------------------------------

export const BookingHistoryIncludeSchema: z.ZodType<Prisma.BookingHistoryInclude> = z.object({
  booking: z.union([z.boolean(),z.lazy(() => BookingArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
}).strict()

export const BookingHistoryArgsSchema: z.ZodType<Prisma.BookingHistoryDefaultArgs> = z.object({
  select: z.lazy(() => BookingHistorySelectSchema).optional(),
  include: z.lazy(() => BookingHistoryIncludeSchema).optional(),
}).strict();

export const BookingHistorySelectSchema: z.ZodType<Prisma.BookingHistorySelect> = z.object({
  id: z.boolean().optional(),
  bookingId: z.boolean().optional(),
  date: z.boolean().optional(),
  action: z.boolean().optional(),
  refId: z.boolean().optional(),
  refNo: z.boolean().optional(),
  userId: z.boolean().optional(),
  locationId: z.boolean().optional(),
  comment: z.boolean().optional(),
  customer: z.boolean().optional(),
  booking: z.union([z.boolean(),z.lazy(() => BookingArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
}).strict()

// BOOKING ITEM
//------------------------------------------------------

export const BookingItemIncludeSchema: z.ZodType<Prisma.BookingItemInclude> = z.object({
  booking: z.union([z.boolean(),z.lazy(() => BookingArgsSchema)]).optional(),
  productType: z.union([z.boolean(),z.lazy(() => ProductTypeArgsSchema)]).optional(),
}).strict()

export const BookingItemArgsSchema: z.ZodType<Prisma.BookingItemDefaultArgs> = z.object({
  select: z.lazy(() => BookingItemSelectSchema).optional(),
  include: z.lazy(() => BookingItemIncludeSchema).optional(),
}).strict();

export const BookingItemSelectSchema: z.ZodType<Prisma.BookingItemSelect> = z.object({
  id: z.boolean().optional(),
  desc: z.boolean().optional(),
  piece: z.boolean().optional(),
  weight: z.boolean().optional(),
  unit: z.boolean().optional(),
  rate: z.boolean().optional(),
  discount: z.boolean().optional(),
  total: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  bookingId: z.boolean().optional(),
  productTypeId: z.boolean().optional(),
  booking: z.union([z.boolean(),z.lazy(() => BookingArgsSchema)]).optional(),
  productType: z.union([z.boolean(),z.lazy(() => ProductTypeArgsSchema)]).optional(),
}).strict()

// BOOKING
//------------------------------------------------------

export const BookingIncludeSchema: z.ZodType<Prisma.BookingInclude> = z.object({
  comments: z.union([z.boolean(),z.lazy(() => BookingCommentFindManyArgsSchema)]).optional(),
  deliveries: z.union([z.boolean(),z.lazy(() => BookingDeliveryFindManyArgsSchema)]).optional(),
  items: z.union([z.boolean(),z.lazy(() => BookingItemFindManyArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  client: z.union([z.boolean(),z.lazy(() => ClientArgsSchema)]).optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  baggage: z.union([z.boolean(),z.lazy(() => BaggageArgsSchema)]).optional(),
  manifest: z.union([z.boolean(),z.lazy(() => ManifestArgsSchema)]).optional(),
  verifiedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  currentLocation: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  history: z.union([z.boolean(),z.lazy(() => BookingHistoryFindManyArgsSchema)]).optional(),
  statement: z.union([z.boolean(),z.lazy(() => StatementArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => BookingCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const BookingArgsSchema: z.ZodType<Prisma.BookingDefaultArgs> = z.object({
  select: z.lazy(() => BookingSelectSchema).optional(),
  include: z.lazy(() => BookingIncludeSchema).optional(),
}).strict();

export const BookingCountOutputTypeArgsSchema: z.ZodType<Prisma.BookingCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => BookingCountOutputTypeSelectSchema).nullish(),
}).strict();

export const BookingCountOutputTypeSelectSchema: z.ZodType<Prisma.BookingCountOutputTypeSelect> = z.object({
  comments: z.boolean().optional(),
  deliveries: z.boolean().optional(),
  items: z.boolean().optional(),
  history: z.boolean().optional(),
}).strict();

export const BookingSelectSchema: z.ZodType<Prisma.BookingSelect> = z.object({
  id: z.boolean().optional(),
  bookingDate: z.boolean().optional(),
  consignmentNo: z.boolean().optional(),
  senderName: z.boolean().optional(),
  senderContactNo: z.boolean().optional(),
  senderEmail: z.boolean().optional(),
  senderPan: z.boolean().optional(),
  receiverName: z.boolean().optional(),
  receiverContactNo: z.boolean().optional(),
  receiverAddr: z.boolean().optional(),
  receiverPan: z.boolean().optional(),
  serviceType: z.boolean().optional(),
  deliveryMode: z.boolean().optional(),
  declaredValue: z.boolean().optional(),
  refNo: z.boolean().optional(),
  invoiceDate: z.boolean().optional(),
  instruction: z.boolean().optional(),
  paymentMode: z.boolean().optional(),
  otherCharge: z.boolean().optional(),
  tax: z.boolean().optional(),
  stCharge: z.boolean().optional(),
  totAmt: z.boolean().optional(),
  status: z.boolean().optional(),
  comment: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  createdById: z.boolean().optional(),
  clientId: z.boolean().optional(),
  originId: z.boolean().optional(),
  destinationId: z.boolean().optional(),
  baggageId: z.boolean().optional(),
  manifestId: z.boolean().optional(),
  verifiedById: z.boolean().optional(),
  verifiedAt: z.boolean().optional(),
  cod: z.boolean().optional(),
  laborCharge: z.boolean().optional(),
  pickupCharge: z.boolean().optional(),
  deliveryCharge: z.boolean().optional(),
  volumeCharge: z.boolean().optional(),
  discount: z.boolean().optional(),
  statementId: z.boolean().optional(),
  currentLocationId: z.boolean().optional(),
  comments: z.union([z.boolean(),z.lazy(() => BookingCommentFindManyArgsSchema)]).optional(),
  deliveries: z.union([z.boolean(),z.lazy(() => BookingDeliveryFindManyArgsSchema)]).optional(),
  items: z.union([z.boolean(),z.lazy(() => BookingItemFindManyArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  client: z.union([z.boolean(),z.lazy(() => ClientArgsSchema)]).optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  baggage: z.union([z.boolean(),z.lazy(() => BaggageArgsSchema)]).optional(),
  manifest: z.union([z.boolean(),z.lazy(() => ManifestArgsSchema)]).optional(),
  verifiedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  currentLocation: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  history: z.union([z.boolean(),z.lazy(() => BookingHistoryFindManyArgsSchema)]).optional(),
  statement: z.union([z.boolean(),z.lazy(() => StatementArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => BookingCountOutputTypeArgsSchema)]).optional(),
}).strict()

// CLIENT
//------------------------------------------------------

export const ClientIncludeSchema: z.ZodType<Prisma.ClientInclude> = z.object({
  bookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  statements: z.union([z.boolean(),z.lazy(() => StatementFindManyArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => ClientCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const ClientArgsSchema: z.ZodType<Prisma.ClientDefaultArgs> = z.object({
  select: z.lazy(() => ClientSelectSchema).optional(),
  include: z.lazy(() => ClientIncludeSchema).optional(),
}).strict();

export const ClientCountOutputTypeArgsSchema: z.ZodType<Prisma.ClientCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => ClientCountOutputTypeSelectSchema).nullish(),
}).strict();

export const ClientCountOutputTypeSelectSchema: z.ZodType<Prisma.ClientCountOutputTypeSelect> = z.object({
  bookings: z.boolean().optional(),
  statements: z.boolean().optional(),
  user: z.boolean().optional(),
}).strict();

export const ClientSelectSchema: z.ZodType<Prisma.ClientSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  contactPerson: z.boolean().optional(),
  contactNo: z.boolean().optional(),
  email: z.boolean().optional(),
  address: z.boolean().optional(),
  pan: z.boolean().optional(),
  status: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  locationId: z.boolean().optional(),
  createdById: z.boolean().optional(),
  bookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  statements: z.union([z.boolean(),z.lazy(() => StatementFindManyArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => ClientCountOutputTypeArgsSchema)]).optional(),
}).strict()

// DELIVERY
//------------------------------------------------------

export const DeliveryIncludeSchema: z.ZodType<Prisma.DeliveryInclude> = z.object({
  bookings: z.union([z.boolean(),z.lazy(() => BookingDeliveryFindManyArgsSchema)]).optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  deliveredBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => DeliveryCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const DeliveryArgsSchema: z.ZodType<Prisma.DeliveryDefaultArgs> = z.object({
  select: z.lazy(() => DeliverySelectSchema).optional(),
  include: z.lazy(() => DeliveryIncludeSchema).optional(),
}).strict();

export const DeliveryCountOutputTypeArgsSchema: z.ZodType<Prisma.DeliveryCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => DeliveryCountOutputTypeSelectSchema).nullish(),
}).strict();

export const DeliveryCountOutputTypeSelectSchema: z.ZodType<Prisma.DeliveryCountOutputTypeSelect> = z.object({
  bookings: z.boolean().optional(),
}).strict();

export const DeliverySelectSchema: z.ZodType<Prisma.DeliverySelect> = z.object({
  id: z.boolean().optional(),
  date: z.boolean().optional(),
  locationId: z.boolean().optional(),
  status: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  deliveredById: z.boolean().optional(),
  createdById: z.boolean().optional(),
  bookings: z.union([z.boolean(),z.lazy(() => BookingDeliveryFindManyArgsSchema)]).optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  deliveredBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => DeliveryCountOutputTypeArgsSchema)]).optional(),
}).strict()

// DISTRICT
//------------------------------------------------------

export const DistrictIncludeSchema: z.ZodType<Prisma.DistrictInclude> = z.object({
  locations: z.union([z.boolean(),z.lazy(() => LocationFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => DistrictCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const DistrictArgsSchema: z.ZodType<Prisma.DistrictDefaultArgs> = z.object({
  select: z.lazy(() => DistrictSelectSchema).optional(),
  include: z.lazy(() => DistrictIncludeSchema).optional(),
}).strict();

export const DistrictCountOutputTypeArgsSchema: z.ZodType<Prisma.DistrictCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => DistrictCountOutputTypeSelectSchema).nullish(),
}).strict();

export const DistrictCountOutputTypeSelectSchema: z.ZodType<Prisma.DistrictCountOutputTypeSelect> = z.object({
  locations: z.boolean().optional(),
}).strict();

export const DistrictSelectSchema: z.ZodType<Prisma.DistrictSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  locations: z.union([z.boolean(),z.lazy(() => LocationFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => DistrictCountOutputTypeArgsSchema)]).optional(),
}).strict()

// FISCAL YEAR
//------------------------------------------------------

export const FiscalYearSelectSchema: z.ZodType<Prisma.FiscalYearSelect> = z.object({
  id: z.boolean().optional(),
  nameEng: z.boolean().optional(),
  nameNep: z.boolean().optional(),
  curr: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
}).strict()

// GEN BOOKING BARCODE
//------------------------------------------------------

export const GenBookingBarcodeSelectSchema: z.ZodType<Prisma.GenBookingBarcodeSelect> = z.object({
  id: z.boolean().optional(),
  year: z.boolean().optional(),
  code: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
}).strict()

// LOCATION
//------------------------------------------------------

export const LocationIncludeSchema: z.ZodType<Prisma.LocationInclude> = z.object({
  originBaggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  destinationBaggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  currentBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  currentBaggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  originBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  destinationBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  clients: z.union([z.boolean(),z.lazy(() => ClientFindManyArgsSchema)]).optional(),
  district: z.union([z.boolean(),z.lazy(() => DistrictArgsSchema)]).optional(),
  currentManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  originManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  destinationManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  originMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  destinationMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  originRates: z.union([z.boolean(),z.lazy(() => RateFindManyArgsSchema)]).optional(),
  destinationRates: z.union([z.boolean(),z.lazy(() => RateFindManyArgsSchema)]).optional(),
  subLocations: z.union([z.boolean(),z.lazy(() => SubLocationFindManyArgsSchema)]).optional(),
  users: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  deliveries: z.union([z.boolean(),z.lazy(() => DeliveryFindManyArgsSchema)]).optional(),
  bookingHistory: z.union([z.boolean(),z.lazy(() => BookingHistoryFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => LocationCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const LocationArgsSchema: z.ZodType<Prisma.LocationDefaultArgs> = z.object({
  select: z.lazy(() => LocationSelectSchema).optional(),
  include: z.lazy(() => LocationIncludeSchema).optional(),
}).strict();

export const LocationCountOutputTypeArgsSchema: z.ZodType<Prisma.LocationCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => LocationCountOutputTypeSelectSchema).nullish(),
}).strict();

export const LocationCountOutputTypeSelectSchema: z.ZodType<Prisma.LocationCountOutputTypeSelect> = z.object({
  originBaggages: z.boolean().optional(),
  destinationBaggages: z.boolean().optional(),
  currentBookings: z.boolean().optional(),
  currentBaggages: z.boolean().optional(),
  originBookings: z.boolean().optional(),
  destinationBookings: z.boolean().optional(),
  clients: z.boolean().optional(),
  currentManifests: z.boolean().optional(),
  originManifests: z.boolean().optional(),
  destinationManifests: z.boolean().optional(),
  originMasterManifests: z.boolean().optional(),
  destinationMasterManifests: z.boolean().optional(),
  originRates: z.boolean().optional(),
  destinationRates: z.boolean().optional(),
  subLocations: z.boolean().optional(),
  users: z.boolean().optional(),
  deliveries: z.boolean().optional(),
  bookingHistory: z.boolean().optional(),
}).strict();

export const LocationSelectSchema: z.ZodType<Prisma.LocationSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  code: z.boolean().optional(),
  contactName: z.boolean().optional(),
  contactNo: z.boolean().optional(),
  status: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  districtId: z.boolean().optional(),
  originBaggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  destinationBaggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  currentBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  currentBaggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  originBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  destinationBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  clients: z.union([z.boolean(),z.lazy(() => ClientFindManyArgsSchema)]).optional(),
  district: z.union([z.boolean(),z.lazy(() => DistrictArgsSchema)]).optional(),
  currentManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  originManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  destinationManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  originMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  destinationMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  originRates: z.union([z.boolean(),z.lazy(() => RateFindManyArgsSchema)]).optional(),
  destinationRates: z.union([z.boolean(),z.lazy(() => RateFindManyArgsSchema)]).optional(),
  subLocations: z.union([z.boolean(),z.lazy(() => SubLocationFindManyArgsSchema)]).optional(),
  users: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  deliveries: z.union([z.boolean(),z.lazy(() => DeliveryFindManyArgsSchema)]).optional(),
  bookingHistory: z.union([z.boolean(),z.lazy(() => BookingHistoryFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => LocationCountOutputTypeArgsSchema)]).optional(),
}).strict()

// MANIFEST
//------------------------------------------------------

export const ManifestIncludeSchema: z.ZodType<Prisma.ManifestInclude> = z.object({
  baggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  bookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  currentLocation: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  currentMasterManifest: z.union([z.boolean(),z.lazy(() => MasterManifestArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  dispatchedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  receivedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  masterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestDetailFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => ManifestCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const ManifestArgsSchema: z.ZodType<Prisma.ManifestDefaultArgs> = z.object({
  select: z.lazy(() => ManifestSelectSchema).optional(),
  include: z.lazy(() => ManifestIncludeSchema).optional(),
}).strict();

export const ManifestCountOutputTypeArgsSchema: z.ZodType<Prisma.ManifestCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => ManifestCountOutputTypeSelectSchema).nullish(),
}).strict();

export const ManifestCountOutputTypeSelectSchema: z.ZodType<Prisma.ManifestCountOutputTypeSelect> = z.object({
  baggages: z.boolean().optional(),
  bookings: z.boolean().optional(),
  masterManifests: z.boolean().optional(),
}).strict();

export const ManifestSelectSchema: z.ZodType<Prisma.ManifestSelect> = z.object({
  id: z.boolean().optional(),
  manifestNo: z.boolean().optional(),
  date: z.boolean().optional(),
  status: z.boolean().optional(),
  checkedBy: z.boolean().optional(),
  dispatchedAt: z.boolean().optional(),
  receivedAt: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  currentLocationId: z.boolean().optional(),
  originId: z.boolean().optional(),
  destinationId: z.boolean().optional(),
  masterManifestId: z.boolean().optional(),
  createdById: z.boolean().optional(),
  dispatchedById: z.boolean().optional(),
  receivedById: z.boolean().optional(),
  baggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  bookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  currentLocation: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  currentMasterManifest: z.union([z.boolean(),z.lazy(() => MasterManifestArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  dispatchedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  receivedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  masterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestDetailFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => ManifestCountOutputTypeArgsSchema)]).optional(),
}).strict()

// MASTER MANIFEST DETAIL
//------------------------------------------------------

export const MasterManifestDetailIncludeSchema: z.ZodType<Prisma.MasterManifestDetailInclude> = z.object({
  manifest: z.union([z.boolean(),z.lazy(() => ManifestArgsSchema)]).optional(),
  masterManifest: z.union([z.boolean(),z.lazy(() => MasterManifestArgsSchema)]).optional(),
}).strict()

export const MasterManifestDetailArgsSchema: z.ZodType<Prisma.MasterManifestDetailDefaultArgs> = z.object({
  select: z.lazy(() => MasterManifestDetailSelectSchema).optional(),
  include: z.lazy(() => MasterManifestDetailIncludeSchema).optional(),
}).strict();

export const MasterManifestDetailSelectSchema: z.ZodType<Prisma.MasterManifestDetailSelect> = z.object({
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  manifestId: z.boolean().optional(),
  masterManifestId: z.boolean().optional(),
  manifest: z.union([z.boolean(),z.lazy(() => ManifestArgsSchema)]).optional(),
  masterManifest: z.union([z.boolean(),z.lazy(() => MasterManifestArgsSchema)]).optional(),
}).strict()

// MASTER MANIFEST
//------------------------------------------------------

export const MasterManifestIncludeSchema: z.ZodType<Prisma.MasterManifestInclude> = z.object({
  manifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  details: z.union([z.boolean(),z.lazy(() => MasterManifestDetailFindManyArgsSchema)]).optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  dispatchedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  receivedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => MasterManifestCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const MasterManifestArgsSchema: z.ZodType<Prisma.MasterManifestDefaultArgs> = z.object({
  select: z.lazy(() => MasterManifestSelectSchema).optional(),
  include: z.lazy(() => MasterManifestIncludeSchema).optional(),
}).strict();

export const MasterManifestCountOutputTypeArgsSchema: z.ZodType<Prisma.MasterManifestCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => MasterManifestCountOutputTypeSelectSchema).nullish(),
}).strict();

export const MasterManifestCountOutputTypeSelectSchema: z.ZodType<Prisma.MasterManifestCountOutputTypeSelect> = z.object({
  manifests: z.boolean().optional(),
  details: z.boolean().optional(),
}).strict();

export const MasterManifestSelectSchema: z.ZodType<Prisma.MasterManifestSelect> = z.object({
  id: z.boolean().optional(),
  masterManifestNo: z.boolean().optional(),
  date: z.boolean().optional(),
  vehicleNo: z.boolean().optional(),
  driverName: z.boolean().optional(),
  mobileNo: z.boolean().optional(),
  freight: z.boolean().optional(),
  advance: z.boolean().optional(),
  balance: z.boolean().optional(),
  status: z.boolean().optional(),
  checkedBy: z.boolean().optional(),
  dispatchedAt: z.boolean().optional(),
  receivedAt: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  originId: z.boolean().optional(),
  destinationId: z.boolean().optional(),
  createdById: z.boolean().optional(),
  dispatchedById: z.boolean().optional(),
  receivedById: z.boolean().optional(),
  manifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  details: z.union([z.boolean(),z.lazy(() => MasterManifestDetailFindManyArgsSchema)]).optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  dispatchedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  receivedBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => MasterManifestCountOutputTypeArgsSchema)]).optional(),
}).strict()

// MONTH
//------------------------------------------------------

export const MonthSelectSchema: z.ZodType<Prisma.MonthSelect> = z.object({
  id: z.boolean().optional(),
  nameEng: z.boolean().optional(),
  nameNep: z.boolean().optional(),
  fyOrder: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
}).strict()

// PRODUCT TYPE
//------------------------------------------------------

export const ProductTypeIncludeSchema: z.ZodType<Prisma.ProductTypeInclude> = z.object({
  bookingItems: z.union([z.boolean(),z.lazy(() => BookingItemFindManyArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => ProductTypeCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const ProductTypeArgsSchema: z.ZodType<Prisma.ProductTypeDefaultArgs> = z.object({
  select: z.lazy(() => ProductTypeSelectSchema).optional(),
  include: z.lazy(() => ProductTypeIncludeSchema).optional(),
}).strict();

export const ProductTypeCountOutputTypeArgsSchema: z.ZodType<Prisma.ProductTypeCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => ProductTypeCountOutputTypeSelectSchema).nullish(),
}).strict();

export const ProductTypeCountOutputTypeSelectSchema: z.ZodType<Prisma.ProductTypeCountOutputTypeSelect> = z.object({
  bookingItems: z.boolean().optional(),
}).strict();

export const ProductTypeSelectSchema: z.ZodType<Prisma.ProductTypeSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  status: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  createdById: z.boolean().optional(),
  unit: z.boolean().optional(),
  bookingItems: z.union([z.boolean(),z.lazy(() => BookingItemFindManyArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => ProductTypeCountOutputTypeArgsSchema)]).optional(),
}).strict()

// RATE
//------------------------------------------------------

export const RateIncludeSchema: z.ZodType<Prisma.RateInclude> = z.object({
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
}).strict()

export const RateArgsSchema: z.ZodType<Prisma.RateDefaultArgs> = z.object({
  select: z.lazy(() => RateSelectSchema).optional(),
  include: z.lazy(() => RateIncludeSchema).optional(),
}).strict();

export const RateSelectSchema: z.ZodType<Prisma.RateSelect> = z.object({
  id: z.boolean().optional(),
  rate: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  originId: z.boolean().optional(),
  destinationId: z.boolean().optional(),
  origin: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  destination: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
}).strict()

// ROLE
//------------------------------------------------------

export const RoleIncludeSchema: z.ZodType<Prisma.RoleInclude> = z.object({
  users: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => RoleCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const RoleArgsSchema: z.ZodType<Prisma.RoleDefaultArgs> = z.object({
  select: z.lazy(() => RoleSelectSchema).optional(),
  include: z.lazy(() => RoleIncludeSchema).optional(),
}).strict();

export const RoleCountOutputTypeArgsSchema: z.ZodType<Prisma.RoleCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => RoleCountOutputTypeSelectSchema).nullish(),
}).strict();

export const RoleCountOutputTypeSelectSchema: z.ZodType<Prisma.RoleCountOutputTypeSelect> = z.object({
  users: z.boolean().optional(),
}).strict();

export const RoleSelectSchema: z.ZodType<Prisma.RoleSelect> = z.object({
  id: z.boolean().optional(),
  code: z.boolean().optional(),
  name: z.boolean().optional(),
  desc: z.boolean().optional(),
  isAdmin: z.boolean().optional(),
  status: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  users: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => RoleCountOutputTypeArgsSchema)]).optional(),
}).strict()

// STATEMENT
//------------------------------------------------------

export const StatementIncludeSchema: z.ZodType<Prisma.StatementInclude> = z.object({
  client: z.union([z.boolean(),z.lazy(() => ClientArgsSchema)]).optional(),
  bookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => StatementCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const StatementArgsSchema: z.ZodType<Prisma.StatementDefaultArgs> = z.object({
  select: z.lazy(() => StatementSelectSchema).optional(),
  include: z.lazy(() => StatementIncludeSchema).optional(),
}).strict();

export const StatementCountOutputTypeArgsSchema: z.ZodType<Prisma.StatementCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => StatementCountOutputTypeSelectSchema).nullish(),
}).strict();

export const StatementCountOutputTypeSelectSchema: z.ZodType<Prisma.StatementCountOutputTypeSelect> = z.object({
  bookings: z.boolean().optional(),
}).strict();

export const StatementSelectSchema: z.ZodType<Prisma.StatementSelect> = z.object({
  id: z.boolean().optional(),
  fromDate: z.boolean().optional(),
  toDate: z.boolean().optional(),
  amount: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  createdById: z.boolean().optional(),
  clientId: z.boolean().optional(),
  client: z.union([z.boolean(),z.lazy(() => ClientArgsSchema)]).optional(),
  bookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  createdBy: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => StatementCountOutputTypeArgsSchema)]).optional(),
}).strict()

// SUB LOCATION
//------------------------------------------------------

export const SubLocationIncludeSchema: z.ZodType<Prisma.SubLocationInclude> = z.object({
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
}).strict()

export const SubLocationArgsSchema: z.ZodType<Prisma.SubLocationDefaultArgs> = z.object({
  select: z.lazy(() => SubLocationSelectSchema).optional(),
  include: z.lazy(() => SubLocationIncludeSchema).optional(),
}).strict();

export const SubLocationSelectSchema: z.ZodType<Prisma.SubLocationSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  locationId: z.boolean().optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
}).strict()

// USER
//------------------------------------------------------

export const UserIncludeSchema: z.ZodType<Prisma.UserInclude> = z.object({
  createdBaggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  bookingComments: z.union([z.boolean(),z.lazy(() => BookingCommentFindManyArgsSchema)]).optional(),
  createdBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  verifiedBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  createdClients: z.union([z.boolean(),z.lazy(() => ClientFindManyArgsSchema)]).optional(),
  deliveredDeliveries: z.union([z.boolean(),z.lazy(() => DeliveryFindManyArgsSchema)]).optional(),
  createdDeliveries: z.union([z.boolean(),z.lazy(() => DeliveryFindManyArgsSchema)]).optional(),
  createdManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  dispatchedManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  receivedManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  createdMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  dispatchedMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  receivedMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  createdProductTypes: z.union([z.boolean(),z.lazy(() => ProductTypeFindManyArgsSchema)]).optional(),
  client: z.union([z.boolean(),z.lazy(() => ClientArgsSchema)]).optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
  bookingHistory: z.union([z.boolean(),z.lazy(() => BookingHistoryFindManyArgsSchema)]).optional(),
  Statement: z.union([z.boolean(),z.lazy(() => StatementFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => UserCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const UserArgsSchema: z.ZodType<Prisma.UserDefaultArgs> = z.object({
  select: z.lazy(() => UserSelectSchema).optional(),
  include: z.lazy(() => UserIncludeSchema).optional(),
}).strict();

export const UserCountOutputTypeArgsSchema: z.ZodType<Prisma.UserCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => UserCountOutputTypeSelectSchema).nullish(),
}).strict();

export const UserCountOutputTypeSelectSchema: z.ZodType<Prisma.UserCountOutputTypeSelect> = z.object({
  createdBaggages: z.boolean().optional(),
  bookingComments: z.boolean().optional(),
  createdBookings: z.boolean().optional(),
  verifiedBookings: z.boolean().optional(),
  createdClients: z.boolean().optional(),
  deliveredDeliveries: z.boolean().optional(),
  createdDeliveries: z.boolean().optional(),
  createdManifests: z.boolean().optional(),
  dispatchedManifests: z.boolean().optional(),
  receivedManifests: z.boolean().optional(),
  createdMasterManifests: z.boolean().optional(),
  dispatchedMasterManifests: z.boolean().optional(),
  receivedMasterManifests: z.boolean().optional(),
  createdProductTypes: z.boolean().optional(),
  bookingHistory: z.boolean().optional(),
  Statement: z.boolean().optional(),
}).strict();

export const UserSelectSchema: z.ZodType<Prisma.UserSelect> = z.object({
  id: z.boolean().optional(),
  firstName: z.boolean().optional(),
  middleName: z.boolean().optional(),
  lastName: z.boolean().optional(),
  email: z.boolean().optional(),
  phone: z.boolean().optional(),
  userName: z.boolean().optional(),
  password: z.boolean().optional(),
  status: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  locationId: z.boolean().optional(),
  roleId: z.boolean().optional(),
  clientId: z.boolean().optional(),
  passwordToken: z.boolean().optional(),
  passwordTokenExpiration: z.boolean().optional(),
  createdBaggages: z.union([z.boolean(),z.lazy(() => BaggageFindManyArgsSchema)]).optional(),
  bookingComments: z.union([z.boolean(),z.lazy(() => BookingCommentFindManyArgsSchema)]).optional(),
  createdBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  verifiedBookings: z.union([z.boolean(),z.lazy(() => BookingFindManyArgsSchema)]).optional(),
  createdClients: z.union([z.boolean(),z.lazy(() => ClientFindManyArgsSchema)]).optional(),
  deliveredDeliveries: z.union([z.boolean(),z.lazy(() => DeliveryFindManyArgsSchema)]).optional(),
  createdDeliveries: z.union([z.boolean(),z.lazy(() => DeliveryFindManyArgsSchema)]).optional(),
  createdManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  dispatchedManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  receivedManifests: z.union([z.boolean(),z.lazy(() => ManifestFindManyArgsSchema)]).optional(),
  createdMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  dispatchedMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  receivedMasterManifests: z.union([z.boolean(),z.lazy(() => MasterManifestFindManyArgsSchema)]).optional(),
  createdProductTypes: z.union([z.boolean(),z.lazy(() => ProductTypeFindManyArgsSchema)]).optional(),
  client: z.union([z.boolean(),z.lazy(() => ClientArgsSchema)]).optional(),
  location: z.union([z.boolean(),z.lazy(() => LocationArgsSchema)]).optional(),
  role: z.union([z.boolean(),z.lazy(() => RoleArgsSchema)]).optional(),
  bookingHistory: z.union([z.boolean(),z.lazy(() => BookingHistoryFindManyArgsSchema)]).optional(),
  Statement: z.union([z.boolean(),z.lazy(() => StatementFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => UserCountOutputTypeArgsSchema)]).optional(),
}).strict()

// WARD
//------------------------------------------------------

export const WardSelectSchema: z.ZodType<Prisma.WardSelect> = z.object({
  id: z.boolean().optional(),
  nameEng: z.boolean().optional(),
  nameNep: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
}).strict()


/////////////////////////////////////////
// INPUT TYPES
/////////////////////////////////////////

export const BaggageWhereInputSchema: z.ZodType<Prisma.BaggageWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BaggageWhereInputSchema),z.lazy(() => BaggageWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BaggageWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BaggageWhereInputSchema),z.lazy(() => BaggageWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  baggageNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  manifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  manifest: z.union([ z.lazy(() => ManifestNullableScalarRelationFilterSchema),z.lazy(() => ManifestWhereInputSchema) ]).optional().nullable(),
  origin: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  destination: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  currentLocation: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingListRelationFilterSchema).optional()
}).strict();

export const BaggageOrderByWithRelationInputSchema: z.ZodType<Prisma.BaggageOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  baggageNo: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  manifest: z.lazy(() => ManifestOrderByWithRelationInputSchema).optional(),
  origin: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  destination: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  currentLocation: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  bookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => BaggageOrderByRelevanceInputSchema).optional()
}).strict();

export const BaggageWhereUniqueInputSchema: z.ZodType<Prisma.BaggageWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    baggageNo: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    baggageNo: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  baggageNo: z.string().optional(),
  AND: z.union([ z.lazy(() => BaggageWhereInputSchema),z.lazy(() => BaggageWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BaggageWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BaggageWhereInputSchema),z.lazy(() => BaggageWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  manifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  manifest: z.union([ z.lazy(() => ManifestNullableScalarRelationFilterSchema),z.lazy(() => ManifestWhereInputSchema) ]).optional().nullable(),
  origin: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  destination: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  currentLocation: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingListRelationFilterSchema).optional()
}).strict());

export const BaggageOrderByWithAggregationInputSchema: z.ZodType<Prisma.BaggageOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  baggageNo: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => BaggageCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => BaggageAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BaggageMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BaggageMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => BaggageSumOrderByAggregateInputSchema).optional()
}).strict();

export const BaggageScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BaggageScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BaggageScalarWhereWithAggregatesInputSchema),z.lazy(() => BaggageScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BaggageScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BaggageScalarWhereWithAggregatesInputSchema),z.lazy(() => BaggageScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  baggageNo: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  manifestId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  currentLocationId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const BookingCommentWhereInputSchema: z.ZodType<Prisma.BookingCommentWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingCommentWhereInputSchema),z.lazy(() => BookingCommentWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingCommentWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingCommentWhereInputSchema),z.lazy(() => BookingCommentWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  comment: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  booking: z.union([ z.lazy(() => BookingScalarRelationFilterSchema),z.lazy(() => BookingWhereInputSchema) ]).optional(),
  createdBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
}).strict();

export const BookingCommentOrderByWithRelationInputSchema: z.ZodType<Prisma.BookingCommentOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  booking: z.lazy(() => BookingOrderByWithRelationInputSchema).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => BookingCommentOrderByRelevanceInputSchema).optional()
}).strict();

export const BookingCommentWhereUniqueInputSchema: z.ZodType<Prisma.BookingCommentWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => BookingCommentWhereInputSchema),z.lazy(() => BookingCommentWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingCommentWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingCommentWhereInputSchema),z.lazy(() => BookingCommentWhereInputSchema).array() ]).optional(),
  comment: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  createdById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  booking: z.union([ z.lazy(() => BookingScalarRelationFilterSchema),z.lazy(() => BookingWhereInputSchema) ]).optional(),
  createdBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
}).strict());

export const BookingCommentOrderByWithAggregationInputSchema: z.ZodType<Prisma.BookingCommentOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => BookingCommentCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => BookingCommentAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BookingCommentMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BookingCommentMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => BookingCommentSumOrderByAggregateInputSchema).optional()
}).strict();

export const BookingCommentScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BookingCommentScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BookingCommentScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingCommentScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingCommentScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingCommentScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingCommentScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  comment: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const BookingDeliveryWhereInputSchema: z.ZodType<Prisma.BookingDeliveryWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingDeliveryWhereInputSchema),z.lazy(() => BookingDeliveryWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingDeliveryWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingDeliveryWhereInputSchema),z.lazy(() => BookingDeliveryWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumBookingDeliveryStatusFilterSchema),z.lazy(() => BookingDeliveryStatusSchema) ]).optional(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deliveredAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  podPath: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  deliveryId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  booking: z.union([ z.lazy(() => BookingScalarRelationFilterSchema),z.lazy(() => BookingWhereInputSchema) ]).optional(),
  delivery: z.union([ z.lazy(() => DeliveryScalarRelationFilterSchema),z.lazy(() => DeliveryWhereInputSchema) ]).optional(),
}).strict();

export const BookingDeliveryOrderByWithRelationInputSchema: z.ZodType<Prisma.BookingDeliveryOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  deliveredAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedBy: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  podPath: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  deliveryId: z.lazy(() => SortOrderSchema).optional(),
  booking: z.lazy(() => BookingOrderByWithRelationInputSchema).optional(),
  delivery: z.lazy(() => DeliveryOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => BookingDeliveryOrderByRelevanceInputSchema).optional()
}).strict();

export const BookingDeliveryWhereUniqueInputSchema: z.ZodType<Prisma.BookingDeliveryWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => BookingDeliveryWhereInputSchema),z.lazy(() => BookingDeliveryWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingDeliveryWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingDeliveryWhereInputSchema),z.lazy(() => BookingDeliveryWhereInputSchema).array() ]).optional(),
  status: z.union([ z.lazy(() => EnumBookingDeliveryStatusFilterSchema),z.lazy(() => BookingDeliveryStatusSchema) ]).optional(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deliveredAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  podPath: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  deliveryId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  booking: z.union([ z.lazy(() => BookingScalarRelationFilterSchema),z.lazy(() => BookingWhereInputSchema) ]).optional(),
  delivery: z.union([ z.lazy(() => DeliveryScalarRelationFilterSchema),z.lazy(() => DeliveryWhereInputSchema) ]).optional(),
}).strict());

export const BookingDeliveryOrderByWithAggregationInputSchema: z.ZodType<Prisma.BookingDeliveryOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  deliveredAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedBy: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  podPath: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  deliveryId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => BookingDeliveryCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => BookingDeliveryAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BookingDeliveryMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BookingDeliveryMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => BookingDeliverySumOrderByAggregateInputSchema).optional()
}).strict();

export const BookingDeliveryScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BookingDeliveryScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BookingDeliveryScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingDeliveryScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingDeliveryScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingDeliveryScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingDeliveryScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumBookingDeliveryStatusWithAggregatesFilterSchema),z.lazy(() => BookingDeliveryStatusSchema) ]).optional(),
  comment: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  deliveredAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedBy: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  podPath: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  deliveryId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const BookingHistoryWhereInputSchema: z.ZodType<Prisma.BookingHistoryWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingHistoryWhereInputSchema),z.lazy(() => BookingHistoryWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingHistoryWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingHistoryWhereInputSchema),z.lazy(() => BookingHistoryWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  action: z.union([ z.lazy(() => EnumBookingActionFilterSchema),z.lazy(() => BookingActionSchema) ]).optional(),
  refId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  refNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  userId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  customer: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  booking: z.union([ z.lazy(() => BookingScalarRelationFilterSchema),z.lazy(() => BookingWhereInputSchema) ]).optional(),
  user: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  location: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
}).strict();

export const BookingHistoryOrderByWithRelationInputSchema: z.ZodType<Prisma.BookingHistoryOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  action: z.lazy(() => SortOrderSchema).optional(),
  refId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  refNo: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  userId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  comment: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  customer: z.lazy(() => SortOrderSchema).optional(),
  booking: z.lazy(() => BookingOrderByWithRelationInputSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  location: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => BookingHistoryOrderByRelevanceInputSchema).optional()
}).strict();

export const BookingHistoryWhereUniqueInputSchema: z.ZodType<Prisma.BookingHistoryWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => BookingHistoryWhereInputSchema),z.lazy(() => BookingHistoryWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingHistoryWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingHistoryWhereInputSchema),z.lazy(() => BookingHistoryWhereInputSchema).array() ]).optional(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  action: z.union([ z.lazy(() => EnumBookingActionFilterSchema),z.lazy(() => BookingActionSchema) ]).optional(),
  refId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  refNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  userId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  customer: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  booking: z.union([ z.lazy(() => BookingScalarRelationFilterSchema),z.lazy(() => BookingWhereInputSchema) ]).optional(),
  user: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  location: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
}).strict());

export const BookingHistoryOrderByWithAggregationInputSchema: z.ZodType<Prisma.BookingHistoryOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  action: z.lazy(() => SortOrderSchema).optional(),
  refId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  refNo: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  userId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  comment: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  customer: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => BookingHistoryCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => BookingHistoryAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BookingHistoryMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BookingHistoryMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => BookingHistorySumOrderByAggregateInputSchema).optional()
}).strict();

export const BookingHistoryScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BookingHistoryScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BookingHistoryScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingHistoryScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingHistoryScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingHistoryScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingHistoryScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  bookingId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  action: z.union([ z.lazy(() => EnumBookingActionWithAggregatesFilterSchema),z.lazy(() => BookingActionSchema) ]).optional(),
  refId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  refNo: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  userId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  comment: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  customer: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
}).strict();

export const BookingItemWhereInputSchema: z.ZodType<Prisma.BookingItemWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingItemWhereInputSchema),z.lazy(() => BookingItemWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingItemWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingItemWhereInputSchema),z.lazy(() => BookingItemWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  desc: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  piece: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  weight: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  unit: z.union([ z.lazy(() => EnumUnitFilterSchema),z.lazy(() => UnitSchema) ]).optional(),
  rate: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  discount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  total: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  productTypeId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  booking: z.union([ z.lazy(() => BookingNullableScalarRelationFilterSchema),z.lazy(() => BookingWhereInputSchema) ]).optional().nullable(),
  productType: z.union([ z.lazy(() => ProductTypeScalarRelationFilterSchema),z.lazy(() => ProductTypeWhereInputSchema) ]).optional(),
}).strict();

export const BookingItemOrderByWithRelationInputSchema: z.ZodType<Prisma.BookingItemOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  desc: z.lazy(() => SortOrderSchema).optional(),
  piece: z.lazy(() => SortOrderSchema).optional(),
  weight: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  total: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  bookingId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  productTypeId: z.lazy(() => SortOrderSchema).optional(),
  booking: z.lazy(() => BookingOrderByWithRelationInputSchema).optional(),
  productType: z.lazy(() => ProductTypeOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => BookingItemOrderByRelevanceInputSchema).optional()
}).strict();

export const BookingItemWhereUniqueInputSchema: z.ZodType<Prisma.BookingItemWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => BookingItemWhereInputSchema),z.lazy(() => BookingItemWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingItemWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingItemWhereInputSchema),z.lazy(() => BookingItemWhereInputSchema).array() ]).optional(),
  desc: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  piece: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  weight: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  unit: z.union([ z.lazy(() => EnumUnitFilterSchema),z.lazy(() => UnitSchema) ]).optional(),
  rate: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  discount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  total: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  productTypeId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  booking: z.union([ z.lazy(() => BookingNullableScalarRelationFilterSchema),z.lazy(() => BookingWhereInputSchema) ]).optional().nullable(),
  productType: z.union([ z.lazy(() => ProductTypeScalarRelationFilterSchema),z.lazy(() => ProductTypeWhereInputSchema) ]).optional(),
}).strict());

export const BookingItemOrderByWithAggregationInputSchema: z.ZodType<Prisma.BookingItemOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  desc: z.lazy(() => SortOrderSchema).optional(),
  piece: z.lazy(() => SortOrderSchema).optional(),
  weight: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  total: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  bookingId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  productTypeId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => BookingItemCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => BookingItemAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BookingItemMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BookingItemMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => BookingItemSumOrderByAggregateInputSchema).optional()
}).strict();

export const BookingItemScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BookingItemScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BookingItemScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingItemScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingItemScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  desc: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  piece: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  weight: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  unit: z.union([ z.lazy(() => EnumUnitWithAggregatesFilterSchema),z.lazy(() => UnitSchema) ]).optional(),
  rate: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  discount: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  total: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  productTypeId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const BookingWhereInputSchema: z.ZodType<Prisma.BookingWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingWhereInputSchema),z.lazy(() => BookingWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingWhereInputSchema),z.lazy(() => BookingWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  bookingDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  consignmentNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderContactNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  senderEmail: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  senderPan: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  receiverContactNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverAddr: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverPan: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => EnumServiceTypeFilterSchema),z.lazy(() => ServiceTypeSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => EnumDeliveryModeNullableFilterSchema),z.lazy(() => DeliveryModeSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.lazy(() => DecimalNullableFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional().nullable(),
  refNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  invoiceDate: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  instruction: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => EnumPaymentModeFilterSchema),z.lazy(() => PaymentModeSchema) ]).optional(),
  otherCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  tax: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  stCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  totAmt: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  status: z.union([ z.lazy(() => EnumBookingStatusFilterSchema),z.lazy(() => BookingStatusSchema) ]).optional(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  clientId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  baggageId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  manifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  verifiedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  verifiedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  cod: z.union([ z.lazy(() => DecimalNullableFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional().nullable(),
  laborCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  pickupCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  deliveryCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  volumeCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  discount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  statementId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentListRelationFilterSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryListRelationFilterSchema).optional(),
  items: z.lazy(() => BookingItemListRelationFilterSchema).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  client: z.union([ z.lazy(() => ClientNullableScalarRelationFilterSchema),z.lazy(() => ClientWhereInputSchema) ]).optional().nullable(),
  origin: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  destination: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  baggage: z.union([ z.lazy(() => BaggageNullableScalarRelationFilterSchema),z.lazy(() => BaggageWhereInputSchema) ]).optional().nullable(),
  manifest: z.union([ z.lazy(() => ManifestNullableScalarRelationFilterSchema),z.lazy(() => ManifestWhereInputSchema) ]).optional().nullable(),
  verifiedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  currentLocation: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  history: z.lazy(() => BookingHistoryListRelationFilterSchema).optional(),
  statement: z.union([ z.lazy(() => StatementNullableScalarRelationFilterSchema),z.lazy(() => StatementWhereInputSchema) ]).optional().nullable(),
}).strict();

export const BookingOrderByWithRelationInputSchema: z.ZodType<Prisma.BookingOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingDate: z.lazy(() => SortOrderSchema).optional(),
  consignmentNo: z.lazy(() => SortOrderSchema).optional(),
  senderName: z.lazy(() => SortOrderSchema).optional(),
  senderContactNo: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  senderEmail: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  senderPan: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receiverName: z.lazy(() => SortOrderSchema).optional(),
  receiverContactNo: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receiverAddr: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receiverPan: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  serviceType: z.lazy(() => SortOrderSchema).optional(),
  deliveryMode: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  declaredValue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  refNo: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  invoiceDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  instruction: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  paymentMode: z.lazy(() => SortOrderSchema).optional(),
  otherCharge: z.lazy(() => SortOrderSchema).optional(),
  tax: z.lazy(() => SortOrderSchema).optional(),
  stCharge: z.lazy(() => SortOrderSchema).optional(),
  totAmt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  baggageId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  manifestId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  verifiedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  verifiedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  cod: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  laborCharge: z.lazy(() => SortOrderSchema).optional(),
  pickupCharge: z.lazy(() => SortOrderSchema).optional(),
  deliveryCharge: z.lazy(() => SortOrderSchema).optional(),
  volumeCharge: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  statementId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  currentLocationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentOrderByRelationAggregateInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryOrderByRelationAggregateInputSchema).optional(),
  items: z.lazy(() => BookingItemOrderByRelationAggregateInputSchema).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  client: z.lazy(() => ClientOrderByWithRelationInputSchema).optional(),
  origin: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  destination: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  baggage: z.lazy(() => BaggageOrderByWithRelationInputSchema).optional(),
  manifest: z.lazy(() => ManifestOrderByWithRelationInputSchema).optional(),
  verifiedBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  currentLocation: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  history: z.lazy(() => BookingHistoryOrderByRelationAggregateInputSchema).optional(),
  statement: z.lazy(() => StatementOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => BookingOrderByRelevanceInputSchema).optional()
}).strict();

export const BookingWhereUniqueInputSchema: z.ZodType<Prisma.BookingWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    consignmentNo: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    consignmentNo: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  consignmentNo: z.string().optional(),
  AND: z.union([ z.lazy(() => BookingWhereInputSchema),z.lazy(() => BookingWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingWhereInputSchema),z.lazy(() => BookingWhereInputSchema).array() ]).optional(),
  bookingDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  senderName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderContactNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  senderEmail: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  senderPan: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  receiverContactNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverAddr: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverPan: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => EnumServiceTypeFilterSchema),z.lazy(() => ServiceTypeSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => EnumDeliveryModeNullableFilterSchema),z.lazy(() => DeliveryModeSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.lazy(() => DecimalNullableFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional().nullable(),
  refNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  invoiceDate: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  instruction: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => EnumPaymentModeFilterSchema),z.lazy(() => PaymentModeSchema) ]).optional(),
  otherCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  tax: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  stCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  totAmt: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  status: z.union([ z.lazy(() => EnumBookingStatusFilterSchema),z.lazy(() => BookingStatusSchema) ]).optional(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  clientId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  baggageId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  manifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  verifiedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  verifiedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  cod: z.union([ z.lazy(() => DecimalNullableFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional().nullable(),
  laborCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  pickupCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  deliveryCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  volumeCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  discount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  statementId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentListRelationFilterSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryListRelationFilterSchema).optional(),
  items: z.lazy(() => BookingItemListRelationFilterSchema).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  client: z.union([ z.lazy(() => ClientNullableScalarRelationFilterSchema),z.lazy(() => ClientWhereInputSchema) ]).optional().nullable(),
  origin: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  destination: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  baggage: z.union([ z.lazy(() => BaggageNullableScalarRelationFilterSchema),z.lazy(() => BaggageWhereInputSchema) ]).optional().nullable(),
  manifest: z.union([ z.lazy(() => ManifestNullableScalarRelationFilterSchema),z.lazy(() => ManifestWhereInputSchema) ]).optional().nullable(),
  verifiedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  currentLocation: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  history: z.lazy(() => BookingHistoryListRelationFilterSchema).optional(),
  statement: z.union([ z.lazy(() => StatementNullableScalarRelationFilterSchema),z.lazy(() => StatementWhereInputSchema) ]).optional().nullable(),
}).strict());

export const BookingOrderByWithAggregationInputSchema: z.ZodType<Prisma.BookingOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingDate: z.lazy(() => SortOrderSchema).optional(),
  consignmentNo: z.lazy(() => SortOrderSchema).optional(),
  senderName: z.lazy(() => SortOrderSchema).optional(),
  senderContactNo: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  senderEmail: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  senderPan: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receiverName: z.lazy(() => SortOrderSchema).optional(),
  receiverContactNo: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receiverAddr: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receiverPan: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  serviceType: z.lazy(() => SortOrderSchema).optional(),
  deliveryMode: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  declaredValue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  refNo: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  invoiceDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  instruction: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  paymentMode: z.lazy(() => SortOrderSchema).optional(),
  otherCharge: z.lazy(() => SortOrderSchema).optional(),
  tax: z.lazy(() => SortOrderSchema).optional(),
  stCharge: z.lazy(() => SortOrderSchema).optional(),
  totAmt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  baggageId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  manifestId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  verifiedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  verifiedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  cod: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  laborCharge: z.lazy(() => SortOrderSchema).optional(),
  pickupCharge: z.lazy(() => SortOrderSchema).optional(),
  deliveryCharge: z.lazy(() => SortOrderSchema).optional(),
  volumeCharge: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  statementId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  currentLocationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => BookingCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => BookingAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BookingMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BookingMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => BookingSumOrderByAggregateInputSchema).optional()
}).strict();

export const BookingScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BookingScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BookingScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingScalarWhereWithAggregatesInputSchema),z.lazy(() => BookingScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  bookingDate: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  consignmentNo: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  senderName: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  senderContactNo: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  senderEmail: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  senderPan: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  receiverName: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  receiverContactNo: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  receiverAddr: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  receiverPan: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => EnumServiceTypeWithAggregatesFilterSchema),z.lazy(() => ServiceTypeSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => EnumDeliveryModeNullableWithAggregatesFilterSchema),z.lazy(() => DeliveryModeSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.lazy(() => DecimalNullableWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional().nullable(),
  refNo: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  invoiceDate: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  instruction: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => EnumPaymentModeWithAggregatesFilterSchema),z.lazy(() => PaymentModeSchema) ]).optional(),
  otherCharge: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  tax: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  stCharge: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  totAmt: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  status: z.union([ z.lazy(() => EnumBookingStatusWithAggregatesFilterSchema),z.lazy(() => BookingStatusSchema) ]).optional(),
  comment: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  clientId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  baggageId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  manifestId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  verifiedById: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  verifiedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  cod: z.union([ z.lazy(() => DecimalNullableWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional().nullable(),
  laborCharge: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  pickupCharge: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  deliveryCharge: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  volumeCharge: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  discount: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  statementId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  currentLocationId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const ClientWhereInputSchema: z.ZodType<Prisma.ClientWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ClientWhereInputSchema),z.lazy(() => ClientWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ClientWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ClientWhereInputSchema),z.lazy(() => ClientWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactPerson: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  pan: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  bookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  location: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  statements: z.lazy(() => StatementListRelationFilterSchema).optional(),
  user: z.lazy(() => UserListRelationFilterSchema).optional()
}).strict();

export const ClientOrderByWithRelationInputSchema: z.ZodType<Prisma.ClientOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  contactPerson: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  pan: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  bookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  location: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  statements: z.lazy(() => StatementOrderByRelationAggregateInputSchema).optional(),
  user: z.lazy(() => UserOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => ClientOrderByRelevanceInputSchema).optional()
}).strict();

export const ClientWhereUniqueInputSchema: z.ZodType<Prisma.ClientWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => ClientWhereInputSchema),z.lazy(() => ClientWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ClientWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ClientWhereInputSchema),z.lazy(() => ClientWhereInputSchema).array() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactPerson: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  pan: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  bookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  location: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  statements: z.lazy(() => StatementListRelationFilterSchema).optional(),
  user: z.lazy(() => UserListRelationFilterSchema).optional()
}).strict());

export const ClientOrderByWithAggregationInputSchema: z.ZodType<Prisma.ClientOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  contactPerson: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  pan: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ClientCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => ClientAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ClientMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ClientMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => ClientSumOrderByAggregateInputSchema).optional()
}).strict();

export const ClientScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ClientScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ClientScalarWhereWithAggregatesInputSchema),z.lazy(() => ClientScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ClientScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ClientScalarWhereWithAggregatesInputSchema),z.lazy(() => ClientScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contactPerson: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contactNo: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  address: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  pan: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const DeliveryWhereInputSchema: z.ZodType<Prisma.DeliveryWhereInput> = z.object({
  AND: z.union([ z.lazy(() => DeliveryWhereInputSchema),z.lazy(() => DeliveryWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => DeliveryWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DeliveryWhereInputSchema),z.lazy(() => DeliveryWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  locationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumDeliveryStatusFilterSchema),z.lazy(() => DeliveryStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  deliveredById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  bookings: z.lazy(() => BookingDeliveryListRelationFilterSchema).optional(),
  location: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  deliveredBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const DeliveryOrderByWithRelationInputSchema: z.ZodType<Prisma.DeliveryOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  deliveredById: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  bookings: z.lazy(() => BookingDeliveryOrderByRelationAggregateInputSchema).optional(),
  location: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  deliveredBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const DeliveryWhereUniqueInputSchema: z.ZodType<Prisma.DeliveryWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => DeliveryWhereInputSchema),z.lazy(() => DeliveryWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => DeliveryWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DeliveryWhereInputSchema),z.lazy(() => DeliveryWhereInputSchema).array() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  locationId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  status: z.union([ z.lazy(() => EnumDeliveryStatusFilterSchema),z.lazy(() => DeliveryStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  deliveredById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  bookings: z.lazy(() => BookingDeliveryListRelationFilterSchema).optional(),
  location: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  deliveredBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const DeliveryOrderByWithAggregationInputSchema: z.ZodType<Prisma.DeliveryOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  deliveredById: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => DeliveryCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => DeliveryAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => DeliveryMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => DeliveryMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => DeliverySumOrderByAggregateInputSchema).optional()
}).strict();

export const DeliveryScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.DeliveryScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => DeliveryScalarWhereWithAggregatesInputSchema),z.lazy(() => DeliveryScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => DeliveryScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DeliveryScalarWhereWithAggregatesInputSchema),z.lazy(() => DeliveryScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  locationId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumDeliveryStatusWithAggregatesFilterSchema),z.lazy(() => DeliveryStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  deliveredById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const DistrictWhereInputSchema: z.ZodType<Prisma.DistrictWhereInput> = z.object({
  AND: z.union([ z.lazy(() => DistrictWhereInputSchema),z.lazy(() => DistrictWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => DistrictWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DistrictWhereInputSchema),z.lazy(() => DistrictWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locations: z.lazy(() => LocationListRelationFilterSchema).optional()
}).strict();

export const DistrictOrderByWithRelationInputSchema: z.ZodType<Prisma.DistrictOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locations: z.lazy(() => LocationOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => DistrictOrderByRelevanceInputSchema).optional()
}).strict();

export const DistrictWhereUniqueInputSchema: z.ZodType<Prisma.DistrictWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    name: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    name: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  name: z.string().optional(),
  AND: z.union([ z.lazy(() => DistrictWhereInputSchema),z.lazy(() => DistrictWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => DistrictWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DistrictWhereInputSchema),z.lazy(() => DistrictWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locations: z.lazy(() => LocationListRelationFilterSchema).optional()
}).strict());

export const DistrictOrderByWithAggregationInputSchema: z.ZodType<Prisma.DistrictOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => DistrictCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => DistrictAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => DistrictMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => DistrictMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => DistrictSumOrderByAggregateInputSchema).optional()
}).strict();

export const DistrictScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.DistrictScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => DistrictScalarWhereWithAggregatesInputSchema),z.lazy(() => DistrictScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => DistrictScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DistrictScalarWhereWithAggregatesInputSchema),z.lazy(() => DistrictScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const FiscalYearWhereInputSchema: z.ZodType<Prisma.FiscalYearWhereInput> = z.object({
  AND: z.union([ z.lazy(() => FiscalYearWhereInputSchema),z.lazy(() => FiscalYearWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => FiscalYearWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FiscalYearWhereInputSchema),z.lazy(() => FiscalYearWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  nameEng: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  nameNep: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  curr: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const FiscalYearOrderByWithRelationInputSchema: z.ZodType<Prisma.FiscalYearOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  curr: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _relevance: z.lazy(() => FiscalYearOrderByRelevanceInputSchema).optional()
}).strict();

export const FiscalYearWhereUniqueInputSchema: z.ZodType<Prisma.FiscalYearWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    nameEng: z.string(),
    nameNep: z.string()
  }),
  z.object({
    id: z.number().int(),
    nameEng: z.string(),
  }),
  z.object({
    id: z.number().int(),
    nameNep: z.string(),
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    nameEng: z.string(),
    nameNep: z.string(),
  }),
  z.object({
    nameEng: z.string(),
  }),
  z.object({
    nameNep: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  nameEng: z.string().optional(),
  nameNep: z.string().optional(),
  AND: z.union([ z.lazy(() => FiscalYearWhereInputSchema),z.lazy(() => FiscalYearWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => FiscalYearWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FiscalYearWhereInputSchema),z.lazy(() => FiscalYearWhereInputSchema).array() ]).optional(),
  curr: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict());

export const FiscalYearOrderByWithAggregationInputSchema: z.ZodType<Prisma.FiscalYearOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  curr: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => FiscalYearCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => FiscalYearAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => FiscalYearMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => FiscalYearMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => FiscalYearSumOrderByAggregateInputSchema).optional()
}).strict();

export const FiscalYearScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.FiscalYearScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => FiscalYearScalarWhereWithAggregatesInputSchema),z.lazy(() => FiscalYearScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => FiscalYearScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FiscalYearScalarWhereWithAggregatesInputSchema),z.lazy(() => FiscalYearScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  nameEng: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  nameNep: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  curr: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const GenBookingBarcodeWhereInputSchema: z.ZodType<Prisma.GenBookingBarcodeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => GenBookingBarcodeWhereInputSchema),z.lazy(() => GenBookingBarcodeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => GenBookingBarcodeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => GenBookingBarcodeWhereInputSchema),z.lazy(() => GenBookingBarcodeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  year: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  code: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const GenBookingBarcodeOrderByWithRelationInputSchema: z.ZodType<Prisma.GenBookingBarcodeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
}).strict();

export const GenBookingBarcodeWhereUniqueInputSchema: z.ZodType<Prisma.GenBookingBarcodeWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => GenBookingBarcodeWhereInputSchema),z.lazy(() => GenBookingBarcodeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => GenBookingBarcodeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => GenBookingBarcodeWhereInputSchema),z.lazy(() => GenBookingBarcodeWhereInputSchema).array() ]).optional(),
  year: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  code: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict());

export const GenBookingBarcodeOrderByWithAggregationInputSchema: z.ZodType<Prisma.GenBookingBarcodeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => GenBookingBarcodeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => GenBookingBarcodeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => GenBookingBarcodeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => GenBookingBarcodeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => GenBookingBarcodeSumOrderByAggregateInputSchema).optional()
}).strict();

export const GenBookingBarcodeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.GenBookingBarcodeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => GenBookingBarcodeScalarWhereWithAggregatesInputSchema),z.lazy(() => GenBookingBarcodeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => GenBookingBarcodeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => GenBookingBarcodeScalarWhereWithAggregatesInputSchema),z.lazy(() => GenBookingBarcodeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  year: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  code: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const LocationWhereInputSchema: z.ZodType<Prisma.LocationWhereInput> = z.object({
  AND: z.union([ z.lazy(() => LocationWhereInputSchema),z.lazy(() => LocationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => LocationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => LocationWhereInputSchema),z.lazy(() => LocationWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  code: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  districtId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  currentBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  currentBaggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  originBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  destinationBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  clients: z.lazy(() => ClientListRelationFilterSchema).optional(),
  district: z.union([ z.lazy(() => DistrictNullableScalarRelationFilterSchema),z.lazy(() => DistrictWhereInputSchema) ]).optional().nullable(),
  currentManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  originManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  destinationManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  originRates: z.lazy(() => RateListRelationFilterSchema).optional(),
  destinationRates: z.lazy(() => RateListRelationFilterSchema).optional(),
  subLocations: z.lazy(() => SubLocationListRelationFilterSchema).optional(),
  users: z.lazy(() => UserListRelationFilterSchema).optional(),
  deliveries: z.lazy(() => DeliveryListRelationFilterSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryListRelationFilterSchema).optional()
}).strict();

export const LocationOrderByWithRelationInputSchema: z.ZodType<Prisma.LocationOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  contactName: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  districtId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originBaggages: z.lazy(() => BaggageOrderByRelationAggregateInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageOrderByRelationAggregateInputSchema).optional(),
  currentBookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageOrderByRelationAggregateInputSchema).optional(),
  originBookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  clients: z.lazy(() => ClientOrderByRelationAggregateInputSchema).optional(),
  district: z.lazy(() => DistrictOrderByWithRelationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestOrderByRelationAggregateInputSchema).optional(),
  originManifests: z.lazy(() => ManifestOrderByRelationAggregateInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestOrderByRelationAggregateInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestOrderByRelationAggregateInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestOrderByRelationAggregateInputSchema).optional(),
  originRates: z.lazy(() => RateOrderByRelationAggregateInputSchema).optional(),
  destinationRates: z.lazy(() => RateOrderByRelationAggregateInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationOrderByRelationAggregateInputSchema).optional(),
  users: z.lazy(() => UserOrderByRelationAggregateInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryOrderByRelationAggregateInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => LocationOrderByRelevanceInputSchema).optional()
}).strict();

export const LocationWhereUniqueInputSchema: z.ZodType<Prisma.LocationWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => LocationWhereInputSchema),z.lazy(() => LocationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => LocationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => LocationWhereInputSchema),z.lazy(() => LocationWhereInputSchema).array() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  code: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  districtId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  currentBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  currentBaggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  originBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  destinationBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  clients: z.lazy(() => ClientListRelationFilterSchema).optional(),
  district: z.union([ z.lazy(() => DistrictNullableScalarRelationFilterSchema),z.lazy(() => DistrictWhereInputSchema) ]).optional().nullable(),
  currentManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  originManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  destinationManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  originRates: z.lazy(() => RateListRelationFilterSchema).optional(),
  destinationRates: z.lazy(() => RateListRelationFilterSchema).optional(),
  subLocations: z.lazy(() => SubLocationListRelationFilterSchema).optional(),
  users: z.lazy(() => UserListRelationFilterSchema).optional(),
  deliveries: z.lazy(() => DeliveryListRelationFilterSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryListRelationFilterSchema).optional()
}).strict());

export const LocationOrderByWithAggregationInputSchema: z.ZodType<Prisma.LocationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  contactName: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  districtId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => LocationCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => LocationAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => LocationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => LocationMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => LocationSumOrderByAggregateInputSchema).optional()
}).strict();

export const LocationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.LocationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => LocationScalarWhereWithAggregatesInputSchema),z.lazy(() => LocationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => LocationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => LocationScalarWhereWithAggregatesInputSchema),z.lazy(() => LocationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  code: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contactName: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contactNo: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  districtId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const ManifestWhereInputSchema: z.ZodType<Prisma.ManifestWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ManifestWhereInputSchema),z.lazy(() => ManifestWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ManifestWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ManifestWhereInputSchema),z.lazy(() => ManifestWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  manifestNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  status: z.union([ z.lazy(() => EnumManifestStatusFilterSchema),z.lazy(() => ManifestStatusSchema) ]).optional(),
  checkedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  dispatchedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  masterManifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  dispatchedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  receivedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  baggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  bookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  currentLocation: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  origin: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  destination: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  currentMasterManifest: z.union([ z.lazy(() => MasterManifestNullableScalarRelationFilterSchema),z.lazy(() => MasterManifestWhereInputSchema) ]).optional().nullable(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  dispatchedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  masterManifests: z.lazy(() => MasterManifestDetailListRelationFilterSchema).optional()
}).strict();

export const ManifestOrderByWithRelationInputSchema: z.ZodType<Prisma.ManifestOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  manifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  dispatchedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  currentLocationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  baggages: z.lazy(() => BaggageOrderByRelationAggregateInputSchema).optional(),
  bookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  currentLocation: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  origin: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  destination: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestOrderByWithRelationInputSchema).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  receivedBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => ManifestOrderByRelevanceInputSchema).optional()
}).strict();

export const ManifestWhereUniqueInputSchema: z.ZodType<Prisma.ManifestWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    manifestNo: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    manifestNo: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  manifestNo: z.string().optional(),
  AND: z.union([ z.lazy(() => ManifestWhereInputSchema),z.lazy(() => ManifestWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ManifestWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ManifestWhereInputSchema),z.lazy(() => ManifestWhereInputSchema).array() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  status: z.union([ z.lazy(() => EnumManifestStatusFilterSchema),z.lazy(() => ManifestStatusSchema) ]).optional(),
  checkedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  dispatchedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  masterManifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  dispatchedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  receivedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  baggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  bookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  currentLocation: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  origin: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  destination: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  currentMasterManifest: z.union([ z.lazy(() => MasterManifestNullableScalarRelationFilterSchema),z.lazy(() => MasterManifestWhereInputSchema) ]).optional().nullable(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  dispatchedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  masterManifests: z.lazy(() => MasterManifestDetailListRelationFilterSchema).optional()
}).strict());

export const ManifestOrderByWithAggregationInputSchema: z.ZodType<Prisma.ManifestOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  manifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  dispatchedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  currentLocationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => ManifestCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => ManifestAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ManifestMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ManifestMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => ManifestSumOrderByAggregateInputSchema).optional()
}).strict();

export const ManifestScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ManifestScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ManifestScalarWhereWithAggregatesInputSchema),z.lazy(() => ManifestScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ManifestScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ManifestScalarWhereWithAggregatesInputSchema),z.lazy(() => ManifestScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  manifestNo: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  status: z.union([ z.lazy(() => EnumManifestStatusWithAggregatesFilterSchema),z.lazy(() => ManifestStatusSchema) ]).optional(),
  checkedBy: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  dispatchedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  currentLocationId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  masterManifestId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  dispatchedById: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  receivedById: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const MasterManifestDetailWhereInputSchema: z.ZodType<Prisma.MasterManifestDetailWhereInput> = z.object({
  AND: z.union([ z.lazy(() => MasterManifestDetailWhereInputSchema),z.lazy(() => MasterManifestDetailWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => MasterManifestDetailWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MasterManifestDetailWhereInputSchema),z.lazy(() => MasterManifestDetailWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  manifestId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  masterManifestId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  manifest: z.union([ z.lazy(() => ManifestScalarRelationFilterSchema),z.lazy(() => ManifestWhereInputSchema) ]).optional(),
  masterManifest: z.union([ z.lazy(() => MasterManifestScalarRelationFilterSchema),z.lazy(() => MasterManifestWhereInputSchema) ]).optional(),
}).strict();

export const MasterManifestDetailOrderByWithRelationInputSchema: z.ZodType<Prisma.MasterManifestDetailOrderByWithRelationInput> = z.object({
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional(),
  manifest: z.lazy(() => ManifestOrderByWithRelationInputSchema).optional(),
  masterManifest: z.lazy(() => MasterManifestOrderByWithRelationInputSchema).optional()
}).strict();

export const MasterManifestDetailWhereUniqueInputSchema: z.ZodType<Prisma.MasterManifestDetailWhereUniqueInput> = z.object({
  manifestId_masterManifestId: z.lazy(() => MasterManifestDetailManifestIdMasterManifestIdCompoundUniqueInputSchema)
})
.and(z.object({
  manifestId_masterManifestId: z.lazy(() => MasterManifestDetailManifestIdMasterManifestIdCompoundUniqueInputSchema).optional(),
  AND: z.union([ z.lazy(() => MasterManifestDetailWhereInputSchema),z.lazy(() => MasterManifestDetailWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => MasterManifestDetailWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MasterManifestDetailWhereInputSchema),z.lazy(() => MasterManifestDetailWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  manifestId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  masterManifestId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  manifest: z.union([ z.lazy(() => ManifestScalarRelationFilterSchema),z.lazy(() => ManifestWhereInputSchema) ]).optional(),
  masterManifest: z.union([ z.lazy(() => MasterManifestScalarRelationFilterSchema),z.lazy(() => MasterManifestWhereInputSchema) ]).optional(),
}).strict());

export const MasterManifestDetailOrderByWithAggregationInputSchema: z.ZodType<Prisma.MasterManifestDetailOrderByWithAggregationInput> = z.object({
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => MasterManifestDetailCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => MasterManifestDetailAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => MasterManifestDetailMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => MasterManifestDetailMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => MasterManifestDetailSumOrderByAggregateInputSchema).optional()
}).strict();

export const MasterManifestDetailScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.MasterManifestDetailScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => MasterManifestDetailScalarWhereWithAggregatesInputSchema),z.lazy(() => MasterManifestDetailScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => MasterManifestDetailScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MasterManifestDetailScalarWhereWithAggregatesInputSchema),z.lazy(() => MasterManifestDetailScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  manifestId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  masterManifestId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const MasterManifestWhereInputSchema: z.ZodType<Prisma.MasterManifestWhereInput> = z.object({
  AND: z.union([ z.lazy(() => MasterManifestWhereInputSchema),z.lazy(() => MasterManifestWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => MasterManifestWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MasterManifestWhereInputSchema),z.lazy(() => MasterManifestWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  masterManifestNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  vehicleNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  driverName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  mobileNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  freight: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  advance: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  balance: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumMasterManifestStatusFilterSchema),z.lazy(() => MasterManifestStatusSchema) ]).optional(),
  checkedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  dispatchedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  dispatchedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  receivedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  manifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  details: z.lazy(() => MasterManifestDetailListRelationFilterSchema).optional(),
  origin: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  destination: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  dispatchedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestOrderByWithRelationInputSchema: z.ZodType<Prisma.MasterManifestOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  masterManifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  vehicleNo: z.lazy(() => SortOrderSchema).optional(),
  driverName: z.lazy(() => SortOrderSchema).optional(),
  mobileNo: z.lazy(() => SortOrderSchema).optional(),
  freight: z.lazy(() => SortOrderSchema).optional(),
  advance: z.lazy(() => SortOrderSchema).optional(),
  balance: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  dispatchedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  manifests: z.lazy(() => ManifestOrderByRelationAggregateInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailOrderByRelationAggregateInputSchema).optional(),
  origin: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  destination: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  receivedBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => MasterManifestOrderByRelevanceInputSchema).optional()
}).strict();

export const MasterManifestWhereUniqueInputSchema: z.ZodType<Prisma.MasterManifestWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    masterManifestNo: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    masterManifestNo: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string().optional(),
  AND: z.union([ z.lazy(() => MasterManifestWhereInputSchema),z.lazy(() => MasterManifestWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => MasterManifestWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MasterManifestWhereInputSchema),z.lazy(() => MasterManifestWhereInputSchema).array() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  vehicleNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  driverName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  mobileNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  freight: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  advance: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  balance: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumMasterManifestStatusFilterSchema),z.lazy(() => MasterManifestStatusSchema) ]).optional(),
  checkedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  dispatchedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  dispatchedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  receivedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  manifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  details: z.lazy(() => MasterManifestDetailListRelationFilterSchema).optional(),
  origin: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  destination: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  dispatchedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.lazy(() => UserNullableScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
}).strict());

export const MasterManifestOrderByWithAggregationInputSchema: z.ZodType<Prisma.MasterManifestOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  masterManifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  vehicleNo: z.lazy(() => SortOrderSchema).optional(),
  driverName: z.lazy(() => SortOrderSchema).optional(),
  mobileNo: z.lazy(() => SortOrderSchema).optional(),
  freight: z.lazy(() => SortOrderSchema).optional(),
  advance: z.lazy(() => SortOrderSchema).optional(),
  balance: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  dispatchedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  receivedById: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => MasterManifestCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => MasterManifestAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => MasterManifestMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => MasterManifestMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => MasterManifestSumOrderByAggregateInputSchema).optional()
}).strict();

export const MasterManifestScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.MasterManifestScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => MasterManifestScalarWhereWithAggregatesInputSchema),z.lazy(() => MasterManifestScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => MasterManifestScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MasterManifestScalarWhereWithAggregatesInputSchema),z.lazy(() => MasterManifestScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  masterManifestNo: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  vehicleNo: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  driverName: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  mobileNo: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  freight: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  advance: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  balance: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumMasterManifestStatusWithAggregatesFilterSchema),z.lazy(() => MasterManifestStatusSchema) ]).optional(),
  checkedBy: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  dispatchedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  dispatchedById: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  receivedById: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const MonthWhereInputSchema: z.ZodType<Prisma.MonthWhereInput> = z.object({
  AND: z.union([ z.lazy(() => MonthWhereInputSchema),z.lazy(() => MonthWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => MonthWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MonthWhereInputSchema),z.lazy(() => MonthWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  nameEng: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  nameNep: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  fyOrder: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const MonthOrderByWithRelationInputSchema: z.ZodType<Prisma.MonthOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  fyOrder: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _relevance: z.lazy(() => MonthOrderByRelevanceInputSchema).optional()
}).strict();

export const MonthWhereUniqueInputSchema: z.ZodType<Prisma.MonthWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    nameEng: z.string(),
    nameNep: z.string(),
    fyOrder: z.number().int()
  }),
  z.object({
    id: z.number().int(),
    nameEng: z.string(),
    nameNep: z.string(),
  }),
  z.object({
    id: z.number().int(),
    nameEng: z.string(),
    fyOrder: z.number().int(),
  }),
  z.object({
    id: z.number().int(),
    nameEng: z.string(),
  }),
  z.object({
    id: z.number().int(),
    nameNep: z.string(),
    fyOrder: z.number().int(),
  }),
  z.object({
    id: z.number().int(),
    nameNep: z.string(),
  }),
  z.object({
    id: z.number().int(),
    fyOrder: z.number().int(),
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    nameEng: z.string(),
    nameNep: z.string(),
    fyOrder: z.number().int(),
  }),
  z.object({
    nameEng: z.string(),
    nameNep: z.string(),
  }),
  z.object({
    nameEng: z.string(),
    fyOrder: z.number().int(),
  }),
  z.object({
    nameEng: z.string(),
  }),
  z.object({
    nameNep: z.string(),
    fyOrder: z.number().int(),
  }),
  z.object({
    nameNep: z.string(),
  }),
  z.object({
    fyOrder: z.number().int(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  nameEng: z.string().optional(),
  nameNep: z.string().optional(),
  fyOrder: z.number().int().optional(),
  AND: z.union([ z.lazy(() => MonthWhereInputSchema),z.lazy(() => MonthWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => MonthWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MonthWhereInputSchema),z.lazy(() => MonthWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict());

export const MonthOrderByWithAggregationInputSchema: z.ZodType<Prisma.MonthOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  fyOrder: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => MonthCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => MonthAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => MonthMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => MonthMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => MonthSumOrderByAggregateInputSchema).optional()
}).strict();

export const MonthScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.MonthScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => MonthScalarWhereWithAggregatesInputSchema),z.lazy(() => MonthScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => MonthScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MonthScalarWhereWithAggregatesInputSchema),z.lazy(() => MonthScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  nameEng: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  nameNep: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  fyOrder: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const ProductTypeWhereInputSchema: z.ZodType<Prisma.ProductTypeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ProductTypeWhereInputSchema),z.lazy(() => ProductTypeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ProductTypeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ProductTypeWhereInputSchema),z.lazy(() => ProductTypeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  unit: z.union([ z.lazy(() => EnumUnitFilterSchema),z.lazy(() => UnitSchema) ]).optional(),
  bookingItems: z.lazy(() => BookingItemListRelationFilterSchema).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const ProductTypeOrderByWithRelationInputSchema: z.ZodType<Prisma.ProductTypeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional(),
  bookingItems: z.lazy(() => BookingItemOrderByRelationAggregateInputSchema).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => ProductTypeOrderByRelevanceInputSchema).optional()
}).strict();

export const ProductTypeWhereUniqueInputSchema: z.ZodType<Prisma.ProductTypeWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => ProductTypeWhereInputSchema),z.lazy(() => ProductTypeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ProductTypeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ProductTypeWhereInputSchema),z.lazy(() => ProductTypeWhereInputSchema).array() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  unit: z.union([ z.lazy(() => EnumUnitFilterSchema),z.lazy(() => UnitSchema) ]).optional(),
  bookingItems: z.lazy(() => BookingItemListRelationFilterSchema).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const ProductTypeOrderByWithAggregationInputSchema: z.ZodType<Prisma.ProductTypeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ProductTypeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => ProductTypeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ProductTypeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ProductTypeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => ProductTypeSumOrderByAggregateInputSchema).optional()
}).strict();

export const ProductTypeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ProductTypeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ProductTypeScalarWhereWithAggregatesInputSchema),z.lazy(() => ProductTypeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ProductTypeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ProductTypeScalarWhereWithAggregatesInputSchema),z.lazy(() => ProductTypeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  unit: z.union([ z.lazy(() => EnumUnitWithAggregatesFilterSchema),z.lazy(() => UnitSchema) ]).optional(),
}).strict();

export const RateWhereInputSchema: z.ZodType<Prisma.RateWhereInput> = z.object({
  AND: z.union([ z.lazy(() => RateWhereInputSchema),z.lazy(() => RateWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => RateWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RateWhereInputSchema),z.lazy(() => RateWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  rate: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  destinationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  origin: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  destination: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
}).strict();

export const RateOrderByWithRelationInputSchema: z.ZodType<Prisma.RateOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  destinationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  origin: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  destination: z.lazy(() => LocationOrderByWithRelationInputSchema).optional()
}).strict();

export const RateWhereUniqueInputSchema: z.ZodType<Prisma.RateWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => RateWhereInputSchema),z.lazy(() => RateWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => RateWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RateWhereInputSchema),z.lazy(() => RateWhereInputSchema).array() ]).optional(),
  rate: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  destinationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  origin: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
  destination: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
}).strict());

export const RateOrderByWithAggregationInputSchema: z.ZodType<Prisma.RateOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  originId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  destinationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => RateCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => RateAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => RateMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => RateMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => RateSumOrderByAggregateInputSchema).optional()
}).strict();

export const RateScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.RateScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => RateScalarWhereWithAggregatesInputSchema),z.lazy(() => RateScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => RateScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RateScalarWhereWithAggregatesInputSchema),z.lazy(() => RateScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  rate: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  destinationId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const RoleWhereInputSchema: z.ZodType<Prisma.RoleWhereInput> = z.object({
  AND: z.union([ z.lazy(() => RoleWhereInputSchema),z.lazy(() => RoleWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => RoleWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RoleWhereInputSchema),z.lazy(() => RoleWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  code: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  desc: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  isAdmin: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  users: z.lazy(() => UserListRelationFilterSchema).optional()
}).strict();

export const RoleOrderByWithRelationInputSchema: z.ZodType<Prisma.RoleOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  desc: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  isAdmin: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  users: z.lazy(() => UserOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => RoleOrderByRelevanceInputSchema).optional()
}).strict();

export const RoleWhereUniqueInputSchema: z.ZodType<Prisma.RoleWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => RoleWhereInputSchema),z.lazy(() => RoleWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => RoleWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RoleWhereInputSchema),z.lazy(() => RoleWhereInputSchema).array() ]).optional(),
  code: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  desc: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  isAdmin: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  users: z.lazy(() => UserListRelationFilterSchema).optional()
}).strict());

export const RoleOrderByWithAggregationInputSchema: z.ZodType<Prisma.RoleOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  desc: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  isAdmin: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => RoleCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => RoleAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => RoleMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => RoleMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => RoleSumOrderByAggregateInputSchema).optional()
}).strict();

export const RoleScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.RoleScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => RoleScalarWhereWithAggregatesInputSchema),z.lazy(() => RoleScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => RoleScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RoleScalarWhereWithAggregatesInputSchema),z.lazy(() => RoleScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  code: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  desc: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  isAdmin: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  status: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const StatementWhereInputSchema: z.ZodType<Prisma.StatementWhereInput> = z.object({
  AND: z.union([ z.lazy(() => StatementWhereInputSchema),z.lazy(() => StatementWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => StatementWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => StatementWhereInputSchema),z.lazy(() => StatementWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  fromDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  toDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  amount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  clientId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  client: z.union([ z.lazy(() => ClientScalarRelationFilterSchema),z.lazy(() => ClientWhereInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const StatementOrderByWithRelationInputSchema: z.ZodType<Prisma.StatementOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fromDate: z.lazy(() => SortOrderSchema).optional(),
  toDate: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  client: z.lazy(() => ClientOrderByWithRelationInputSchema).optional(),
  bookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  createdBy: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const StatementWhereUniqueInputSchema: z.ZodType<Prisma.StatementWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => StatementWhereInputSchema),z.lazy(() => StatementWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => StatementWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => StatementWhereInputSchema),z.lazy(() => StatementWhereInputSchema).array() ]).optional(),
  fromDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  toDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  amount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  clientId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  client: z.union([ z.lazy(() => ClientScalarRelationFilterSchema),z.lazy(() => ClientWhereInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  createdBy: z.union([ z.lazy(() => UserScalarRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const StatementOrderByWithAggregationInputSchema: z.ZodType<Prisma.StatementOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fromDate: z.lazy(() => SortOrderSchema).optional(),
  toDate: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => StatementCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => StatementAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => StatementMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => StatementMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => StatementSumOrderByAggregateInputSchema).optional()
}).strict();

export const StatementScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.StatementScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => StatementScalarWhereWithAggregatesInputSchema),z.lazy(() => StatementScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => StatementScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => StatementScalarWhereWithAggregatesInputSchema),z.lazy(() => StatementScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  fromDate: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  toDate: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  amount: z.union([ z.lazy(() => DecimalWithAggregatesFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  clientId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const SubLocationWhereInputSchema: z.ZodType<Prisma.SubLocationWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SubLocationWhereInputSchema),z.lazy(() => SubLocationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SubLocationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SubLocationWhereInputSchema),z.lazy(() => SubLocationWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  location: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
}).strict();

export const SubLocationOrderByWithRelationInputSchema: z.ZodType<Prisma.SubLocationOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  location: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  _relevance: z.lazy(() => SubLocationOrderByRelevanceInputSchema).optional()
}).strict();

export const SubLocationWhereUniqueInputSchema: z.ZodType<Prisma.SubLocationWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => SubLocationWhereInputSchema),z.lazy(() => SubLocationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SubLocationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SubLocationWhereInputSchema),z.lazy(() => SubLocationWhereInputSchema).array() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  location: z.union([ z.lazy(() => LocationNullableScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional().nullable(),
}).strict());

export const SubLocationOrderByWithAggregationInputSchema: z.ZodType<Prisma.SubLocationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => SubLocationCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => SubLocationAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SubLocationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SubLocationMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => SubLocationSumOrderByAggregateInputSchema).optional()
}).strict();

export const SubLocationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SubLocationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SubLocationScalarWhereWithAggregatesInputSchema),z.lazy(() => SubLocationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SubLocationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SubLocationScalarWhereWithAggregatesInputSchema),z.lazy(() => SubLocationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const UserWhereInputSchema: z.ZodType<Prisma.UserWhereInput> = z.object({
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  middleName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  phone: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  password: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  roleId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  clientId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  passwordToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentListRelationFilterSchema).optional(),
  createdBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  verifiedBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  createdClients: z.lazy(() => ClientListRelationFilterSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryListRelationFilterSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryListRelationFilterSchema).optional(),
  createdManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  receivedManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeListRelationFilterSchema).optional(),
  client: z.union([ z.lazy(() => ClientNullableScalarRelationFilterSchema),z.lazy(() => ClientWhereInputSchema) ]).optional().nullable(),
  location: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleNullableScalarRelationFilterSchema),z.lazy(() => RoleWhereInputSchema) ]).optional().nullable(),
  bookingHistory: z.lazy(() => BookingHistoryListRelationFilterSchema).optional(),
  Statement: z.lazy(() => StatementListRelationFilterSchema).optional()
}).strict();

export const UserOrderByWithRelationInputSchema: z.ZodType<Prisma.UserOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  middleName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  lastName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  userName: z.lazy(() => SortOrderSchema).optional(),
  password: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  clientId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  passwordToken: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  passwordTokenExpiration: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdBaggages: z.lazy(() => BaggageOrderByRelationAggregateInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentOrderByRelationAggregateInputSchema).optional(),
  createdBookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingOrderByRelationAggregateInputSchema).optional(),
  createdClients: z.lazy(() => ClientOrderByRelationAggregateInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryOrderByRelationAggregateInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryOrderByRelationAggregateInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestOrderByRelationAggregateInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestOrderByRelationAggregateInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestOrderByRelationAggregateInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestOrderByRelationAggregateInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestOrderByRelationAggregateInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestOrderByRelationAggregateInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeOrderByRelationAggregateInputSchema).optional(),
  client: z.lazy(() => ClientOrderByWithRelationInputSchema).optional(),
  location: z.lazy(() => LocationOrderByWithRelationInputSchema).optional(),
  role: z.lazy(() => RoleOrderByWithRelationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryOrderByRelationAggregateInputSchema).optional(),
  Statement: z.lazy(() => StatementOrderByRelationAggregateInputSchema).optional(),
  _relevance: z.lazy(() => UserOrderByRelevanceInputSchema).optional()
}).strict();

export const UserWhereUniqueInputSchema: z.ZodType<Prisma.UserWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    phone: z.string(),
    userName: z.string()
  }),
  z.object({
    id: z.number().int(),
    phone: z.string(),
  }),
  z.object({
    id: z.number().int(),
    userName: z.string(),
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    phone: z.string(),
    userName: z.string(),
  }),
  z.object({
    phone: z.string(),
  }),
  z.object({
    userName: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  phone: z.string().optional(),
  userName: z.string().optional(),
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  middleName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  password: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  roleId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  clientId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  passwordToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageListRelationFilterSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentListRelationFilterSchema).optional(),
  createdBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  verifiedBookings: z.lazy(() => BookingListRelationFilterSchema).optional(),
  createdClients: z.lazy(() => ClientListRelationFilterSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryListRelationFilterSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryListRelationFilterSchema).optional(),
  createdManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  receivedManifests: z.lazy(() => ManifestListRelationFilterSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestListRelationFilterSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeListRelationFilterSchema).optional(),
  client: z.union([ z.lazy(() => ClientNullableScalarRelationFilterSchema),z.lazy(() => ClientWhereInputSchema) ]).optional().nullable(),
  location: z.union([ z.lazy(() => LocationScalarRelationFilterSchema),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleNullableScalarRelationFilterSchema),z.lazy(() => RoleWhereInputSchema) ]).optional().nullable(),
  bookingHistory: z.lazy(() => BookingHistoryListRelationFilterSchema).optional(),
  Statement: z.lazy(() => StatementListRelationFilterSchema).optional()
}).strict());

export const UserOrderByWithAggregationInputSchema: z.ZodType<Prisma.UserOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  middleName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  lastName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  userName: z.lazy(() => SortOrderSchema).optional(),
  password: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  clientId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  passwordToken: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  passwordTokenExpiration: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => UserCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => UserAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => UserMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => UserMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => UserSumOrderByAggregateInputSchema).optional()
}).strict();

export const UserScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.UserScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  firstName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  middleName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  phone: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  userName: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  password: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  status: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  roleId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  clientId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  passwordToken: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const WardWhereInputSchema: z.ZodType<Prisma.WardWhereInput> = z.object({
  AND: z.union([ z.lazy(() => WardWhereInputSchema),z.lazy(() => WardWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WardWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WardWhereInputSchema),z.lazy(() => WardWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  nameEng: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  nameNep: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const WardOrderByWithRelationInputSchema: z.ZodType<Prisma.WardOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _relevance: z.lazy(() => WardOrderByRelevanceInputSchema).optional()
}).strict();

export const WardWhereUniqueInputSchema: z.ZodType<Prisma.WardWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    nameEng: z.string(),
    nameNep: z.string()
  }),
  z.object({
    id: z.number().int(),
    nameEng: z.string(),
  }),
  z.object({
    id: z.number().int(),
    nameNep: z.string(),
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    nameEng: z.string(),
    nameNep: z.string(),
  }),
  z.object({
    nameEng: z.string(),
  }),
  z.object({
    nameNep: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  nameEng: z.string().optional(),
  nameNep: z.string().optional(),
  AND: z.union([ z.lazy(() => WardWhereInputSchema),z.lazy(() => WardWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WardWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WardWhereInputSchema),z.lazy(() => WardWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict());

export const WardOrderByWithAggregationInputSchema: z.ZodType<Prisma.WardOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => WardCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => WardAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => WardMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => WardMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => WardSumOrderByAggregateInputSchema).optional()
}).strict();

export const WardScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.WardScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => WardScalarWhereWithAggregatesInputSchema),z.lazy(() => WardScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => WardScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WardScalarWhereWithAggregatesInputSchema),z.lazy(() => WardScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  nameEng: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  nameNep: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const BaggageCreateInputSchema: z.ZodType<Prisma.BaggageCreateInput> = z.object({
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBaggagesInputSchema),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBaggagesInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBaggagesInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBaggagesInputSchema),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBaggagesInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageUncheckedCreateInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageUpdateInputSchema: z.ZodType<Prisma.BaggageUpdateInput> = z.object({
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBaggagesNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBaggagesNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBaggagesNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBaggagesNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBaggagesNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageCreateManyInputSchema: z.ZodType<Prisma.BaggageCreateManyInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BaggageUpdateManyMutationInputSchema: z.ZodType<Prisma.BaggageUpdateManyMutationInput> = z.object({
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BaggageUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingCommentCreateInputSchema: z.ZodType<Prisma.BookingCommentCreateInput> = z.object({
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutCommentsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutBookingCommentsInputSchema).optional()
}).strict();

export const BookingCommentUncheckedCreateInputSchema: z.ZodType<Prisma.BookingCommentUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int(),
  createdById: z.number().int().optional().nullable()
}).strict();

export const BookingCommentUpdateInputSchema: z.ZodType<Prisma.BookingCommentUpdateInput> = z.object({
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  booking: z.lazy(() => BookingUpdateOneRequiredWithoutCommentsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneWithoutBookingCommentsNestedInputSchema).optional()
}).strict();

export const BookingCommentUncheckedUpdateInputSchema: z.ZodType<Prisma.BookingCommentUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingCommentCreateManyInputSchema: z.ZodType<Prisma.BookingCommentCreateManyInput> = z.object({
  id: z.number().int().optional(),
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int(),
  createdById: z.number().int().optional().nullable()
}).strict();

export const BookingCommentUpdateManyMutationInputSchema: z.ZodType<Prisma.BookingCommentUpdateManyMutationInput> = z.object({
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingCommentUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BookingCommentUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingDeliveryCreateInputSchema: z.ZodType<Prisma.BookingDeliveryCreateInput> = z.object({
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutDeliveriesInputSchema),
  delivery: z.lazy(() => DeliveryCreateNestedOneWithoutBookingsInputSchema)
}).strict();

export const BookingDeliveryUncheckedCreateInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int(),
  deliveryId: z.number().int()
}).strict();

export const BookingDeliveryUpdateInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateInput> = z.object({
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  booking: z.lazy(() => BookingUpdateOneRequiredWithoutDeliveriesNestedInputSchema).optional(),
  delivery: z.lazy(() => DeliveryUpdateOneRequiredWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingDeliveryUncheckedUpdateInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingDeliveryCreateManyInputSchema: z.ZodType<Prisma.BookingDeliveryCreateManyInput> = z.object({
  id: z.number().int().optional(),
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int(),
  deliveryId: z.number().int()
}).strict();

export const BookingDeliveryUpdateManyMutationInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateManyMutationInput> = z.object({
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingDeliveryUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryCreateInputSchema: z.ZodType<Prisma.BookingHistoryCreateInput> = z.object({
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutHistoryInputSchema),
  user: z.lazy(() => UserCreateNestedOneWithoutBookingHistoryInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutBookingHistoryInputSchema).optional()
}).strict();

export const BookingHistoryUncheckedCreateInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  bookingId: z.number().int(),
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  userId: z.number().int().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean()
}).strict();

export const BookingHistoryUpdateInputSchema: z.ZodType<Prisma.BookingHistoryUpdateInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  booking: z.lazy(() => BookingUpdateOneRequiredWithoutHistoryNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneWithoutBookingHistoryNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneWithoutBookingHistoryNestedInputSchema).optional()
}).strict();

export const BookingHistoryUncheckedUpdateInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  userId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryCreateManyInputSchema: z.ZodType<Prisma.BookingHistoryCreateManyInput> = z.object({
  id: z.number().int().optional(),
  bookingId: z.number().int(),
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  userId: z.number().int().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean()
}).strict();

export const BookingHistoryUpdateManyMutationInputSchema: z.ZodType<Prisma.BookingHistoryUpdateManyMutationInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  userId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingItemCreateInputSchema: z.ZodType<Prisma.BookingItemCreateInput> = z.object({
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutItemsInputSchema).optional(),
  productType: z.lazy(() => ProductTypeCreateNestedOneWithoutBookingItemsInputSchema)
}).strict();

export const BookingItemUncheckedCreateInputSchema: z.ZodType<Prisma.BookingItemUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int().optional().nullable(),
  productTypeId: z.number().int()
}).strict();

export const BookingItemUpdateInputSchema: z.ZodType<Prisma.BookingItemUpdateInput> = z.object({
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  booking: z.lazy(() => BookingUpdateOneWithoutItemsNestedInputSchema).optional(),
  productType: z.lazy(() => ProductTypeUpdateOneRequiredWithoutBookingItemsNestedInputSchema).optional()
}).strict();

export const BookingItemUncheckedUpdateInputSchema: z.ZodType<Prisma.BookingItemUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  productTypeId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingItemCreateManyInputSchema: z.ZodType<Prisma.BookingItemCreateManyInput> = z.object({
  id: z.number().int().optional(),
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int().optional().nullable(),
  productTypeId: z.number().int()
}).strict();

export const BookingItemUpdateManyMutationInputSchema: z.ZodType<Prisma.BookingItemUpdateManyMutationInput> = z.object({
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingItemUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BookingItemUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  productTypeId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingCreateInputSchema: z.ZodType<Prisma.BookingCreateInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateInputSchema: z.ZodType<Prisma.BookingUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingUpdateInputSchema: z.ZodType<Prisma.BookingUpdateInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingCreateManyInputSchema: z.ZodType<Prisma.BookingCreateManyInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BookingUpdateManyMutationInputSchema: z.ZodType<Prisma.BookingUpdateManyMutationInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ClientCreateInputSchema: z.ZodType<Prisma.ClientCreateInput> = z.object({
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutClientInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedClientsInputSchema),
  location: z.lazy(() => LocationCreateNestedOneWithoutClientsInputSchema).optional(),
  statements: z.lazy(() => StatementCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientUncheckedCreateInputSchema: z.ZodType<Prisma.ClientUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  statements: z.lazy(() => StatementUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserUncheckedCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientUpdateInputSchema: z.ZodType<Prisma.ClientUpdateInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUpdateManyWithoutClientNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedClientsNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneWithoutClientsNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientUncheckedUpdateInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUncheckedUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientCreateManyInputSchema: z.ZodType<Prisma.ClientCreateManyInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  createdById: z.number().int()
}).strict();

export const ClientUpdateManyMutationInputSchema: z.ZodType<Prisma.ClientUpdateManyMutationInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ClientUncheckedUpdateManyInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DeliveryCreateInputSchema: z.ZodType<Prisma.DeliveryCreateInput> = z.object({
  date: z.coerce.date().optional(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingDeliveryCreateNestedManyWithoutDeliveryInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutDeliveriesInputSchema),
  deliveredBy: z.lazy(() => UserCreateNestedOneWithoutDeliveredDeliveriesInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedDeliveriesInputSchema)
}).strict();

export const DeliveryUncheckedCreateInputSchema: z.ZodType<Prisma.DeliveryUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  locationId: z.number().int(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveredById: z.number().int(),
  createdById: z.number().int(),
  bookings: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutDeliveryInputSchema).optional()
}).strict();

export const DeliveryUpdateInputSchema: z.ZodType<Prisma.DeliveryUpdateInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingDeliveryUpdateManyWithoutDeliveryNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutDeliveriesNestedInputSchema).optional(),
  deliveredBy: z.lazy(() => UserUpdateOneRequiredWithoutDeliveredDeliveriesNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedDeliveriesNestedInputSchema).optional()
}).strict();

export const DeliveryUncheckedUpdateInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutDeliveryNestedInputSchema).optional()
}).strict();

export const DeliveryCreateManyInputSchema: z.ZodType<Prisma.DeliveryCreateManyInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  locationId: z.number().int(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveredById: z.number().int(),
  createdById: z.number().int()
}).strict();

export const DeliveryUpdateManyMutationInputSchema: z.ZodType<Prisma.DeliveryUpdateManyMutationInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DeliveryUncheckedUpdateManyInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DistrictCreateInputSchema: z.ZodType<Prisma.DistrictCreateInput> = z.object({
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locations: z.lazy(() => LocationCreateNestedManyWithoutDistrictInputSchema).optional()
}).strict();

export const DistrictUncheckedCreateInputSchema: z.ZodType<Prisma.DistrictUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locations: z.lazy(() => LocationUncheckedCreateNestedManyWithoutDistrictInputSchema).optional()
}).strict();

export const DistrictUpdateInputSchema: z.ZodType<Prisma.DistrictUpdateInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => LocationUpdateManyWithoutDistrictNestedInputSchema).optional()
}).strict();

export const DistrictUncheckedUpdateInputSchema: z.ZodType<Prisma.DistrictUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locations: z.lazy(() => LocationUncheckedUpdateManyWithoutDistrictNestedInputSchema).optional()
}).strict();

export const DistrictCreateManyInputSchema: z.ZodType<Prisma.DistrictCreateManyInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const DistrictUpdateManyMutationInputSchema: z.ZodType<Prisma.DistrictUpdateManyMutationInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DistrictUncheckedUpdateManyInputSchema: z.ZodType<Prisma.DistrictUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FiscalYearCreateInputSchema: z.ZodType<Prisma.FiscalYearCreateInput> = z.object({
  nameEng: z.string(),
  nameNep: z.string(),
  curr: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const FiscalYearUncheckedCreateInputSchema: z.ZodType<Prisma.FiscalYearUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  nameEng: z.string(),
  nameNep: z.string(),
  curr: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const FiscalYearUpdateInputSchema: z.ZodType<Prisma.FiscalYearUpdateInput> = z.object({
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  curr: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FiscalYearUncheckedUpdateInputSchema: z.ZodType<Prisma.FiscalYearUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  curr: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FiscalYearCreateManyInputSchema: z.ZodType<Prisma.FiscalYearCreateManyInput> = z.object({
  id: z.number().int().optional(),
  nameEng: z.string(),
  nameNep: z.string(),
  curr: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const FiscalYearUpdateManyMutationInputSchema: z.ZodType<Prisma.FiscalYearUpdateManyMutationInput> = z.object({
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  curr: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FiscalYearUncheckedUpdateManyInputSchema: z.ZodType<Prisma.FiscalYearUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  curr: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const GenBookingBarcodeCreateInputSchema: z.ZodType<Prisma.GenBookingBarcodeCreateInput> = z.object({
  year: z.number().int(),
  code: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const GenBookingBarcodeUncheckedCreateInputSchema: z.ZodType<Prisma.GenBookingBarcodeUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  year: z.number().int(),
  code: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const GenBookingBarcodeUpdateInputSchema: z.ZodType<Prisma.GenBookingBarcodeUpdateInput> = z.object({
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const GenBookingBarcodeUncheckedUpdateInputSchema: z.ZodType<Prisma.GenBookingBarcodeUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const GenBookingBarcodeCreateManyInputSchema: z.ZodType<Prisma.GenBookingBarcodeCreateManyInput> = z.object({
  id: z.number().int().optional(),
  year: z.number().int(),
  code: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const GenBookingBarcodeUpdateManyMutationInputSchema: z.ZodType<Prisma.GenBookingBarcodeUpdateManyMutationInput> = z.object({
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const GenBookingBarcodeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.GenBookingBarcodeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const LocationCreateInputSchema: z.ZodType<Prisma.LocationCreateInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateInputSchema: z.ZodType<Prisma.LocationUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUpdateInputSchema: z.ZodType<Prisma.LocationUpdateInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationCreateManyInputSchema: z.ZodType<Prisma.LocationCreateManyInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable()
}).strict();

export const LocationUpdateManyMutationInputSchema: z.ZodType<Prisma.LocationUpdateManyMutationInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const LocationUncheckedUpdateManyInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ManifestCreateInputSchema: z.ZodType<Prisma.ManifestCreateInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUpdateInputSchema: z.ZodType<Prisma.ManifestUpdateInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestCreateManyInputSchema: z.ZodType<Prisma.ManifestCreateManyInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const ManifestUpdateManyMutationInputSchema: z.ZodType<Prisma.ManifestUpdateManyMutationInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ManifestUncheckedUpdateManyInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestDetailCreateInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutMasterManifestsInputSchema),
  masterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutDetailsInputSchema)
}).strict();

export const MasterManifestDetailUncheckedCreateInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedCreateInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  manifestId: z.number().int(),
  masterManifestId: z.number().int()
}).strict();

export const MasterManifestDetailUpdateInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  manifest: z.lazy(() => ManifestUpdateOneRequiredWithoutMasterManifestsNestedInputSchema).optional(),
  masterManifest: z.lazy(() => MasterManifestUpdateOneRequiredWithoutDetailsNestedInputSchema).optional()
}).strict();

export const MasterManifestDetailUncheckedUpdateInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedUpdateInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const MasterManifestDetailCreateManyInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateManyInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  manifestId: z.number().int(),
  masterManifestId: z.number().int()
}).strict();

export const MasterManifestDetailUpdateManyMutationInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateManyMutationInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const MasterManifestDetailUncheckedUpdateManyInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedUpdateManyInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const MasterManifestCreateInputSchema: z.ZodType<Prisma.MasterManifestCreateInput> = z.object({
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutMasterManifestInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginMasterManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationMasterManifestsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedMasterManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedMasterManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedMasterManifestsInputSchema).optional()
}).strict();

export const MasterManifestUncheckedCreateInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInputSchema).optional()
}).strict();

export const MasterManifestUpdateInputSchema: z.ZodType<Prisma.MasterManifestUpdateInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUpdateManyWithoutMasterManifestNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedMasterManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInputSchema).optional()
}).strict();

export const MasterManifestCreateManyInputSchema: z.ZodType<Prisma.MasterManifestCreateManyInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const MasterManifestUpdateManyMutationInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyMutationInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestUncheckedUpdateManyInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MonthCreateInputSchema: z.ZodType<Prisma.MonthCreateInput> = z.object({
  nameEng: z.string(),
  nameNep: z.string(),
  fyOrder: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const MonthUncheckedCreateInputSchema: z.ZodType<Prisma.MonthUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  nameEng: z.string(),
  nameNep: z.string(),
  fyOrder: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const MonthUpdateInputSchema: z.ZodType<Prisma.MonthUpdateInput> = z.object({
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  fyOrder: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MonthUncheckedUpdateInputSchema: z.ZodType<Prisma.MonthUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  fyOrder: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MonthCreateManyInputSchema: z.ZodType<Prisma.MonthCreateManyInput> = z.object({
  id: z.number().int().optional(),
  nameEng: z.string(),
  nameNep: z.string(),
  fyOrder: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const MonthUpdateManyMutationInputSchema: z.ZodType<Prisma.MonthUpdateManyMutationInput> = z.object({
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  fyOrder: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MonthUncheckedUpdateManyInputSchema: z.ZodType<Prisma.MonthUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  fyOrder: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ProductTypeCreateInputSchema: z.ZodType<Prisma.ProductTypeCreateInput> = z.object({
  name: z.string(),
  status: z.boolean().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  unit: z.lazy(() => UnitSchema).optional(),
  bookingItems: z.lazy(() => BookingItemCreateNestedManyWithoutProductTypeInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedProductTypesInputSchema)
}).strict();

export const ProductTypeUncheckedCreateInputSchema: z.ZodType<Prisma.ProductTypeUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  status: z.boolean().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  unit: z.lazy(() => UnitSchema).optional(),
  bookingItems: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutProductTypeInputSchema).optional()
}).strict();

export const ProductTypeUpdateInputSchema: z.ZodType<Prisma.ProductTypeUpdateInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  bookingItems: z.lazy(() => BookingItemUpdateManyWithoutProductTypeNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedProductTypesNestedInputSchema).optional()
}).strict();

export const ProductTypeUncheckedUpdateInputSchema: z.ZodType<Prisma.ProductTypeUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  bookingItems: z.lazy(() => BookingItemUncheckedUpdateManyWithoutProductTypeNestedInputSchema).optional()
}).strict();

export const ProductTypeCreateManyInputSchema: z.ZodType<Prisma.ProductTypeCreateManyInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  status: z.boolean().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  unit: z.lazy(() => UnitSchema).optional()
}).strict();

export const ProductTypeUpdateManyMutationInputSchema: z.ZodType<Prisma.ProductTypeUpdateManyMutationInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const ProductTypeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.ProductTypeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const RateCreateInputSchema: z.ZodType<Prisma.RateCreateInput> = z.object({
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginRatesInputSchema).optional(),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationRatesInputSchema).optional()
}).strict();

export const RateUncheckedCreateInputSchema: z.ZodType<Prisma.RateUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int().optional().nullable(),
  destinationId: z.number().int().optional().nullable()
}).strict();

export const RateUpdateInputSchema: z.ZodType<Prisma.RateUpdateInput> = z.object({
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  origin: z.lazy(() => LocationUpdateOneWithoutOriginRatesNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneWithoutDestinationRatesNestedInputSchema).optional()
}).strict();

export const RateUncheckedUpdateInputSchema: z.ZodType<Prisma.RateUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RateCreateManyInputSchema: z.ZodType<Prisma.RateCreateManyInput> = z.object({
  id: z.number().int().optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int().optional().nullable(),
  destinationId: z.number().int().optional().nullable()
}).strict();

export const RateUpdateManyMutationInputSchema: z.ZodType<Prisma.RateUpdateManyMutationInput> = z.object({
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RateUncheckedUpdateManyInputSchema: z.ZodType<Prisma.RateUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RoleCreateInputSchema: z.ZodType<Prisma.RoleCreateInput> = z.object({
  code: z.string(),
  name: z.string(),
  desc: z.string().optional().nullable(),
  isAdmin: z.boolean(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  users: z.lazy(() => UserCreateNestedManyWithoutRoleInputSchema).optional()
}).strict();

export const RoleUncheckedCreateInputSchema: z.ZodType<Prisma.RoleUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  code: z.string(),
  name: z.string(),
  desc: z.string().optional().nullable(),
  isAdmin: z.boolean(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutRoleInputSchema).optional()
}).strict();

export const RoleUpdateInputSchema: z.ZodType<Prisma.RoleUpdateInput> = z.object({
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdmin: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  users: z.lazy(() => UserUpdateManyWithoutRoleNestedInputSchema).optional()
}).strict();

export const RoleUncheckedUpdateInputSchema: z.ZodType<Prisma.RoleUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdmin: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutRoleNestedInputSchema).optional()
}).strict();

export const RoleCreateManyInputSchema: z.ZodType<Prisma.RoleCreateManyInput> = z.object({
  id: z.number().int().optional(),
  code: z.string(),
  name: z.string(),
  desc: z.string().optional().nullable(),
  isAdmin: z.boolean(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const RoleUpdateManyMutationInputSchema: z.ZodType<Prisma.RoleUpdateManyMutationInput> = z.object({
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdmin: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RoleUncheckedUpdateManyInputSchema: z.ZodType<Prisma.RoleUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdmin: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const StatementCreateInputSchema: z.ZodType<Prisma.StatementCreateInput> = z.object({
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  client: z.lazy(() => ClientCreateNestedOneWithoutStatementsInputSchema),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutStatementInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutStatementInputSchema)
}).strict();

export const StatementUncheckedCreateInputSchema: z.ZodType<Prisma.StatementUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutStatementInputSchema).optional()
}).strict();

export const StatementUpdateInputSchema: z.ZodType<Prisma.StatementUpdateInput> = z.object({
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  client: z.lazy(() => ClientUpdateOneRequiredWithoutStatementsNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutStatementNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutStatementNestedInputSchema).optional()
}).strict();

export const StatementUncheckedUpdateInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutStatementNestedInputSchema).optional()
}).strict();

export const StatementCreateManyInputSchema: z.ZodType<Prisma.StatementCreateManyInput> = z.object({
  id: z.number().int().optional(),
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int()
}).strict();

export const StatementUpdateManyMutationInputSchema: z.ZodType<Prisma.StatementUpdateManyMutationInput> = z.object({
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const StatementUncheckedUpdateManyInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubLocationCreateInputSchema: z.ZodType<Prisma.SubLocationCreateInput> = z.object({
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  location: z.lazy(() => LocationCreateNestedOneWithoutSubLocationsInputSchema).optional()
}).strict();

export const SubLocationUncheckedCreateInputSchema: z.ZodType<Prisma.SubLocationUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable()
}).strict();

export const SubLocationUpdateInputSchema: z.ZodType<Prisma.SubLocationUpdateInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  location: z.lazy(() => LocationUpdateOneWithoutSubLocationsNestedInputSchema).optional()
}).strict();

export const SubLocationUncheckedUpdateInputSchema: z.ZodType<Prisma.SubLocationUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SubLocationCreateManyInputSchema: z.ZodType<Prisma.SubLocationCreateManyInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable()
}).strict();

export const SubLocationUpdateManyMutationInputSchema: z.ZodType<Prisma.SubLocationUpdateManyMutationInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SubLocationUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SubLocationUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const UserCreateInputSchema: z.ZodType<Prisma.UserCreateInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateInputSchema: z.ZodType<Prisma.UserUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUpdateInputSchema: z.ZodType<Prisma.UserUpdateInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateInputSchema: z.ZodType<Prisma.UserUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserCreateManyInputSchema: z.ZodType<Prisma.UserCreateManyInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable()
}).strict();

export const UserUpdateManyMutationInputSchema: z.ZodType<Prisma.UserUpdateManyMutationInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const UserUncheckedUpdateManyInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WardCreateInputSchema: z.ZodType<Prisma.WardCreateInput> = z.object({
  nameEng: z.string(),
  nameNep: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const WardUncheckedCreateInputSchema: z.ZodType<Prisma.WardUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  nameEng: z.string(),
  nameNep: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const WardUpdateInputSchema: z.ZodType<Prisma.WardUpdateInput> = z.object({
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WardUncheckedUpdateInputSchema: z.ZodType<Prisma.WardUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WardCreateManyInputSchema: z.ZodType<Prisma.WardCreateManyInput> = z.object({
  id: z.number().int().optional(),
  nameEng: z.string(),
  nameNep: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const WardUpdateManyMutationInputSchema: z.ZodType<Prisma.WardUpdateManyMutationInput> = z.object({
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WardUncheckedUpdateManyInputSchema: z.ZodType<Prisma.WardUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  nameEng: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nameNep: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const IntFilterSchema: z.ZodType<Prisma.IntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const StringFilterSchema: z.ZodType<Prisma.StringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const DateTimeFilterSchema: z.ZodType<Prisma.DateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict();

export const DateTimeNullableFilterSchema: z.ZodType<Prisma.DateTimeNullableFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const IntNullableFilterSchema: z.ZodType<Prisma.IntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const UserScalarRelationFilterSchema: z.ZodType<Prisma.UserScalarRelationFilter> = z.object({
  is: z.lazy(() => UserWhereInputSchema).optional(),
  isNot: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const ManifestNullableScalarRelationFilterSchema: z.ZodType<Prisma.ManifestNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => ManifestWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => ManifestWhereInputSchema).optional().nullable()
}).strict();

export const LocationScalarRelationFilterSchema: z.ZodType<Prisma.LocationScalarRelationFilter> = z.object({
  is: z.lazy(() => LocationWhereInputSchema).optional(),
  isNot: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationNullableScalarRelationFilterSchema: z.ZodType<Prisma.LocationNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => LocationWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => LocationWhereInputSchema).optional().nullable()
}).strict();

export const BookingListRelationFilterSchema: z.ZodType<Prisma.BookingListRelationFilter> = z.object({
  every: z.lazy(() => BookingWhereInputSchema).optional(),
  some: z.lazy(() => BookingWhereInputSchema).optional(),
  none: z.lazy(() => BookingWhereInputSchema).optional()
}).strict();

export const SortOrderInputSchema: z.ZodType<Prisma.SortOrderInput> = z.object({
  sort: z.lazy(() => SortOrderSchema),
  nulls: z.lazy(() => NullsOrderSchema).optional()
}).strict();

export const BookingOrderByRelationAggregateInputSchema: z.ZodType<Prisma.BookingOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BaggageOrderByRelevanceInputSchema: z.ZodType<Prisma.BaggageOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => BaggageOrderByRelevanceFieldEnumSchema),z.lazy(() => BaggageOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const BaggageCountOrderByAggregateInputSchema: z.ZodType<Prisma.BaggageCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  baggageNo: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BaggageAvgOrderByAggregateInputSchema: z.ZodType<Prisma.BaggageAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BaggageMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BaggageMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  baggageNo: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BaggageMinOrderByAggregateInputSchema: z.ZodType<Prisma.BaggageMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  baggageNo: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BaggageSumOrderByAggregateInputSchema: z.ZodType<Prisma.BaggageSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const IntWithAggregatesFilterSchema: z.ZodType<Prisma.IntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict();

export const StringWithAggregatesFilterSchema: z.ZodType<Prisma.StringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const DateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict();

export const DateTimeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeNullableWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeNullableFilterSchema).optional()
}).strict();

export const IntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.IntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const BookingScalarRelationFilterSchema: z.ZodType<Prisma.BookingScalarRelationFilter> = z.object({
  is: z.lazy(() => BookingWhereInputSchema).optional(),
  isNot: z.lazy(() => BookingWhereInputSchema).optional()
}).strict();

export const UserNullableScalarRelationFilterSchema: z.ZodType<Prisma.UserNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => UserWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => UserWhereInputSchema).optional().nullable()
}).strict();

export const BookingCommentOrderByRelevanceInputSchema: z.ZodType<Prisma.BookingCommentOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => BookingCommentOrderByRelevanceFieldEnumSchema),z.lazy(() => BookingCommentOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const BookingCommentCountOrderByAggregateInputSchema: z.ZodType<Prisma.BookingCommentCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingCommentAvgOrderByAggregateInputSchema: z.ZodType<Prisma.BookingCommentAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingCommentMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BookingCommentMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingCommentMinOrderByAggregateInputSchema: z.ZodType<Prisma.BookingCommentMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingCommentSumOrderByAggregateInputSchema: z.ZodType<Prisma.BookingCommentSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumBookingDeliveryStatusFilterSchema: z.ZodType<Prisma.EnumBookingDeliveryStatusFilter> = z.object({
  equals: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  in: z.lazy(() => BookingDeliveryStatusSchema).array().optional(),
  notIn: z.lazy(() => BookingDeliveryStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => NestedEnumBookingDeliveryStatusFilterSchema) ]).optional(),
}).strict();

export const StringNullableFilterSchema: z.ZodType<Prisma.StringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const DeliveryScalarRelationFilterSchema: z.ZodType<Prisma.DeliveryScalarRelationFilter> = z.object({
  is: z.lazy(() => DeliveryWhereInputSchema).optional(),
  isNot: z.lazy(() => DeliveryWhereInputSchema).optional()
}).strict();

export const BookingDeliveryOrderByRelevanceInputSchema: z.ZodType<Prisma.BookingDeliveryOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => BookingDeliveryOrderByRelevanceFieldEnumSchema),z.lazy(() => BookingDeliveryOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const BookingDeliveryCountOrderByAggregateInputSchema: z.ZodType<Prisma.BookingDeliveryCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  deliveredAt: z.lazy(() => SortOrderSchema).optional(),
  receivedBy: z.lazy(() => SortOrderSchema).optional(),
  podPath: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  deliveryId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingDeliveryAvgOrderByAggregateInputSchema: z.ZodType<Prisma.BookingDeliveryAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  deliveryId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingDeliveryMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BookingDeliveryMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  deliveredAt: z.lazy(() => SortOrderSchema).optional(),
  receivedBy: z.lazy(() => SortOrderSchema).optional(),
  podPath: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  deliveryId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingDeliveryMinOrderByAggregateInputSchema: z.ZodType<Prisma.BookingDeliveryMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  deliveredAt: z.lazy(() => SortOrderSchema).optional(),
  receivedBy: z.lazy(() => SortOrderSchema).optional(),
  podPath: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  deliveryId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingDeliverySumOrderByAggregateInputSchema: z.ZodType<Prisma.BookingDeliverySumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  deliveryId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumBookingDeliveryStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumBookingDeliveryStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  in: z.lazy(() => BookingDeliveryStatusSchema).array().optional(),
  notIn: z.lazy(() => BookingDeliveryStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => NestedEnumBookingDeliveryStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumBookingDeliveryStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumBookingDeliveryStatusFilterSchema).optional()
}).strict();

export const StringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.StringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const EnumBookingActionFilterSchema: z.ZodType<Prisma.EnumBookingActionFilter> = z.object({
  equals: z.lazy(() => BookingActionSchema).optional(),
  in: z.lazy(() => BookingActionSchema).array().optional(),
  notIn: z.lazy(() => BookingActionSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => NestedEnumBookingActionFilterSchema) ]).optional(),
}).strict();

export const BoolFilterSchema: z.ZodType<Prisma.BoolFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolFilterSchema) ]).optional(),
}).strict();

export const BookingHistoryOrderByRelevanceInputSchema: z.ZodType<Prisma.BookingHistoryOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => BookingHistoryOrderByRelevanceFieldEnumSchema),z.lazy(() => BookingHistoryOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const BookingHistoryCountOrderByAggregateInputSchema: z.ZodType<Prisma.BookingHistoryCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  action: z.lazy(() => SortOrderSchema).optional(),
  refId: z.lazy(() => SortOrderSchema).optional(),
  refNo: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingHistoryAvgOrderByAggregateInputSchema: z.ZodType<Prisma.BookingHistoryAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  refId: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingHistoryMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BookingHistoryMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  action: z.lazy(() => SortOrderSchema).optional(),
  refId: z.lazy(() => SortOrderSchema).optional(),
  refNo: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingHistoryMinOrderByAggregateInputSchema: z.ZodType<Prisma.BookingHistoryMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  action: z.lazy(() => SortOrderSchema).optional(),
  refId: z.lazy(() => SortOrderSchema).optional(),
  refNo: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingHistorySumOrderByAggregateInputSchema: z.ZodType<Prisma.BookingHistorySumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  refId: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumBookingActionWithAggregatesFilterSchema: z.ZodType<Prisma.EnumBookingActionWithAggregatesFilter> = z.object({
  equals: z.lazy(() => BookingActionSchema).optional(),
  in: z.lazy(() => BookingActionSchema).array().optional(),
  notIn: z.lazy(() => BookingActionSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => NestedEnumBookingActionWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumBookingActionFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumBookingActionFilterSchema).optional()
}).strict();

export const BoolWithAggregatesFilterSchema: z.ZodType<Prisma.BoolWithAggregatesFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolFilterSchema).optional()
}).strict();

export const DecimalFilterSchema: z.ZodType<Prisma.DecimalFilter> = z.object({
  equals: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  in: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional(),
  notIn: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional(),
  lt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  lte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  not: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NestedDecimalFilterSchema) ]).optional(),
}).strict();

export const EnumUnitFilterSchema: z.ZodType<Prisma.EnumUnitFilter> = z.object({
  equals: z.lazy(() => UnitSchema).optional(),
  in: z.lazy(() => UnitSchema).array().optional(),
  notIn: z.lazy(() => UnitSchema).array().optional(),
  not: z.union([ z.lazy(() => UnitSchema),z.lazy(() => NestedEnumUnitFilterSchema) ]).optional(),
}).strict();

export const BookingNullableScalarRelationFilterSchema: z.ZodType<Prisma.BookingNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => BookingWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => BookingWhereInputSchema).optional().nullable()
}).strict();

export const ProductTypeScalarRelationFilterSchema: z.ZodType<Prisma.ProductTypeScalarRelationFilter> = z.object({
  is: z.lazy(() => ProductTypeWhereInputSchema).optional(),
  isNot: z.lazy(() => ProductTypeWhereInputSchema).optional()
}).strict();

export const BookingItemOrderByRelevanceInputSchema: z.ZodType<Prisma.BookingItemOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => BookingItemOrderByRelevanceFieldEnumSchema),z.lazy(() => BookingItemOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const BookingItemCountOrderByAggregateInputSchema: z.ZodType<Prisma.BookingItemCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  desc: z.lazy(() => SortOrderSchema).optional(),
  piece: z.lazy(() => SortOrderSchema).optional(),
  weight: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  total: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  productTypeId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingItemAvgOrderByAggregateInputSchema: z.ZodType<Prisma.BookingItemAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  piece: z.lazy(() => SortOrderSchema).optional(),
  weight: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  total: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  productTypeId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingItemMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BookingItemMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  desc: z.lazy(() => SortOrderSchema).optional(),
  piece: z.lazy(() => SortOrderSchema).optional(),
  weight: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  total: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  productTypeId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingItemMinOrderByAggregateInputSchema: z.ZodType<Prisma.BookingItemMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  desc: z.lazy(() => SortOrderSchema).optional(),
  piece: z.lazy(() => SortOrderSchema).optional(),
  weight: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  total: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  productTypeId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingItemSumOrderByAggregateInputSchema: z.ZodType<Prisma.BookingItemSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  piece: z.lazy(() => SortOrderSchema).optional(),
  weight: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  total: z.lazy(() => SortOrderSchema).optional(),
  bookingId: z.lazy(() => SortOrderSchema).optional(),
  productTypeId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DecimalWithAggregatesFilterSchema: z.ZodType<Prisma.DecimalWithAggregatesFilter> = z.object({
  equals: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  in: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional(),
  notIn: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional(),
  lt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  lte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  not: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NestedDecimalWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedDecimalFilterSchema).optional(),
  _sum: z.lazy(() => NestedDecimalFilterSchema).optional(),
  _min: z.lazy(() => NestedDecimalFilterSchema).optional(),
  _max: z.lazy(() => NestedDecimalFilterSchema).optional()
}).strict();

export const EnumUnitWithAggregatesFilterSchema: z.ZodType<Prisma.EnumUnitWithAggregatesFilter> = z.object({
  equals: z.lazy(() => UnitSchema).optional(),
  in: z.lazy(() => UnitSchema).array().optional(),
  notIn: z.lazy(() => UnitSchema).array().optional(),
  not: z.union([ z.lazy(() => UnitSchema),z.lazy(() => NestedEnumUnitWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumUnitFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumUnitFilterSchema).optional()
}).strict();

export const EnumServiceTypeFilterSchema: z.ZodType<Prisma.EnumServiceTypeFilter> = z.object({
  equals: z.lazy(() => ServiceTypeSchema).optional(),
  in: z.lazy(() => ServiceTypeSchema).array().optional(),
  notIn: z.lazy(() => ServiceTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => NestedEnumServiceTypeFilterSchema) ]).optional(),
}).strict();

export const EnumDeliveryModeNullableFilterSchema: z.ZodType<Prisma.EnumDeliveryModeNullableFilter> = z.object({
  equals: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  in: z.lazy(() => DeliveryModeSchema).array().optional().nullable(),
  notIn: z.lazy(() => DeliveryModeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NestedEnumDeliveryModeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const DecimalNullableFilterSchema: z.ZodType<Prisma.DecimalNullableFilter> = z.object({
  equals: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  in: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional().nullable(),
  notIn: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional().nullable(),
  lt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  lte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  not: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NestedDecimalNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const EnumPaymentModeFilterSchema: z.ZodType<Prisma.EnumPaymentModeFilter> = z.object({
  equals: z.lazy(() => PaymentModeSchema).optional(),
  in: z.lazy(() => PaymentModeSchema).array().optional(),
  notIn: z.lazy(() => PaymentModeSchema).array().optional(),
  not: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => NestedEnumPaymentModeFilterSchema) ]).optional(),
}).strict();

export const EnumBookingStatusFilterSchema: z.ZodType<Prisma.EnumBookingStatusFilter> = z.object({
  equals: z.lazy(() => BookingStatusSchema).optional(),
  in: z.lazy(() => BookingStatusSchema).array().optional(),
  notIn: z.lazy(() => BookingStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => NestedEnumBookingStatusFilterSchema) ]).optional(),
}).strict();

export const BookingCommentListRelationFilterSchema: z.ZodType<Prisma.BookingCommentListRelationFilter> = z.object({
  every: z.lazy(() => BookingCommentWhereInputSchema).optional(),
  some: z.lazy(() => BookingCommentWhereInputSchema).optional(),
  none: z.lazy(() => BookingCommentWhereInputSchema).optional()
}).strict();

export const BookingDeliveryListRelationFilterSchema: z.ZodType<Prisma.BookingDeliveryListRelationFilter> = z.object({
  every: z.lazy(() => BookingDeliveryWhereInputSchema).optional(),
  some: z.lazy(() => BookingDeliveryWhereInputSchema).optional(),
  none: z.lazy(() => BookingDeliveryWhereInputSchema).optional()
}).strict();

export const BookingItemListRelationFilterSchema: z.ZodType<Prisma.BookingItemListRelationFilter> = z.object({
  every: z.lazy(() => BookingItemWhereInputSchema).optional(),
  some: z.lazy(() => BookingItemWhereInputSchema).optional(),
  none: z.lazy(() => BookingItemWhereInputSchema).optional()
}).strict();

export const ClientNullableScalarRelationFilterSchema: z.ZodType<Prisma.ClientNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => ClientWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => ClientWhereInputSchema).optional().nullable()
}).strict();

export const BaggageNullableScalarRelationFilterSchema: z.ZodType<Prisma.BaggageNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => BaggageWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => BaggageWhereInputSchema).optional().nullable()
}).strict();

export const BookingHistoryListRelationFilterSchema: z.ZodType<Prisma.BookingHistoryListRelationFilter> = z.object({
  every: z.lazy(() => BookingHistoryWhereInputSchema).optional(),
  some: z.lazy(() => BookingHistoryWhereInputSchema).optional(),
  none: z.lazy(() => BookingHistoryWhereInputSchema).optional()
}).strict();

export const StatementNullableScalarRelationFilterSchema: z.ZodType<Prisma.StatementNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => StatementWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => StatementWhereInputSchema).optional().nullable()
}).strict();

export const BookingCommentOrderByRelationAggregateInputSchema: z.ZodType<Prisma.BookingCommentOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingDeliveryOrderByRelationAggregateInputSchema: z.ZodType<Prisma.BookingDeliveryOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingItemOrderByRelationAggregateInputSchema: z.ZodType<Prisma.BookingItemOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingHistoryOrderByRelationAggregateInputSchema: z.ZodType<Prisma.BookingHistoryOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingOrderByRelevanceInputSchema: z.ZodType<Prisma.BookingOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => BookingOrderByRelevanceFieldEnumSchema),z.lazy(() => BookingOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const BookingCountOrderByAggregateInputSchema: z.ZodType<Prisma.BookingCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingDate: z.lazy(() => SortOrderSchema).optional(),
  consignmentNo: z.lazy(() => SortOrderSchema).optional(),
  senderName: z.lazy(() => SortOrderSchema).optional(),
  senderContactNo: z.lazy(() => SortOrderSchema).optional(),
  senderEmail: z.lazy(() => SortOrderSchema).optional(),
  senderPan: z.lazy(() => SortOrderSchema).optional(),
  receiverName: z.lazy(() => SortOrderSchema).optional(),
  receiverContactNo: z.lazy(() => SortOrderSchema).optional(),
  receiverAddr: z.lazy(() => SortOrderSchema).optional(),
  receiverPan: z.lazy(() => SortOrderSchema).optional(),
  serviceType: z.lazy(() => SortOrderSchema).optional(),
  deliveryMode: z.lazy(() => SortOrderSchema).optional(),
  declaredValue: z.lazy(() => SortOrderSchema).optional(),
  refNo: z.lazy(() => SortOrderSchema).optional(),
  invoiceDate: z.lazy(() => SortOrderSchema).optional(),
  instruction: z.lazy(() => SortOrderSchema).optional(),
  paymentMode: z.lazy(() => SortOrderSchema).optional(),
  otherCharge: z.lazy(() => SortOrderSchema).optional(),
  tax: z.lazy(() => SortOrderSchema).optional(),
  stCharge: z.lazy(() => SortOrderSchema).optional(),
  totAmt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  baggageId: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  verifiedById: z.lazy(() => SortOrderSchema).optional(),
  verifiedAt: z.lazy(() => SortOrderSchema).optional(),
  cod: z.lazy(() => SortOrderSchema).optional(),
  laborCharge: z.lazy(() => SortOrderSchema).optional(),
  pickupCharge: z.lazy(() => SortOrderSchema).optional(),
  deliveryCharge: z.lazy(() => SortOrderSchema).optional(),
  volumeCharge: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  statementId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingAvgOrderByAggregateInputSchema: z.ZodType<Prisma.BookingAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  declaredValue: z.lazy(() => SortOrderSchema).optional(),
  otherCharge: z.lazy(() => SortOrderSchema).optional(),
  tax: z.lazy(() => SortOrderSchema).optional(),
  stCharge: z.lazy(() => SortOrderSchema).optional(),
  totAmt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  baggageId: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  verifiedById: z.lazy(() => SortOrderSchema).optional(),
  cod: z.lazy(() => SortOrderSchema).optional(),
  laborCharge: z.lazy(() => SortOrderSchema).optional(),
  pickupCharge: z.lazy(() => SortOrderSchema).optional(),
  deliveryCharge: z.lazy(() => SortOrderSchema).optional(),
  volumeCharge: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  statementId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BookingMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingDate: z.lazy(() => SortOrderSchema).optional(),
  consignmentNo: z.lazy(() => SortOrderSchema).optional(),
  senderName: z.lazy(() => SortOrderSchema).optional(),
  senderContactNo: z.lazy(() => SortOrderSchema).optional(),
  senderEmail: z.lazy(() => SortOrderSchema).optional(),
  senderPan: z.lazy(() => SortOrderSchema).optional(),
  receiverName: z.lazy(() => SortOrderSchema).optional(),
  receiverContactNo: z.lazy(() => SortOrderSchema).optional(),
  receiverAddr: z.lazy(() => SortOrderSchema).optional(),
  receiverPan: z.lazy(() => SortOrderSchema).optional(),
  serviceType: z.lazy(() => SortOrderSchema).optional(),
  deliveryMode: z.lazy(() => SortOrderSchema).optional(),
  declaredValue: z.lazy(() => SortOrderSchema).optional(),
  refNo: z.lazy(() => SortOrderSchema).optional(),
  invoiceDate: z.lazy(() => SortOrderSchema).optional(),
  instruction: z.lazy(() => SortOrderSchema).optional(),
  paymentMode: z.lazy(() => SortOrderSchema).optional(),
  otherCharge: z.lazy(() => SortOrderSchema).optional(),
  tax: z.lazy(() => SortOrderSchema).optional(),
  stCharge: z.lazy(() => SortOrderSchema).optional(),
  totAmt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  baggageId: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  verifiedById: z.lazy(() => SortOrderSchema).optional(),
  verifiedAt: z.lazy(() => SortOrderSchema).optional(),
  cod: z.lazy(() => SortOrderSchema).optional(),
  laborCharge: z.lazy(() => SortOrderSchema).optional(),
  pickupCharge: z.lazy(() => SortOrderSchema).optional(),
  deliveryCharge: z.lazy(() => SortOrderSchema).optional(),
  volumeCharge: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  statementId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingMinOrderByAggregateInputSchema: z.ZodType<Prisma.BookingMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  bookingDate: z.lazy(() => SortOrderSchema).optional(),
  consignmentNo: z.lazy(() => SortOrderSchema).optional(),
  senderName: z.lazy(() => SortOrderSchema).optional(),
  senderContactNo: z.lazy(() => SortOrderSchema).optional(),
  senderEmail: z.lazy(() => SortOrderSchema).optional(),
  senderPan: z.lazy(() => SortOrderSchema).optional(),
  receiverName: z.lazy(() => SortOrderSchema).optional(),
  receiverContactNo: z.lazy(() => SortOrderSchema).optional(),
  receiverAddr: z.lazy(() => SortOrderSchema).optional(),
  receiverPan: z.lazy(() => SortOrderSchema).optional(),
  serviceType: z.lazy(() => SortOrderSchema).optional(),
  deliveryMode: z.lazy(() => SortOrderSchema).optional(),
  declaredValue: z.lazy(() => SortOrderSchema).optional(),
  refNo: z.lazy(() => SortOrderSchema).optional(),
  invoiceDate: z.lazy(() => SortOrderSchema).optional(),
  instruction: z.lazy(() => SortOrderSchema).optional(),
  paymentMode: z.lazy(() => SortOrderSchema).optional(),
  otherCharge: z.lazy(() => SortOrderSchema).optional(),
  tax: z.lazy(() => SortOrderSchema).optional(),
  stCharge: z.lazy(() => SortOrderSchema).optional(),
  totAmt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  baggageId: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  verifiedById: z.lazy(() => SortOrderSchema).optional(),
  verifiedAt: z.lazy(() => SortOrderSchema).optional(),
  cod: z.lazy(() => SortOrderSchema).optional(),
  laborCharge: z.lazy(() => SortOrderSchema).optional(),
  pickupCharge: z.lazy(() => SortOrderSchema).optional(),
  deliveryCharge: z.lazy(() => SortOrderSchema).optional(),
  volumeCharge: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  statementId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BookingSumOrderByAggregateInputSchema: z.ZodType<Prisma.BookingSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  declaredValue: z.lazy(() => SortOrderSchema).optional(),
  otherCharge: z.lazy(() => SortOrderSchema).optional(),
  tax: z.lazy(() => SortOrderSchema).optional(),
  stCharge: z.lazy(() => SortOrderSchema).optional(),
  totAmt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  baggageId: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  verifiedById: z.lazy(() => SortOrderSchema).optional(),
  cod: z.lazy(() => SortOrderSchema).optional(),
  laborCharge: z.lazy(() => SortOrderSchema).optional(),
  pickupCharge: z.lazy(() => SortOrderSchema).optional(),
  deliveryCharge: z.lazy(() => SortOrderSchema).optional(),
  volumeCharge: z.lazy(() => SortOrderSchema).optional(),
  discount: z.lazy(() => SortOrderSchema).optional(),
  statementId: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumServiceTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumServiceTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ServiceTypeSchema).optional(),
  in: z.lazy(() => ServiceTypeSchema).array().optional(),
  notIn: z.lazy(() => ServiceTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => NestedEnumServiceTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumServiceTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumServiceTypeFilterSchema).optional()
}).strict();

export const EnumDeliveryModeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumDeliveryModeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  in: z.lazy(() => DeliveryModeSchema).array().optional().nullable(),
  notIn: z.lazy(() => DeliveryModeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NestedEnumDeliveryModeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumDeliveryModeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumDeliveryModeNullableFilterSchema).optional()
}).strict();

export const DecimalNullableWithAggregatesFilterSchema: z.ZodType<Prisma.DecimalNullableWithAggregatesFilter> = z.object({
  equals: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  in: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional().nullable(),
  notIn: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional().nullable(),
  lt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  lte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  not: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NestedDecimalNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedDecimalNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedDecimalNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDecimalNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDecimalNullableFilterSchema).optional()
}).strict();

export const EnumPaymentModeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumPaymentModeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PaymentModeSchema).optional(),
  in: z.lazy(() => PaymentModeSchema).array().optional(),
  notIn: z.lazy(() => PaymentModeSchema).array().optional(),
  not: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => NestedEnumPaymentModeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPaymentModeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPaymentModeFilterSchema).optional()
}).strict();

export const EnumBookingStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumBookingStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => BookingStatusSchema).optional(),
  in: z.lazy(() => BookingStatusSchema).array().optional(),
  notIn: z.lazy(() => BookingStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => NestedEnumBookingStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumBookingStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumBookingStatusFilterSchema).optional()
}).strict();

export const StatementListRelationFilterSchema: z.ZodType<Prisma.StatementListRelationFilter> = z.object({
  every: z.lazy(() => StatementWhereInputSchema).optional(),
  some: z.lazy(() => StatementWhereInputSchema).optional(),
  none: z.lazy(() => StatementWhereInputSchema).optional()
}).strict();

export const UserListRelationFilterSchema: z.ZodType<Prisma.UserListRelationFilter> = z.object({
  every: z.lazy(() => UserWhereInputSchema).optional(),
  some: z.lazy(() => UserWhereInputSchema).optional(),
  none: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const StatementOrderByRelationAggregateInputSchema: z.ZodType<Prisma.StatementOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserOrderByRelationAggregateInputSchema: z.ZodType<Prisma.UserOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ClientOrderByRelevanceInputSchema: z.ZodType<Prisma.ClientOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => ClientOrderByRelevanceFieldEnumSchema),z.lazy(() => ClientOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const ClientCountOrderByAggregateInputSchema: z.ZodType<Prisma.ClientCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  contactPerson: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  pan: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ClientAvgOrderByAggregateInputSchema: z.ZodType<Prisma.ClientAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ClientMaxOrderByAggregateInputSchema: z.ZodType<Prisma.ClientMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  contactPerson: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  pan: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ClientMinOrderByAggregateInputSchema: z.ZodType<Prisma.ClientMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  contactPerson: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  pan: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ClientSumOrderByAggregateInputSchema: z.ZodType<Prisma.ClientSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumDeliveryStatusFilterSchema: z.ZodType<Prisma.EnumDeliveryStatusFilter> = z.object({
  equals: z.lazy(() => DeliveryStatusSchema).optional(),
  in: z.lazy(() => DeliveryStatusSchema).array().optional(),
  notIn: z.lazy(() => DeliveryStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => NestedEnumDeliveryStatusFilterSchema) ]).optional(),
}).strict();

export const DeliveryCountOrderByAggregateInputSchema: z.ZodType<Prisma.DeliveryCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  deliveredById: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeliveryAvgOrderByAggregateInputSchema: z.ZodType<Prisma.DeliveryAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  deliveredById: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeliveryMaxOrderByAggregateInputSchema: z.ZodType<Prisma.DeliveryMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  deliveredById: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeliveryMinOrderByAggregateInputSchema: z.ZodType<Prisma.DeliveryMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  deliveredById: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeliverySumOrderByAggregateInputSchema: z.ZodType<Prisma.DeliverySumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  deliveredById: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumDeliveryStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumDeliveryStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => DeliveryStatusSchema).optional(),
  in: z.lazy(() => DeliveryStatusSchema).array().optional(),
  notIn: z.lazy(() => DeliveryStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => NestedEnumDeliveryStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumDeliveryStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumDeliveryStatusFilterSchema).optional()
}).strict();

export const LocationListRelationFilterSchema: z.ZodType<Prisma.LocationListRelationFilter> = z.object({
  every: z.lazy(() => LocationWhereInputSchema).optional(),
  some: z.lazy(() => LocationWhereInputSchema).optional(),
  none: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationOrderByRelationAggregateInputSchema: z.ZodType<Prisma.LocationOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DistrictOrderByRelevanceInputSchema: z.ZodType<Prisma.DistrictOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => DistrictOrderByRelevanceFieldEnumSchema),z.lazy(() => DistrictOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const DistrictCountOrderByAggregateInputSchema: z.ZodType<Prisma.DistrictCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DistrictAvgOrderByAggregateInputSchema: z.ZodType<Prisma.DistrictAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DistrictMaxOrderByAggregateInputSchema: z.ZodType<Prisma.DistrictMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DistrictMinOrderByAggregateInputSchema: z.ZodType<Prisma.DistrictMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DistrictSumOrderByAggregateInputSchema: z.ZodType<Prisma.DistrictSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FiscalYearOrderByRelevanceInputSchema: z.ZodType<Prisma.FiscalYearOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => FiscalYearOrderByRelevanceFieldEnumSchema),z.lazy(() => FiscalYearOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const FiscalYearCountOrderByAggregateInputSchema: z.ZodType<Prisma.FiscalYearCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  curr: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FiscalYearAvgOrderByAggregateInputSchema: z.ZodType<Prisma.FiscalYearAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FiscalYearMaxOrderByAggregateInputSchema: z.ZodType<Prisma.FiscalYearMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  curr: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FiscalYearMinOrderByAggregateInputSchema: z.ZodType<Prisma.FiscalYearMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  curr: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FiscalYearSumOrderByAggregateInputSchema: z.ZodType<Prisma.FiscalYearSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const GenBookingBarcodeCountOrderByAggregateInputSchema: z.ZodType<Prisma.GenBookingBarcodeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const GenBookingBarcodeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.GenBookingBarcodeAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const GenBookingBarcodeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.GenBookingBarcodeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const GenBookingBarcodeMinOrderByAggregateInputSchema: z.ZodType<Prisma.GenBookingBarcodeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const GenBookingBarcodeSumOrderByAggregateInputSchema: z.ZodType<Prisma.GenBookingBarcodeSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BaggageListRelationFilterSchema: z.ZodType<Prisma.BaggageListRelationFilter> = z.object({
  every: z.lazy(() => BaggageWhereInputSchema).optional(),
  some: z.lazy(() => BaggageWhereInputSchema).optional(),
  none: z.lazy(() => BaggageWhereInputSchema).optional()
}).strict();

export const ClientListRelationFilterSchema: z.ZodType<Prisma.ClientListRelationFilter> = z.object({
  every: z.lazy(() => ClientWhereInputSchema).optional(),
  some: z.lazy(() => ClientWhereInputSchema).optional(),
  none: z.lazy(() => ClientWhereInputSchema).optional()
}).strict();

export const DistrictNullableScalarRelationFilterSchema: z.ZodType<Prisma.DistrictNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => DistrictWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => DistrictWhereInputSchema).optional().nullable()
}).strict();

export const ManifestListRelationFilterSchema: z.ZodType<Prisma.ManifestListRelationFilter> = z.object({
  every: z.lazy(() => ManifestWhereInputSchema).optional(),
  some: z.lazy(() => ManifestWhereInputSchema).optional(),
  none: z.lazy(() => ManifestWhereInputSchema).optional()
}).strict();

export const MasterManifestListRelationFilterSchema: z.ZodType<Prisma.MasterManifestListRelationFilter> = z.object({
  every: z.lazy(() => MasterManifestWhereInputSchema).optional(),
  some: z.lazy(() => MasterManifestWhereInputSchema).optional(),
  none: z.lazy(() => MasterManifestWhereInputSchema).optional()
}).strict();

export const RateListRelationFilterSchema: z.ZodType<Prisma.RateListRelationFilter> = z.object({
  every: z.lazy(() => RateWhereInputSchema).optional(),
  some: z.lazy(() => RateWhereInputSchema).optional(),
  none: z.lazy(() => RateWhereInputSchema).optional()
}).strict();

export const SubLocationListRelationFilterSchema: z.ZodType<Prisma.SubLocationListRelationFilter> = z.object({
  every: z.lazy(() => SubLocationWhereInputSchema).optional(),
  some: z.lazy(() => SubLocationWhereInputSchema).optional(),
  none: z.lazy(() => SubLocationWhereInputSchema).optional()
}).strict();

export const DeliveryListRelationFilterSchema: z.ZodType<Prisma.DeliveryListRelationFilter> = z.object({
  every: z.lazy(() => DeliveryWhereInputSchema).optional(),
  some: z.lazy(() => DeliveryWhereInputSchema).optional(),
  none: z.lazy(() => DeliveryWhereInputSchema).optional()
}).strict();

export const BaggageOrderByRelationAggregateInputSchema: z.ZodType<Prisma.BaggageOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ClientOrderByRelationAggregateInputSchema: z.ZodType<Prisma.ClientOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ManifestOrderByRelationAggregateInputSchema: z.ZodType<Prisma.ManifestOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestOrderByRelationAggregateInputSchema: z.ZodType<Prisma.MasterManifestOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RateOrderByRelationAggregateInputSchema: z.ZodType<Prisma.RateOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SubLocationOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SubLocationOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeliveryOrderByRelationAggregateInputSchema: z.ZodType<Prisma.DeliveryOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LocationOrderByRelevanceInputSchema: z.ZodType<Prisma.LocationOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => LocationOrderByRelevanceFieldEnumSchema),z.lazy(() => LocationOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const LocationCountOrderByAggregateInputSchema: z.ZodType<Prisma.LocationCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  contactName: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  districtId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LocationAvgOrderByAggregateInputSchema: z.ZodType<Prisma.LocationAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  districtId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LocationMaxOrderByAggregateInputSchema: z.ZodType<Prisma.LocationMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  contactName: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  districtId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LocationMinOrderByAggregateInputSchema: z.ZodType<Prisma.LocationMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  contactName: z.lazy(() => SortOrderSchema).optional(),
  contactNo: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  districtId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LocationSumOrderByAggregateInputSchema: z.ZodType<Prisma.LocationSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  districtId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumManifestStatusFilterSchema: z.ZodType<Prisma.EnumManifestStatusFilter> = z.object({
  equals: z.lazy(() => ManifestStatusSchema).optional(),
  in: z.lazy(() => ManifestStatusSchema).array().optional(),
  notIn: z.lazy(() => ManifestStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => NestedEnumManifestStatusFilterSchema) ]).optional(),
}).strict();

export const MasterManifestNullableScalarRelationFilterSchema: z.ZodType<Prisma.MasterManifestNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => MasterManifestWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => MasterManifestWhereInputSchema).optional().nullable()
}).strict();

export const MasterManifestDetailListRelationFilterSchema: z.ZodType<Prisma.MasterManifestDetailListRelationFilter> = z.object({
  every: z.lazy(() => MasterManifestDetailWhereInputSchema).optional(),
  some: z.lazy(() => MasterManifestDetailWhereInputSchema).optional(),
  none: z.lazy(() => MasterManifestDetailWhereInputSchema).optional()
}).strict();

export const MasterManifestDetailOrderByRelationAggregateInputSchema: z.ZodType<Prisma.MasterManifestDetailOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ManifestOrderByRelevanceInputSchema: z.ZodType<Prisma.ManifestOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => ManifestOrderByRelevanceFieldEnumSchema),z.lazy(() => ManifestOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const ManifestCountOrderByAggregateInputSchema: z.ZodType<Prisma.ManifestCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  manifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.lazy(() => SortOrderSchema).optional(),
  dispatchedAt: z.lazy(() => SortOrderSchema).optional(),
  receivedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ManifestAvgOrderByAggregateInputSchema: z.ZodType<Prisma.ManifestAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ManifestMaxOrderByAggregateInputSchema: z.ZodType<Prisma.ManifestMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  manifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.lazy(() => SortOrderSchema).optional(),
  dispatchedAt: z.lazy(() => SortOrderSchema).optional(),
  receivedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ManifestMinOrderByAggregateInputSchema: z.ZodType<Prisma.ManifestMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  manifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.lazy(() => SortOrderSchema).optional(),
  dispatchedAt: z.lazy(() => SortOrderSchema).optional(),
  receivedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ManifestSumOrderByAggregateInputSchema: z.ZodType<Prisma.ManifestSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  currentLocationId: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumManifestStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumManifestStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ManifestStatusSchema).optional(),
  in: z.lazy(() => ManifestStatusSchema).array().optional(),
  notIn: z.lazy(() => ManifestStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => NestedEnumManifestStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumManifestStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumManifestStatusFilterSchema).optional()
}).strict();

export const ManifestScalarRelationFilterSchema: z.ZodType<Prisma.ManifestScalarRelationFilter> = z.object({
  is: z.lazy(() => ManifestWhereInputSchema).optional(),
  isNot: z.lazy(() => ManifestWhereInputSchema).optional()
}).strict();

export const MasterManifestScalarRelationFilterSchema: z.ZodType<Prisma.MasterManifestScalarRelationFilter> = z.object({
  is: z.lazy(() => MasterManifestWhereInputSchema).optional(),
  isNot: z.lazy(() => MasterManifestWhereInputSchema).optional()
}).strict();

export const MasterManifestDetailManifestIdMasterManifestIdCompoundUniqueInputSchema: z.ZodType<Prisma.MasterManifestDetailManifestIdMasterManifestIdCompoundUniqueInput> = z.object({
  manifestId: z.number(),
  masterManifestId: z.number()
}).strict();

export const MasterManifestDetailCountOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestDetailCountOrderByAggregateInput> = z.object({
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestDetailAvgOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestDetailAvgOrderByAggregateInput> = z.object({
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestDetailMaxOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestDetailMaxOrderByAggregateInput> = z.object({
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestDetailMinOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestDetailMinOrderByAggregateInput> = z.object({
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestDetailSumOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestDetailSumOrderByAggregateInput> = z.object({
  manifestId: z.lazy(() => SortOrderSchema).optional(),
  masterManifestId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FloatFilterSchema: z.ZodType<Prisma.FloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict();

export const EnumMasterManifestStatusFilterSchema: z.ZodType<Prisma.EnumMasterManifestStatusFilter> = z.object({
  equals: z.lazy(() => MasterManifestStatusSchema).optional(),
  in: z.lazy(() => MasterManifestStatusSchema).array().optional(),
  notIn: z.lazy(() => MasterManifestStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => NestedEnumMasterManifestStatusFilterSchema) ]).optional(),
}).strict();

export const MasterManifestOrderByRelevanceInputSchema: z.ZodType<Prisma.MasterManifestOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => MasterManifestOrderByRelevanceFieldEnumSchema),z.lazy(() => MasterManifestOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const MasterManifestCountOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  masterManifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  vehicleNo: z.lazy(() => SortOrderSchema).optional(),
  driverName: z.lazy(() => SortOrderSchema).optional(),
  mobileNo: z.lazy(() => SortOrderSchema).optional(),
  freight: z.lazy(() => SortOrderSchema).optional(),
  advance: z.lazy(() => SortOrderSchema).optional(),
  balance: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.lazy(() => SortOrderSchema).optional(),
  dispatchedAt: z.lazy(() => SortOrderSchema).optional(),
  receivedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestAvgOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  freight: z.lazy(() => SortOrderSchema).optional(),
  advance: z.lazy(() => SortOrderSchema).optional(),
  balance: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestMaxOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  masterManifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  vehicleNo: z.lazy(() => SortOrderSchema).optional(),
  driverName: z.lazy(() => SortOrderSchema).optional(),
  mobileNo: z.lazy(() => SortOrderSchema).optional(),
  freight: z.lazy(() => SortOrderSchema).optional(),
  advance: z.lazy(() => SortOrderSchema).optional(),
  balance: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.lazy(() => SortOrderSchema).optional(),
  dispatchedAt: z.lazy(() => SortOrderSchema).optional(),
  receivedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestMinOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  masterManifestNo: z.lazy(() => SortOrderSchema).optional(),
  date: z.lazy(() => SortOrderSchema).optional(),
  vehicleNo: z.lazy(() => SortOrderSchema).optional(),
  driverName: z.lazy(() => SortOrderSchema).optional(),
  mobileNo: z.lazy(() => SortOrderSchema).optional(),
  freight: z.lazy(() => SortOrderSchema).optional(),
  advance: z.lazy(() => SortOrderSchema).optional(),
  balance: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  checkedBy: z.lazy(() => SortOrderSchema).optional(),
  dispatchedAt: z.lazy(() => SortOrderSchema).optional(),
  receivedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MasterManifestSumOrderByAggregateInputSchema: z.ZodType<Prisma.MasterManifestSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  freight: z.lazy(() => SortOrderSchema).optional(),
  advance: z.lazy(() => SortOrderSchema).optional(),
  balance: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  dispatchedById: z.lazy(() => SortOrderSchema).optional(),
  receivedById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FloatWithAggregatesFilterSchema: z.ZodType<Prisma.FloatWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatFilterSchema).optional()
}).strict();

export const EnumMasterManifestStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumMasterManifestStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => MasterManifestStatusSchema).optional(),
  in: z.lazy(() => MasterManifestStatusSchema).array().optional(),
  notIn: z.lazy(() => MasterManifestStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => NestedEnumMasterManifestStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumMasterManifestStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumMasterManifestStatusFilterSchema).optional()
}).strict();

export const MonthOrderByRelevanceInputSchema: z.ZodType<Prisma.MonthOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => MonthOrderByRelevanceFieldEnumSchema),z.lazy(() => MonthOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const MonthCountOrderByAggregateInputSchema: z.ZodType<Prisma.MonthCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  fyOrder: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MonthAvgOrderByAggregateInputSchema: z.ZodType<Prisma.MonthAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fyOrder: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MonthMaxOrderByAggregateInputSchema: z.ZodType<Prisma.MonthMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  fyOrder: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MonthMinOrderByAggregateInputSchema: z.ZodType<Prisma.MonthMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  fyOrder: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const MonthSumOrderByAggregateInputSchema: z.ZodType<Prisma.MonthSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fyOrder: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ProductTypeOrderByRelevanceInputSchema: z.ZodType<Prisma.ProductTypeOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => ProductTypeOrderByRelevanceFieldEnumSchema),z.lazy(() => ProductTypeOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const ProductTypeCountOrderByAggregateInputSchema: z.ZodType<Prisma.ProductTypeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ProductTypeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.ProductTypeAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ProductTypeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.ProductTypeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ProductTypeMinOrderByAggregateInputSchema: z.ZodType<Prisma.ProductTypeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  unit: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ProductTypeSumOrderByAggregateInputSchema: z.ZodType<Prisma.ProductTypeSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RateCountOrderByAggregateInputSchema: z.ZodType<Prisma.RateCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RateAvgOrderByAggregateInputSchema: z.ZodType<Prisma.RateAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RateMaxOrderByAggregateInputSchema: z.ZodType<Prisma.RateMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RateMinOrderByAggregateInputSchema: z.ZodType<Prisma.RateMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RateSumOrderByAggregateInputSchema: z.ZodType<Prisma.RateSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  originId: z.lazy(() => SortOrderSchema).optional(),
  destinationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RoleOrderByRelevanceInputSchema: z.ZodType<Prisma.RoleOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => RoleOrderByRelevanceFieldEnumSchema),z.lazy(() => RoleOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const RoleCountOrderByAggregateInputSchema: z.ZodType<Prisma.RoleCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  desc: z.lazy(() => SortOrderSchema).optional(),
  isAdmin: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RoleAvgOrderByAggregateInputSchema: z.ZodType<Prisma.RoleAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RoleMaxOrderByAggregateInputSchema: z.ZodType<Prisma.RoleMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  desc: z.lazy(() => SortOrderSchema).optional(),
  isAdmin: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RoleMinOrderByAggregateInputSchema: z.ZodType<Prisma.RoleMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  code: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  desc: z.lazy(() => SortOrderSchema).optional(),
  isAdmin: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const RoleSumOrderByAggregateInputSchema: z.ZodType<Prisma.RoleSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ClientScalarRelationFilterSchema: z.ZodType<Prisma.ClientScalarRelationFilter> = z.object({
  is: z.lazy(() => ClientWhereInputSchema).optional(),
  isNot: z.lazy(() => ClientWhereInputSchema).optional()
}).strict();

export const StatementCountOrderByAggregateInputSchema: z.ZodType<Prisma.StatementCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fromDate: z.lazy(() => SortOrderSchema).optional(),
  toDate: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StatementAvgOrderByAggregateInputSchema: z.ZodType<Prisma.StatementAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StatementMaxOrderByAggregateInputSchema: z.ZodType<Prisma.StatementMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fromDate: z.lazy(() => SortOrderSchema).optional(),
  toDate: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StatementMinOrderByAggregateInputSchema: z.ZodType<Prisma.StatementMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fromDate: z.lazy(() => SortOrderSchema).optional(),
  toDate: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StatementSumOrderByAggregateInputSchema: z.ZodType<Prisma.StatementSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  createdById: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SubLocationOrderByRelevanceInputSchema: z.ZodType<Prisma.SubLocationOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => SubLocationOrderByRelevanceFieldEnumSchema),z.lazy(() => SubLocationOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const SubLocationCountOrderByAggregateInputSchema: z.ZodType<Prisma.SubLocationCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SubLocationAvgOrderByAggregateInputSchema: z.ZodType<Prisma.SubLocationAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SubLocationMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SubLocationMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SubLocationMinOrderByAggregateInputSchema: z.ZodType<Prisma.SubLocationMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SubLocationSumOrderByAggregateInputSchema: z.ZodType<Prisma.SubLocationSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ProductTypeListRelationFilterSchema: z.ZodType<Prisma.ProductTypeListRelationFilter> = z.object({
  every: z.lazy(() => ProductTypeWhereInputSchema).optional(),
  some: z.lazy(() => ProductTypeWhereInputSchema).optional(),
  none: z.lazy(() => ProductTypeWhereInputSchema).optional()
}).strict();

export const RoleNullableScalarRelationFilterSchema: z.ZodType<Prisma.RoleNullableScalarRelationFilter> = z.object({
  is: z.lazy(() => RoleWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => RoleWhereInputSchema).optional().nullable()
}).strict();

export const ProductTypeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.ProductTypeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserOrderByRelevanceInputSchema: z.ZodType<Prisma.UserOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => UserOrderByRelevanceFieldEnumSchema),z.lazy(() => UserOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const UserCountOrderByAggregateInputSchema: z.ZodType<Prisma.UserCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  middleName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  userName: z.lazy(() => SortOrderSchema).optional(),
  password: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  passwordToken: z.lazy(() => SortOrderSchema).optional(),
  passwordTokenExpiration: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserAvgOrderByAggregateInputSchema: z.ZodType<Prisma.UserAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserMaxOrderByAggregateInputSchema: z.ZodType<Prisma.UserMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  middleName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  userName: z.lazy(() => SortOrderSchema).optional(),
  password: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  passwordToken: z.lazy(() => SortOrderSchema).optional(),
  passwordTokenExpiration: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserMinOrderByAggregateInputSchema: z.ZodType<Prisma.UserMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  middleName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  userName: z.lazy(() => SortOrderSchema).optional(),
  password: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional(),
  passwordToken: z.lazy(() => SortOrderSchema).optional(),
  passwordTokenExpiration: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserSumOrderByAggregateInputSchema: z.ZodType<Prisma.UserSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locationId: z.lazy(() => SortOrderSchema).optional(),
  roleId: z.lazy(() => SortOrderSchema).optional(),
  clientId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WardOrderByRelevanceInputSchema: z.ZodType<Prisma.WardOrderByRelevanceInput> = z.object({
  fields: z.union([ z.lazy(() => WardOrderByRelevanceFieldEnumSchema),z.lazy(() => WardOrderByRelevanceFieldEnumSchema).array() ]),
  sort: z.lazy(() => SortOrderSchema),
  search: z.string()
}).strict();

export const WardCountOrderByAggregateInputSchema: z.ZodType<Prisma.WardCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WardAvgOrderByAggregateInputSchema: z.ZodType<Prisma.WardAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WardMaxOrderByAggregateInputSchema: z.ZodType<Prisma.WardMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WardMinOrderByAggregateInputSchema: z.ZodType<Prisma.WardMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  nameEng: z.lazy(() => SortOrderSchema).optional(),
  nameNep: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WardSumOrderByAggregateInputSchema: z.ZodType<Prisma.WardSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutCreatedBaggagesInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCreatedBaggagesInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedBaggagesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedBaggagesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const ManifestCreateNestedOneWithoutBaggagesInputSchema: z.ZodType<Prisma.ManifestCreateNestedOneWithoutBaggagesInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutBaggagesInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ManifestCreateOrConnectWithoutBaggagesInputSchema).optional(),
  connect: z.lazy(() => ManifestWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutOriginBaggagesInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutOriginBaggagesInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginBaggagesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutDestinationBaggagesInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutDestinationBaggagesInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationBaggagesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutCurrentBaggagesInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutCurrentBaggagesInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutCurrentBaggagesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const BookingCreateNestedManyWithoutBaggageInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutBaggageInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutBaggageInputSchema),z.lazy(() => BookingCreateWithoutBaggageInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutBaggageInputSchema),z.lazy(() => BookingCreateOrConnectWithoutBaggageInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyBaggageInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedCreateNestedManyWithoutBaggageInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutBaggageInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutBaggageInputSchema),z.lazy(() => BookingCreateWithoutBaggageInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutBaggageInputSchema),z.lazy(() => BookingCreateOrConnectWithoutBaggageInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyBaggageInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const StringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.StringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional()
}).strict();

export const DateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.DateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional()
}).strict();

export const NullableDateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableDateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional().nullable()
}).strict();

export const UserUpdateOneRequiredWithoutCreatedBaggagesNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCreatedBaggagesNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedBaggagesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedBaggagesInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCreatedBaggagesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCreatedBaggagesInputSchema),z.lazy(() => UserUpdateWithoutCreatedBaggagesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedBaggagesInputSchema) ]).optional(),
}).strict();

export const ManifestUpdateOneWithoutBaggagesNestedInputSchema: z.ZodType<Prisma.ManifestUpdateOneWithoutBaggagesNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutBaggagesInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ManifestCreateOrConnectWithoutBaggagesInputSchema).optional(),
  upsert: z.lazy(() => ManifestUpsertWithoutBaggagesInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => ManifestWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => ManifestWhereInputSchema) ]).optional(),
  connect: z.lazy(() => ManifestWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateToOneWithWhereWithoutBaggagesInputSchema),z.lazy(() => ManifestUpdateWithoutBaggagesInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutBaggagesInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutOriginBaggagesNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutOriginBaggagesNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginBaggagesInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutOriginBaggagesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutOriginBaggagesInputSchema),z.lazy(() => LocationUpdateWithoutOriginBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginBaggagesInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutDestinationBaggagesNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutDestinationBaggagesNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationBaggagesInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutDestinationBaggagesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutDestinationBaggagesInputSchema),z.lazy(() => LocationUpdateWithoutDestinationBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationBaggagesInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneWithoutCurrentBaggagesNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneWithoutCurrentBaggagesNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentBaggagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutCurrentBaggagesInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutCurrentBaggagesInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutCurrentBaggagesInputSchema),z.lazy(() => LocationUpdateWithoutCurrentBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentBaggagesInputSchema) ]).optional(),
}).strict();

export const BookingUpdateManyWithoutBaggageNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutBaggageNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutBaggageInputSchema),z.lazy(() => BookingCreateWithoutBaggageInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutBaggageInputSchema),z.lazy(() => BookingCreateOrConnectWithoutBaggageInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutBaggageInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutBaggageInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyBaggageInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutBaggageInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutBaggageInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutBaggageInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutBaggageInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const IntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.IntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NullableIntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableIntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutBaggageNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutBaggageNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutBaggageInputSchema),z.lazy(() => BookingCreateWithoutBaggageInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutBaggageInputSchema),z.lazy(() => BookingCreateOrConnectWithoutBaggageInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutBaggageInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutBaggageInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyBaggageInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutBaggageInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutBaggageInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutBaggageInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutBaggageInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingCreateNestedOneWithoutCommentsInputSchema: z.ZodType<Prisma.BookingCreateNestedOneWithoutCommentsInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCommentsInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCommentsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BookingCreateOrConnectWithoutCommentsInputSchema).optional(),
  connect: z.lazy(() => BookingWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutBookingCommentsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutBookingCommentsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutBookingCommentsInputSchema),z.lazy(() => UserUncheckedCreateWithoutBookingCommentsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutBookingCommentsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const BookingUpdateOneRequiredWithoutCommentsNestedInputSchema: z.ZodType<Prisma.BookingUpdateOneRequiredWithoutCommentsNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCommentsInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCommentsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BookingCreateOrConnectWithoutCommentsInputSchema).optional(),
  upsert: z.lazy(() => BookingUpsertWithoutCommentsInputSchema).optional(),
  connect: z.lazy(() => BookingWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => BookingUpdateToOneWithWhereWithoutCommentsInputSchema),z.lazy(() => BookingUpdateWithoutCommentsInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutCommentsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutBookingCommentsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutBookingCommentsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutBookingCommentsInputSchema),z.lazy(() => UserUncheckedCreateWithoutBookingCommentsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutBookingCommentsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutBookingCommentsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutBookingCommentsInputSchema),z.lazy(() => UserUpdateWithoutBookingCommentsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutBookingCommentsInputSchema) ]).optional(),
}).strict();

export const BookingCreateNestedOneWithoutDeliveriesInputSchema: z.ZodType<Prisma.BookingCreateNestedOneWithoutDeliveriesInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutDeliveriesInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDeliveriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BookingCreateOrConnectWithoutDeliveriesInputSchema).optional(),
  connect: z.lazy(() => BookingWhereUniqueInputSchema).optional()
}).strict();

export const DeliveryCreateNestedOneWithoutBookingsInputSchema: z.ZodType<Prisma.DeliveryCreateNestedOneWithoutBookingsInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutBookingsInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => DeliveryCreateOrConnectWithoutBookingsInputSchema).optional(),
  connect: z.lazy(() => DeliveryWhereUniqueInputSchema).optional()
}).strict();

export const EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumBookingDeliveryStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => BookingDeliveryStatusSchema).optional()
}).strict();

export const NullableStringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableStringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional().nullable()
}).strict();

export const BookingUpdateOneRequiredWithoutDeliveriesNestedInputSchema: z.ZodType<Prisma.BookingUpdateOneRequiredWithoutDeliveriesNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutDeliveriesInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDeliveriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BookingCreateOrConnectWithoutDeliveriesInputSchema).optional(),
  upsert: z.lazy(() => BookingUpsertWithoutDeliveriesInputSchema).optional(),
  connect: z.lazy(() => BookingWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => BookingUpdateToOneWithWhereWithoutDeliveriesInputSchema),z.lazy(() => BookingUpdateWithoutDeliveriesInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutDeliveriesInputSchema) ]).optional(),
}).strict();

export const DeliveryUpdateOneRequiredWithoutBookingsNestedInputSchema: z.ZodType<Prisma.DeliveryUpdateOneRequiredWithoutBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutBookingsInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => DeliveryCreateOrConnectWithoutBookingsInputSchema).optional(),
  upsert: z.lazy(() => DeliveryUpsertWithoutBookingsInputSchema).optional(),
  connect: z.lazy(() => DeliveryWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => DeliveryUpdateToOneWithWhereWithoutBookingsInputSchema),z.lazy(() => DeliveryUpdateWithoutBookingsInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutBookingsInputSchema) ]).optional(),
}).strict();

export const BookingCreateNestedOneWithoutHistoryInputSchema: z.ZodType<Prisma.BookingCreateNestedOneWithoutHistoryInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutHistoryInputSchema),z.lazy(() => BookingUncheckedCreateWithoutHistoryInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BookingCreateOrConnectWithoutHistoryInputSchema).optional(),
  connect: z.lazy(() => BookingWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutBookingHistoryInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutBookingHistoryInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutBookingHistoryInputSchema),z.lazy(() => UserUncheckedCreateWithoutBookingHistoryInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutBookingHistoryInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutBookingHistoryInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutBookingHistoryInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutBookingHistoryInputSchema),z.lazy(() => LocationUncheckedCreateWithoutBookingHistoryInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutBookingHistoryInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const EnumBookingActionFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumBookingActionFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => BookingActionSchema).optional()
}).strict();

export const BoolFieldUpdateOperationsInputSchema: z.ZodType<Prisma.BoolFieldUpdateOperationsInput> = z.object({
  set: z.boolean().optional()
}).strict();

export const BookingUpdateOneRequiredWithoutHistoryNestedInputSchema: z.ZodType<Prisma.BookingUpdateOneRequiredWithoutHistoryNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutHistoryInputSchema),z.lazy(() => BookingUncheckedCreateWithoutHistoryInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BookingCreateOrConnectWithoutHistoryInputSchema).optional(),
  upsert: z.lazy(() => BookingUpsertWithoutHistoryInputSchema).optional(),
  connect: z.lazy(() => BookingWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => BookingUpdateToOneWithWhereWithoutHistoryInputSchema),z.lazy(() => BookingUpdateWithoutHistoryInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutHistoryInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutBookingHistoryNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutBookingHistoryNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutBookingHistoryInputSchema),z.lazy(() => UserUncheckedCreateWithoutBookingHistoryInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutBookingHistoryInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutBookingHistoryInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutBookingHistoryInputSchema),z.lazy(() => UserUpdateWithoutBookingHistoryInputSchema),z.lazy(() => UserUncheckedUpdateWithoutBookingHistoryInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneWithoutBookingHistoryNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneWithoutBookingHistoryNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutBookingHistoryInputSchema),z.lazy(() => LocationUncheckedCreateWithoutBookingHistoryInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutBookingHistoryInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutBookingHistoryInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutBookingHistoryInputSchema),z.lazy(() => LocationUpdateWithoutBookingHistoryInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutBookingHistoryInputSchema) ]).optional(),
}).strict();

export const BookingCreateNestedOneWithoutItemsInputSchema: z.ZodType<Prisma.BookingCreateNestedOneWithoutItemsInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutItemsInputSchema),z.lazy(() => BookingUncheckedCreateWithoutItemsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BookingCreateOrConnectWithoutItemsInputSchema).optional(),
  connect: z.lazy(() => BookingWhereUniqueInputSchema).optional()
}).strict();

export const ProductTypeCreateNestedOneWithoutBookingItemsInputSchema: z.ZodType<Prisma.ProductTypeCreateNestedOneWithoutBookingItemsInput> = z.object({
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutBookingItemsInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutBookingItemsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ProductTypeCreateOrConnectWithoutBookingItemsInputSchema).optional(),
  connect: z.lazy(() => ProductTypeWhereUniqueInputSchema).optional()
}).strict();

export const DecimalFieldUpdateOperationsInputSchema: z.ZodType<Prisma.DecimalFieldUpdateOperationsInput> = z.object({
  set: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  increment: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  decrement: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  multiply: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  divide: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional()
}).strict();

export const EnumUnitFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumUnitFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => UnitSchema).optional()
}).strict();

export const BookingUpdateOneWithoutItemsNestedInputSchema: z.ZodType<Prisma.BookingUpdateOneWithoutItemsNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutItemsInputSchema),z.lazy(() => BookingUncheckedCreateWithoutItemsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BookingCreateOrConnectWithoutItemsInputSchema).optional(),
  upsert: z.lazy(() => BookingUpsertWithoutItemsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => BookingWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => BookingWhereInputSchema) ]).optional(),
  connect: z.lazy(() => BookingWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => BookingUpdateToOneWithWhereWithoutItemsInputSchema),z.lazy(() => BookingUpdateWithoutItemsInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutItemsInputSchema) ]).optional(),
}).strict();

export const ProductTypeUpdateOneRequiredWithoutBookingItemsNestedInputSchema: z.ZodType<Prisma.ProductTypeUpdateOneRequiredWithoutBookingItemsNestedInput> = z.object({
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutBookingItemsInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutBookingItemsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ProductTypeCreateOrConnectWithoutBookingItemsInputSchema).optional(),
  upsert: z.lazy(() => ProductTypeUpsertWithoutBookingItemsInputSchema).optional(),
  connect: z.lazy(() => ProductTypeWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => ProductTypeUpdateToOneWithWhereWithoutBookingItemsInputSchema),z.lazy(() => ProductTypeUpdateWithoutBookingItemsInputSchema),z.lazy(() => ProductTypeUncheckedUpdateWithoutBookingItemsInputSchema) ]).optional(),
}).strict();

export const BookingCommentCreateNestedManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentCreateNestedManyWithoutBookingInput> = z.object({
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCommentCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingCommentCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCommentCreateManyBookingInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingDeliveryCreateNestedManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryCreateNestedManyWithoutBookingInput> = z.object({
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingDeliveryCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingDeliveryCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingDeliveryCreateManyBookingInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingItemCreateNestedManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemCreateNestedManyWithoutBookingInput> = z.object({
  create: z.union([ z.lazy(() => BookingItemCreateWithoutBookingInputSchema),z.lazy(() => BookingItemCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingItemCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingItemCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingItemCreateManyBookingInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutCreatedBookingsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCreatedBookingsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedBookingsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedBookingsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const ClientCreateNestedOneWithoutBookingsInputSchema: z.ZodType<Prisma.ClientCreateNestedOneWithoutBookingsInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutBookingsInputSchema),z.lazy(() => ClientUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ClientCreateOrConnectWithoutBookingsInputSchema).optional(),
  connect: z.lazy(() => ClientWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutOriginBookingsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutOriginBookingsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginBookingsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutDestinationBookingsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutDestinationBookingsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationBookingsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const BaggageCreateNestedOneWithoutBookingsInputSchema: z.ZodType<Prisma.BaggageCreateNestedOneWithoutBookingsInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutBookingsInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BaggageCreateOrConnectWithoutBookingsInputSchema).optional(),
  connect: z.lazy(() => BaggageWhereUniqueInputSchema).optional()
}).strict();

export const ManifestCreateNestedOneWithoutBookingsInputSchema: z.ZodType<Prisma.ManifestCreateNestedOneWithoutBookingsInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutBookingsInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ManifestCreateOrConnectWithoutBookingsInputSchema).optional(),
  connect: z.lazy(() => ManifestWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutVerifiedBookingsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutVerifiedBookingsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutVerifiedBookingsInputSchema),z.lazy(() => UserUncheckedCreateWithoutVerifiedBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutVerifiedBookingsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutCurrentBookingsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutCurrentBookingsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutCurrentBookingsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const BookingHistoryCreateNestedManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryCreateNestedManyWithoutBookingInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyBookingInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const StatementCreateNestedOneWithoutBookingsInputSchema: z.ZodType<Prisma.StatementCreateNestedOneWithoutBookingsInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutBookingsInputSchema),z.lazy(() => StatementUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => StatementCreateOrConnectWithoutBookingsInputSchema).optional(),
  connect: z.lazy(() => StatementWhereUniqueInputSchema).optional()
}).strict();

export const BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentUncheckedCreateNestedManyWithoutBookingInput> = z.object({
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCommentCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingCommentCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCommentCreateManyBookingInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedCreateNestedManyWithoutBookingInput> = z.object({
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingDeliveryCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingDeliveryCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingDeliveryCreateManyBookingInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemUncheckedCreateNestedManyWithoutBookingInput> = z.object({
  create: z.union([ z.lazy(() => BookingItemCreateWithoutBookingInputSchema),z.lazy(() => BookingItemCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingItemCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingItemCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingItemCreateManyBookingInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedCreateNestedManyWithoutBookingInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyBookingInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const EnumServiceTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumServiceTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ServiceTypeSchema).optional()
}).strict();

export const NullableEnumDeliveryModeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumDeliveryModeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => DeliveryModeSchema).optional().nullable()
}).strict();

export const NullableDecimalFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableDecimalFieldUpdateOperationsInput> = z.object({
  set: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  increment: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  decrement: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  multiply: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  divide: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional()
}).strict();

export const EnumPaymentModeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumPaymentModeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => PaymentModeSchema).optional()
}).strict();

export const EnumBookingStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumBookingStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => BookingStatusSchema).optional()
}).strict();

export const BookingCommentUpdateManyWithoutBookingNestedInputSchema: z.ZodType<Prisma.BookingCommentUpdateManyWithoutBookingNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCommentCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingCommentCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingCommentUpsertWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingCommentUpsertWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCommentCreateManyBookingInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingCommentUpdateWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingCommentUpdateWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingCommentUpdateManyWithWhereWithoutBookingInputSchema),z.lazy(() => BookingCommentUpdateManyWithWhereWithoutBookingInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingCommentScalarWhereInputSchema),z.lazy(() => BookingCommentScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingDeliveryUpdateManyWithoutBookingNestedInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateManyWithoutBookingNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingDeliveryCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingDeliveryCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingDeliveryUpsertWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUpsertWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingDeliveryCreateManyBookingInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingDeliveryUpdateWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUpdateWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingDeliveryUpdateManyWithWhereWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUpdateManyWithWhereWithoutBookingInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingDeliveryScalarWhereInputSchema),z.lazy(() => BookingDeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingItemUpdateManyWithoutBookingNestedInputSchema: z.ZodType<Prisma.BookingItemUpdateManyWithoutBookingNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingItemCreateWithoutBookingInputSchema),z.lazy(() => BookingItemCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingItemCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingItemCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingItemUpsertWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingItemUpsertWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingItemCreateManyBookingInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingItemUpdateWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingItemUpdateWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingItemUpdateManyWithWhereWithoutBookingInputSchema),z.lazy(() => BookingItemUpdateManyWithWhereWithoutBookingInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingItemScalarWhereInputSchema),z.lazy(() => BookingItemScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCreatedBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedBookingsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedBookingsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCreatedBookingsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCreatedBookingsInputSchema),z.lazy(() => UserUpdateWithoutCreatedBookingsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedBookingsInputSchema) ]).optional(),
}).strict();

export const ClientUpdateOneWithoutBookingsNestedInputSchema: z.ZodType<Prisma.ClientUpdateOneWithoutBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutBookingsInputSchema),z.lazy(() => ClientUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ClientCreateOrConnectWithoutBookingsInputSchema).optional(),
  upsert: z.lazy(() => ClientUpsertWithoutBookingsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => ClientWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => ClientWhereInputSchema) ]).optional(),
  connect: z.lazy(() => ClientWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => ClientUpdateToOneWithWhereWithoutBookingsInputSchema),z.lazy(() => ClientUpdateWithoutBookingsInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutBookingsInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutOriginBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginBookingsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutOriginBookingsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutOriginBookingsInputSchema),z.lazy(() => LocationUpdateWithoutOriginBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginBookingsInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutDestinationBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationBookingsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutDestinationBookingsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutDestinationBookingsInputSchema),z.lazy(() => LocationUpdateWithoutDestinationBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationBookingsInputSchema) ]).optional(),
}).strict();

export const BaggageUpdateOneWithoutBookingsNestedInputSchema: z.ZodType<Prisma.BaggageUpdateOneWithoutBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutBookingsInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BaggageCreateOrConnectWithoutBookingsInputSchema).optional(),
  upsert: z.lazy(() => BaggageUpsertWithoutBookingsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => BaggageWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => BaggageWhereInputSchema) ]).optional(),
  connect: z.lazy(() => BaggageWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateToOneWithWhereWithoutBookingsInputSchema),z.lazy(() => BaggageUpdateWithoutBookingsInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutBookingsInputSchema) ]).optional(),
}).strict();

export const ManifestUpdateOneWithoutBookingsNestedInputSchema: z.ZodType<Prisma.ManifestUpdateOneWithoutBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutBookingsInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ManifestCreateOrConnectWithoutBookingsInputSchema).optional(),
  upsert: z.lazy(() => ManifestUpsertWithoutBookingsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => ManifestWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => ManifestWhereInputSchema) ]).optional(),
  connect: z.lazy(() => ManifestWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateToOneWithWhereWithoutBookingsInputSchema),z.lazy(() => ManifestUpdateWithoutBookingsInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutBookingsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutVerifiedBookingsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutVerifiedBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutVerifiedBookingsInputSchema),z.lazy(() => UserUncheckedCreateWithoutVerifiedBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutVerifiedBookingsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutVerifiedBookingsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutVerifiedBookingsInputSchema),z.lazy(() => UserUpdateWithoutVerifiedBookingsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutVerifiedBookingsInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneWithoutCurrentBookingsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneWithoutCurrentBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutCurrentBookingsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutCurrentBookingsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutCurrentBookingsInputSchema),z.lazy(() => LocationUpdateWithoutCurrentBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentBookingsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUpdateManyWithoutBookingNestedInputSchema: z.ZodType<Prisma.BookingHistoryUpdateManyWithoutBookingNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyBookingInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutBookingInputSchema),z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutBookingInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingHistoryScalarWhereInputSchema),z.lazy(() => BookingHistoryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const StatementUpdateOneWithoutBookingsNestedInputSchema: z.ZodType<Prisma.StatementUpdateOneWithoutBookingsNestedInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutBookingsInputSchema),z.lazy(() => StatementUncheckedCreateWithoutBookingsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => StatementCreateOrConnectWithoutBookingsInputSchema).optional(),
  upsert: z.lazy(() => StatementUpsertWithoutBookingsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => StatementWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => StatementWhereInputSchema) ]).optional(),
  connect: z.lazy(() => StatementWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => StatementUpdateToOneWithWhereWithoutBookingsInputSchema),z.lazy(() => StatementUpdateWithoutBookingsInputSchema),z.lazy(() => StatementUncheckedUpdateWithoutBookingsInputSchema) ]).optional(),
}).strict();

export const BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema: z.ZodType<Prisma.BookingCommentUncheckedUpdateManyWithoutBookingNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCommentCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingCommentCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingCommentUpsertWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingCommentUpsertWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCommentCreateManyBookingInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingCommentUpdateWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingCommentUpdateWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingCommentUpdateManyWithWhereWithoutBookingInputSchema),z.lazy(() => BookingCommentUpdateManyWithWhereWithoutBookingInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingCommentScalarWhereInputSchema),z.lazy(() => BookingCommentScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingDeliveryCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingDeliveryCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingDeliveryUpsertWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUpsertWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingDeliveryCreateManyBookingInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingDeliveryUpdateWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUpdateWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingDeliveryUpdateManyWithWhereWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUpdateManyWithWhereWithoutBookingInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingDeliveryScalarWhereInputSchema),z.lazy(() => BookingDeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema: z.ZodType<Prisma.BookingItemUncheckedUpdateManyWithoutBookingNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingItemCreateWithoutBookingInputSchema),z.lazy(() => BookingItemCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingItemCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingItemCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingItemUpsertWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingItemUpsertWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingItemCreateManyBookingInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingItemUpdateWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingItemUpdateWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingItemUpdateManyWithWhereWithoutBookingInputSchema),z.lazy(() => BookingItemUpdateManyWithWhereWithoutBookingInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingItemScalarWhereInputSchema),z.lazy(() => BookingItemScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateManyWithoutBookingNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutBookingInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutBookingInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyBookingInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutBookingInputSchema),z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutBookingInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutBookingInputSchema),z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutBookingInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingHistoryScalarWhereInputSchema),z.lazy(() => BookingHistoryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingCreateNestedManyWithoutClientInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutClientInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutClientInputSchema),z.lazy(() => BookingCreateWithoutClientInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutClientInputSchema),z.lazy(() => BookingCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyClientInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutCreatedClientsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCreatedClientsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedClientsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedClientsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedClientsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutClientsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutClientsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutClientsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutClientsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutClientsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const StatementCreateNestedManyWithoutClientInputSchema: z.ZodType<Prisma.StatementCreateNestedManyWithoutClientInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutClientInputSchema),z.lazy(() => StatementCreateWithoutClientInputSchema).array(),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => StatementCreateOrConnectWithoutClientInputSchema),z.lazy(() => StatementCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => StatementCreateManyClientInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserCreateNestedManyWithoutClientInputSchema: z.ZodType<Prisma.UserCreateNestedManyWithoutClientInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutClientInputSchema),z.lazy(() => UserCreateWithoutClientInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutClientInputSchema),z.lazy(() => UserCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyClientInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedCreateNestedManyWithoutClientInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutClientInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutClientInputSchema),z.lazy(() => BookingCreateWithoutClientInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutClientInputSchema),z.lazy(() => BookingCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyClientInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const StatementUncheckedCreateNestedManyWithoutClientInputSchema: z.ZodType<Prisma.StatementUncheckedCreateNestedManyWithoutClientInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutClientInputSchema),z.lazy(() => StatementCreateWithoutClientInputSchema).array(),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => StatementCreateOrConnectWithoutClientInputSchema),z.lazy(() => StatementCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => StatementCreateManyClientInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedCreateNestedManyWithoutClientInputSchema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutClientInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutClientInputSchema),z.lazy(() => UserCreateWithoutClientInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutClientInputSchema),z.lazy(() => UserCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyClientInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUpdateManyWithoutClientNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutClientNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutClientInputSchema),z.lazy(() => BookingCreateWithoutClientInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutClientInputSchema),z.lazy(() => BookingCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutClientInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyClientInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutClientInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutClientInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutClientInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutCreatedClientsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCreatedClientsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedClientsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedClientsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedClientsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCreatedClientsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCreatedClientsInputSchema),z.lazy(() => UserUpdateWithoutCreatedClientsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedClientsInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneWithoutClientsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneWithoutClientsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutClientsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutClientsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutClientsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutClientsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutClientsInputSchema),z.lazy(() => LocationUpdateWithoutClientsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutClientsInputSchema) ]).optional(),
}).strict();

export const StatementUpdateManyWithoutClientNestedInputSchema: z.ZodType<Prisma.StatementUpdateManyWithoutClientNestedInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutClientInputSchema),z.lazy(() => StatementCreateWithoutClientInputSchema).array(),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => StatementCreateOrConnectWithoutClientInputSchema),z.lazy(() => StatementCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => StatementUpsertWithWhereUniqueWithoutClientInputSchema),z.lazy(() => StatementUpsertWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => StatementCreateManyClientInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => StatementUpdateWithWhereUniqueWithoutClientInputSchema),z.lazy(() => StatementUpdateWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => StatementUpdateManyWithWhereWithoutClientInputSchema),z.lazy(() => StatementUpdateManyWithWhereWithoutClientInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => StatementScalarWhereInputSchema),z.lazy(() => StatementScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUpdateManyWithoutClientNestedInputSchema: z.ZodType<Prisma.UserUpdateManyWithoutClientNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutClientInputSchema),z.lazy(() => UserCreateWithoutClientInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutClientInputSchema),z.lazy(() => UserCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutClientInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyClientInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutClientInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutClientInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutClientInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyWithoutClientNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutClientNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutClientInputSchema),z.lazy(() => BookingCreateWithoutClientInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutClientInputSchema),z.lazy(() => BookingCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutClientInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyClientInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutClientInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutClientInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutClientInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const StatementUncheckedUpdateManyWithoutClientNestedInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateManyWithoutClientNestedInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutClientInputSchema),z.lazy(() => StatementCreateWithoutClientInputSchema).array(),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => StatementCreateOrConnectWithoutClientInputSchema),z.lazy(() => StatementCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => StatementUpsertWithWhereUniqueWithoutClientInputSchema),z.lazy(() => StatementUpsertWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => StatementCreateManyClientInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => StatementUpdateWithWhereUniqueWithoutClientInputSchema),z.lazy(() => StatementUpdateWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => StatementUpdateManyWithWhereWithoutClientInputSchema),z.lazy(() => StatementUpdateManyWithWhereWithoutClientInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => StatementScalarWhereInputSchema),z.lazy(() => StatementScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedUpdateManyWithoutClientNestedInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutClientNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutClientInputSchema),z.lazy(() => UserCreateWithoutClientInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutClientInputSchema),z.lazy(() => UserCreateOrConnectWithoutClientInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutClientInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyClientInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutClientInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutClientInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutClientInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutClientInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingDeliveryCreateNestedManyWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryCreateNestedManyWithoutDeliveryInput> = z.object({
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema).array(),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingDeliveryCreateManyDeliveryInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const LocationCreateNestedOneWithoutDeliveriesInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutDeliveriesInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDeliveriesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDeliveriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDeliveriesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutDeliveredDeliveriesInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutDeliveredDeliveriesInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutDeliveredDeliveriesInputSchema),z.lazy(() => UserUncheckedCreateWithoutDeliveredDeliveriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutDeliveredDeliveriesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutCreatedDeliveriesInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCreatedDeliveriesInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedDeliveriesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedDeliveriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedDeliveriesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const BookingDeliveryUncheckedCreateNestedManyWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedCreateNestedManyWithoutDeliveryInput> = z.object({
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema).array(),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingDeliveryCreateManyDeliveryInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const EnumDeliveryStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumDeliveryStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => DeliveryStatusSchema).optional()
}).strict();

export const BookingDeliveryUpdateManyWithoutDeliveryNestedInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateManyWithoutDeliveryNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema).array(),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingDeliveryUpsertWithWhereUniqueWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUpsertWithWhereUniqueWithoutDeliveryInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingDeliveryCreateManyDeliveryInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingDeliveryUpdateWithWhereUniqueWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUpdateWithWhereUniqueWithoutDeliveryInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingDeliveryUpdateManyWithWhereWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUpdateManyWithWhereWithoutDeliveryInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingDeliveryScalarWhereInputSchema),z.lazy(() => BookingDeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutDeliveriesNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutDeliveriesNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDeliveriesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDeliveriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDeliveriesInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutDeliveriesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutDeliveriesInputSchema),z.lazy(() => LocationUpdateWithoutDeliveriesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDeliveriesInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutDeliveredDeliveriesNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutDeliveredDeliveriesNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutDeliveredDeliveriesInputSchema),z.lazy(() => UserUncheckedCreateWithoutDeliveredDeliveriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutDeliveredDeliveriesInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutDeliveredDeliveriesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutDeliveredDeliveriesInputSchema),z.lazy(() => UserUpdateWithoutDeliveredDeliveriesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDeliveredDeliveriesInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutCreatedDeliveriesNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCreatedDeliveriesNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedDeliveriesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedDeliveriesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedDeliveriesInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCreatedDeliveriesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCreatedDeliveriesInputSchema),z.lazy(() => UserUpdateWithoutCreatedDeliveriesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedDeliveriesInputSchema) ]).optional(),
}).strict();

export const BookingDeliveryUncheckedUpdateManyWithoutDeliveryNestedInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedUpdateManyWithoutDeliveryNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema).array(),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingDeliveryUpsertWithWhereUniqueWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUpsertWithWhereUniqueWithoutDeliveryInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingDeliveryCreateManyDeliveryInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingDeliveryWhereUniqueInputSchema),z.lazy(() => BookingDeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingDeliveryUpdateWithWhereUniqueWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUpdateWithWhereUniqueWithoutDeliveryInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingDeliveryUpdateManyWithWhereWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUpdateManyWithWhereWithoutDeliveryInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingDeliveryScalarWhereInputSchema),z.lazy(() => BookingDeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const LocationCreateNestedManyWithoutDistrictInputSchema: z.ZodType<Prisma.LocationCreateNestedManyWithoutDistrictInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDistrictInputSchema),z.lazy(() => LocationCreateWithoutDistrictInputSchema).array(),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => LocationCreateOrConnectWithoutDistrictInputSchema),z.lazy(() => LocationCreateOrConnectWithoutDistrictInputSchema).array() ]).optional(),
  createMany: z.lazy(() => LocationCreateManyDistrictInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const LocationUncheckedCreateNestedManyWithoutDistrictInputSchema: z.ZodType<Prisma.LocationUncheckedCreateNestedManyWithoutDistrictInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDistrictInputSchema),z.lazy(() => LocationCreateWithoutDistrictInputSchema).array(),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => LocationCreateOrConnectWithoutDistrictInputSchema),z.lazy(() => LocationCreateOrConnectWithoutDistrictInputSchema).array() ]).optional(),
  createMany: z.lazy(() => LocationCreateManyDistrictInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const LocationUpdateManyWithoutDistrictNestedInputSchema: z.ZodType<Prisma.LocationUpdateManyWithoutDistrictNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDistrictInputSchema),z.lazy(() => LocationCreateWithoutDistrictInputSchema).array(),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => LocationCreateOrConnectWithoutDistrictInputSchema),z.lazy(() => LocationCreateOrConnectWithoutDistrictInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => LocationUpsertWithWhereUniqueWithoutDistrictInputSchema),z.lazy(() => LocationUpsertWithWhereUniqueWithoutDistrictInputSchema).array() ]).optional(),
  createMany: z.lazy(() => LocationCreateManyDistrictInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => LocationUpdateWithWhereUniqueWithoutDistrictInputSchema),z.lazy(() => LocationUpdateWithWhereUniqueWithoutDistrictInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => LocationUpdateManyWithWhereWithoutDistrictInputSchema),z.lazy(() => LocationUpdateManyWithWhereWithoutDistrictInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => LocationScalarWhereInputSchema),z.lazy(() => LocationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const LocationUncheckedUpdateManyWithoutDistrictNestedInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateManyWithoutDistrictNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDistrictInputSchema),z.lazy(() => LocationCreateWithoutDistrictInputSchema).array(),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => LocationCreateOrConnectWithoutDistrictInputSchema),z.lazy(() => LocationCreateOrConnectWithoutDistrictInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => LocationUpsertWithWhereUniqueWithoutDistrictInputSchema),z.lazy(() => LocationUpsertWithWhereUniqueWithoutDistrictInputSchema).array() ]).optional(),
  createMany: z.lazy(() => LocationCreateManyDistrictInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => LocationWhereUniqueInputSchema),z.lazy(() => LocationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => LocationUpdateWithWhereUniqueWithoutDistrictInputSchema),z.lazy(() => LocationUpdateWithWhereUniqueWithoutDistrictInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => LocationUpdateManyWithWhereWithoutDistrictInputSchema),z.lazy(() => LocationUpdateManyWithWhereWithoutDistrictInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => LocationScalarWhereInputSchema),z.lazy(() => LocationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.BaggageCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutOriginInputSchema),z.lazy(() => BaggageCreateWithoutOriginInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutOriginInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutDestinationInputSchema),z.lazy(() => BaggageCreateWithoutDestinationInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingCreateNestedManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutCurrentLocationInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => BookingCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageCreateNestedManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageCreateNestedManyWithoutCurrentLocationInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutOriginInputSchema),z.lazy(() => BookingCreateWithoutOriginInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutOriginInputSchema),z.lazy(() => BookingCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutDestinationInputSchema),z.lazy(() => BookingCreateWithoutDestinationInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => BookingCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ClientCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.ClientCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutLocationInputSchema),z.lazy(() => ClientCreateWithoutLocationInputSchema).array(),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ClientCreateOrConnectWithoutLocationInputSchema),z.lazy(() => ClientCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ClientCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const DistrictCreateNestedOneWithoutLocationsInputSchema: z.ZodType<Prisma.DistrictCreateNestedOneWithoutLocationsInput> = z.object({
  create: z.union([ z.lazy(() => DistrictCreateWithoutLocationsInputSchema),z.lazy(() => DistrictUncheckedCreateWithoutLocationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => DistrictCreateOrConnectWithoutLocationsInputSchema).optional(),
  connect: z.lazy(() => DistrictWhereUniqueInputSchema).optional()
}).strict();

export const ManifestCreateNestedManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestCreateNestedManyWithoutCurrentLocationInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.ManifestCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutOriginInputSchema),z.lazy(() => ManifestCreateWithoutOriginInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutOriginInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutDestinationInputSchema),z.lazy(() => ManifestCreateWithoutDestinationInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestCreateWithoutOriginInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutOriginInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const RateCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.RateCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => RateCreateWithoutOriginInputSchema),z.lazy(() => RateCreateWithoutOriginInputSchema).array(),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RateCreateOrConnectWithoutOriginInputSchema),z.lazy(() => RateCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RateCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const RateCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.RateCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => RateCreateWithoutDestinationInputSchema),z.lazy(() => RateCreateWithoutDestinationInputSchema).array(),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RateCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => RateCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RateCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SubLocationCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => SubLocationCreateWithoutLocationInputSchema),z.lazy(() => SubLocationCreateWithoutLocationInputSchema).array(),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubLocationCreateOrConnectWithoutLocationInputSchema),z.lazy(() => SubLocationCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubLocationCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.UserCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutLocationInputSchema),z.lazy(() => UserCreateWithoutLocationInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutLocationInputSchema),z.lazy(() => UserCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const DeliveryCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutLocationInputSchema),z.lazy(() => DeliveryCreateWithoutLocationInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutLocationInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingHistoryCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutLocationInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutOriginInputSchema),z.lazy(() => BaggageCreateWithoutOriginInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutOriginInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutDestinationInputSchema),z.lazy(() => BaggageCreateWithoutDestinationInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutCurrentLocationInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => BookingCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateNestedManyWithoutCurrentLocationInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutOriginInputSchema),z.lazy(() => BookingCreateWithoutOriginInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutOriginInputSchema),z.lazy(() => BookingCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutDestinationInputSchema),z.lazy(() => BookingCreateWithoutDestinationInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => BookingCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ClientUncheckedCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.ClientUncheckedCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutLocationInputSchema),z.lazy(() => ClientCreateWithoutLocationInputSchema).array(),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ClientCreateOrConnectWithoutLocationInputSchema),z.lazy(() => ClientCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ClientCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateNestedManyWithoutCurrentLocationInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutOriginInputSchema),z.lazy(() => ManifestCreateWithoutOriginInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutOriginInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutDestinationInputSchema),z.lazy(() => ManifestCreateWithoutDestinationInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestCreateWithoutOriginInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutOriginInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const RateUncheckedCreateNestedManyWithoutOriginInputSchema: z.ZodType<Prisma.RateUncheckedCreateNestedManyWithoutOriginInput> = z.object({
  create: z.union([ z.lazy(() => RateCreateWithoutOriginInputSchema),z.lazy(() => RateCreateWithoutOriginInputSchema).array(),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RateCreateOrConnectWithoutOriginInputSchema),z.lazy(() => RateCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RateCreateManyOriginInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const RateUncheckedCreateNestedManyWithoutDestinationInputSchema: z.ZodType<Prisma.RateUncheckedCreateNestedManyWithoutDestinationInput> = z.object({
  create: z.union([ z.lazy(() => RateCreateWithoutDestinationInputSchema),z.lazy(() => RateCreateWithoutDestinationInputSchema).array(),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RateCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => RateCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RateCreateManyDestinationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationUncheckedCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => SubLocationCreateWithoutLocationInputSchema),z.lazy(() => SubLocationCreateWithoutLocationInputSchema).array(),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubLocationCreateOrConnectWithoutLocationInputSchema),z.lazy(() => SubLocationCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubLocationCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutLocationInputSchema),z.lazy(() => UserCreateWithoutLocationInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutLocationInputSchema),z.lazy(() => UserCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryUncheckedCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutLocationInputSchema),z.lazy(() => DeliveryCreateWithoutLocationInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutLocationInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedCreateNestedManyWithoutLocationInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutLocationInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyLocationInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutOriginInputSchema),z.lazy(() => BaggageCreateWithoutOriginInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutOriginInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutDestinationInputSchema),z.lazy(() => BaggageCreateWithoutDestinationInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUpdateManyWithoutCurrentLocationNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutCurrentLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => BookingCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutCurrentLocationInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutCurrentLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageUpdateManyWithoutCurrentLocationNestedInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithoutCurrentLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutCurrentLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutOriginInputSchema),z.lazy(() => BookingCreateWithoutOriginInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutOriginInputSchema),z.lazy(() => BookingCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutDestinationInputSchema),z.lazy(() => BookingCreateWithoutDestinationInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => BookingCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ClientUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.ClientUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutLocationInputSchema),z.lazy(() => ClientCreateWithoutLocationInputSchema).array(),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ClientCreateOrConnectWithoutLocationInputSchema),z.lazy(() => ClientCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ClientUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => ClientUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ClientCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ClientUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => ClientUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ClientUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => ClientUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ClientScalarWhereInputSchema),z.lazy(() => ClientScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const DistrictUpdateOneWithoutLocationsNestedInputSchema: z.ZodType<Prisma.DistrictUpdateOneWithoutLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => DistrictCreateWithoutLocationsInputSchema),z.lazy(() => DistrictUncheckedCreateWithoutLocationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => DistrictCreateOrConnectWithoutLocationsInputSchema).optional(),
  upsert: z.lazy(() => DistrictUpsertWithoutLocationsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => DistrictWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => DistrictWhereInputSchema) ]).optional(),
  connect: z.lazy(() => DistrictWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => DistrictUpdateToOneWithWhereWithoutLocationsInputSchema),z.lazy(() => DistrictUpdateWithoutLocationsInputSchema),z.lazy(() => DistrictUncheckedUpdateWithoutLocationsInputSchema) ]).optional(),
}).strict();

export const ManifestUpdateManyWithoutCurrentLocationNestedInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithoutCurrentLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutCurrentLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutOriginInputSchema),z.lazy(() => ManifestCreateWithoutOriginInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutOriginInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutDestinationInputSchema),z.lazy(() => ManifestCreateWithoutDestinationInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestCreateWithoutOriginInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutOriginInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const RateUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.RateUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => RateCreateWithoutOriginInputSchema),z.lazy(() => RateCreateWithoutOriginInputSchema).array(),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RateCreateOrConnectWithoutOriginInputSchema),z.lazy(() => RateCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => RateUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => RateUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RateCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => RateUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => RateUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => RateUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => RateUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => RateScalarWhereInputSchema),z.lazy(() => RateScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const RateUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.RateUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => RateCreateWithoutDestinationInputSchema),z.lazy(() => RateCreateWithoutDestinationInputSchema).array(),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RateCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => RateCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => RateUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => RateUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RateCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => RateUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => RateUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => RateUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => RateUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => RateScalarWhereInputSchema),z.lazy(() => RateScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SubLocationUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.SubLocationUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => SubLocationCreateWithoutLocationInputSchema),z.lazy(() => SubLocationCreateWithoutLocationInputSchema).array(),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubLocationCreateOrConnectWithoutLocationInputSchema),z.lazy(() => SubLocationCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SubLocationUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => SubLocationUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubLocationCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SubLocationUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => SubLocationUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SubLocationUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => SubLocationUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SubLocationScalarWhereInputSchema),z.lazy(() => SubLocationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.UserUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutLocationInputSchema),z.lazy(() => UserCreateWithoutLocationInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutLocationInputSchema),z.lazy(() => UserCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.DeliveryUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutLocationInputSchema),z.lazy(() => DeliveryCreateWithoutLocationInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutLocationInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => DeliveryUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => DeliveryUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => DeliveryScalarWhereInputSchema),z.lazy(() => DeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingHistoryUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.BookingHistoryUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutLocationInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingHistoryScalarWhereInputSchema),z.lazy(() => BookingHistoryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutOriginInputSchema),z.lazy(() => BaggageCreateWithoutOriginInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutOriginInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutDestinationInputSchema),z.lazy(() => BaggageCreateWithoutDestinationInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutCurrentLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => BookingCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutCurrentLocationInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutCurrentLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutCurrentLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutOriginInputSchema),z.lazy(() => BookingCreateWithoutOriginInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutOriginInputSchema),z.lazy(() => BookingCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutDestinationInputSchema),z.lazy(() => BookingCreateWithoutDestinationInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => BookingCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ClientUncheckedUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutLocationInputSchema),z.lazy(() => ClientCreateWithoutLocationInputSchema).array(),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ClientCreateOrConnectWithoutLocationInputSchema),z.lazy(() => ClientCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ClientUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => ClientUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ClientCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ClientUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => ClientUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ClientUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => ClientUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ClientScalarWhereInputSchema),z.lazy(() => ClientScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCurrentLocationInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCurrentLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCurrentLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCurrentLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutCurrentLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutOriginInputSchema),z.lazy(() => ManifestCreateWithoutOriginInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutOriginInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutDestinationInputSchema),z.lazy(() => ManifestCreateWithoutDestinationInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestCreateWithoutOriginInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutOriginInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const RateUncheckedUpdateManyWithoutOriginNestedInputSchema: z.ZodType<Prisma.RateUncheckedUpdateManyWithoutOriginNestedInput> = z.object({
  create: z.union([ z.lazy(() => RateCreateWithoutOriginInputSchema),z.lazy(() => RateCreateWithoutOriginInputSchema).array(),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RateCreateOrConnectWithoutOriginInputSchema),z.lazy(() => RateCreateOrConnectWithoutOriginInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => RateUpsertWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => RateUpsertWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RateCreateManyOriginInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => RateUpdateWithWhereUniqueWithoutOriginInputSchema),z.lazy(() => RateUpdateWithWhereUniqueWithoutOriginInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => RateUpdateManyWithWhereWithoutOriginInputSchema),z.lazy(() => RateUpdateManyWithWhereWithoutOriginInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => RateScalarWhereInputSchema),z.lazy(() => RateScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const RateUncheckedUpdateManyWithoutDestinationNestedInputSchema: z.ZodType<Prisma.RateUncheckedUpdateManyWithoutDestinationNestedInput> = z.object({
  create: z.union([ z.lazy(() => RateCreateWithoutDestinationInputSchema),z.lazy(() => RateCreateWithoutDestinationInputSchema).array(),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => RateCreateOrConnectWithoutDestinationInputSchema),z.lazy(() => RateCreateOrConnectWithoutDestinationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => RateUpsertWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => RateUpsertWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => RateCreateManyDestinationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => RateWhereUniqueInputSchema),z.lazy(() => RateWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => RateUpdateWithWhereUniqueWithoutDestinationInputSchema),z.lazy(() => RateUpdateWithWhereUniqueWithoutDestinationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => RateUpdateManyWithWhereWithoutDestinationInputSchema),z.lazy(() => RateUpdateManyWithWhereWithoutDestinationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => RateScalarWhereInputSchema),z.lazy(() => RateScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.SubLocationUncheckedUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => SubLocationCreateWithoutLocationInputSchema),z.lazy(() => SubLocationCreateWithoutLocationInputSchema).array(),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubLocationCreateOrConnectWithoutLocationInputSchema),z.lazy(() => SubLocationCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SubLocationUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => SubLocationUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubLocationCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SubLocationWhereUniqueInputSchema),z.lazy(() => SubLocationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SubLocationUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => SubLocationUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SubLocationUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => SubLocationUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SubLocationScalarWhereInputSchema),z.lazy(() => SubLocationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutLocationInputSchema),z.lazy(() => UserCreateWithoutLocationInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutLocationInputSchema),z.lazy(() => UserCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutLocationInputSchema),z.lazy(() => DeliveryCreateWithoutLocationInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutLocationInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => DeliveryUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => DeliveryUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => DeliveryScalarWhereInputSchema),z.lazy(() => DeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateManyWithoutLocationNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutLocationInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutLocationInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyLocationInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutLocationInputSchema),z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutLocationInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutLocationInputSchema),z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutLocationInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingHistoryScalarWhereInputSchema),z.lazy(() => BookingHistoryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageCreateNestedManyWithoutManifestInputSchema: z.ZodType<Prisma.BaggageCreateNestedManyWithoutManifestInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutManifestInputSchema),z.lazy(() => BaggageCreateWithoutManifestInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutManifestInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingCreateNestedManyWithoutManifestInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutManifestInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutManifestInputSchema),z.lazy(() => BookingCreateWithoutManifestInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutManifestInputSchema),z.lazy(() => BookingCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const LocationCreateNestedOneWithoutCurrentManifestsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutCurrentManifestsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutCurrentManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutOriginManifestsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutOriginManifestsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutDestinationManifestsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutDestinationManifestsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const MasterManifestCreateNestedOneWithoutManifestsInputSchema: z.ZodType<Prisma.MasterManifestCreateNestedOneWithoutManifestsInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutManifestsInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => MasterManifestCreateOrConnectWithoutManifestsInputSchema).optional(),
  connect: z.lazy(() => MasterManifestWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutCreatedManifestsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCreatedManifestsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedManifestsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutDispatchedManifestsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutDispatchedManifestsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutDispatchedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutDispatchedManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutDispatchedManifestsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutReceivedManifestsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutReceivedManifestsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutReceivedManifestsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const MasterManifestDetailCreateNestedManyWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateNestedManyWithoutManifestInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema).array(),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestDetailCreateOrConnectWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestDetailCreateManyManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedCreateNestedManyWithoutManifestInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateNestedManyWithoutManifestInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutManifestInputSchema),z.lazy(() => BaggageCreateWithoutManifestInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutManifestInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedCreateNestedManyWithoutManifestInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutManifestInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutManifestInputSchema),z.lazy(() => BookingCreateWithoutManifestInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutManifestInputSchema),z.lazy(() => BookingCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema).array(),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestDetailCreateOrConnectWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestDetailCreateManyManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const EnumManifestStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumManifestStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ManifestStatusSchema).optional()
}).strict();

export const BaggageUpdateManyWithoutManifestNestedInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithoutManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutManifestInputSchema),z.lazy(() => BaggageCreateWithoutManifestInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutManifestInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutManifestInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUpdateManyWithoutManifestNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutManifestInputSchema),z.lazy(() => BookingCreateWithoutManifestInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutManifestInputSchema),z.lazy(() => BookingCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutManifestInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const LocationUpdateOneWithoutCurrentManifestsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneWithoutCurrentManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutCurrentManifestsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutCurrentManifestsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutCurrentManifestsInputSchema),z.lazy(() => LocationUpdateWithoutCurrentManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentManifestsInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutOriginManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginManifestsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutOriginManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutOriginManifestsInputSchema),z.lazy(() => LocationUpdateWithoutOriginManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginManifestsInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutDestinationManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationManifestsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutDestinationManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutDestinationManifestsInputSchema),z.lazy(() => LocationUpdateWithoutDestinationManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationManifestsInputSchema) ]).optional(),
}).strict();

export const MasterManifestUpdateOneWithoutManifestsNestedInputSchema: z.ZodType<Prisma.MasterManifestUpdateOneWithoutManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutManifestsInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => MasterManifestCreateOrConnectWithoutManifestsInputSchema).optional(),
  upsert: z.lazy(() => MasterManifestUpsertWithoutManifestsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => MasterManifestWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => MasterManifestWhereInputSchema) ]).optional(),
  connect: z.lazy(() => MasterManifestWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateToOneWithWhereWithoutManifestsInputSchema),z.lazy(() => MasterManifestUpdateWithoutManifestsInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutManifestsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCreatedManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedManifestsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCreatedManifestsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCreatedManifestsInputSchema),z.lazy(() => UserUpdateWithoutCreatedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedManifestsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutDispatchedManifestsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutDispatchedManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutDispatchedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutDispatchedManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutDispatchedManifestsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutDispatchedManifestsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutDispatchedManifestsInputSchema),z.lazy(() => UserUpdateWithoutDispatchedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDispatchedManifestsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutReceivedManifestsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutReceivedManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutReceivedManifestsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutReceivedManifestsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutReceivedManifestsInputSchema),z.lazy(() => UserUpdateWithoutReceivedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedManifestsInputSchema) ]).optional(),
}).strict();

export const MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateManyWithoutManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema).array(),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestDetailCreateOrConnectWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestDetailUpsertWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUpsertWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestDetailCreateManyManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestDetailUpdateWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUpdateWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestDetailUpdateManyWithWhereWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUpdateManyWithWhereWithoutManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestDetailScalarWhereInputSchema),z.lazy(() => MasterManifestDetailScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutManifestInputSchema),z.lazy(() => BaggageCreateWithoutManifestInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutManifestInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutManifestInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyWithoutManifestNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutManifestInputSchema),z.lazy(() => BookingCreateWithoutManifestInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutManifestInputSchema),z.lazy(() => BookingCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutManifestInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema).array(),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestDetailCreateOrConnectWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailCreateOrConnectWithoutManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestDetailUpsertWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUpsertWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestDetailCreateManyManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestDetailUpdateWithWhereUniqueWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUpdateWithWhereUniqueWithoutManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestDetailUpdateManyWithWhereWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUpdateManyWithWhereWithoutManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestDetailScalarWhereInputSchema),z.lazy(() => MasterManifestDetailScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestCreateNestedOneWithoutMasterManifestsInputSchema: z.ZodType<Prisma.ManifestCreateNestedOneWithoutMasterManifestsInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutMasterManifestsInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ManifestCreateOrConnectWithoutMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => ManifestWhereUniqueInputSchema).optional()
}).strict();

export const MasterManifestCreateNestedOneWithoutDetailsInputSchema: z.ZodType<Prisma.MasterManifestCreateNestedOneWithoutDetailsInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDetailsInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDetailsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => MasterManifestCreateOrConnectWithoutDetailsInputSchema).optional(),
  connect: z.lazy(() => MasterManifestWhereUniqueInputSchema).optional()
}).strict();

export const ManifestUpdateOneRequiredWithoutMasterManifestsNestedInputSchema: z.ZodType<Prisma.ManifestUpdateOneRequiredWithoutMasterManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutMasterManifestsInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ManifestCreateOrConnectWithoutMasterManifestsInputSchema).optional(),
  upsert: z.lazy(() => ManifestUpsertWithoutMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => ManifestWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateToOneWithWhereWithoutMasterManifestsInputSchema),z.lazy(() => ManifestUpdateWithoutMasterManifestsInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutMasterManifestsInputSchema) ]).optional(),
}).strict();

export const MasterManifestUpdateOneRequiredWithoutDetailsNestedInputSchema: z.ZodType<Prisma.MasterManifestUpdateOneRequiredWithoutDetailsNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDetailsInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDetailsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => MasterManifestCreateOrConnectWithoutDetailsInputSchema).optional(),
  upsert: z.lazy(() => MasterManifestUpsertWithoutDetailsInputSchema).optional(),
  connect: z.lazy(() => MasterManifestWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateToOneWithWhereWithoutDetailsInputSchema),z.lazy(() => MasterManifestUpdateWithoutDetailsInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutDetailsInputSchema) ]).optional(),
}).strict();

export const ManifestCreateNestedManyWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestCreateNestedManyWithoutCurrentMasterManifestInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCurrentMasterManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestDetailCreateNestedManyWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateNestedManyWithoutMasterManifestInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema).array(),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestDetailCreateManyMasterManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const LocationCreateNestedOneWithoutOriginMasterManifestsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutOriginMasterManifestsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginMasterManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutDestinationMasterManifestsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutDestinationMasterManifestsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationMasterManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutCreatedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCreatedMasterManifestsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutDispatchedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutDispatchedMasterManifestsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutDispatchedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutDispatchedMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutDispatchedMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutReceivedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutReceivedMasterManifestsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutReceivedMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCurrentMasterManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema).array(),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestDetailCreateManyMasterManifestInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const FloatFieldUpdateOperationsInputSchema: z.ZodType<Prisma.FloatFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const EnumMasterManifestStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumMasterManifestStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => MasterManifestStatusSchema).optional()
}).strict();

export const ManifestUpdateManyWithoutCurrentMasterManifestNestedInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithoutCurrentMasterManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCurrentMasterManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestDetailUpdateManyWithoutMasterManifestNestedInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateManyWithoutMasterManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema).array(),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestDetailUpsertWithWhereUniqueWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUpsertWithWhereUniqueWithoutMasterManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestDetailCreateManyMasterManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestDetailUpdateWithWhereUniqueWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUpdateWithWhereUniqueWithoutMasterManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestDetailUpdateManyWithWhereWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUpdateManyWithWhereWithoutMasterManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestDetailScalarWhereInputSchema),z.lazy(() => MasterManifestDetailScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginMasterManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginMasterManifestsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutOriginMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutOriginMasterManifestsInputSchema),z.lazy(() => LocationUpdateWithoutOriginMasterManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginMasterManifestsInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationMasterManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationMasterManifestsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutDestinationMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutDestinationMasterManifestsInputSchema),z.lazy(() => LocationUpdateWithoutDestinationMasterManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationMasterManifestsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedMasterManifestsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCreatedMasterManifestsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCreatedMasterManifestsInputSchema),z.lazy(() => UserUpdateWithoutCreatedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedMasterManifestsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutDispatchedMasterManifestsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutDispatchedMasterManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutDispatchedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutDispatchedMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutDispatchedMasterManifestsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutDispatchedMasterManifestsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutDispatchedMasterManifestsInputSchema),z.lazy(() => UserUpdateWithoutDispatchedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDispatchedMasterManifestsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutReceivedMasterManifestsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutReceivedMasterManifestsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedMasterManifestsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutReceivedMasterManifestsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutReceivedMasterManifestsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutReceivedMasterManifestsInputSchema),z.lazy(() => UserUpdateWithoutReceivedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedMasterManifestsInputSchema) ]).optional(),
}).strict();

export const ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCurrentMasterManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutCurrentMasterManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema).array(),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestDetailUpsertWithWhereUniqueWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUpsertWithWhereUniqueWithoutMasterManifestInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestDetailCreateManyMasterManifestInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),z.lazy(() => MasterManifestDetailWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestDetailUpdateWithWhereUniqueWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUpdateWithWhereUniqueWithoutMasterManifestInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestDetailUpdateManyWithWhereWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUpdateManyWithWhereWithoutMasterManifestInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestDetailScalarWhereInputSchema),z.lazy(() => MasterManifestDetailScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingItemCreateNestedManyWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemCreateNestedManyWithoutProductTypeInput> = z.object({
  create: z.union([ z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema).array(),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingItemCreateOrConnectWithoutProductTypeInputSchema),z.lazy(() => BookingItemCreateOrConnectWithoutProductTypeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingItemCreateManyProductTypeInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutCreatedProductTypesInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCreatedProductTypesInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedProductTypesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedProductTypesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedProductTypesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const BookingItemUncheckedCreateNestedManyWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemUncheckedCreateNestedManyWithoutProductTypeInput> = z.object({
  create: z.union([ z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema).array(),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingItemCreateOrConnectWithoutProductTypeInputSchema),z.lazy(() => BookingItemCreateOrConnectWithoutProductTypeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingItemCreateManyProductTypeInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingItemUpdateManyWithoutProductTypeNestedInputSchema: z.ZodType<Prisma.BookingItemUpdateManyWithoutProductTypeNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema).array(),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingItemCreateOrConnectWithoutProductTypeInputSchema),z.lazy(() => BookingItemCreateOrConnectWithoutProductTypeInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingItemUpsertWithWhereUniqueWithoutProductTypeInputSchema),z.lazy(() => BookingItemUpsertWithWhereUniqueWithoutProductTypeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingItemCreateManyProductTypeInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingItemUpdateWithWhereUniqueWithoutProductTypeInputSchema),z.lazy(() => BookingItemUpdateWithWhereUniqueWithoutProductTypeInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingItemUpdateManyWithWhereWithoutProductTypeInputSchema),z.lazy(() => BookingItemUpdateManyWithWhereWithoutProductTypeInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingItemScalarWhereInputSchema),z.lazy(() => BookingItemScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutCreatedProductTypesNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCreatedProductTypesNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedProductTypesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedProductTypesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCreatedProductTypesInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCreatedProductTypesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCreatedProductTypesInputSchema),z.lazy(() => UserUpdateWithoutCreatedProductTypesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedProductTypesInputSchema) ]).optional(),
}).strict();

export const BookingItemUncheckedUpdateManyWithoutProductTypeNestedInputSchema: z.ZodType<Prisma.BookingItemUncheckedUpdateManyWithoutProductTypeNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema).array(),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingItemCreateOrConnectWithoutProductTypeInputSchema),z.lazy(() => BookingItemCreateOrConnectWithoutProductTypeInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingItemUpsertWithWhereUniqueWithoutProductTypeInputSchema),z.lazy(() => BookingItemUpsertWithWhereUniqueWithoutProductTypeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingItemCreateManyProductTypeInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingItemWhereUniqueInputSchema),z.lazy(() => BookingItemWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingItemUpdateWithWhereUniqueWithoutProductTypeInputSchema),z.lazy(() => BookingItemUpdateWithWhereUniqueWithoutProductTypeInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingItemUpdateManyWithWhereWithoutProductTypeInputSchema),z.lazy(() => BookingItemUpdateManyWithWhereWithoutProductTypeInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingItemScalarWhereInputSchema),z.lazy(() => BookingItemScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const LocationCreateNestedOneWithoutOriginRatesInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutOriginRatesInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginRatesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginRatesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginRatesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutDestinationRatesInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutDestinationRatesInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationRatesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationRatesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationRatesInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationUpdateOneWithoutOriginRatesNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneWithoutOriginRatesNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginRatesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginRatesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutOriginRatesInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutOriginRatesInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutOriginRatesInputSchema),z.lazy(() => LocationUpdateWithoutOriginRatesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginRatesInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneWithoutDestinationRatesNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneWithoutDestinationRatesNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationRatesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationRatesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutDestinationRatesInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutDestinationRatesInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutDestinationRatesInputSchema),z.lazy(() => LocationUpdateWithoutDestinationRatesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationRatesInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedManyWithoutRoleInputSchema: z.ZodType<Prisma.UserCreateNestedManyWithoutRoleInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutRoleInputSchema),z.lazy(() => UserCreateWithoutRoleInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutRoleInputSchema),z.lazy(() => UserCreateOrConnectWithoutRoleInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyRoleInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedCreateNestedManyWithoutRoleInputSchema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutRoleInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutRoleInputSchema),z.lazy(() => UserCreateWithoutRoleInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutRoleInputSchema),z.lazy(() => UserCreateOrConnectWithoutRoleInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyRoleInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserUpdateManyWithoutRoleNestedInputSchema: z.ZodType<Prisma.UserUpdateManyWithoutRoleNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutRoleInputSchema),z.lazy(() => UserCreateWithoutRoleInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutRoleInputSchema),z.lazy(() => UserCreateOrConnectWithoutRoleInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutRoleInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutRoleInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyRoleInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutRoleInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutRoleInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutRoleInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutRoleInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedUpdateManyWithoutRoleNestedInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutRoleNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutRoleInputSchema),z.lazy(() => UserCreateWithoutRoleInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutRoleInputSchema),z.lazy(() => UserCreateOrConnectWithoutRoleInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutRoleInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutRoleInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyRoleInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutRoleInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutRoleInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutRoleInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutRoleInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ClientCreateNestedOneWithoutStatementsInputSchema: z.ZodType<Prisma.ClientCreateNestedOneWithoutStatementsInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutStatementsInputSchema),z.lazy(() => ClientUncheckedCreateWithoutStatementsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ClientCreateOrConnectWithoutStatementsInputSchema).optional(),
  connect: z.lazy(() => ClientWhereUniqueInputSchema).optional()
}).strict();

export const BookingCreateNestedManyWithoutStatementInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutStatementInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutStatementInputSchema),z.lazy(() => BookingCreateWithoutStatementInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutStatementInputSchema),z.lazy(() => BookingCreateOrConnectWithoutStatementInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyStatementInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutStatementInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutStatementInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutStatementInputSchema),z.lazy(() => UserUncheckedCreateWithoutStatementInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutStatementInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const BookingUncheckedCreateNestedManyWithoutStatementInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutStatementInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutStatementInputSchema),z.lazy(() => BookingCreateWithoutStatementInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutStatementInputSchema),z.lazy(() => BookingCreateOrConnectWithoutStatementInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyStatementInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ClientUpdateOneRequiredWithoutStatementsNestedInputSchema: z.ZodType<Prisma.ClientUpdateOneRequiredWithoutStatementsNestedInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutStatementsInputSchema),z.lazy(() => ClientUncheckedCreateWithoutStatementsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ClientCreateOrConnectWithoutStatementsInputSchema).optional(),
  upsert: z.lazy(() => ClientUpsertWithoutStatementsInputSchema).optional(),
  connect: z.lazy(() => ClientWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => ClientUpdateToOneWithWhereWithoutStatementsInputSchema),z.lazy(() => ClientUpdateWithoutStatementsInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutStatementsInputSchema) ]).optional(),
}).strict();

export const BookingUpdateManyWithoutStatementNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutStatementNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutStatementInputSchema),z.lazy(() => BookingCreateWithoutStatementInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutStatementInputSchema),z.lazy(() => BookingCreateOrConnectWithoutStatementInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutStatementInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutStatementInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyStatementInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutStatementInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutStatementInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutStatementInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutStatementInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutStatementNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutStatementNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutStatementInputSchema),z.lazy(() => UserUncheckedCreateWithoutStatementInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutStatementInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutStatementInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutStatementInputSchema),z.lazy(() => UserUpdateWithoutStatementInputSchema),z.lazy(() => UserUncheckedUpdateWithoutStatementInputSchema) ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyWithoutStatementNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutStatementNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutStatementInputSchema),z.lazy(() => BookingCreateWithoutStatementInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutStatementInputSchema),z.lazy(() => BookingCreateOrConnectWithoutStatementInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutStatementInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutStatementInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyStatementInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutStatementInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutStatementInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutStatementInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutStatementInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const LocationCreateNestedOneWithoutSubLocationsInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutSubLocationsInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutSubLocationsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutSubLocationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutSubLocationsInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const LocationUpdateOneWithoutSubLocationsNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneWithoutSubLocationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutSubLocationsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutSubLocationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutSubLocationsInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutSubLocationsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => LocationWhereInputSchema) ]).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutSubLocationsInputSchema),z.lazy(() => LocationUpdateWithoutSubLocationsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutSubLocationsInputSchema) ]).optional(),
}).strict();

export const BaggageCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingCommentCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCommentCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BookingCommentCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCommentCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BookingCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingCreateNestedManyWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingCreateNestedManyWithoutVerifiedByInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingCreateWithoutVerifiedByInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutVerifiedByInputSchema),z.lazy(() => BookingCreateOrConnectWithoutVerifiedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyVerifiedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ClientCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutCreatedByInputSchema),z.lazy(() => ClientCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ClientCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ClientCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ClientCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const DeliveryCreateNestedManyWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryCreateNestedManyWithoutDeliveredByInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutDeliveredByInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutDeliveredByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyDeliveredByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const DeliveryCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestCreateNestedManyWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestCreateNestedManyWithoutDispatchedByInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutDispatchedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutDispatchedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyDispatchedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestCreateNestedManyWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestCreateNestedManyWithoutReceivedByInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestCreateWithoutReceivedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutReceivedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutReceivedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyReceivedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestCreateNestedManyWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestCreateNestedManyWithoutDispatchedByInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutDispatchedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyDispatchedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestCreateNestedManyWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestCreateNestedManyWithoutReceivedByInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutReceivedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutReceivedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyReceivedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ProductTypeCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ProductTypeCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ProductTypeCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ProductTypeCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ClientCreateNestedOneWithoutUserInputSchema: z.ZodType<Prisma.ClientCreateNestedOneWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutUserInputSchema),z.lazy(() => ClientUncheckedCreateWithoutUserInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ClientCreateOrConnectWithoutUserInputSchema).optional(),
  connect: z.lazy(() => ClientWhereUniqueInputSchema).optional()
}).strict();

export const LocationCreateNestedOneWithoutUsersInputSchema: z.ZodType<Prisma.LocationCreateNestedOneWithoutUsersInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutUsersInputSchema),z.lazy(() => LocationUncheckedCreateWithoutUsersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutUsersInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional()
}).strict();

export const RoleCreateNestedOneWithoutUsersInputSchema: z.ZodType<Prisma.RoleCreateNestedOneWithoutUsersInput> = z.object({
  create: z.union([ z.lazy(() => RoleCreateWithoutUsersInputSchema),z.lazy(() => RoleUncheckedCreateWithoutUsersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => RoleCreateOrConnectWithoutUsersInputSchema).optional(),
  connect: z.lazy(() => RoleWhereUniqueInputSchema).optional()
}).strict();

export const BookingHistoryCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryCreateWithoutUserInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutUserInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const StatementCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutCreatedByInputSchema),z.lazy(() => StatementCreateWithoutCreatedByInputSchema).array(),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => StatementCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => StatementCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => StatementCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCommentCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BookingCommentCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCommentCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BookingCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingUncheckedCreateNestedManyWithoutVerifiedByInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingCreateWithoutVerifiedByInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutVerifiedByInputSchema),z.lazy(() => BookingCreateOrConnectWithoutVerifiedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyVerifiedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutCreatedByInputSchema),z.lazy(() => ClientCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ClientCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ClientCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ClientCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryUncheckedCreateNestedManyWithoutDeliveredByInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutDeliveredByInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutDeliveredByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyDeliveredByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateNestedManyWithoutDispatchedByInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutDispatchedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutDispatchedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyDispatchedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateNestedManyWithoutReceivedByInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestCreateWithoutReceivedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutReceivedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutReceivedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyReceivedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutDispatchedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyDispatchedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateNestedManyWithoutReceivedByInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutReceivedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutReceivedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyReceivedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ProductTypeCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ProductTypeCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ProductTypeCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryCreateWithoutUserInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutUserInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementUncheckedCreateNestedManyWithoutCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutCreatedByInputSchema),z.lazy(() => StatementCreateWithoutCreatedByInputSchema).array(),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => StatementCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => StatementCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => StatementCreateManyCreatedByInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BaggageUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingCommentUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.BookingCommentUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCommentCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BookingCommentCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingCommentUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCommentCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingCommentUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingCommentUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingCommentScalarWhereInputSchema),z.lazy(() => BookingCommentScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BookingCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUpdateManyWithoutVerifiedByNestedInputSchema: z.ZodType<Prisma.BookingUpdateManyWithoutVerifiedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingCreateWithoutVerifiedByInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutVerifiedByInputSchema),z.lazy(() => BookingCreateOrConnectWithoutVerifiedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutVerifiedByInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutVerifiedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyVerifiedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutVerifiedByInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutVerifiedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutVerifiedByInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutVerifiedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ClientUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.ClientUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutCreatedByInputSchema),z.lazy(() => ClientCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ClientCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ClientCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ClientUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ClientUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ClientCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ClientUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ClientUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ClientUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => ClientUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ClientScalarWhereInputSchema),z.lazy(() => ClientScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUpdateManyWithoutDeliveredByNestedInputSchema: z.ZodType<Prisma.DeliveryUpdateManyWithoutDeliveredByNestedInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutDeliveredByInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutDeliveredByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutDeliveredByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyDeliveredByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutDeliveredByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => DeliveryUpdateManyWithWhereWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUpdateManyWithWhereWithoutDeliveredByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => DeliveryScalarWhereInputSchema),z.lazy(() => DeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.DeliveryUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => DeliveryUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => DeliveryUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => DeliveryScalarWhereInputSchema),z.lazy(() => DeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUpdateManyWithoutDispatchedByNestedInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithoutDispatchedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutDispatchedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutDispatchedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyDispatchedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutDispatchedByInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutDispatchedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUpdateManyWithoutReceivedByNestedInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithoutReceivedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestCreateWithoutReceivedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutReceivedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutReceivedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyReceivedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutReceivedByInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutReceivedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithoutDispatchedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutDispatchedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyDispatchedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutDispatchedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUpdateManyWithoutReceivedByNestedInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithoutReceivedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutReceivedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutReceivedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyReceivedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutReceivedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ProductTypeUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.ProductTypeUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ProductTypeCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ProductTypeCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ProductTypeUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ProductTypeCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ProductTypeUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ProductTypeUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ProductTypeScalarWhereInputSchema),z.lazy(() => ProductTypeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ClientUpdateOneWithoutUserNestedInputSchema: z.ZodType<Prisma.ClientUpdateOneWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutUserInputSchema),z.lazy(() => ClientUncheckedCreateWithoutUserInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => ClientCreateOrConnectWithoutUserInputSchema).optional(),
  upsert: z.lazy(() => ClientUpsertWithoutUserInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => ClientWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => ClientWhereInputSchema) ]).optional(),
  connect: z.lazy(() => ClientWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => ClientUpdateToOneWithWhereWithoutUserInputSchema),z.lazy(() => ClientUpdateWithoutUserInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutUserInputSchema) ]).optional(),
}).strict();

export const LocationUpdateOneRequiredWithoutUsersNestedInputSchema: z.ZodType<Prisma.LocationUpdateOneRequiredWithoutUsersNestedInput> = z.object({
  create: z.union([ z.lazy(() => LocationCreateWithoutUsersInputSchema),z.lazy(() => LocationUncheckedCreateWithoutUsersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => LocationCreateOrConnectWithoutUsersInputSchema).optional(),
  upsert: z.lazy(() => LocationUpsertWithoutUsersInputSchema).optional(),
  connect: z.lazy(() => LocationWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => LocationUpdateToOneWithWhereWithoutUsersInputSchema),z.lazy(() => LocationUpdateWithoutUsersInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutUsersInputSchema) ]).optional(),
}).strict();

export const RoleUpdateOneWithoutUsersNestedInputSchema: z.ZodType<Prisma.RoleUpdateOneWithoutUsersNestedInput> = z.object({
  create: z.union([ z.lazy(() => RoleCreateWithoutUsersInputSchema),z.lazy(() => RoleUncheckedCreateWithoutUsersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => RoleCreateOrConnectWithoutUsersInputSchema).optional(),
  upsert: z.lazy(() => RoleUpsertWithoutUsersInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => RoleWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => RoleWhereInputSchema) ]).optional(),
  connect: z.lazy(() => RoleWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => RoleUpdateToOneWithWhereWithoutUsersInputSchema),z.lazy(() => RoleUpdateWithoutUsersInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutUsersInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.BookingHistoryUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryCreateWithoutUserInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutUserInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingHistoryScalarWhereInputSchema),z.lazy(() => BookingHistoryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const StatementUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.StatementUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutCreatedByInputSchema),z.lazy(() => StatementCreateWithoutCreatedByInputSchema).array(),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => StatementCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => StatementCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => StatementUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => StatementUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => StatementCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => StatementUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => StatementUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => StatementUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => StatementUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => StatementScalarWhereInputSchema),z.lazy(() => StatementScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => BaggageCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BaggageCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BaggageCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BaggageUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BaggageUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BaggageCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BaggageWhereUniqueInputSchema),z.lazy(() => BaggageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BaggageUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BaggageUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BaggageUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => BaggageUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCommentCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BookingCommentCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingCommentUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCommentCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingCommentWhereUniqueInputSchema),z.lazy(() => BookingCommentWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingCommentUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingCommentUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingCommentScalarWhereInputSchema),z.lazy(() => BookingCommentScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCreateWithoutCreatedByInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => BookingCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutVerifiedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingCreateWithoutVerifiedByInputSchema).array(),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingCreateOrConnectWithoutVerifiedByInputSchema),z.lazy(() => BookingCreateOrConnectWithoutVerifiedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingUpsertWithWhereUniqueWithoutVerifiedByInputSchema),z.lazy(() => BookingUpsertWithWhereUniqueWithoutVerifiedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingCreateManyVerifiedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingWhereUniqueInputSchema),z.lazy(() => BookingWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingUpdateWithWhereUniqueWithoutVerifiedByInputSchema),z.lazy(() => BookingUpdateWithWhereUniqueWithoutVerifiedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingUpdateManyWithWhereWithoutVerifiedByInputSchema),z.lazy(() => BookingUpdateManyWithWhereWithoutVerifiedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ClientCreateWithoutCreatedByInputSchema),z.lazy(() => ClientCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ClientCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ClientCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ClientUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ClientUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ClientCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ClientWhereUniqueInputSchema),z.lazy(() => ClientWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ClientUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ClientUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ClientUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => ClientUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ClientScalarWhereInputSchema),z.lazy(() => ClientScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutDeliveredByInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutDeliveredByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutDeliveredByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyDeliveredByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutDeliveredByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => DeliveryUpdateManyWithWhereWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUpdateManyWithWhereWithoutDeliveredByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => DeliveryScalarWhereInputSchema),z.lazy(() => DeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema).array(),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => DeliveryCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => DeliveryCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => DeliveryUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => DeliveryCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => DeliveryWhereUniqueInputSchema),z.lazy(() => DeliveryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => DeliveryUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => DeliveryUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => DeliveryUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => DeliveryScalarWhereInputSchema),z.lazy(() => DeliveryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutDispatchedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutDispatchedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutDispatchedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyDispatchedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutDispatchedByInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutDispatchedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutReceivedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ManifestCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestCreateWithoutReceivedByInputSchema).array(),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ManifestCreateOrConnectWithoutReceivedByInputSchema),z.lazy(() => ManifestCreateOrConnectWithoutReceivedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema),z.lazy(() => ManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ManifestCreateManyReceivedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ManifestWhereUniqueInputSchema),z.lazy(() => ManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema),z.lazy(() => ManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ManifestUpdateManyWithWhereWithoutReceivedByInputSchema),z.lazy(() => ManifestUpdateManyWithWhereWithoutReceivedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutDispatchedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyDispatchedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutDispatchedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema).array(),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => MasterManifestCreateOrConnectWithoutReceivedByInputSchema),z.lazy(() => MasterManifestCreateOrConnectWithoutReceivedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => MasterManifestCreateManyReceivedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => MasterManifestWhereUniqueInputSchema),z.lazy(() => MasterManifestWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => MasterManifestUpdateManyWithWhereWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUpdateManyWithWhereWithoutReceivedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema).array(),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => ProductTypeCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => ProductTypeCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => ProductTypeUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => ProductTypeCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => ProductTypeWhereUniqueInputSchema),z.lazy(() => ProductTypeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => ProductTypeUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => ProductTypeUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => ProductTypeScalarWhereInputSchema),z.lazy(() => ProductTypeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryCreateWithoutUserInputSchema).array(),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => BookingHistoryCreateOrConnectWithoutUserInputSchema),z.lazy(() => BookingHistoryCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => BookingHistoryUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => BookingHistoryCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => BookingHistoryWhereUniqueInputSchema),z.lazy(() => BookingHistoryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => BookingHistoryUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => BookingHistoryUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => BookingHistoryScalarWhereInputSchema),z.lazy(() => BookingHistoryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateManyWithoutCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => StatementCreateWithoutCreatedByInputSchema),z.lazy(() => StatementCreateWithoutCreatedByInputSchema).array(),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => StatementCreateOrConnectWithoutCreatedByInputSchema),z.lazy(() => StatementCreateOrConnectWithoutCreatedByInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => StatementUpsertWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => StatementUpsertWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  createMany: z.lazy(() => StatementCreateManyCreatedByInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => StatementWhereUniqueInputSchema),z.lazy(() => StatementWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => StatementUpdateWithWhereUniqueWithoutCreatedByInputSchema),z.lazy(() => StatementUpdateWithWhereUniqueWithoutCreatedByInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => StatementUpdateManyWithWhereWithoutCreatedByInputSchema),z.lazy(() => StatementUpdateManyWithWhereWithoutCreatedByInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => StatementScalarWhereInputSchema),z.lazy(() => StatementScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const NestedIntFilterSchema: z.ZodType<Prisma.NestedIntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const NestedStringFilterSchema: z.ZodType<Prisma.NestedStringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const NestedDateTimeFilterSchema: z.ZodType<Prisma.NestedDateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict();

export const NestedDateTimeNullableFilterSchema: z.ZodType<Prisma.NestedDateTimeNullableFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedIntNullableFilterSchema: z.ZodType<Prisma.NestedIntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedIntWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict();

export const NestedFloatFilterSchema: z.ZodType<Prisma.NestedFloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict();

export const NestedStringWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const NestedDateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict();

export const NestedDateTimeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeNullableWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeNullableFilterSchema).optional()
}).strict();

export const NestedIntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const NestedFloatNullableFilterSchema: z.ZodType<Prisma.NestedFloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumBookingDeliveryStatusFilterSchema: z.ZodType<Prisma.NestedEnumBookingDeliveryStatusFilter> = z.object({
  equals: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  in: z.lazy(() => BookingDeliveryStatusSchema).array().optional(),
  notIn: z.lazy(() => BookingDeliveryStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => NestedEnumBookingDeliveryStatusFilterSchema) ]).optional(),
}).strict();

export const NestedStringNullableFilterSchema: z.ZodType<Prisma.NestedStringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumBookingDeliveryStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumBookingDeliveryStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  in: z.lazy(() => BookingDeliveryStatusSchema).array().optional(),
  notIn: z.lazy(() => BookingDeliveryStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => NestedEnumBookingDeliveryStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumBookingDeliveryStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumBookingDeliveryStatusFilterSchema).optional()
}).strict();

export const NestedStringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  search: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const NestedEnumBookingActionFilterSchema: z.ZodType<Prisma.NestedEnumBookingActionFilter> = z.object({
  equals: z.lazy(() => BookingActionSchema).optional(),
  in: z.lazy(() => BookingActionSchema).array().optional(),
  notIn: z.lazy(() => BookingActionSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => NestedEnumBookingActionFilterSchema) ]).optional(),
}).strict();

export const NestedBoolFilterSchema: z.ZodType<Prisma.NestedBoolFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolFilterSchema) ]).optional(),
}).strict();

export const NestedEnumBookingActionWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumBookingActionWithAggregatesFilter> = z.object({
  equals: z.lazy(() => BookingActionSchema).optional(),
  in: z.lazy(() => BookingActionSchema).array().optional(),
  notIn: z.lazy(() => BookingActionSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => NestedEnumBookingActionWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumBookingActionFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumBookingActionFilterSchema).optional()
}).strict();

export const NestedBoolWithAggregatesFilterSchema: z.ZodType<Prisma.NestedBoolWithAggregatesFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolFilterSchema).optional()
}).strict();

export const NestedDecimalFilterSchema: z.ZodType<Prisma.NestedDecimalFilter> = z.object({
  equals: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  in: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional(),
  notIn: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional(),
  lt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  lte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  not: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NestedDecimalFilterSchema) ]).optional(),
}).strict();

export const NestedEnumUnitFilterSchema: z.ZodType<Prisma.NestedEnumUnitFilter> = z.object({
  equals: z.lazy(() => UnitSchema).optional(),
  in: z.lazy(() => UnitSchema).array().optional(),
  notIn: z.lazy(() => UnitSchema).array().optional(),
  not: z.union([ z.lazy(() => UnitSchema),z.lazy(() => NestedEnumUnitFilterSchema) ]).optional(),
}).strict();

export const NestedDecimalWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDecimalWithAggregatesFilter> = z.object({
  equals: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  in: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional(),
  notIn: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional(),
  lt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  lte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  not: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NestedDecimalWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedDecimalFilterSchema).optional(),
  _sum: z.lazy(() => NestedDecimalFilterSchema).optional(),
  _min: z.lazy(() => NestedDecimalFilterSchema).optional(),
  _max: z.lazy(() => NestedDecimalFilterSchema).optional()
}).strict();

export const NestedEnumUnitWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumUnitWithAggregatesFilter> = z.object({
  equals: z.lazy(() => UnitSchema).optional(),
  in: z.lazy(() => UnitSchema).array().optional(),
  notIn: z.lazy(() => UnitSchema).array().optional(),
  not: z.union([ z.lazy(() => UnitSchema),z.lazy(() => NestedEnumUnitWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumUnitFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumUnitFilterSchema).optional()
}).strict();

export const NestedEnumServiceTypeFilterSchema: z.ZodType<Prisma.NestedEnumServiceTypeFilter> = z.object({
  equals: z.lazy(() => ServiceTypeSchema).optional(),
  in: z.lazy(() => ServiceTypeSchema).array().optional(),
  notIn: z.lazy(() => ServiceTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => NestedEnumServiceTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumDeliveryModeNullableFilterSchema: z.ZodType<Prisma.NestedEnumDeliveryModeNullableFilter> = z.object({
  equals: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  in: z.lazy(() => DeliveryModeSchema).array().optional().nullable(),
  notIn: z.lazy(() => DeliveryModeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NestedEnumDeliveryModeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedDecimalNullableFilterSchema: z.ZodType<Prisma.NestedDecimalNullableFilter> = z.object({
  equals: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  in: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional().nullable(),
  notIn: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional().nullable(),
  lt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  lte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  not: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NestedDecimalNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumPaymentModeFilterSchema: z.ZodType<Prisma.NestedEnumPaymentModeFilter> = z.object({
  equals: z.lazy(() => PaymentModeSchema).optional(),
  in: z.lazy(() => PaymentModeSchema).array().optional(),
  notIn: z.lazy(() => PaymentModeSchema).array().optional(),
  not: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => NestedEnumPaymentModeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumBookingStatusFilterSchema: z.ZodType<Prisma.NestedEnumBookingStatusFilter> = z.object({
  equals: z.lazy(() => BookingStatusSchema).optional(),
  in: z.lazy(() => BookingStatusSchema).array().optional(),
  notIn: z.lazy(() => BookingStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => NestedEnumBookingStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumServiceTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumServiceTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ServiceTypeSchema).optional(),
  in: z.lazy(() => ServiceTypeSchema).array().optional(),
  notIn: z.lazy(() => ServiceTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => NestedEnumServiceTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumServiceTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumServiceTypeFilterSchema).optional()
}).strict();

export const NestedEnumDeliveryModeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumDeliveryModeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  in: z.lazy(() => DeliveryModeSchema).array().optional().nullable(),
  notIn: z.lazy(() => DeliveryModeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NestedEnumDeliveryModeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumDeliveryModeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumDeliveryModeNullableFilterSchema).optional()
}).strict();

export const NestedDecimalNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDecimalNullableWithAggregatesFilter> = z.object({
  equals: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  in: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional().nullable(),
  notIn: z.union([z.number().array(),z.string().array(),z.instanceof(Prisma.Decimal).array(),DecimalJsLikeSchema.array(),]).refine((v) => Array.isArray(v) && (v as any[]).every((v) => isValidDecimalInput(v)), { message: 'Must be a Decimal' }).optional().nullable(),
  lt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  lte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  gte: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  not: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NestedDecimalNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedDecimalNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedDecimalNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDecimalNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDecimalNullableFilterSchema).optional()
}).strict();

export const NestedEnumPaymentModeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumPaymentModeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PaymentModeSchema).optional(),
  in: z.lazy(() => PaymentModeSchema).array().optional(),
  notIn: z.lazy(() => PaymentModeSchema).array().optional(),
  not: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => NestedEnumPaymentModeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPaymentModeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPaymentModeFilterSchema).optional()
}).strict();

export const NestedEnumBookingStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumBookingStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => BookingStatusSchema).optional(),
  in: z.lazy(() => BookingStatusSchema).array().optional(),
  notIn: z.lazy(() => BookingStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => NestedEnumBookingStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumBookingStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumBookingStatusFilterSchema).optional()
}).strict();

export const NestedEnumDeliveryStatusFilterSchema: z.ZodType<Prisma.NestedEnumDeliveryStatusFilter> = z.object({
  equals: z.lazy(() => DeliveryStatusSchema).optional(),
  in: z.lazy(() => DeliveryStatusSchema).array().optional(),
  notIn: z.lazy(() => DeliveryStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => NestedEnumDeliveryStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumDeliveryStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumDeliveryStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => DeliveryStatusSchema).optional(),
  in: z.lazy(() => DeliveryStatusSchema).array().optional(),
  notIn: z.lazy(() => DeliveryStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => NestedEnumDeliveryStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumDeliveryStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumDeliveryStatusFilterSchema).optional()
}).strict();

export const NestedEnumManifestStatusFilterSchema: z.ZodType<Prisma.NestedEnumManifestStatusFilter> = z.object({
  equals: z.lazy(() => ManifestStatusSchema).optional(),
  in: z.lazy(() => ManifestStatusSchema).array().optional(),
  notIn: z.lazy(() => ManifestStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => NestedEnumManifestStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumManifestStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumManifestStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ManifestStatusSchema).optional(),
  in: z.lazy(() => ManifestStatusSchema).array().optional(),
  notIn: z.lazy(() => ManifestStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => NestedEnumManifestStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumManifestStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumManifestStatusFilterSchema).optional()
}).strict();

export const NestedEnumMasterManifestStatusFilterSchema: z.ZodType<Prisma.NestedEnumMasterManifestStatusFilter> = z.object({
  equals: z.lazy(() => MasterManifestStatusSchema).optional(),
  in: z.lazy(() => MasterManifestStatusSchema).array().optional(),
  notIn: z.lazy(() => MasterManifestStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => NestedEnumMasterManifestStatusFilterSchema) ]).optional(),
}).strict();

export const NestedFloatWithAggregatesFilterSchema: z.ZodType<Prisma.NestedFloatWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatFilterSchema).optional()
}).strict();

export const NestedEnumMasterManifestStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumMasterManifestStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => MasterManifestStatusSchema).optional(),
  in: z.lazy(() => MasterManifestStatusSchema).array().optional(),
  notIn: z.lazy(() => MasterManifestStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => NestedEnumMasterManifestStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumMasterManifestStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumMasterManifestStatusFilterSchema).optional()
}).strict();

export const UserCreateWithoutCreatedBaggagesInputSchema: z.ZodType<Prisma.UserCreateWithoutCreatedBaggagesInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCreatedBaggagesInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCreatedBaggagesInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCreatedBaggagesInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCreatedBaggagesInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedBaggagesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedBaggagesInputSchema) ]),
}).strict();

export const ManifestCreateWithoutBaggagesInputSchema: z.ZodType<Prisma.ManifestCreateWithoutBaggagesInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutBaggagesInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutBaggagesInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutBaggagesInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutBaggagesInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutBaggagesInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutBaggagesInputSchema) ]),
}).strict();

export const LocationCreateWithoutOriginBaggagesInputSchema: z.ZodType<Prisma.LocationCreateWithoutOriginBaggagesInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutOriginBaggagesInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutOriginBaggagesInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutOriginBaggagesInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutOriginBaggagesInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginBaggagesInputSchema) ]),
}).strict();

export const LocationCreateWithoutDestinationBaggagesInputSchema: z.ZodType<Prisma.LocationCreateWithoutDestinationBaggagesInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutDestinationBaggagesInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutDestinationBaggagesInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutDestinationBaggagesInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutDestinationBaggagesInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationBaggagesInputSchema) ]),
}).strict();

export const LocationCreateWithoutCurrentBaggagesInputSchema: z.ZodType<Prisma.LocationCreateWithoutCurrentBaggagesInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutCurrentBaggagesInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutCurrentBaggagesInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutCurrentBaggagesInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutCurrentBaggagesInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentBaggagesInputSchema) ]),
}).strict();

export const BookingCreateWithoutBaggageInputSchema: z.ZodType<Prisma.BookingCreateWithoutBaggageInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutBaggageInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutBaggageInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutBaggageInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutBaggageInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutBaggageInputSchema),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema) ]),
}).strict();

export const BookingCreateManyBaggageInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyBaggageInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyBaggageInputSchema),z.lazy(() => BookingCreateManyBaggageInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserUpsertWithoutCreatedBaggagesInputSchema: z.ZodType<Prisma.UserUpsertWithoutCreatedBaggagesInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCreatedBaggagesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedBaggagesInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedBaggagesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedBaggagesInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCreatedBaggagesInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCreatedBaggagesInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCreatedBaggagesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedBaggagesInputSchema) ]),
}).strict();

export const UserUpdateWithoutCreatedBaggagesInputSchema: z.ZodType<Prisma.UserUpdateWithoutCreatedBaggagesInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCreatedBaggagesInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCreatedBaggagesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const ManifestUpsertWithoutBaggagesInputSchema: z.ZodType<Prisma.ManifestUpsertWithoutBaggagesInput> = z.object({
  update: z.union([ z.lazy(() => ManifestUpdateWithoutBaggagesInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutBaggagesInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutBaggagesInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutBaggagesInputSchema) ]),
  where: z.lazy(() => ManifestWhereInputSchema).optional()
}).strict();

export const ManifestUpdateToOneWithWhereWithoutBaggagesInputSchema: z.ZodType<Prisma.ManifestUpdateToOneWithWhereWithoutBaggagesInput> = z.object({
  where: z.lazy(() => ManifestWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutBaggagesInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutBaggagesInputSchema) ]),
}).strict();

export const ManifestUpdateWithoutBaggagesInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutBaggagesInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutBaggagesInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutBaggagesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutOriginBaggagesInputSchema: z.ZodType<Prisma.LocationUpsertWithoutOriginBaggagesInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutOriginBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginBaggagesInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginBaggagesInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutOriginBaggagesInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutOriginBaggagesInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutOriginBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginBaggagesInputSchema) ]),
}).strict();

export const LocationUpdateWithoutOriginBaggagesInputSchema: z.ZodType<Prisma.LocationUpdateWithoutOriginBaggagesInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutOriginBaggagesInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutOriginBaggagesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutDestinationBaggagesInputSchema: z.ZodType<Prisma.LocationUpsertWithoutDestinationBaggagesInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutDestinationBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationBaggagesInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationBaggagesInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutDestinationBaggagesInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutDestinationBaggagesInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutDestinationBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationBaggagesInputSchema) ]),
}).strict();

export const LocationUpdateWithoutDestinationBaggagesInputSchema: z.ZodType<Prisma.LocationUpdateWithoutDestinationBaggagesInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutDestinationBaggagesInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutDestinationBaggagesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutCurrentBaggagesInputSchema: z.ZodType<Prisma.LocationUpsertWithoutCurrentBaggagesInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutCurrentBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentBaggagesInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentBaggagesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentBaggagesInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutCurrentBaggagesInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutCurrentBaggagesInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutCurrentBaggagesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentBaggagesInputSchema) ]),
}).strict();

export const LocationUpdateWithoutCurrentBaggagesInputSchema: z.ZodType<Prisma.LocationUpdateWithoutCurrentBaggagesInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutCurrentBaggagesInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutCurrentBaggagesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const BookingUpsertWithWhereUniqueWithoutBaggageInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutBaggageInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutBaggageInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutBaggageInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutBaggageInputSchema),z.lazy(() => BookingUncheckedCreateWithoutBaggageInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutBaggageInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutBaggageInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutBaggageInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutBaggageInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutBaggageInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutBaggageInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutBaggageInputSchema) ]),
}).strict();

export const BookingScalarWhereInputSchema: z.ZodType<Prisma.BookingScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingScalarWhereInputSchema),z.lazy(() => BookingScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  bookingDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  consignmentNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderContactNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  senderEmail: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  senderPan: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  receiverContactNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverAddr: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  receiverPan: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => EnumServiceTypeFilterSchema),z.lazy(() => ServiceTypeSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => EnumDeliveryModeNullableFilterSchema),z.lazy(() => DeliveryModeSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.lazy(() => DecimalNullableFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional().nullable(),
  refNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  invoiceDate: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  instruction: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => EnumPaymentModeFilterSchema),z.lazy(() => PaymentModeSchema) ]).optional(),
  otherCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  tax: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  stCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  totAmt: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  status: z.union([ z.lazy(() => EnumBookingStatusFilterSchema),z.lazy(() => BookingStatusSchema) ]).optional(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  clientId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  baggageId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  manifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  verifiedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  verifiedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  cod: z.union([ z.lazy(() => DecimalNullableFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional().nullable(),
  laborCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  pickupCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  deliveryCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  volumeCharge: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  discount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  statementId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const BookingCreateWithoutCommentsInputSchema: z.ZodType<Prisma.BookingCreateWithoutCommentsInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutCommentsInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutCommentsInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutCommentsInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutCommentsInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutCommentsInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCommentsInputSchema) ]),
}).strict();

export const UserCreateWithoutBookingCommentsInputSchema: z.ZodType<Prisma.UserCreateWithoutBookingCommentsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutBookingCommentsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutBookingCommentsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutBookingCommentsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutBookingCommentsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutBookingCommentsInputSchema),z.lazy(() => UserUncheckedCreateWithoutBookingCommentsInputSchema) ]),
}).strict();

export const BookingUpsertWithoutCommentsInputSchema: z.ZodType<Prisma.BookingUpsertWithoutCommentsInput> = z.object({
  update: z.union([ z.lazy(() => BookingUpdateWithoutCommentsInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutCommentsInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutCommentsInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCommentsInputSchema) ]),
  where: z.lazy(() => BookingWhereInputSchema).optional()
}).strict();

export const BookingUpdateToOneWithWhereWithoutCommentsInputSchema: z.ZodType<Prisma.BookingUpdateToOneWithWhereWithoutCommentsInput> = z.object({
  where: z.lazy(() => BookingWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => BookingUpdateWithoutCommentsInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutCommentsInputSchema) ]),
}).strict();

export const BookingUpdateWithoutCommentsInputSchema: z.ZodType<Prisma.BookingUpdateWithoutCommentsInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutCommentsInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutCommentsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutBookingCommentsInputSchema: z.ZodType<Prisma.UserUpsertWithoutBookingCommentsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutBookingCommentsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutBookingCommentsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutBookingCommentsInputSchema),z.lazy(() => UserUncheckedCreateWithoutBookingCommentsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutBookingCommentsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutBookingCommentsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutBookingCommentsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutBookingCommentsInputSchema) ]),
}).strict();

export const UserUpdateWithoutBookingCommentsInputSchema: z.ZodType<Prisma.UserUpdateWithoutBookingCommentsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutBookingCommentsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutBookingCommentsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const BookingCreateWithoutDeliveriesInputSchema: z.ZodType<Prisma.BookingCreateWithoutDeliveriesInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutDeliveriesInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutDeliveriesInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutDeliveriesInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutDeliveriesInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutDeliveriesInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDeliveriesInputSchema) ]),
}).strict();

export const DeliveryCreateWithoutBookingsInputSchema: z.ZodType<Prisma.DeliveryCreateWithoutBookingsInput> = z.object({
  date: z.coerce.date().optional(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  location: z.lazy(() => LocationCreateNestedOneWithoutDeliveriesInputSchema),
  deliveredBy: z.lazy(() => UserCreateNestedOneWithoutDeliveredDeliveriesInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedDeliveriesInputSchema)
}).strict();

export const DeliveryUncheckedCreateWithoutBookingsInputSchema: z.ZodType<Prisma.DeliveryUncheckedCreateWithoutBookingsInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  locationId: z.number().int(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveredById: z.number().int(),
  createdById: z.number().int()
}).strict();

export const DeliveryCreateOrConnectWithoutBookingsInputSchema: z.ZodType<Prisma.DeliveryCreateOrConnectWithoutBookingsInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => DeliveryCreateWithoutBookingsInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutBookingsInputSchema) ]),
}).strict();

export const BookingUpsertWithoutDeliveriesInputSchema: z.ZodType<Prisma.BookingUpsertWithoutDeliveriesInput> = z.object({
  update: z.union([ z.lazy(() => BookingUpdateWithoutDeliveriesInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutDeliveriesInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutDeliveriesInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDeliveriesInputSchema) ]),
  where: z.lazy(() => BookingWhereInputSchema).optional()
}).strict();

export const BookingUpdateToOneWithWhereWithoutDeliveriesInputSchema: z.ZodType<Prisma.BookingUpdateToOneWithWhereWithoutDeliveriesInput> = z.object({
  where: z.lazy(() => BookingWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => BookingUpdateWithoutDeliveriesInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutDeliveriesInputSchema) ]),
}).strict();

export const BookingUpdateWithoutDeliveriesInputSchema: z.ZodType<Prisma.BookingUpdateWithoutDeliveriesInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutDeliveriesInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutDeliveriesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const DeliveryUpsertWithoutBookingsInputSchema: z.ZodType<Prisma.DeliveryUpsertWithoutBookingsInput> = z.object({
  update: z.union([ z.lazy(() => DeliveryUpdateWithoutBookingsInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => DeliveryCreateWithoutBookingsInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutBookingsInputSchema) ]),
  where: z.lazy(() => DeliveryWhereInputSchema).optional()
}).strict();

export const DeliveryUpdateToOneWithWhereWithoutBookingsInputSchema: z.ZodType<Prisma.DeliveryUpdateToOneWithWhereWithoutBookingsInput> = z.object({
  where: z.lazy(() => DeliveryWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => DeliveryUpdateWithoutBookingsInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutBookingsInputSchema) ]),
}).strict();

export const DeliveryUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.DeliveryUpdateWithoutBookingsInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutDeliveriesNestedInputSchema).optional(),
  deliveredBy: z.lazy(() => UserUpdateOneRequiredWithoutDeliveredDeliveriesNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedDeliveriesNestedInputSchema).optional()
}).strict();

export const DeliveryUncheckedUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateWithoutBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingCreateWithoutHistoryInputSchema: z.ZodType<Prisma.BookingCreateWithoutHistoryInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutHistoryInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutHistoryInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutHistoryInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutHistoryInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutHistoryInputSchema),z.lazy(() => BookingUncheckedCreateWithoutHistoryInputSchema) ]),
}).strict();

export const UserCreateWithoutBookingHistoryInputSchema: z.ZodType<Prisma.UserCreateWithoutBookingHistoryInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutBookingHistoryInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutBookingHistoryInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutBookingHistoryInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutBookingHistoryInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutBookingHistoryInputSchema),z.lazy(() => UserUncheckedCreateWithoutBookingHistoryInputSchema) ]),
}).strict();

export const LocationCreateWithoutBookingHistoryInputSchema: z.ZodType<Prisma.LocationCreateWithoutBookingHistoryInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutBookingHistoryInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutBookingHistoryInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutBookingHistoryInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutBookingHistoryInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutBookingHistoryInputSchema),z.lazy(() => LocationUncheckedCreateWithoutBookingHistoryInputSchema) ]),
}).strict();

export const BookingUpsertWithoutHistoryInputSchema: z.ZodType<Prisma.BookingUpsertWithoutHistoryInput> = z.object({
  update: z.union([ z.lazy(() => BookingUpdateWithoutHistoryInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutHistoryInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutHistoryInputSchema),z.lazy(() => BookingUncheckedCreateWithoutHistoryInputSchema) ]),
  where: z.lazy(() => BookingWhereInputSchema).optional()
}).strict();

export const BookingUpdateToOneWithWhereWithoutHistoryInputSchema: z.ZodType<Prisma.BookingUpdateToOneWithWhereWithoutHistoryInput> = z.object({
  where: z.lazy(() => BookingWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => BookingUpdateWithoutHistoryInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutHistoryInputSchema) ]),
}).strict();

export const BookingUpdateWithoutHistoryInputSchema: z.ZodType<Prisma.BookingUpdateWithoutHistoryInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutHistoryInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutHistoryInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutBookingHistoryInputSchema: z.ZodType<Prisma.UserUpsertWithoutBookingHistoryInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutBookingHistoryInputSchema),z.lazy(() => UserUncheckedUpdateWithoutBookingHistoryInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutBookingHistoryInputSchema),z.lazy(() => UserUncheckedCreateWithoutBookingHistoryInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutBookingHistoryInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutBookingHistoryInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutBookingHistoryInputSchema),z.lazy(() => UserUncheckedUpdateWithoutBookingHistoryInputSchema) ]),
}).strict();

export const UserUpdateWithoutBookingHistoryInputSchema: z.ZodType<Prisma.UserUpdateWithoutBookingHistoryInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutBookingHistoryInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutBookingHistoryInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutBookingHistoryInputSchema: z.ZodType<Prisma.LocationUpsertWithoutBookingHistoryInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutBookingHistoryInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutBookingHistoryInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutBookingHistoryInputSchema),z.lazy(() => LocationUncheckedCreateWithoutBookingHistoryInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutBookingHistoryInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutBookingHistoryInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutBookingHistoryInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutBookingHistoryInputSchema) ]),
}).strict();

export const LocationUpdateWithoutBookingHistoryInputSchema: z.ZodType<Prisma.LocationUpdateWithoutBookingHistoryInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutBookingHistoryInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutBookingHistoryInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const BookingCreateWithoutItemsInputSchema: z.ZodType<Prisma.BookingCreateWithoutItemsInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutItemsInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutItemsInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutItemsInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutItemsInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutItemsInputSchema),z.lazy(() => BookingUncheckedCreateWithoutItemsInputSchema) ]),
}).strict();

export const ProductTypeCreateWithoutBookingItemsInputSchema: z.ZodType<Prisma.ProductTypeCreateWithoutBookingItemsInput> = z.object({
  name: z.string(),
  status: z.boolean().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  unit: z.lazy(() => UnitSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedProductTypesInputSchema)
}).strict();

export const ProductTypeUncheckedCreateWithoutBookingItemsInputSchema: z.ZodType<Prisma.ProductTypeUncheckedCreateWithoutBookingItemsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  status: z.boolean().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  unit: z.lazy(() => UnitSchema).optional()
}).strict();

export const ProductTypeCreateOrConnectWithoutBookingItemsInputSchema: z.ZodType<Prisma.ProductTypeCreateOrConnectWithoutBookingItemsInput> = z.object({
  where: z.lazy(() => ProductTypeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutBookingItemsInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutBookingItemsInputSchema) ]),
}).strict();

export const BookingUpsertWithoutItemsInputSchema: z.ZodType<Prisma.BookingUpsertWithoutItemsInput> = z.object({
  update: z.union([ z.lazy(() => BookingUpdateWithoutItemsInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutItemsInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutItemsInputSchema),z.lazy(() => BookingUncheckedCreateWithoutItemsInputSchema) ]),
  where: z.lazy(() => BookingWhereInputSchema).optional()
}).strict();

export const BookingUpdateToOneWithWhereWithoutItemsInputSchema: z.ZodType<Prisma.BookingUpdateToOneWithWhereWithoutItemsInput> = z.object({
  where: z.lazy(() => BookingWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => BookingUpdateWithoutItemsInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutItemsInputSchema) ]),
}).strict();

export const BookingUpdateWithoutItemsInputSchema: z.ZodType<Prisma.BookingUpdateWithoutItemsInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutItemsInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutItemsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const ProductTypeUpsertWithoutBookingItemsInputSchema: z.ZodType<Prisma.ProductTypeUpsertWithoutBookingItemsInput> = z.object({
  update: z.union([ z.lazy(() => ProductTypeUpdateWithoutBookingItemsInputSchema),z.lazy(() => ProductTypeUncheckedUpdateWithoutBookingItemsInputSchema) ]),
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutBookingItemsInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutBookingItemsInputSchema) ]),
  where: z.lazy(() => ProductTypeWhereInputSchema).optional()
}).strict();

export const ProductTypeUpdateToOneWithWhereWithoutBookingItemsInputSchema: z.ZodType<Prisma.ProductTypeUpdateToOneWithWhereWithoutBookingItemsInput> = z.object({
  where: z.lazy(() => ProductTypeWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => ProductTypeUpdateWithoutBookingItemsInputSchema),z.lazy(() => ProductTypeUncheckedUpdateWithoutBookingItemsInputSchema) ]),
}).strict();

export const ProductTypeUpdateWithoutBookingItemsInputSchema: z.ZodType<Prisma.ProductTypeUpdateWithoutBookingItemsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedProductTypesNestedInputSchema).optional()
}).strict();

export const ProductTypeUncheckedUpdateWithoutBookingItemsInputSchema: z.ZodType<Prisma.ProductTypeUncheckedUpdateWithoutBookingItemsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingCommentCreateWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentCreateWithoutBookingInput> = z.object({
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutBookingCommentsInputSchema).optional()
}).strict();

export const BookingCommentUncheckedCreateWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentUncheckedCreateWithoutBookingInput> = z.object({
  id: z.number().int().optional(),
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int().optional().nullable()
}).strict();

export const BookingCommentCreateOrConnectWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentCreateOrConnectWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingCommentWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema) ]),
}).strict();

export const BookingCommentCreateManyBookingInputEnvelopeSchema: z.ZodType<Prisma.BookingCommentCreateManyBookingInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCommentCreateManyBookingInputSchema),z.lazy(() => BookingCommentCreateManyBookingInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingDeliveryCreateWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryCreateWithoutBookingInput> = z.object({
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  delivery: z.lazy(() => DeliveryCreateNestedOneWithoutBookingsInputSchema)
}).strict();

export const BookingDeliveryUncheckedCreateWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedCreateWithoutBookingInput> = z.object({
  id: z.number().int().optional(),
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveryId: z.number().int()
}).strict();

export const BookingDeliveryCreateOrConnectWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryCreateOrConnectWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingDeliveryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema) ]),
}).strict();

export const BookingDeliveryCreateManyBookingInputEnvelopeSchema: z.ZodType<Prisma.BookingDeliveryCreateManyBookingInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingDeliveryCreateManyBookingInputSchema),z.lazy(() => BookingDeliveryCreateManyBookingInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingItemCreateWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemCreateWithoutBookingInput> = z.object({
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  productType: z.lazy(() => ProductTypeCreateNestedOneWithoutBookingItemsInputSchema)
}).strict();

export const BookingItemUncheckedCreateWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemUncheckedCreateWithoutBookingInput> = z.object({
  id: z.number().int().optional(),
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  productTypeId: z.number().int()
}).strict();

export const BookingItemCreateOrConnectWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemCreateOrConnectWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingItemWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingItemCreateWithoutBookingInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema) ]),
}).strict();

export const BookingItemCreateManyBookingInputEnvelopeSchema: z.ZodType<Prisma.BookingItemCreateManyBookingInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingItemCreateManyBookingInputSchema),z.lazy(() => BookingItemCreateManyBookingInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserCreateWithoutCreatedBookingsInputSchema: z.ZodType<Prisma.UserCreateWithoutCreatedBookingsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCreatedBookingsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCreatedBookingsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCreatedBookingsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCreatedBookingsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedBookingsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedBookingsInputSchema) ]),
}).strict();

export const ClientCreateWithoutBookingsInputSchema: z.ZodType<Prisma.ClientCreateWithoutBookingsInput> = z.object({
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedClientsInputSchema),
  location: z.lazy(() => LocationCreateNestedOneWithoutClientsInputSchema).optional(),
  statements: z.lazy(() => StatementCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientUncheckedCreateWithoutBookingsInputSchema: z.ZodType<Prisma.ClientUncheckedCreateWithoutBookingsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  statements: z.lazy(() => StatementUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserUncheckedCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientCreateOrConnectWithoutBookingsInputSchema: z.ZodType<Prisma.ClientCreateOrConnectWithoutBookingsInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ClientCreateWithoutBookingsInputSchema),z.lazy(() => ClientUncheckedCreateWithoutBookingsInputSchema) ]),
}).strict();

export const LocationCreateWithoutOriginBookingsInputSchema: z.ZodType<Prisma.LocationCreateWithoutOriginBookingsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutOriginBookingsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutOriginBookingsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutOriginBookingsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutOriginBookingsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginBookingsInputSchema) ]),
}).strict();

export const LocationCreateWithoutDestinationBookingsInputSchema: z.ZodType<Prisma.LocationCreateWithoutDestinationBookingsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutDestinationBookingsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutDestinationBookingsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutDestinationBookingsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutDestinationBookingsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationBookingsInputSchema) ]),
}).strict();

export const BaggageCreateWithoutBookingsInputSchema: z.ZodType<Prisma.BaggageCreateWithoutBookingsInput> = z.object({
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBaggagesInputSchema),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBaggagesInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBaggagesInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBaggagesInputSchema),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBaggagesInputSchema).optional()
}).strict();

export const BaggageUncheckedCreateWithoutBookingsInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateWithoutBookingsInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BaggageCreateOrConnectWithoutBookingsInputSchema: z.ZodType<Prisma.BaggageCreateOrConnectWithoutBookingsInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BaggageCreateWithoutBookingsInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutBookingsInputSchema) ]),
}).strict();

export const ManifestCreateWithoutBookingsInputSchema: z.ZodType<Prisma.ManifestCreateWithoutBookingsInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutBookingsInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutBookingsInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutBookingsInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutBookingsInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutBookingsInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutBookingsInputSchema) ]),
}).strict();

export const UserCreateWithoutVerifiedBookingsInputSchema: z.ZodType<Prisma.UserCreateWithoutVerifiedBookingsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutVerifiedBookingsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutVerifiedBookingsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutVerifiedBookingsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutVerifiedBookingsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutVerifiedBookingsInputSchema),z.lazy(() => UserUncheckedCreateWithoutVerifiedBookingsInputSchema) ]),
}).strict();

export const LocationCreateWithoutCurrentBookingsInputSchema: z.ZodType<Prisma.LocationCreateWithoutCurrentBookingsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutCurrentBookingsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutCurrentBookingsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutCurrentBookingsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutCurrentBookingsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentBookingsInputSchema) ]),
}).strict();

export const BookingHistoryCreateWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryCreateWithoutBookingInput> = z.object({
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean(),
  user: z.lazy(() => UserCreateNestedOneWithoutBookingHistoryInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutBookingHistoryInputSchema).optional()
}).strict();

export const BookingHistoryUncheckedCreateWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedCreateWithoutBookingInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  userId: z.number().int().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean()
}).strict();

export const BookingHistoryCreateOrConnectWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryCreateOrConnectWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema) ]),
}).strict();

export const BookingHistoryCreateManyBookingInputEnvelopeSchema: z.ZodType<Prisma.BookingHistoryCreateManyBookingInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingHistoryCreateManyBookingInputSchema),z.lazy(() => BookingHistoryCreateManyBookingInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const StatementCreateWithoutBookingsInputSchema: z.ZodType<Prisma.StatementCreateWithoutBookingsInput> = z.object({
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  client: z.lazy(() => ClientCreateNestedOneWithoutStatementsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutStatementInputSchema)
}).strict();

export const StatementUncheckedCreateWithoutBookingsInputSchema: z.ZodType<Prisma.StatementUncheckedCreateWithoutBookingsInput> = z.object({
  id: z.number().int().optional(),
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int()
}).strict();

export const StatementCreateOrConnectWithoutBookingsInputSchema: z.ZodType<Prisma.StatementCreateOrConnectWithoutBookingsInput> = z.object({
  where: z.lazy(() => StatementWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => StatementCreateWithoutBookingsInputSchema),z.lazy(() => StatementUncheckedCreateWithoutBookingsInputSchema) ]),
}).strict();

export const BookingCommentUpsertWithWhereUniqueWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentUpsertWithWhereUniqueWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingCommentWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingCommentUpdateWithoutBookingInputSchema),z.lazy(() => BookingCommentUncheckedUpdateWithoutBookingInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutBookingInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutBookingInputSchema) ]),
}).strict();

export const BookingCommentUpdateWithWhereUniqueWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentUpdateWithWhereUniqueWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingCommentWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingCommentUpdateWithoutBookingInputSchema),z.lazy(() => BookingCommentUncheckedUpdateWithoutBookingInputSchema) ]),
}).strict();

export const BookingCommentUpdateManyWithWhereWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentUpdateManyWithWhereWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingCommentScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingCommentUpdateManyMutationInputSchema),z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingInputSchema) ]),
}).strict();

export const BookingCommentScalarWhereInputSchema: z.ZodType<Prisma.BookingCommentScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingCommentScalarWhereInputSchema),z.lazy(() => BookingCommentScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingCommentScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingCommentScalarWhereInputSchema),z.lazy(() => BookingCommentScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  comment: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const BookingDeliveryUpsertWithWhereUniqueWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryUpsertWithWhereUniqueWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingDeliveryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingDeliveryUpdateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUncheckedUpdateWithoutBookingInputSchema) ]),
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutBookingInputSchema) ]),
}).strict();

export const BookingDeliveryUpdateWithWhereUniqueWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateWithWhereUniqueWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingDeliveryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingDeliveryUpdateWithoutBookingInputSchema),z.lazy(() => BookingDeliveryUncheckedUpdateWithoutBookingInputSchema) ]),
}).strict();

export const BookingDeliveryUpdateManyWithWhereWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateManyWithWhereWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingDeliveryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingDeliveryUpdateManyMutationInputSchema),z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingInputSchema) ]),
}).strict();

export const BookingDeliveryScalarWhereInputSchema: z.ZodType<Prisma.BookingDeliveryScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingDeliveryScalarWhereInputSchema),z.lazy(() => BookingDeliveryScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingDeliveryScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingDeliveryScalarWhereInputSchema),z.lazy(() => BookingDeliveryScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumBookingDeliveryStatusFilterSchema),z.lazy(() => BookingDeliveryStatusSchema) ]).optional(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deliveredAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  podPath: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  deliveryId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const BookingItemUpsertWithWhereUniqueWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemUpsertWithWhereUniqueWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingItemWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingItemUpdateWithoutBookingInputSchema),z.lazy(() => BookingItemUncheckedUpdateWithoutBookingInputSchema) ]),
  create: z.union([ z.lazy(() => BookingItemCreateWithoutBookingInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutBookingInputSchema) ]),
}).strict();

export const BookingItemUpdateWithWhereUniqueWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemUpdateWithWhereUniqueWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingItemWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingItemUpdateWithoutBookingInputSchema),z.lazy(() => BookingItemUncheckedUpdateWithoutBookingInputSchema) ]),
}).strict();

export const BookingItemUpdateManyWithWhereWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemUpdateManyWithWhereWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingItemScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingItemUpdateManyMutationInputSchema),z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingInputSchema) ]),
}).strict();

export const BookingItemScalarWhereInputSchema: z.ZodType<Prisma.BookingItemScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingItemScalarWhereInputSchema),z.lazy(() => BookingItemScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingItemScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingItemScalarWhereInputSchema),z.lazy(() => BookingItemScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  desc: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  piece: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  weight: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  unit: z.union([ z.lazy(() => EnumUnitFilterSchema),z.lazy(() => UnitSchema) ]).optional(),
  rate: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  discount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  total: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  bookingId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  productTypeId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const UserUpsertWithoutCreatedBookingsInputSchema: z.ZodType<Prisma.UserUpsertWithoutCreatedBookingsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCreatedBookingsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedBookingsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedBookingsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCreatedBookingsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCreatedBookingsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCreatedBookingsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedBookingsInputSchema) ]),
}).strict();

export const UserUpdateWithoutCreatedBookingsInputSchema: z.ZodType<Prisma.UserUpdateWithoutCreatedBookingsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCreatedBookingsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCreatedBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const ClientUpsertWithoutBookingsInputSchema: z.ZodType<Prisma.ClientUpsertWithoutBookingsInput> = z.object({
  update: z.union([ z.lazy(() => ClientUpdateWithoutBookingsInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => ClientCreateWithoutBookingsInputSchema),z.lazy(() => ClientUncheckedCreateWithoutBookingsInputSchema) ]),
  where: z.lazy(() => ClientWhereInputSchema).optional()
}).strict();

export const ClientUpdateToOneWithWhereWithoutBookingsInputSchema: z.ZodType<Prisma.ClientUpdateToOneWithWhereWithoutBookingsInput> = z.object({
  where: z.lazy(() => ClientWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => ClientUpdateWithoutBookingsInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutBookingsInputSchema) ]),
}).strict();

export const ClientUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.ClientUpdateWithoutBookingsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedClientsNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneWithoutClientsNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientUncheckedUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateWithoutBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  statements: z.lazy(() => StatementUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUncheckedUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutOriginBookingsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutOriginBookingsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutOriginBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginBookingsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutOriginBookingsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutOriginBookingsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutOriginBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginBookingsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutOriginBookingsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutOriginBookingsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutOriginBookingsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutOriginBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutDestinationBookingsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutDestinationBookingsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutDestinationBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationBookingsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutDestinationBookingsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutDestinationBookingsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutDestinationBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationBookingsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutDestinationBookingsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutDestinationBookingsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutDestinationBookingsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutDestinationBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const BaggageUpsertWithoutBookingsInputSchema: z.ZodType<Prisma.BaggageUpsertWithoutBookingsInput> = z.object({
  update: z.union([ z.lazy(() => BaggageUpdateWithoutBookingsInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => BaggageCreateWithoutBookingsInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutBookingsInputSchema) ]),
  where: z.lazy(() => BaggageWhereInputSchema).optional()
}).strict();

export const BaggageUpdateToOneWithWhereWithoutBookingsInputSchema: z.ZodType<Prisma.BaggageUpdateToOneWithWhereWithoutBookingsInput> = z.object({
  where: z.lazy(() => BaggageWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => BaggageUpdateWithoutBookingsInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutBookingsInputSchema) ]),
}).strict();

export const BaggageUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.BaggageUpdateWithoutBookingsInput> = z.object({
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBaggagesNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBaggagesNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBaggagesNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBaggagesNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBaggagesNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateWithoutBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ManifestUpsertWithoutBookingsInputSchema: z.ZodType<Prisma.ManifestUpsertWithoutBookingsInput> = z.object({
  update: z.union([ z.lazy(() => ManifestUpdateWithoutBookingsInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutBookingsInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutBookingsInputSchema) ]),
  where: z.lazy(() => ManifestWhereInputSchema).optional()
}).strict();

export const ManifestUpdateToOneWithWhereWithoutBookingsInputSchema: z.ZodType<Prisma.ManifestUpdateToOneWithWhereWithoutBookingsInput> = z.object({
  where: z.lazy(() => ManifestWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutBookingsInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutBookingsInputSchema) ]),
}).strict();

export const ManifestUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutBookingsInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutVerifiedBookingsInputSchema: z.ZodType<Prisma.UserUpsertWithoutVerifiedBookingsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutVerifiedBookingsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutVerifiedBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutVerifiedBookingsInputSchema),z.lazy(() => UserUncheckedCreateWithoutVerifiedBookingsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutVerifiedBookingsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutVerifiedBookingsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutVerifiedBookingsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutVerifiedBookingsInputSchema) ]),
}).strict();

export const UserUpdateWithoutVerifiedBookingsInputSchema: z.ZodType<Prisma.UserUpdateWithoutVerifiedBookingsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutVerifiedBookingsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutVerifiedBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutCurrentBookingsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutCurrentBookingsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutCurrentBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentBookingsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentBookingsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutCurrentBookingsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutCurrentBookingsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutCurrentBookingsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentBookingsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutCurrentBookingsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutCurrentBookingsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutCurrentBookingsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutCurrentBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const BookingHistoryUpsertWithWhereUniqueWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryUpsertWithWhereUniqueWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithoutBookingInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateWithoutBookingInputSchema) ]),
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutBookingInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutBookingInputSchema) ]),
}).strict();

export const BookingHistoryUpdateWithWhereUniqueWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryUpdateWithWhereUniqueWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingHistoryUpdateWithoutBookingInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateWithoutBookingInputSchema) ]),
}).strict();

export const BookingHistoryUpdateManyWithWhereWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryUpdateManyWithWhereWithoutBookingInput> = z.object({
  where: z.lazy(() => BookingHistoryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingHistoryUpdateManyMutationInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingInputSchema) ]),
}).strict();

export const BookingHistoryScalarWhereInputSchema: z.ZodType<Prisma.BookingHistoryScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BookingHistoryScalarWhereInputSchema),z.lazy(() => BookingHistoryScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BookingHistoryScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BookingHistoryScalarWhereInputSchema),z.lazy(() => BookingHistoryScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  bookingId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  action: z.union([ z.lazy(() => EnumBookingActionFilterSchema),z.lazy(() => BookingActionSchema) ]).optional(),
  refId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  refNo: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  userId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  comment: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  customer: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
}).strict();

export const StatementUpsertWithoutBookingsInputSchema: z.ZodType<Prisma.StatementUpsertWithoutBookingsInput> = z.object({
  update: z.union([ z.lazy(() => StatementUpdateWithoutBookingsInputSchema),z.lazy(() => StatementUncheckedUpdateWithoutBookingsInputSchema) ]),
  create: z.union([ z.lazy(() => StatementCreateWithoutBookingsInputSchema),z.lazy(() => StatementUncheckedCreateWithoutBookingsInputSchema) ]),
  where: z.lazy(() => StatementWhereInputSchema).optional()
}).strict();

export const StatementUpdateToOneWithWhereWithoutBookingsInputSchema: z.ZodType<Prisma.StatementUpdateToOneWithWhereWithoutBookingsInput> = z.object({
  where: z.lazy(() => StatementWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => StatementUpdateWithoutBookingsInputSchema),z.lazy(() => StatementUncheckedUpdateWithoutBookingsInputSchema) ]),
}).strict();

export const StatementUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.StatementUpdateWithoutBookingsInput> = z.object({
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  client: z.lazy(() => ClientUpdateOneRequiredWithoutStatementsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutStatementNestedInputSchema).optional()
}).strict();

export const StatementUncheckedUpdateWithoutBookingsInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateWithoutBookingsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingCreateWithoutClientInputSchema: z.ZodType<Prisma.BookingCreateWithoutClientInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutClientInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutClientInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutClientInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutClientInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutClientInputSchema),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema) ]),
}).strict();

export const BookingCreateManyClientInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyClientInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyClientInputSchema),z.lazy(() => BookingCreateManyClientInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserCreateWithoutCreatedClientsInputSchema: z.ZodType<Prisma.UserCreateWithoutCreatedClientsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCreatedClientsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCreatedClientsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCreatedClientsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCreatedClientsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedClientsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedClientsInputSchema) ]),
}).strict();

export const LocationCreateWithoutClientsInputSchema: z.ZodType<Prisma.LocationCreateWithoutClientsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutClientsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutClientsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutClientsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutClientsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutClientsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutClientsInputSchema) ]),
}).strict();

export const StatementCreateWithoutClientInputSchema: z.ZodType<Prisma.StatementCreateWithoutClientInput> = z.object({
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutStatementInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutStatementInputSchema)
}).strict();

export const StatementUncheckedCreateWithoutClientInputSchema: z.ZodType<Prisma.StatementUncheckedCreateWithoutClientInput> = z.object({
  id: z.number().int().optional(),
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutStatementInputSchema).optional()
}).strict();

export const StatementCreateOrConnectWithoutClientInputSchema: z.ZodType<Prisma.StatementCreateOrConnectWithoutClientInput> = z.object({
  where: z.lazy(() => StatementWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => StatementCreateWithoutClientInputSchema),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema) ]),
}).strict();

export const StatementCreateManyClientInputEnvelopeSchema: z.ZodType<Prisma.StatementCreateManyClientInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => StatementCreateManyClientInputSchema),z.lazy(() => StatementCreateManyClientInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserCreateWithoutClientInputSchema: z.ZodType<Prisma.UserCreateWithoutClientInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutClientInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutClientInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutClientInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutClientInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutClientInputSchema),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema) ]),
}).strict();

export const UserCreateManyClientInputEnvelopeSchema: z.ZodType<Prisma.UserCreateManyClientInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => UserCreateManyClientInputSchema),z.lazy(() => UserCreateManyClientInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingUpsertWithWhereUniqueWithoutClientInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutClientInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutClientInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutClientInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutClientInputSchema),z.lazy(() => BookingUncheckedCreateWithoutClientInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutClientInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutClientInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutClientInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutClientInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutClientInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutClientInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutClientInputSchema) ]),
}).strict();

export const UserUpsertWithoutCreatedClientsInputSchema: z.ZodType<Prisma.UserUpsertWithoutCreatedClientsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCreatedClientsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedClientsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedClientsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedClientsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCreatedClientsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCreatedClientsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCreatedClientsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedClientsInputSchema) ]),
}).strict();

export const UserUpdateWithoutCreatedClientsInputSchema: z.ZodType<Prisma.UserUpdateWithoutCreatedClientsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCreatedClientsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCreatedClientsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutClientsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutClientsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutClientsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutClientsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutClientsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutClientsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutClientsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutClientsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutClientsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutClientsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutClientsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutClientsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutClientsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutClientsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const StatementUpsertWithWhereUniqueWithoutClientInputSchema: z.ZodType<Prisma.StatementUpsertWithWhereUniqueWithoutClientInput> = z.object({
  where: z.lazy(() => StatementWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => StatementUpdateWithoutClientInputSchema),z.lazy(() => StatementUncheckedUpdateWithoutClientInputSchema) ]),
  create: z.union([ z.lazy(() => StatementCreateWithoutClientInputSchema),z.lazy(() => StatementUncheckedCreateWithoutClientInputSchema) ]),
}).strict();

export const StatementUpdateWithWhereUniqueWithoutClientInputSchema: z.ZodType<Prisma.StatementUpdateWithWhereUniqueWithoutClientInput> = z.object({
  where: z.lazy(() => StatementWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => StatementUpdateWithoutClientInputSchema),z.lazy(() => StatementUncheckedUpdateWithoutClientInputSchema) ]),
}).strict();

export const StatementUpdateManyWithWhereWithoutClientInputSchema: z.ZodType<Prisma.StatementUpdateManyWithWhereWithoutClientInput> = z.object({
  where: z.lazy(() => StatementScalarWhereInputSchema),
  data: z.union([ z.lazy(() => StatementUpdateManyMutationInputSchema),z.lazy(() => StatementUncheckedUpdateManyWithoutClientInputSchema) ]),
}).strict();

export const StatementScalarWhereInputSchema: z.ZodType<Prisma.StatementScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => StatementScalarWhereInputSchema),z.lazy(() => StatementScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => StatementScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => StatementScalarWhereInputSchema),z.lazy(() => StatementScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  fromDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  toDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  amount: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  clientId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const UserUpsertWithWhereUniqueWithoutClientInputSchema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutClientInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => UserUpdateWithoutClientInputSchema),z.lazy(() => UserUncheckedUpdateWithoutClientInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutClientInputSchema),z.lazy(() => UserUncheckedCreateWithoutClientInputSchema) ]),
}).strict();

export const UserUpdateWithWhereUniqueWithoutClientInputSchema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutClientInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => UserUpdateWithoutClientInputSchema),z.lazy(() => UserUncheckedUpdateWithoutClientInputSchema) ]),
}).strict();

export const UserUpdateManyWithWhereWithoutClientInputSchema: z.ZodType<Prisma.UserUpdateManyWithWhereWithoutClientInput> = z.object({
  where: z.lazy(() => UserScalarWhereInputSchema),
  data: z.union([ z.lazy(() => UserUpdateManyMutationInputSchema),z.lazy(() => UserUncheckedUpdateManyWithoutClientInputSchema) ]),
}).strict();

export const UserScalarWhereInputSchema: z.ZodType<Prisma.UserScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  middleName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  phone: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  password: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  roleId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  clientId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  passwordToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const BookingDeliveryCreateWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryCreateWithoutDeliveryInput> = z.object({
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutDeliveriesInputSchema)
}).strict();

export const BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedCreateWithoutDeliveryInput> = z.object({
  id: z.number().int().optional(),
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int()
}).strict();

export const BookingDeliveryCreateOrConnectWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryCreateOrConnectWithoutDeliveryInput> = z.object({
  where: z.lazy(() => BookingDeliveryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema) ]),
}).strict();

export const BookingDeliveryCreateManyDeliveryInputEnvelopeSchema: z.ZodType<Prisma.BookingDeliveryCreateManyDeliveryInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingDeliveryCreateManyDeliveryInputSchema),z.lazy(() => BookingDeliveryCreateManyDeliveryInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const LocationCreateWithoutDeliveriesInputSchema: z.ZodType<Prisma.LocationCreateWithoutDeliveriesInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutDeliveriesInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutDeliveriesInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutDeliveriesInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutDeliveriesInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutDeliveriesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDeliveriesInputSchema) ]),
}).strict();

export const UserCreateWithoutDeliveredDeliveriesInputSchema: z.ZodType<Prisma.UserCreateWithoutDeliveredDeliveriesInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutDeliveredDeliveriesInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutDeliveredDeliveriesInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutDeliveredDeliveriesInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutDeliveredDeliveriesInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutDeliveredDeliveriesInputSchema),z.lazy(() => UserUncheckedCreateWithoutDeliveredDeliveriesInputSchema) ]),
}).strict();

export const UserCreateWithoutCreatedDeliveriesInputSchema: z.ZodType<Prisma.UserCreateWithoutCreatedDeliveriesInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCreatedDeliveriesInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCreatedDeliveriesInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCreatedDeliveriesInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCreatedDeliveriesInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedDeliveriesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedDeliveriesInputSchema) ]),
}).strict();

export const BookingDeliveryUpsertWithWhereUniqueWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryUpsertWithWhereUniqueWithoutDeliveryInput> = z.object({
  where: z.lazy(() => BookingDeliveryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingDeliveryUpdateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUncheckedUpdateWithoutDeliveryInputSchema) ]),
  create: z.union([ z.lazy(() => BookingDeliveryCreateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUncheckedCreateWithoutDeliveryInputSchema) ]),
}).strict();

export const BookingDeliveryUpdateWithWhereUniqueWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateWithWhereUniqueWithoutDeliveryInput> = z.object({
  where: z.lazy(() => BookingDeliveryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingDeliveryUpdateWithoutDeliveryInputSchema),z.lazy(() => BookingDeliveryUncheckedUpdateWithoutDeliveryInputSchema) ]),
}).strict();

export const BookingDeliveryUpdateManyWithWhereWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateManyWithWhereWithoutDeliveryInput> = z.object({
  where: z.lazy(() => BookingDeliveryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingDeliveryUpdateManyMutationInputSchema),z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutDeliveryInputSchema) ]),
}).strict();

export const LocationUpsertWithoutDeliveriesInputSchema: z.ZodType<Prisma.LocationUpsertWithoutDeliveriesInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutDeliveriesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDeliveriesInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutDeliveriesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDeliveriesInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutDeliveriesInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutDeliveriesInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutDeliveriesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDeliveriesInputSchema) ]),
}).strict();

export const LocationUpdateWithoutDeliveriesInputSchema: z.ZodType<Prisma.LocationUpdateWithoutDeliveriesInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutDeliveriesInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutDeliveriesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutDeliveredDeliveriesInputSchema: z.ZodType<Prisma.UserUpsertWithoutDeliveredDeliveriesInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutDeliveredDeliveriesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDeliveredDeliveriesInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutDeliveredDeliveriesInputSchema),z.lazy(() => UserUncheckedCreateWithoutDeliveredDeliveriesInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutDeliveredDeliveriesInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutDeliveredDeliveriesInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutDeliveredDeliveriesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDeliveredDeliveriesInputSchema) ]),
}).strict();

export const UserUpdateWithoutDeliveredDeliveriesInputSchema: z.ZodType<Prisma.UserUpdateWithoutDeliveredDeliveriesInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutDeliveredDeliveriesInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutDeliveredDeliveriesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutCreatedDeliveriesInputSchema: z.ZodType<Prisma.UserUpsertWithoutCreatedDeliveriesInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCreatedDeliveriesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedDeliveriesInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedDeliveriesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedDeliveriesInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCreatedDeliveriesInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCreatedDeliveriesInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCreatedDeliveriesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedDeliveriesInputSchema) ]),
}).strict();

export const UserUpdateWithoutCreatedDeliveriesInputSchema: z.ZodType<Prisma.UserUpdateWithoutCreatedDeliveriesInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCreatedDeliveriesInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCreatedDeliveriesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const LocationCreateWithoutDistrictInputSchema: z.ZodType<Prisma.LocationCreateWithoutDistrictInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutDistrictInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutDistrictInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutDistrictInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutDistrictInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutDistrictInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema) ]),
}).strict();

export const LocationCreateManyDistrictInputEnvelopeSchema: z.ZodType<Prisma.LocationCreateManyDistrictInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => LocationCreateManyDistrictInputSchema),z.lazy(() => LocationCreateManyDistrictInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const LocationUpsertWithWhereUniqueWithoutDistrictInputSchema: z.ZodType<Prisma.LocationUpsertWithWhereUniqueWithoutDistrictInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => LocationUpdateWithoutDistrictInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDistrictInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutDistrictInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDistrictInputSchema) ]),
}).strict();

export const LocationUpdateWithWhereUniqueWithoutDistrictInputSchema: z.ZodType<Prisma.LocationUpdateWithWhereUniqueWithoutDistrictInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => LocationUpdateWithoutDistrictInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDistrictInputSchema) ]),
}).strict();

export const LocationUpdateManyWithWhereWithoutDistrictInputSchema: z.ZodType<Prisma.LocationUpdateManyWithWhereWithoutDistrictInput> = z.object({
  where: z.lazy(() => LocationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => LocationUpdateManyMutationInputSchema),z.lazy(() => LocationUncheckedUpdateManyWithoutDistrictInputSchema) ]),
}).strict();

export const LocationScalarWhereInputSchema: z.ZodType<Prisma.LocationScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => LocationScalarWhereInputSchema),z.lazy(() => LocationScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => LocationScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => LocationScalarWhereInputSchema),z.lazy(() => LocationScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  code: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  districtId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const BaggageCreateWithoutOriginInputSchema: z.ZodType<Prisma.BaggageCreateWithoutOriginInput> = z.object({
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBaggagesInputSchema),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBaggagesInputSchema).optional(),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBaggagesInputSchema),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBaggagesInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageUncheckedCreateWithoutOriginInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateWithoutOriginInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageCreateOrConnectWithoutOriginInputSchema: z.ZodType<Prisma.BaggageCreateOrConnectWithoutOriginInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BaggageCreateWithoutOriginInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const BaggageCreateManyOriginInputEnvelopeSchema: z.ZodType<Prisma.BaggageCreateManyOriginInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BaggageCreateManyOriginInputSchema),z.lazy(() => BaggageCreateManyOriginInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BaggageCreateWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageCreateWithoutDestinationInput> = z.object({
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBaggagesInputSchema),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBaggagesInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBaggagesInputSchema),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBaggagesInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageUncheckedCreateWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateWithoutDestinationInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageCreateOrConnectWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageCreateOrConnectWithoutDestinationInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BaggageCreateWithoutDestinationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const BaggageCreateManyDestinationInputEnvelopeSchema: z.ZodType<Prisma.BaggageCreateManyDestinationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BaggageCreateManyDestinationInputSchema),z.lazy(() => BaggageCreateManyDestinationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingCreateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingCreateWithoutCurrentLocationInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutCurrentLocationInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const BookingCreateManyCurrentLocationInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyCurrentLocationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyCurrentLocationInputSchema),z.lazy(() => BookingCreateManyCurrentLocationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BaggageCreateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageCreateWithoutCurrentLocationInput> = z.object({
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBaggagesInputSchema),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBaggagesInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBaggagesInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBaggagesInputSchema),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageUncheckedCreateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateWithoutCurrentLocationInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageCreateOrConnectWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageCreateOrConnectWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const BaggageCreateManyCurrentLocationInputEnvelopeSchema: z.ZodType<Prisma.BaggageCreateManyCurrentLocationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BaggageCreateManyCurrentLocationInputSchema),z.lazy(() => BaggageCreateManyCurrentLocationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingCreateWithoutOriginInputSchema: z.ZodType<Prisma.BookingCreateWithoutOriginInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutOriginInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutOriginInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutOriginInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutOriginInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutOriginInputSchema),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const BookingCreateManyOriginInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyOriginInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyOriginInputSchema),z.lazy(() => BookingCreateManyOriginInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingCreateWithoutDestinationInputSchema: z.ZodType<Prisma.BookingCreateWithoutDestinationInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutDestinationInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutDestinationInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutDestinationInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutDestinationInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutDestinationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const BookingCreateManyDestinationInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyDestinationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyDestinationInputSchema),z.lazy(() => BookingCreateManyDestinationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ClientCreateWithoutLocationInputSchema: z.ZodType<Prisma.ClientCreateWithoutLocationInput> = z.object({
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutClientInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedClientsInputSchema),
  statements: z.lazy(() => StatementCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientUncheckedCreateWithoutLocationInputSchema: z.ZodType<Prisma.ClientUncheckedCreateWithoutLocationInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  statements: z.lazy(() => StatementUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserUncheckedCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientCreateOrConnectWithoutLocationInputSchema: z.ZodType<Prisma.ClientCreateOrConnectWithoutLocationInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ClientCreateWithoutLocationInputSchema),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const ClientCreateManyLocationInputEnvelopeSchema: z.ZodType<Prisma.ClientCreateManyLocationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ClientCreateManyLocationInputSchema),z.lazy(() => ClientCreateManyLocationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const DistrictCreateWithoutLocationsInputSchema: z.ZodType<Prisma.DistrictCreateWithoutLocationsInput> = z.object({
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const DistrictUncheckedCreateWithoutLocationsInputSchema: z.ZodType<Prisma.DistrictUncheckedCreateWithoutLocationsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const DistrictCreateOrConnectWithoutLocationsInputSchema: z.ZodType<Prisma.DistrictCreateOrConnectWithoutLocationsInput> = z.object({
  where: z.lazy(() => DistrictWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => DistrictCreateWithoutLocationsInputSchema),z.lazy(() => DistrictUncheckedCreateWithoutLocationsInputSchema) ]),
}).strict();

export const ManifestCreateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestCreateWithoutCurrentLocationInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutCurrentLocationInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const ManifestCreateManyCurrentLocationInputEnvelopeSchema: z.ZodType<Prisma.ManifestCreateManyCurrentLocationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ManifestCreateManyCurrentLocationInputSchema),z.lazy(() => ManifestCreateManyCurrentLocationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ManifestCreateWithoutOriginInputSchema: z.ZodType<Prisma.ManifestCreateWithoutOriginInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutOriginInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutOriginInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutOriginInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutOriginInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutOriginInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const ManifestCreateManyOriginInputEnvelopeSchema: z.ZodType<Prisma.ManifestCreateManyOriginInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ManifestCreateManyOriginInputSchema),z.lazy(() => ManifestCreateManyOriginInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ManifestCreateWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestCreateWithoutDestinationInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutDestinationInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutDestinationInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutDestinationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const ManifestCreateManyDestinationInputEnvelopeSchema: z.ZodType<Prisma.ManifestCreateManyDestinationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ManifestCreateManyDestinationInputSchema),z.lazy(() => ManifestCreateManyDestinationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const MasterManifestCreateWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestCreateWithoutOriginInput> = z.object({
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutMasterManifestInputSchema).optional(),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationMasterManifestsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedMasterManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedMasterManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedMasterManifestsInputSchema).optional()
}).strict();

export const MasterManifestUncheckedCreateWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateWithoutOriginInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInputSchema).optional()
}).strict();

export const MasterManifestCreateOrConnectWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestCreateOrConnectWithoutOriginInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const MasterManifestCreateManyOriginInputEnvelopeSchema: z.ZodType<Prisma.MasterManifestCreateManyOriginInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => MasterManifestCreateManyOriginInputSchema),z.lazy(() => MasterManifestCreateManyOriginInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const MasterManifestCreateWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestCreateWithoutDestinationInput> = z.object({
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutMasterManifestInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginMasterManifestsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedMasterManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedMasterManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedMasterManifestsInputSchema).optional()
}).strict();

export const MasterManifestUncheckedCreateWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateWithoutDestinationInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInputSchema).optional()
}).strict();

export const MasterManifestCreateOrConnectWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestCreateOrConnectWithoutDestinationInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const MasterManifestCreateManyDestinationInputEnvelopeSchema: z.ZodType<Prisma.MasterManifestCreateManyDestinationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => MasterManifestCreateManyDestinationInputSchema),z.lazy(() => MasterManifestCreateManyDestinationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const RateCreateWithoutOriginInputSchema: z.ZodType<Prisma.RateCreateWithoutOriginInput> = z.object({
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationRatesInputSchema).optional()
}).strict();

export const RateUncheckedCreateWithoutOriginInputSchema: z.ZodType<Prisma.RateUncheckedCreateWithoutOriginInput> = z.object({
  id: z.number().int().optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  destinationId: z.number().int().optional().nullable()
}).strict();

export const RateCreateOrConnectWithoutOriginInputSchema: z.ZodType<Prisma.RateCreateOrConnectWithoutOriginInput> = z.object({
  where: z.lazy(() => RateWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RateCreateWithoutOriginInputSchema),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const RateCreateManyOriginInputEnvelopeSchema: z.ZodType<Prisma.RateCreateManyOriginInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => RateCreateManyOriginInputSchema),z.lazy(() => RateCreateManyOriginInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const RateCreateWithoutDestinationInputSchema: z.ZodType<Prisma.RateCreateWithoutDestinationInput> = z.object({
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginRatesInputSchema).optional()
}).strict();

export const RateUncheckedCreateWithoutDestinationInputSchema: z.ZodType<Prisma.RateUncheckedCreateWithoutDestinationInput> = z.object({
  id: z.number().int().optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int().optional().nullable()
}).strict();

export const RateCreateOrConnectWithoutDestinationInputSchema: z.ZodType<Prisma.RateCreateOrConnectWithoutDestinationInput> = z.object({
  where: z.lazy(() => RateWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RateCreateWithoutDestinationInputSchema),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const RateCreateManyDestinationInputEnvelopeSchema: z.ZodType<Prisma.RateCreateManyDestinationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => RateCreateManyDestinationInputSchema),z.lazy(() => RateCreateManyDestinationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SubLocationCreateWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationCreateWithoutLocationInput> = z.object({
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const SubLocationUncheckedCreateWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationUncheckedCreateWithoutLocationInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const SubLocationCreateOrConnectWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationCreateOrConnectWithoutLocationInput> = z.object({
  where: z.lazy(() => SubLocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SubLocationCreateWithoutLocationInputSchema),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const SubLocationCreateManyLocationInputEnvelopeSchema: z.ZodType<Prisma.SubLocationCreateManyLocationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SubLocationCreateManyLocationInputSchema),z.lazy(() => SubLocationCreateManyLocationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserCreateWithoutLocationInputSchema: z.ZodType<Prisma.UserCreateWithoutLocationInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutLocationInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutLocationInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutLocationInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutLocationInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutLocationInputSchema),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const UserCreateManyLocationInputEnvelopeSchema: z.ZodType<Prisma.UserCreateManyLocationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => UserCreateManyLocationInputSchema),z.lazy(() => UserCreateManyLocationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const DeliveryCreateWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryCreateWithoutLocationInput> = z.object({
  date: z.coerce.date().optional(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingDeliveryCreateNestedManyWithoutDeliveryInputSchema).optional(),
  deliveredBy: z.lazy(() => UserCreateNestedOneWithoutDeliveredDeliveriesInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedDeliveriesInputSchema)
}).strict();

export const DeliveryUncheckedCreateWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryUncheckedCreateWithoutLocationInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveredById: z.number().int(),
  createdById: z.number().int(),
  bookings: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutDeliveryInputSchema).optional()
}).strict();

export const DeliveryCreateOrConnectWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryCreateOrConnectWithoutLocationInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => DeliveryCreateWithoutLocationInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const DeliveryCreateManyLocationInputEnvelopeSchema: z.ZodType<Prisma.DeliveryCreateManyLocationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => DeliveryCreateManyLocationInputSchema),z.lazy(() => DeliveryCreateManyLocationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingHistoryCreateWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryCreateWithoutLocationInput> = z.object({
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutHistoryInputSchema),
  user: z.lazy(() => UserCreateNestedOneWithoutBookingHistoryInputSchema).optional()
}).strict();

export const BookingHistoryUncheckedCreateWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedCreateWithoutLocationInput> = z.object({
  id: z.number().int().optional(),
  bookingId: z.number().int(),
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  userId: z.number().int().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean()
}).strict();

export const BookingHistoryCreateOrConnectWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryCreateOrConnectWithoutLocationInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const BookingHistoryCreateManyLocationInputEnvelopeSchema: z.ZodType<Prisma.BookingHistoryCreateManyLocationInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingHistoryCreateManyLocationInputSchema),z.lazy(() => BookingHistoryCreateManyLocationInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BaggageUpsertWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.BaggageUpsertWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BaggageUpdateWithoutOriginInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutOriginInputSchema) ]),
  create: z.union([ z.lazy(() => BaggageCreateWithoutOriginInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const BaggageUpdateWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.BaggageUpdateWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateWithoutOriginInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutOriginInputSchema) ]),
}).strict();

export const BaggageUpdateManyWithWhereWithoutOriginInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithWhereWithoutOriginInput> = z.object({
  where: z.lazy(() => BaggageScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateManyMutationInputSchema),z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginInputSchema) ]),
}).strict();

export const BaggageScalarWhereInputSchema: z.ZodType<Prisma.BaggageScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BaggageScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BaggageScalarWhereInputSchema),z.lazy(() => BaggageScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  baggageNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  manifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const BaggageUpsertWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageUpsertWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BaggageUpdateWithoutDestinationInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutDestinationInputSchema) ]),
  create: z.union([ z.lazy(() => BaggageCreateWithoutDestinationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const BaggageUpdateWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageUpdateWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateWithoutDestinationInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutDestinationInputSchema) ]),
}).strict();

export const BaggageUpdateManyWithWhereWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithWhereWithoutDestinationInput> = z.object({
  where: z.lazy(() => BaggageScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateManyMutationInputSchema),z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationInputSchema) ]),
}).strict();

export const BookingUpsertWithWhereUniqueWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutCurrentLocationInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutCurrentLocationInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutCurrentLocationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutCurrentLocationInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationInputSchema) ]),
}).strict();

export const BaggageUpsertWithWhereUniqueWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageUpsertWithWhereUniqueWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BaggageUpdateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutCurrentLocationInputSchema) ]),
  create: z.union([ z.lazy(() => BaggageCreateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const BaggageUpdateWithWhereUniqueWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageUpdateWithWhereUniqueWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateWithoutCurrentLocationInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const BaggageUpdateManyWithWhereWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithWhereWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => BaggageScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateManyMutationInputSchema),z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationInputSchema) ]),
}).strict();

export const BookingUpsertWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutOriginInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutOriginInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutOriginInputSchema),z.lazy(() => BookingUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutOriginInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutOriginInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutOriginInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutOriginInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutOriginInputSchema) ]),
}).strict();

export const BookingUpsertWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutDestinationInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutDestinationInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutDestinationInputSchema),z.lazy(() => BookingUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutDestinationInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutDestinationInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutDestinationInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutDestinationInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationInputSchema) ]),
}).strict();

export const ClientUpsertWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.ClientUpsertWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ClientUpdateWithoutLocationInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutLocationInputSchema) ]),
  create: z.union([ z.lazy(() => ClientCreateWithoutLocationInputSchema),z.lazy(() => ClientUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const ClientUpdateWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.ClientUpdateWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ClientUpdateWithoutLocationInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutLocationInputSchema) ]),
}).strict();

export const ClientUpdateManyWithWhereWithoutLocationInputSchema: z.ZodType<Prisma.ClientUpdateManyWithWhereWithoutLocationInput> = z.object({
  where: z.lazy(() => ClientScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ClientUpdateManyMutationInputSchema),z.lazy(() => ClientUncheckedUpdateManyWithoutLocationInputSchema) ]),
}).strict();

export const ClientScalarWhereInputSchema: z.ZodType<Prisma.ClientScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ClientScalarWhereInputSchema),z.lazy(() => ClientScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ClientScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ClientScalarWhereInputSchema),z.lazy(() => ClientScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactPerson: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contactNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  pan: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const DistrictUpsertWithoutLocationsInputSchema: z.ZodType<Prisma.DistrictUpsertWithoutLocationsInput> = z.object({
  update: z.union([ z.lazy(() => DistrictUpdateWithoutLocationsInputSchema),z.lazy(() => DistrictUncheckedUpdateWithoutLocationsInputSchema) ]),
  create: z.union([ z.lazy(() => DistrictCreateWithoutLocationsInputSchema),z.lazy(() => DistrictUncheckedCreateWithoutLocationsInputSchema) ]),
  where: z.lazy(() => DistrictWhereInputSchema).optional()
}).strict();

export const DistrictUpdateToOneWithWhereWithoutLocationsInputSchema: z.ZodType<Prisma.DistrictUpdateToOneWithWhereWithoutLocationsInput> = z.object({
  where: z.lazy(() => DistrictWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => DistrictUpdateWithoutLocationsInputSchema),z.lazy(() => DistrictUncheckedUpdateWithoutLocationsInputSchema) ]),
}).strict();

export const DistrictUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.DistrictUpdateWithoutLocationsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DistrictUncheckedUpdateWithoutLocationsInputSchema: z.ZodType<Prisma.DistrictUncheckedUpdateWithoutLocationsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ManifestUpsertWithWhereUniqueWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestUpsertWithWhereUniqueWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ManifestUpdateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutCurrentLocationInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const ManifestUpdateWithWhereUniqueWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestUpdateWithWhereUniqueWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutCurrentLocationInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutCurrentLocationInputSchema) ]),
}).strict();

export const ManifestUpdateManyWithWhereWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithWhereWithoutCurrentLocationInput> = z.object({
  where: z.lazy(() => ManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateManyMutationInputSchema),z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationInputSchema) ]),
}).strict();

export const ManifestScalarWhereInputSchema: z.ZodType<Prisma.ManifestScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ManifestScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ManifestScalarWhereInputSchema),z.lazy(() => ManifestScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  manifestNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  status: z.union([ z.lazy(() => EnumManifestStatusFilterSchema),z.lazy(() => ManifestStatusSchema) ]).optional(),
  checkedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  dispatchedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  currentLocationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  masterManifestId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  dispatchedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  receivedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const ManifestUpsertWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.ManifestUpsertWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ManifestUpdateWithoutOriginInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutOriginInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutOriginInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const ManifestUpdateWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.ManifestUpdateWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutOriginInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutOriginInputSchema) ]),
}).strict();

export const ManifestUpdateManyWithWhereWithoutOriginInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithWhereWithoutOriginInput> = z.object({
  where: z.lazy(() => ManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateManyMutationInputSchema),z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginInputSchema) ]),
}).strict();

export const ManifestUpsertWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestUpsertWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ManifestUpdateWithoutDestinationInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutDestinationInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutDestinationInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const ManifestUpdateWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestUpdateWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutDestinationInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutDestinationInputSchema) ]),
}).strict();

export const ManifestUpdateManyWithWhereWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithWhereWithoutDestinationInput> = z.object({
  where: z.lazy(() => ManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateManyMutationInputSchema),z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationInputSchema) ]),
}).strict();

export const MasterManifestUpsertWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestUpsertWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithoutOriginInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutOriginInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutOriginInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const MasterManifestUpdateWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateWithoutOriginInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutOriginInputSchema) ]),
}).strict();

export const MasterManifestUpdateManyWithWhereWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithWhereWithoutOriginInput> = z.object({
  where: z.lazy(() => MasterManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateManyMutationInputSchema),z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginInputSchema) ]),
}).strict();

export const MasterManifestScalarWhereInputSchema: z.ZodType<Prisma.MasterManifestScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => MasterManifestScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MasterManifestScalarWhereInputSchema),z.lazy(() => MasterManifestScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  masterManifestNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  vehicleNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  driverName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  mobileNo: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  freight: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  advance: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  balance: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumMasterManifestStatusFilterSchema),z.lazy(() => MasterManifestStatusSchema) ]).optional(),
  checkedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  dispatchedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  receivedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  destinationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  dispatchedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  receivedById: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const MasterManifestUpsertWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestUpsertWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithoutDestinationInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutDestinationInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDestinationInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const MasterManifestUpdateWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateWithoutDestinationInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutDestinationInputSchema) ]),
}).strict();

export const MasterManifestUpdateManyWithWhereWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithWhereWithoutDestinationInput> = z.object({
  where: z.lazy(() => MasterManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateManyMutationInputSchema),z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationInputSchema) ]),
}).strict();

export const RateUpsertWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.RateUpsertWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => RateWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => RateUpdateWithoutOriginInputSchema),z.lazy(() => RateUncheckedUpdateWithoutOriginInputSchema) ]),
  create: z.union([ z.lazy(() => RateCreateWithoutOriginInputSchema),z.lazy(() => RateUncheckedCreateWithoutOriginInputSchema) ]),
}).strict();

export const RateUpdateWithWhereUniqueWithoutOriginInputSchema: z.ZodType<Prisma.RateUpdateWithWhereUniqueWithoutOriginInput> = z.object({
  where: z.lazy(() => RateWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => RateUpdateWithoutOriginInputSchema),z.lazy(() => RateUncheckedUpdateWithoutOriginInputSchema) ]),
}).strict();

export const RateUpdateManyWithWhereWithoutOriginInputSchema: z.ZodType<Prisma.RateUpdateManyWithWhereWithoutOriginInput> = z.object({
  where: z.lazy(() => RateScalarWhereInputSchema),
  data: z.union([ z.lazy(() => RateUpdateManyMutationInputSchema),z.lazy(() => RateUncheckedUpdateManyWithoutOriginInputSchema) ]),
}).strict();

export const RateScalarWhereInputSchema: z.ZodType<Prisma.RateScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => RateScalarWhereInputSchema),z.lazy(() => RateScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => RateScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => RateScalarWhereInputSchema),z.lazy(() => RateScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  rate: z.union([ z.lazy(() => DecimalFilterSchema),z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  originId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  destinationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const RateUpsertWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.RateUpsertWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => RateWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => RateUpdateWithoutDestinationInputSchema),z.lazy(() => RateUncheckedUpdateWithoutDestinationInputSchema) ]),
  create: z.union([ z.lazy(() => RateCreateWithoutDestinationInputSchema),z.lazy(() => RateUncheckedCreateWithoutDestinationInputSchema) ]),
}).strict();

export const RateUpdateWithWhereUniqueWithoutDestinationInputSchema: z.ZodType<Prisma.RateUpdateWithWhereUniqueWithoutDestinationInput> = z.object({
  where: z.lazy(() => RateWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => RateUpdateWithoutDestinationInputSchema),z.lazy(() => RateUncheckedUpdateWithoutDestinationInputSchema) ]),
}).strict();

export const RateUpdateManyWithWhereWithoutDestinationInputSchema: z.ZodType<Prisma.RateUpdateManyWithWhereWithoutDestinationInput> = z.object({
  where: z.lazy(() => RateScalarWhereInputSchema),
  data: z.union([ z.lazy(() => RateUpdateManyMutationInputSchema),z.lazy(() => RateUncheckedUpdateManyWithoutDestinationInputSchema) ]),
}).strict();

export const SubLocationUpsertWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationUpsertWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => SubLocationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SubLocationUpdateWithoutLocationInputSchema),z.lazy(() => SubLocationUncheckedUpdateWithoutLocationInputSchema) ]),
  create: z.union([ z.lazy(() => SubLocationCreateWithoutLocationInputSchema),z.lazy(() => SubLocationUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const SubLocationUpdateWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationUpdateWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => SubLocationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SubLocationUpdateWithoutLocationInputSchema),z.lazy(() => SubLocationUncheckedUpdateWithoutLocationInputSchema) ]),
}).strict();

export const SubLocationUpdateManyWithWhereWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationUpdateManyWithWhereWithoutLocationInput> = z.object({
  where: z.lazy(() => SubLocationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SubLocationUpdateManyMutationInputSchema),z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationInputSchema) ]),
}).strict();

export const SubLocationScalarWhereInputSchema: z.ZodType<Prisma.SubLocationScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SubLocationScalarWhereInputSchema),z.lazy(() => SubLocationScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SubLocationScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SubLocationScalarWhereInputSchema),z.lazy(() => SubLocationScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  locationId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const UserUpsertWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => UserUpdateWithoutLocationInputSchema),z.lazy(() => UserUncheckedUpdateWithoutLocationInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutLocationInputSchema),z.lazy(() => UserUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const UserUpdateWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => UserUpdateWithoutLocationInputSchema),z.lazy(() => UserUncheckedUpdateWithoutLocationInputSchema) ]),
}).strict();

export const UserUpdateManyWithWhereWithoutLocationInputSchema: z.ZodType<Prisma.UserUpdateManyWithWhereWithoutLocationInput> = z.object({
  where: z.lazy(() => UserScalarWhereInputSchema),
  data: z.union([ z.lazy(() => UserUpdateManyMutationInputSchema),z.lazy(() => UserUncheckedUpdateManyWithoutLocationInputSchema) ]),
}).strict();

export const DeliveryUpsertWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryUpsertWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => DeliveryUpdateWithoutLocationInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutLocationInputSchema) ]),
  create: z.union([ z.lazy(() => DeliveryCreateWithoutLocationInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const DeliveryUpdateWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryUpdateWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => DeliveryUpdateWithoutLocationInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutLocationInputSchema) ]),
}).strict();

export const DeliveryUpdateManyWithWhereWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryUpdateManyWithWhereWithoutLocationInput> = z.object({
  where: z.lazy(() => DeliveryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => DeliveryUpdateManyMutationInputSchema),z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationInputSchema) ]),
}).strict();

export const DeliveryScalarWhereInputSchema: z.ZodType<Prisma.DeliveryScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => DeliveryScalarWhereInputSchema),z.lazy(() => DeliveryScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => DeliveryScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DeliveryScalarWhereInputSchema),z.lazy(() => DeliveryScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  date: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  locationId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumDeliveryStatusFilterSchema),z.lazy(() => DeliveryStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  deliveredById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const BookingHistoryUpsertWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryUpsertWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithoutLocationInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateWithoutLocationInputSchema) ]),
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutLocationInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutLocationInputSchema) ]),
}).strict();

export const BookingHistoryUpdateWithWhereUniqueWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryUpdateWithWhereUniqueWithoutLocationInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingHistoryUpdateWithoutLocationInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateWithoutLocationInputSchema) ]),
}).strict();

export const BookingHistoryUpdateManyWithWhereWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryUpdateManyWithWhereWithoutLocationInput> = z.object({
  where: z.lazy(() => BookingHistoryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingHistoryUpdateManyMutationInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationInputSchema) ]),
}).strict();

export const BaggageCreateWithoutManifestInputSchema: z.ZodType<Prisma.BaggageCreateWithoutManifestInput> = z.object({
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBaggagesInputSchema),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBaggagesInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBaggagesInputSchema),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBaggagesInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageUncheckedCreateWithoutManifestInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateWithoutManifestInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageCreateOrConnectWithoutManifestInputSchema: z.ZodType<Prisma.BaggageCreateOrConnectWithoutManifestInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BaggageCreateWithoutManifestInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema) ]),
}).strict();

export const BaggageCreateManyManifestInputEnvelopeSchema: z.ZodType<Prisma.BaggageCreateManyManifestInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BaggageCreateManyManifestInputSchema),z.lazy(() => BaggageCreateManyManifestInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingCreateWithoutManifestInputSchema: z.ZodType<Prisma.BookingCreateWithoutManifestInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutManifestInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutManifestInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutManifestInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutManifestInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutManifestInputSchema),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema) ]),
}).strict();

export const BookingCreateManyManifestInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyManifestInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyManifestInputSchema),z.lazy(() => BookingCreateManyManifestInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const LocationCreateWithoutCurrentManifestsInputSchema: z.ZodType<Prisma.LocationCreateWithoutCurrentManifestsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutCurrentManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutCurrentManifestsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutCurrentManifestsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutCurrentManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentManifestsInputSchema) ]),
}).strict();

export const LocationCreateWithoutOriginManifestsInputSchema: z.ZodType<Prisma.LocationCreateWithoutOriginManifestsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutOriginManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutOriginManifestsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutOriginManifestsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutOriginManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginManifestsInputSchema) ]),
}).strict();

export const LocationCreateWithoutDestinationManifestsInputSchema: z.ZodType<Prisma.LocationCreateWithoutDestinationManifestsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutDestinationManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutDestinationManifestsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutDestinationManifestsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutDestinationManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationManifestsInputSchema) ]),
}).strict();

export const MasterManifestCreateWithoutManifestsInputSchema: z.ZodType<Prisma.MasterManifestCreateWithoutManifestsInput> = z.object({
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  details: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutMasterManifestInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginMasterManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationMasterManifestsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedMasterManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedMasterManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedMasterManifestsInputSchema).optional()
}).strict();

export const MasterManifestUncheckedCreateWithoutManifestsInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateWithoutManifestsInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  details: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInputSchema).optional()
}).strict();

export const MasterManifestCreateOrConnectWithoutManifestsInputSchema: z.ZodType<Prisma.MasterManifestCreateOrConnectWithoutManifestsInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutManifestsInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutManifestsInputSchema) ]),
}).strict();

export const UserCreateWithoutCreatedManifestsInputSchema: z.ZodType<Prisma.UserCreateWithoutCreatedManifestsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCreatedManifestsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCreatedManifestsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCreatedManifestsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCreatedManifestsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedManifestsInputSchema) ]),
}).strict();

export const UserCreateWithoutDispatchedManifestsInputSchema: z.ZodType<Prisma.UserCreateWithoutDispatchedManifestsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutDispatchedManifestsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutDispatchedManifestsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutDispatchedManifestsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutDispatchedManifestsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutDispatchedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutDispatchedManifestsInputSchema) ]),
}).strict();

export const UserCreateWithoutReceivedManifestsInputSchema: z.ZodType<Prisma.UserCreateWithoutReceivedManifestsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutReceivedManifestsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutReceivedManifestsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutReceivedManifestsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutReceivedManifestsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedManifestsInputSchema) ]),
}).strict();

export const MasterManifestDetailCreateWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateWithoutManifestInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  masterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutDetailsInputSchema)
}).strict();

export const MasterManifestDetailUncheckedCreateWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedCreateWithoutManifestInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  masterManifestId: z.number().int()
}).strict();

export const MasterManifestDetailCreateOrConnectWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateOrConnectWithoutManifestInput> = z.object({
  where: z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema) ]),
}).strict();

export const MasterManifestDetailCreateManyManifestInputEnvelopeSchema: z.ZodType<Prisma.MasterManifestDetailCreateManyManifestInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => MasterManifestDetailCreateManyManifestInputSchema),z.lazy(() => MasterManifestDetailCreateManyManifestInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BaggageUpsertWithWhereUniqueWithoutManifestInputSchema: z.ZodType<Prisma.BaggageUpsertWithWhereUniqueWithoutManifestInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BaggageUpdateWithoutManifestInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutManifestInputSchema) ]),
  create: z.union([ z.lazy(() => BaggageCreateWithoutManifestInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutManifestInputSchema) ]),
}).strict();

export const BaggageUpdateWithWhereUniqueWithoutManifestInputSchema: z.ZodType<Prisma.BaggageUpdateWithWhereUniqueWithoutManifestInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateWithoutManifestInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutManifestInputSchema) ]),
}).strict();

export const BaggageUpdateManyWithWhereWithoutManifestInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithWhereWithoutManifestInput> = z.object({
  where: z.lazy(() => BaggageScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateManyMutationInputSchema),z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestInputSchema) ]),
}).strict();

export const BookingUpsertWithWhereUniqueWithoutManifestInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutManifestInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutManifestInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutManifestInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutManifestInputSchema),z.lazy(() => BookingUncheckedCreateWithoutManifestInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutManifestInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutManifestInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutManifestInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutManifestInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutManifestInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutManifestInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutManifestInputSchema) ]),
}).strict();

export const LocationUpsertWithoutCurrentManifestsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutCurrentManifestsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutCurrentManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutCurrentManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutCurrentManifestsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutCurrentManifestsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutCurrentManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutCurrentManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutCurrentManifestsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutCurrentManifestsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutCurrentManifestsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutCurrentManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutCurrentManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutOriginManifestsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutOriginManifestsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutOriginManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginManifestsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutOriginManifestsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutOriginManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutOriginManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginManifestsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutOriginManifestsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutOriginManifestsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutOriginManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutOriginManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutDestinationManifestsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutDestinationManifestsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutDestinationManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationManifestsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutDestinationManifestsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutDestinationManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutDestinationManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationManifestsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutDestinationManifestsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutDestinationManifestsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutDestinationManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutDestinationManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const MasterManifestUpsertWithoutManifestsInputSchema: z.ZodType<Prisma.MasterManifestUpsertWithoutManifestsInput> = z.object({
  update: z.union([ z.lazy(() => MasterManifestUpdateWithoutManifestsInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutManifestsInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutManifestsInputSchema) ]),
  where: z.lazy(() => MasterManifestWhereInputSchema).optional()
}).strict();

export const MasterManifestUpdateToOneWithWhereWithoutManifestsInputSchema: z.ZodType<Prisma.MasterManifestUpdateToOneWithWhereWithoutManifestsInput> = z.object({
  where: z.lazy(() => MasterManifestWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => MasterManifestUpdateWithoutManifestsInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutManifestsInputSchema) ]),
}).strict();

export const MasterManifestUpdateWithoutManifestsInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithoutManifestsInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  details: z.lazy(() => MasterManifestDetailUpdateManyWithoutMasterManifestNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedMasterManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateWithoutManifestsInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateWithoutManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  details: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutCreatedManifestsInputSchema: z.ZodType<Prisma.UserUpsertWithoutCreatedManifestsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCreatedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedManifestsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCreatedManifestsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCreatedManifestsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCreatedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedManifestsInputSchema) ]),
}).strict();

export const UserUpdateWithoutCreatedManifestsInputSchema: z.ZodType<Prisma.UserUpdateWithoutCreatedManifestsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCreatedManifestsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCreatedManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutDispatchedManifestsInputSchema: z.ZodType<Prisma.UserUpsertWithoutDispatchedManifestsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutDispatchedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDispatchedManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutDispatchedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutDispatchedManifestsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutDispatchedManifestsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutDispatchedManifestsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutDispatchedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDispatchedManifestsInputSchema) ]),
}).strict();

export const UserUpdateWithoutDispatchedManifestsInputSchema: z.ZodType<Prisma.UserUpdateWithoutDispatchedManifestsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutDispatchedManifestsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutDispatchedManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutReceivedManifestsInputSchema: z.ZodType<Prisma.UserUpsertWithoutReceivedManifestsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutReceivedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedManifestsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutReceivedManifestsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutReceivedManifestsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutReceivedManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedManifestsInputSchema) ]),
}).strict();

export const UserUpdateWithoutReceivedManifestsInputSchema: z.ZodType<Prisma.UserUpdateWithoutReceivedManifestsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutReceivedManifestsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutReceivedManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const MasterManifestDetailUpsertWithWhereUniqueWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUpsertWithWhereUniqueWithoutManifestInput> = z.object({
  where: z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => MasterManifestDetailUpdateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedUpdateWithoutManifestInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutManifestInputSchema) ]),
}).strict();

export const MasterManifestDetailUpdateWithWhereUniqueWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateWithWhereUniqueWithoutManifestInput> = z.object({
  where: z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => MasterManifestDetailUpdateWithoutManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedUpdateWithoutManifestInputSchema) ]),
}).strict();

export const MasterManifestDetailUpdateManyWithWhereWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateManyWithWhereWithoutManifestInput> = z.object({
  where: z.lazy(() => MasterManifestDetailScalarWhereInputSchema),
  data: z.union([ z.lazy(() => MasterManifestDetailUpdateManyMutationInputSchema),z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestInputSchema) ]),
}).strict();

export const MasterManifestDetailScalarWhereInputSchema: z.ZodType<Prisma.MasterManifestDetailScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => MasterManifestDetailScalarWhereInputSchema),z.lazy(() => MasterManifestDetailScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => MasterManifestDetailScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => MasterManifestDetailScalarWhereInputSchema),z.lazy(() => MasterManifestDetailScalarWhereInputSchema).array() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  manifestId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  masterManifestId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const ManifestCreateWithoutMasterManifestsInputSchema: z.ZodType<Prisma.ManifestCreateWithoutMasterManifestsInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutMasterManifestsInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutMasterManifestsInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutMasterManifestsInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutMasterManifestsInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutMasterManifestsInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutMasterManifestsInputSchema) ]),
}).strict();

export const MasterManifestCreateWithoutDetailsInputSchema: z.ZodType<Prisma.MasterManifestCreateWithoutDetailsInput> = z.object({
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginMasterManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationMasterManifestsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedMasterManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedMasterManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedMasterManifestsInputSchema).optional()
}).strict();

export const MasterManifestUncheckedCreateWithoutDetailsInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateWithoutDetailsInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional()
}).strict();

export const MasterManifestCreateOrConnectWithoutDetailsInputSchema: z.ZodType<Prisma.MasterManifestCreateOrConnectWithoutDetailsInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDetailsInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDetailsInputSchema) ]),
}).strict();

export const ManifestUpsertWithoutMasterManifestsInputSchema: z.ZodType<Prisma.ManifestUpsertWithoutMasterManifestsInput> = z.object({
  update: z.union([ z.lazy(() => ManifestUpdateWithoutMasterManifestsInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutMasterManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutMasterManifestsInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutMasterManifestsInputSchema) ]),
  where: z.lazy(() => ManifestWhereInputSchema).optional()
}).strict();

export const ManifestUpdateToOneWithWhereWithoutMasterManifestsInputSchema: z.ZodType<Prisma.ManifestUpdateToOneWithWhereWithoutMasterManifestsInput> = z.object({
  where: z.lazy(() => ManifestWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutMasterManifestsInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutMasterManifestsInputSchema) ]),
}).strict();

export const ManifestUpdateWithoutMasterManifestsInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutMasterManifestsInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutMasterManifestsInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutMasterManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const MasterManifestUpsertWithoutDetailsInputSchema: z.ZodType<Prisma.MasterManifestUpsertWithoutDetailsInput> = z.object({
  update: z.union([ z.lazy(() => MasterManifestUpdateWithoutDetailsInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutDetailsInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDetailsInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDetailsInputSchema) ]),
  where: z.lazy(() => MasterManifestWhereInputSchema).optional()
}).strict();

export const MasterManifestUpdateToOneWithWhereWithoutDetailsInputSchema: z.ZodType<Prisma.MasterManifestUpdateToOneWithWhereWithoutDetailsInput> = z.object({
  where: z.lazy(() => MasterManifestWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => MasterManifestUpdateWithoutDetailsInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutDetailsInputSchema) ]),
}).strict();

export const MasterManifestUpdateWithoutDetailsInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithoutDetailsInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedMasterManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateWithoutDetailsInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateWithoutDetailsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional()
}).strict();

export const ManifestCreateWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestCreateWithoutCurrentMasterManifestInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutCurrentMasterManifestInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutCurrentMasterManifestInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema) ]),
}).strict();

export const ManifestCreateManyCurrentMasterManifestInputEnvelopeSchema: z.ZodType<Prisma.ManifestCreateManyCurrentMasterManifestInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ManifestCreateManyCurrentMasterManifestInputSchema),z.lazy(() => ManifestCreateManyCurrentMasterManifestInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const MasterManifestDetailCreateWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateWithoutMasterManifestInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutMasterManifestsInputSchema)
}).strict();

export const MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedCreateWithoutMasterManifestInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  manifestId: z.number().int()
}).strict();

export const MasterManifestDetailCreateOrConnectWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateOrConnectWithoutMasterManifestInput> = z.object({
  where: z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema) ]),
}).strict();

export const MasterManifestDetailCreateManyMasterManifestInputEnvelopeSchema: z.ZodType<Prisma.MasterManifestDetailCreateManyMasterManifestInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => MasterManifestDetailCreateManyMasterManifestInputSchema),z.lazy(() => MasterManifestDetailCreateManyMasterManifestInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const LocationCreateWithoutOriginMasterManifestsInputSchema: z.ZodType<Prisma.LocationCreateWithoutOriginMasterManifestsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutOriginMasterManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutOriginMasterManifestsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutOriginMasterManifestsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutOriginMasterManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginMasterManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginMasterManifestsInputSchema) ]),
}).strict();

export const LocationCreateWithoutDestinationMasterManifestsInputSchema: z.ZodType<Prisma.LocationCreateWithoutDestinationMasterManifestsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutDestinationMasterManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutDestinationMasterManifestsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutDestinationMasterManifestsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutDestinationMasterManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationMasterManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationMasterManifestsInputSchema) ]),
}).strict();

export const UserCreateWithoutCreatedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateWithoutCreatedMasterManifestsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCreatedMasterManifestsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCreatedMasterManifestsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCreatedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCreatedMasterManifestsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedMasterManifestsInputSchema) ]),
}).strict();

export const UserCreateWithoutDispatchedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateWithoutDispatchedMasterManifestsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutDispatchedMasterManifestsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutDispatchedMasterManifestsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutDispatchedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutDispatchedMasterManifestsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutDispatchedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutDispatchedMasterManifestsInputSchema) ]),
}).strict();

export const UserCreateWithoutReceivedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateWithoutReceivedMasterManifestsInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutReceivedMasterManifestsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutReceivedMasterManifestsInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutReceivedMasterManifestsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutReceivedMasterManifestsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedMasterManifestsInputSchema) ]),
}).strict();

export const ManifestUpsertWithWhereUniqueWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestUpsertWithWhereUniqueWithoutCurrentMasterManifestInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ManifestUpdateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutCurrentMasterManifestInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCurrentMasterManifestInputSchema) ]),
}).strict();

export const ManifestUpdateWithWhereUniqueWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestUpdateWithWhereUniqueWithoutCurrentMasterManifestInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutCurrentMasterManifestInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutCurrentMasterManifestInputSchema) ]),
}).strict();

export const ManifestUpdateManyWithWhereWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithWhereWithoutCurrentMasterManifestInput> = z.object({
  where: z.lazy(() => ManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateManyMutationInputSchema),z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentMasterManifestInputSchema) ]),
}).strict();

export const MasterManifestDetailUpsertWithWhereUniqueWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUpsertWithWhereUniqueWithoutMasterManifestInput> = z.object({
  where: z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => MasterManifestDetailUpdateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedUpdateWithoutMasterManifestInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestDetailCreateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedCreateWithoutMasterManifestInputSchema) ]),
}).strict();

export const MasterManifestDetailUpdateWithWhereUniqueWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateWithWhereUniqueWithoutMasterManifestInput> = z.object({
  where: z.lazy(() => MasterManifestDetailWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => MasterManifestDetailUpdateWithoutMasterManifestInputSchema),z.lazy(() => MasterManifestDetailUncheckedUpdateWithoutMasterManifestInputSchema) ]),
}).strict();

export const MasterManifestDetailUpdateManyWithWhereWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateManyWithWhereWithoutMasterManifestInput> = z.object({
  where: z.lazy(() => MasterManifestDetailScalarWhereInputSchema),
  data: z.union([ z.lazy(() => MasterManifestDetailUpdateManyMutationInputSchema),z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestInputSchema) ]),
}).strict();

export const LocationUpsertWithoutOriginMasterManifestsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutOriginMasterManifestsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutOriginMasterManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginMasterManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginMasterManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginMasterManifestsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutOriginMasterManifestsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutOriginMasterManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutOriginMasterManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginMasterManifestsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutOriginMasterManifestsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutOriginMasterManifestsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutOriginMasterManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutOriginMasterManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutDestinationMasterManifestsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutDestinationMasterManifestsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutDestinationMasterManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationMasterManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationMasterManifestsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationMasterManifestsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutDestinationMasterManifestsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutDestinationMasterManifestsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutDestinationMasterManifestsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationMasterManifestsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutDestinationMasterManifestsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutDestinationMasterManifestsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutDestinationMasterManifestsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutDestinationMasterManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutCreatedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpsertWithoutCreatedMasterManifestsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCreatedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedMasterManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedMasterManifestsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCreatedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCreatedMasterManifestsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCreatedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedMasterManifestsInputSchema) ]),
}).strict();

export const UserUpdateWithoutCreatedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpdateWithoutCreatedMasterManifestsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCreatedMasterManifestsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCreatedMasterManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutDispatchedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpsertWithoutDispatchedMasterManifestsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutDispatchedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDispatchedMasterManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutDispatchedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutDispatchedMasterManifestsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutDispatchedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutDispatchedMasterManifestsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutDispatchedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutDispatchedMasterManifestsInputSchema) ]),
}).strict();

export const UserUpdateWithoutDispatchedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpdateWithoutDispatchedMasterManifestsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutDispatchedMasterManifestsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutDispatchedMasterManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutReceivedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpsertWithoutReceivedMasterManifestsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutReceivedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedMasterManifestsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedMasterManifestsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedMasterManifestsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutReceivedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutReceivedMasterManifestsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutReceivedMasterManifestsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedMasterManifestsInputSchema) ]),
}).strict();

export const UserUpdateWithoutReceivedMasterManifestsInputSchema: z.ZodType<Prisma.UserUpdateWithoutReceivedMasterManifestsInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutReceivedMasterManifestsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutReceivedMasterManifestsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const BookingItemCreateWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemCreateWithoutProductTypeInput> = z.object({
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutItemsInputSchema).optional()
}).strict();

export const BookingItemUncheckedCreateWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemUncheckedCreateWithoutProductTypeInput> = z.object({
  id: z.number().int().optional(),
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int().optional().nullable()
}).strict();

export const BookingItemCreateOrConnectWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemCreateOrConnectWithoutProductTypeInput> = z.object({
  where: z.lazy(() => BookingItemWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema) ]),
}).strict();

export const BookingItemCreateManyProductTypeInputEnvelopeSchema: z.ZodType<Prisma.BookingItemCreateManyProductTypeInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingItemCreateManyProductTypeInputSchema),z.lazy(() => BookingItemCreateManyProductTypeInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserCreateWithoutCreatedProductTypesInputSchema: z.ZodType<Prisma.UserCreateWithoutCreatedProductTypesInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCreatedProductTypesInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCreatedProductTypesInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCreatedProductTypesInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCreatedProductTypesInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedProductTypesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedProductTypesInputSchema) ]),
}).strict();

export const BookingItemUpsertWithWhereUniqueWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemUpsertWithWhereUniqueWithoutProductTypeInput> = z.object({
  where: z.lazy(() => BookingItemWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingItemUpdateWithoutProductTypeInputSchema),z.lazy(() => BookingItemUncheckedUpdateWithoutProductTypeInputSchema) ]),
  create: z.union([ z.lazy(() => BookingItemCreateWithoutProductTypeInputSchema),z.lazy(() => BookingItemUncheckedCreateWithoutProductTypeInputSchema) ]),
}).strict();

export const BookingItemUpdateWithWhereUniqueWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemUpdateWithWhereUniqueWithoutProductTypeInput> = z.object({
  where: z.lazy(() => BookingItemWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingItemUpdateWithoutProductTypeInputSchema),z.lazy(() => BookingItemUncheckedUpdateWithoutProductTypeInputSchema) ]),
}).strict();

export const BookingItemUpdateManyWithWhereWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemUpdateManyWithWhereWithoutProductTypeInput> = z.object({
  where: z.lazy(() => BookingItemScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingItemUpdateManyMutationInputSchema),z.lazy(() => BookingItemUncheckedUpdateManyWithoutProductTypeInputSchema) ]),
}).strict();

export const UserUpsertWithoutCreatedProductTypesInputSchema: z.ZodType<Prisma.UserUpsertWithoutCreatedProductTypesInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCreatedProductTypesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedProductTypesInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCreatedProductTypesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCreatedProductTypesInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCreatedProductTypesInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCreatedProductTypesInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCreatedProductTypesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCreatedProductTypesInputSchema) ]),
}).strict();

export const UserUpdateWithoutCreatedProductTypesInputSchema: z.ZodType<Prisma.UserUpdateWithoutCreatedProductTypesInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCreatedProductTypesInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCreatedProductTypesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const LocationCreateWithoutOriginRatesInputSchema: z.ZodType<Prisma.LocationCreateWithoutOriginRatesInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutOriginRatesInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutOriginRatesInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutOriginRatesInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutOriginRatesInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginRatesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginRatesInputSchema) ]),
}).strict();

export const LocationCreateWithoutDestinationRatesInputSchema: z.ZodType<Prisma.LocationCreateWithoutDestinationRatesInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutDestinationRatesInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutDestinationRatesInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutDestinationRatesInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutDestinationRatesInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationRatesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationRatesInputSchema) ]),
}).strict();

export const LocationUpsertWithoutOriginRatesInputSchema: z.ZodType<Prisma.LocationUpsertWithoutOriginRatesInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutOriginRatesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginRatesInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutOriginRatesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutOriginRatesInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutOriginRatesInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutOriginRatesInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutOriginRatesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutOriginRatesInputSchema) ]),
}).strict();

export const LocationUpdateWithoutOriginRatesInputSchema: z.ZodType<Prisma.LocationUpdateWithoutOriginRatesInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutOriginRatesInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutOriginRatesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutDestinationRatesInputSchema: z.ZodType<Prisma.LocationUpsertWithoutDestinationRatesInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutDestinationRatesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationRatesInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutDestinationRatesInputSchema),z.lazy(() => LocationUncheckedCreateWithoutDestinationRatesInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutDestinationRatesInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutDestinationRatesInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutDestinationRatesInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutDestinationRatesInputSchema) ]),
}).strict();

export const LocationUpdateWithoutDestinationRatesInputSchema: z.ZodType<Prisma.LocationUpdateWithoutDestinationRatesInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutDestinationRatesInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutDestinationRatesInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutRoleInputSchema: z.ZodType<Prisma.UserCreateWithoutRoleInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutRoleInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutRoleInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutRoleInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutRoleInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutRoleInputSchema),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema) ]),
}).strict();

export const UserCreateManyRoleInputEnvelopeSchema: z.ZodType<Prisma.UserCreateManyRoleInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => UserCreateManyRoleInputSchema),z.lazy(() => UserCreateManyRoleInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserUpsertWithWhereUniqueWithoutRoleInputSchema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutRoleInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => UserUpdateWithoutRoleInputSchema),z.lazy(() => UserUncheckedUpdateWithoutRoleInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutRoleInputSchema),z.lazy(() => UserUncheckedCreateWithoutRoleInputSchema) ]),
}).strict();

export const UserUpdateWithWhereUniqueWithoutRoleInputSchema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutRoleInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => UserUpdateWithoutRoleInputSchema),z.lazy(() => UserUncheckedUpdateWithoutRoleInputSchema) ]),
}).strict();

export const UserUpdateManyWithWhereWithoutRoleInputSchema: z.ZodType<Prisma.UserUpdateManyWithWhereWithoutRoleInput> = z.object({
  where: z.lazy(() => UserScalarWhereInputSchema),
  data: z.union([ z.lazy(() => UserUpdateManyMutationInputSchema),z.lazy(() => UserUncheckedUpdateManyWithoutRoleInputSchema) ]),
}).strict();

export const ClientCreateWithoutStatementsInputSchema: z.ZodType<Prisma.ClientCreateWithoutStatementsInput> = z.object({
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutClientInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedClientsInputSchema),
  location: z.lazy(() => LocationCreateNestedOneWithoutClientsInputSchema).optional(),
  user: z.lazy(() => UserCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientUncheckedCreateWithoutStatementsInputSchema: z.ZodType<Prisma.ClientUncheckedCreateWithoutStatementsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserUncheckedCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientCreateOrConnectWithoutStatementsInputSchema: z.ZodType<Prisma.ClientCreateOrConnectWithoutStatementsInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ClientCreateWithoutStatementsInputSchema),z.lazy(() => ClientUncheckedCreateWithoutStatementsInputSchema) ]),
}).strict();

export const BookingCreateWithoutStatementInputSchema: z.ZodType<Prisma.BookingCreateWithoutStatementInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutStatementInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutStatementInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutStatementInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutStatementInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutStatementInputSchema),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema) ]),
}).strict();

export const BookingCreateManyStatementInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyStatementInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyStatementInputSchema),z.lazy(() => BookingCreateManyStatementInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserCreateWithoutStatementInputSchema: z.ZodType<Prisma.UserCreateWithoutStatementInput> = z.object({
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeCreateNestedManyWithoutCreatedByInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutUserInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutUsersInputSchema),
  role: z.lazy(() => RoleCreateNestedOneWithoutUsersInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutStatementInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutStatementInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutVerifiedByInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutDeliveredByInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDispatchedByInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutReceivedByInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedCreateNestedManyWithoutCreatedByInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutStatementInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutStatementInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutStatementInputSchema),z.lazy(() => UserUncheckedCreateWithoutStatementInputSchema) ]),
}).strict();

export const ClientUpsertWithoutStatementsInputSchema: z.ZodType<Prisma.ClientUpsertWithoutStatementsInput> = z.object({
  update: z.union([ z.lazy(() => ClientUpdateWithoutStatementsInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutStatementsInputSchema) ]),
  create: z.union([ z.lazy(() => ClientCreateWithoutStatementsInputSchema),z.lazy(() => ClientUncheckedCreateWithoutStatementsInputSchema) ]),
  where: z.lazy(() => ClientWhereInputSchema).optional()
}).strict();

export const ClientUpdateToOneWithWhereWithoutStatementsInputSchema: z.ZodType<Prisma.ClientUpdateToOneWithWhereWithoutStatementsInput> = z.object({
  where: z.lazy(() => ClientWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => ClientUpdateWithoutStatementsInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutStatementsInputSchema) ]),
}).strict();

export const ClientUpdateWithoutStatementsInputSchema: z.ZodType<Prisma.ClientUpdateWithoutStatementsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUpdateManyWithoutClientNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedClientsNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneWithoutClientsNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientUncheckedUpdateWithoutStatementsInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateWithoutStatementsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUncheckedUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const BookingUpsertWithWhereUniqueWithoutStatementInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutStatementInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutStatementInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutStatementInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutStatementInputSchema),z.lazy(() => BookingUncheckedCreateWithoutStatementInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutStatementInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutStatementInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutStatementInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutStatementInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutStatementInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutStatementInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutStatementInputSchema) ]),
}).strict();

export const UserUpsertWithoutStatementInputSchema: z.ZodType<Prisma.UserUpsertWithoutStatementInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutStatementInputSchema),z.lazy(() => UserUncheckedUpdateWithoutStatementInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutStatementInputSchema),z.lazy(() => UserUncheckedCreateWithoutStatementInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutStatementInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutStatementInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutStatementInputSchema),z.lazy(() => UserUncheckedUpdateWithoutStatementInputSchema) ]),
}).strict();

export const UserUpdateWithoutStatementInputSchema: z.ZodType<Prisma.UserUpdateWithoutStatementInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutStatementInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutStatementInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const LocationCreateWithoutSubLocationsInputSchema: z.ZodType<Prisma.LocationCreateWithoutSubLocationsInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutSubLocationsInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutSubLocationsInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutSubLocationsInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutSubLocationsInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutSubLocationsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutSubLocationsInputSchema) ]),
}).strict();

export const LocationUpsertWithoutSubLocationsInputSchema: z.ZodType<Prisma.LocationUpsertWithoutSubLocationsInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutSubLocationsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutSubLocationsInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutSubLocationsInputSchema),z.lazy(() => LocationUncheckedCreateWithoutSubLocationsInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutSubLocationsInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutSubLocationsInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutSubLocationsInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutSubLocationsInputSchema) ]),
}).strict();

export const LocationUpdateWithoutSubLocationsInputSchema: z.ZodType<Prisma.LocationUpdateWithoutSubLocationsInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutSubLocationsInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutSubLocationsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const BaggageCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageCreateWithoutCreatedByInput> = z.object({
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBaggagesInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBaggagesInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBaggagesInputSchema),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBaggagesInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutBaggageInputSchema).optional()
}).strict();

export const BaggageCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BaggageCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const BaggageCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.BaggageCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BaggageCreateManyCreatedByInputSchema),z.lazy(() => BaggageCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingCommentCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentCreateWithoutCreatedByInput> = z.object({
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutCommentsInputSchema)
}).strict();

export const BookingCommentUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int()
}).strict();

export const BookingCommentCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BookingCommentWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingCommentCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.BookingCommentCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCommentCreateManyCreatedByInputSchema),z.lazy(() => BookingCommentCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCreateWithoutCreatedByInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  verifiedBy: z.lazy(() => UserCreateNestedOneWithoutVerifiedBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutCreatedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyCreatedByInputSchema),z.lazy(() => BookingCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BookingCreateWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingCreateWithoutVerifiedByInput> = z.object({
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  comments: z.lazy(() => BookingCommentCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemCreateNestedManyWithoutBookingInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedBookingsInputSchema),
  client: z.lazy(() => ClientCreateNestedOneWithoutBookingsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginBookingsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationBookingsInputSchema),
  baggage: z.lazy(() => BaggageCreateNestedOneWithoutBookingsInputSchema).optional(),
  manifest: z.lazy(() => ManifestCreateNestedOneWithoutBookingsInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentBookingsInputSchema).optional(),
  history: z.lazy(() => BookingHistoryCreateNestedManyWithoutBookingInputSchema).optional(),
  statement: z.lazy(() => StatementCreateNestedOneWithoutBookingsInputSchema).optional()
}).strict();

export const BookingUncheckedCreateWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingUncheckedCreateWithoutVerifiedByInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutBookingInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutBookingInputSchema).optional()
}).strict();

export const BookingCreateOrConnectWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingCreateOrConnectWithoutVerifiedByInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema) ]),
}).strict();

export const BookingCreateManyVerifiedByInputEnvelopeSchema: z.ZodType<Prisma.BookingCreateManyVerifiedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingCreateManyVerifiedByInputSchema),z.lazy(() => BookingCreateManyVerifiedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ClientCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientCreateWithoutCreatedByInput> = z.object({
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutClientInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutClientsInputSchema).optional(),
  statements: z.lazy(() => StatementCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  statements: z.lazy(() => StatementUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  user: z.lazy(() => UserUncheckedCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ClientCreateWithoutCreatedByInputSchema),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const ClientCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.ClientCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ClientCreateManyCreatedByInputSchema),z.lazy(() => ClientCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const DeliveryCreateWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryCreateWithoutDeliveredByInput> = z.object({
  date: z.coerce.date().optional(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingDeliveryCreateNestedManyWithoutDeliveryInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutDeliveriesInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedDeliveriesInputSchema)
}).strict();

export const DeliveryUncheckedCreateWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryUncheckedCreateWithoutDeliveredByInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  locationId: z.number().int(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  bookings: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutDeliveryInputSchema).optional()
}).strict();

export const DeliveryCreateOrConnectWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryCreateOrConnectWithoutDeliveredByInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema) ]),
}).strict();

export const DeliveryCreateManyDeliveredByInputEnvelopeSchema: z.ZodType<Prisma.DeliveryCreateManyDeliveredByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => DeliveryCreateManyDeliveredByInputSchema),z.lazy(() => DeliveryCreateManyDeliveredByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const DeliveryCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryCreateWithoutCreatedByInput> = z.object({
  date: z.coerce.date().optional(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingDeliveryCreateNestedManyWithoutDeliveryInputSchema).optional(),
  location: z.lazy(() => LocationCreateNestedOneWithoutDeliveriesInputSchema),
  deliveredBy: z.lazy(() => UserCreateNestedOneWithoutDeliveredDeliveriesInputSchema)
}).strict();

export const DeliveryUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  locationId: z.number().int(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveredById: z.number().int(),
  bookings: z.lazy(() => BookingDeliveryUncheckedCreateNestedManyWithoutDeliveryInputSchema).optional()
}).strict();

export const DeliveryCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const DeliveryCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.DeliveryCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => DeliveryCreateManyCreatedByInputSchema),z.lazy(() => DeliveryCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ManifestCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestCreateWithoutCreatedByInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const ManifestCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.ManifestCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ManifestCreateManyCreatedByInputSchema),z.lazy(() => ManifestCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ManifestCreateWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestCreateWithoutDispatchedByInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutDispatchedByInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  receivedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutDispatchedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema) ]),
}).strict();

export const ManifestCreateManyDispatchedByInputEnvelopeSchema: z.ZodType<Prisma.ManifestCreateManyDispatchedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ManifestCreateManyDispatchedByInputSchema),z.lazy(() => ManifestCreateManyDispatchedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ManifestCreateWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestCreateWithoutReceivedByInput> = z.object({
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  baggages: z.lazy(() => BaggageCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutManifestInputSchema).optional(),
  currentLocation: z.lazy(() => LocationCreateNestedOneWithoutCurrentManifestsInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationManifestsInputSchema),
  currentMasterManifest: z.lazy(() => MasterManifestCreateNestedOneWithoutManifestsInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedManifestsInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestUncheckedCreateWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestUncheckedCreateWithoutReceivedByInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutManifestInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutManifestInputSchema).optional()
}).strict();

export const ManifestCreateOrConnectWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestCreateOrConnectWithoutReceivedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ManifestCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema) ]),
}).strict();

export const ManifestCreateManyReceivedByInputEnvelopeSchema: z.ZodType<Prisma.ManifestCreateManyReceivedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ManifestCreateManyReceivedByInputSchema),z.lazy(() => ManifestCreateManyReceivedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const MasterManifestCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestCreateWithoutCreatedByInput> = z.object({
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutMasterManifestInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginMasterManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationMasterManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedMasterManifestsInputSchema).optional(),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedMasterManifestsInputSchema).optional()
}).strict();

export const MasterManifestUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInputSchema).optional()
}).strict();

export const MasterManifestCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const MasterManifestCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.MasterManifestCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => MasterManifestCreateManyCreatedByInputSchema),z.lazy(() => MasterManifestCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const MasterManifestCreateWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestCreateWithoutDispatchedByInput> = z.object({
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutMasterManifestInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginMasterManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationMasterManifestsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedMasterManifestsInputSchema),
  receivedBy: z.lazy(() => UserCreateNestedOneWithoutReceivedMasterManifestsInputSchema).optional()
}).strict();

export const MasterManifestUncheckedCreateWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateWithoutDispatchedByInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  receivedById: z.number().int().optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInputSchema).optional()
}).strict();

export const MasterManifestCreateOrConnectWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestCreateOrConnectWithoutDispatchedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema) ]),
}).strict();

export const MasterManifestCreateManyDispatchedByInputEnvelopeSchema: z.ZodType<Prisma.MasterManifestCreateManyDispatchedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => MasterManifestCreateManyDispatchedByInputSchema),z.lazy(() => MasterManifestCreateManyDispatchedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const MasterManifestCreateWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestCreateWithoutReceivedByInput> = z.object({
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailCreateNestedManyWithoutMasterManifestInputSchema).optional(),
  origin: z.lazy(() => LocationCreateNestedOneWithoutOriginMasterManifestsInputSchema),
  destination: z.lazy(() => LocationCreateNestedOneWithoutDestinationMasterManifestsInputSchema),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedMasterManifestsInputSchema),
  dispatchedBy: z.lazy(() => UserCreateNestedOneWithoutDispatchedMasterManifestsInputSchema).optional()
}).strict();

export const MasterManifestUncheckedCreateWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedCreateWithoutReceivedByInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentMasterManifestInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedCreateNestedManyWithoutMasterManifestInputSchema).optional()
}).strict();

export const MasterManifestCreateOrConnectWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestCreateOrConnectWithoutReceivedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema) ]),
}).strict();

export const MasterManifestCreateManyReceivedByInputEnvelopeSchema: z.ZodType<Prisma.MasterManifestCreateManyReceivedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => MasterManifestCreateManyReceivedByInputSchema),z.lazy(() => MasterManifestCreateManyReceivedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ProductTypeCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeCreateWithoutCreatedByInput> = z.object({
  name: z.string(),
  status: z.boolean().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  unit: z.lazy(() => UnitSchema).optional(),
  bookingItems: z.lazy(() => BookingItemCreateNestedManyWithoutProductTypeInputSchema).optional()
}).strict();

export const ProductTypeUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  status: z.boolean().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  unit: z.lazy(() => UnitSchema).optional(),
  bookingItems: z.lazy(() => BookingItemUncheckedCreateNestedManyWithoutProductTypeInputSchema).optional()
}).strict();

export const ProductTypeCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ProductTypeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const ProductTypeCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.ProductTypeCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => ProductTypeCreateManyCreatedByInputSchema),z.lazy(() => ProductTypeCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const ClientCreateWithoutUserInputSchema: z.ZodType<Prisma.ClientCreateWithoutUserInput> = z.object({
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutClientInputSchema).optional(),
  createdBy: z.lazy(() => UserCreateNestedOneWithoutCreatedClientsInputSchema),
  location: z.lazy(() => LocationCreateNestedOneWithoutClientsInputSchema).optional(),
  statements: z.lazy(() => StatementCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.ClientUncheckedCreateWithoutUserInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutClientInputSchema).optional(),
  statements: z.lazy(() => StatementUncheckedCreateNestedManyWithoutClientInputSchema).optional()
}).strict();

export const ClientCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.ClientCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => ClientCreateWithoutUserInputSchema),z.lazy(() => ClientUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const LocationCreateWithoutUsersInputSchema: z.ZodType<Prisma.LocationCreateWithoutUsersInput> = z.object({
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originBaggages: z.lazy(() => BaggageCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientCreateNestedManyWithoutLocationInputSchema).optional(),
  district: z.lazy(() => DistrictCreateNestedOneWithoutLocationsInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationUncheckedCreateWithoutUsersInputSchema: z.ZodType<Prisma.LocationUncheckedCreateWithoutUsersInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  districtId: z.number().int().optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutCurrentLocationInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutOriginInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedCreateNestedManyWithoutDestinationInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedCreateNestedManyWithoutLocationInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedCreateNestedManyWithoutLocationInputSchema).optional()
}).strict();

export const LocationCreateOrConnectWithoutUsersInputSchema: z.ZodType<Prisma.LocationCreateOrConnectWithoutUsersInput> = z.object({
  where: z.lazy(() => LocationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => LocationCreateWithoutUsersInputSchema),z.lazy(() => LocationUncheckedCreateWithoutUsersInputSchema) ]),
}).strict();

export const RoleCreateWithoutUsersInputSchema: z.ZodType<Prisma.RoleCreateWithoutUsersInput> = z.object({
  code: z.string(),
  name: z.string(),
  desc: z.string().optional().nullable(),
  isAdmin: z.boolean(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const RoleUncheckedCreateWithoutUsersInputSchema: z.ZodType<Prisma.RoleUncheckedCreateWithoutUsersInput> = z.object({
  id: z.number().int().optional(),
  code: z.string(),
  name: z.string(),
  desc: z.string().optional().nullable(),
  isAdmin: z.boolean(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const RoleCreateOrConnectWithoutUsersInputSchema: z.ZodType<Prisma.RoleCreateOrConnectWithoutUsersInput> = z.object({
  where: z.lazy(() => RoleWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => RoleCreateWithoutUsersInputSchema),z.lazy(() => RoleUncheckedCreateWithoutUsersInputSchema) ]),
}).strict();

export const BookingHistoryCreateWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryCreateWithoutUserInput> = z.object({
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean(),
  booking: z.lazy(() => BookingCreateNestedOneWithoutHistoryInputSchema),
  location: z.lazy(() => LocationCreateNestedOneWithoutBookingHistoryInputSchema).optional()
}).strict();

export const BookingHistoryUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedCreateWithoutUserInput> = z.object({
  id: z.number().int().optional(),
  bookingId: z.number().int(),
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean()
}).strict();

export const BookingHistoryCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const BookingHistoryCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.BookingHistoryCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => BookingHistoryCreateManyUserInputSchema),z.lazy(() => BookingHistoryCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const StatementCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementCreateWithoutCreatedByInput> = z.object({
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  client: z.lazy(() => ClientCreateNestedOneWithoutStatementsInputSchema),
  bookings: z.lazy(() => BookingCreateNestedManyWithoutStatementInputSchema).optional()
}).strict();

export const StatementUncheckedCreateWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementUncheckedCreateWithoutCreatedByInput> = z.object({
  id: z.number().int().optional(),
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  clientId: z.number().int(),
  bookings: z.lazy(() => BookingUncheckedCreateNestedManyWithoutStatementInputSchema).optional()
}).strict();

export const StatementCreateOrConnectWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementCreateOrConnectWithoutCreatedByInput> = z.object({
  where: z.lazy(() => StatementWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => StatementCreateWithoutCreatedByInputSchema),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const StatementCreateManyCreatedByInputEnvelopeSchema: z.ZodType<Prisma.StatementCreateManyCreatedByInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => StatementCreateManyCreatedByInputSchema),z.lazy(() => StatementCreateManyCreatedByInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const BaggageUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BaggageUpdateWithoutCreatedByInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => BaggageCreateWithoutCreatedByInputSchema),z.lazy(() => BaggageUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const BaggageUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BaggageWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateWithoutCreatedByInputSchema),z.lazy(() => BaggageUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const BaggageUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BaggageScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BaggageUpdateManyMutationInputSchema),z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingCommentUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BookingCommentWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingCommentUpdateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCommentCreateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingCommentUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BookingCommentWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingCommentUpdateWithoutCreatedByInputSchema),z.lazy(() => BookingCommentUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingCommentUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BookingCommentScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingCommentUpdateManyMutationInputSchema),z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutCreatedByInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutCreatedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutCreatedByInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingUpsertWithWhereUniqueWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingUpsertWithWhereUniqueWithoutVerifiedByInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingUpdateWithoutVerifiedByInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutVerifiedByInputSchema) ]),
  create: z.union([ z.lazy(() => BookingCreateWithoutVerifiedByInputSchema),z.lazy(() => BookingUncheckedCreateWithoutVerifiedByInputSchema) ]),
}).strict();

export const BookingUpdateWithWhereUniqueWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingUpdateWithWhereUniqueWithoutVerifiedByInput> = z.object({
  where: z.lazy(() => BookingWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateWithoutVerifiedByInputSchema),z.lazy(() => BookingUncheckedUpdateWithoutVerifiedByInputSchema) ]),
}).strict();

export const BookingUpdateManyWithWhereWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingUpdateManyWithWhereWithoutVerifiedByInput> = z.object({
  where: z.lazy(() => BookingScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingUpdateManyMutationInputSchema),z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByInputSchema) ]),
}).strict();

export const ClientUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ClientUpdateWithoutCreatedByInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => ClientCreateWithoutCreatedByInputSchema),z.lazy(() => ClientUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const ClientUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ClientWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ClientUpdateWithoutCreatedByInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const ClientUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ClientScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ClientUpdateManyMutationInputSchema),z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const DeliveryUpsertWithWhereUniqueWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryUpsertWithWhereUniqueWithoutDeliveredByInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => DeliveryUpdateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutDeliveredByInputSchema) ]),
  create: z.union([ z.lazy(() => DeliveryCreateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutDeliveredByInputSchema) ]),
}).strict();

export const DeliveryUpdateWithWhereUniqueWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryUpdateWithWhereUniqueWithoutDeliveredByInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => DeliveryUpdateWithoutDeliveredByInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutDeliveredByInputSchema) ]),
}).strict();

export const DeliveryUpdateManyWithWhereWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryUpdateManyWithWhereWithoutDeliveredByInput> = z.object({
  where: z.lazy(() => DeliveryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => DeliveryUpdateManyMutationInputSchema),z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByInputSchema) ]),
}).strict();

export const DeliveryUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => DeliveryUpdateWithoutCreatedByInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => DeliveryCreateWithoutCreatedByInputSchema),z.lazy(() => DeliveryUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const DeliveryUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => DeliveryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => DeliveryUpdateWithoutCreatedByInputSchema),z.lazy(() => DeliveryUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const DeliveryUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => DeliveryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => DeliveryUpdateManyMutationInputSchema),z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const ManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ManifestUpdateWithoutCreatedByInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutCreatedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const ManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutCreatedByInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const ManifestUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateManyMutationInputSchema),z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const ManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestUpsertWithWhereUniqueWithoutDispatchedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ManifestUpdateWithoutDispatchedByInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutDispatchedByInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutDispatchedByInputSchema) ]),
}).strict();

export const ManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestUpdateWithWhereUniqueWithoutDispatchedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutDispatchedByInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutDispatchedByInputSchema) ]),
}).strict();

export const ManifestUpdateManyWithWhereWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithWhereWithoutDispatchedByInput> = z.object({
  where: z.lazy(() => ManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateManyMutationInputSchema),z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByInputSchema) ]),
}).strict();

export const ManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestUpsertWithWhereUniqueWithoutReceivedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ManifestUpdateWithoutReceivedByInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutReceivedByInputSchema) ]),
  create: z.union([ z.lazy(() => ManifestCreateWithoutReceivedByInputSchema),z.lazy(() => ManifestUncheckedCreateWithoutReceivedByInputSchema) ]),
}).strict();

export const ManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestUpdateWithWhereUniqueWithoutReceivedByInput> = z.object({
  where: z.lazy(() => ManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateWithoutReceivedByInputSchema),z.lazy(() => ManifestUncheckedUpdateWithoutReceivedByInputSchema) ]),
}).strict();

export const ManifestUpdateManyWithWhereWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestUpdateManyWithWhereWithoutReceivedByInput> = z.object({
  where: z.lazy(() => ManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ManifestUpdateManyMutationInputSchema),z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByInputSchema) ]),
}).strict();

export const MasterManifestUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const MasterManifestUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateWithoutCreatedByInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const MasterManifestUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => MasterManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateManyMutationInputSchema),z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const MasterManifestUpsertWithWhereUniqueWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestUpsertWithWhereUniqueWithoutDispatchedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutDispatchedByInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutDispatchedByInputSchema) ]),
}).strict();

export const MasterManifestUpdateWithWhereUniqueWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithWhereUniqueWithoutDispatchedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateWithoutDispatchedByInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutDispatchedByInputSchema) ]),
}).strict();

export const MasterManifestUpdateManyWithWhereWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithWhereWithoutDispatchedByInput> = z.object({
  where: z.lazy(() => MasterManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateManyMutationInputSchema),z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByInputSchema) ]),
}).strict();

export const MasterManifestUpsertWithWhereUniqueWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestUpsertWithWhereUniqueWithoutReceivedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => MasterManifestUpdateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutReceivedByInputSchema) ]),
  create: z.union([ z.lazy(() => MasterManifestCreateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUncheckedCreateWithoutReceivedByInputSchema) ]),
}).strict();

export const MasterManifestUpdateWithWhereUniqueWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithWhereUniqueWithoutReceivedByInput> = z.object({
  where: z.lazy(() => MasterManifestWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateWithoutReceivedByInputSchema),z.lazy(() => MasterManifestUncheckedUpdateWithoutReceivedByInputSchema) ]),
}).strict();

export const MasterManifestUpdateManyWithWhereWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateManyWithWhereWithoutReceivedByInput> = z.object({
  where: z.lazy(() => MasterManifestScalarWhereInputSchema),
  data: z.union([ z.lazy(() => MasterManifestUpdateManyMutationInputSchema),z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByInputSchema) ]),
}).strict();

export const ProductTypeUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ProductTypeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => ProductTypeUpdateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => ProductTypeCreateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const ProductTypeUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ProductTypeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => ProductTypeUpdateWithoutCreatedByInputSchema),z.lazy(() => ProductTypeUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const ProductTypeUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => ProductTypeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => ProductTypeUpdateManyMutationInputSchema),z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const ProductTypeScalarWhereInputSchema: z.ZodType<Prisma.ProductTypeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ProductTypeScalarWhereInputSchema),z.lazy(() => ProductTypeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ProductTypeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ProductTypeScalarWhereInputSchema),z.lazy(() => ProductTypeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  deletedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdById: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  unit: z.union([ z.lazy(() => EnumUnitFilterSchema),z.lazy(() => UnitSchema) ]).optional(),
}).strict();

export const ClientUpsertWithoutUserInputSchema: z.ZodType<Prisma.ClientUpsertWithoutUserInput> = z.object({
  update: z.union([ z.lazy(() => ClientUpdateWithoutUserInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => ClientCreateWithoutUserInputSchema),z.lazy(() => ClientUncheckedCreateWithoutUserInputSchema) ]),
  where: z.lazy(() => ClientWhereInputSchema).optional()
}).strict();

export const ClientUpdateToOneWithWhereWithoutUserInputSchema: z.ZodType<Prisma.ClientUpdateToOneWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => ClientWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => ClientUpdateWithoutUserInputSchema),z.lazy(() => ClientUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const ClientUpdateWithoutUserInputSchema: z.ZodType<Prisma.ClientUpdateWithoutUserInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUpdateManyWithoutClientNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedClientsNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneWithoutClientsNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUncheckedUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const LocationUpsertWithoutUsersInputSchema: z.ZodType<Prisma.LocationUpsertWithoutUsersInput> = z.object({
  update: z.union([ z.lazy(() => LocationUpdateWithoutUsersInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutUsersInputSchema) ]),
  create: z.union([ z.lazy(() => LocationCreateWithoutUsersInputSchema),z.lazy(() => LocationUncheckedCreateWithoutUsersInputSchema) ]),
  where: z.lazy(() => LocationWhereInputSchema).optional()
}).strict();

export const LocationUpdateToOneWithWhereWithoutUsersInputSchema: z.ZodType<Prisma.LocationUpdateToOneWithWhereWithoutUsersInput> = z.object({
  where: z.lazy(() => LocationWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => LocationUpdateWithoutUsersInputSchema),z.lazy(() => LocationUncheckedUpdateWithoutUsersInputSchema) ]),
}).strict();

export const LocationUpdateWithoutUsersInputSchema: z.ZodType<Prisma.LocationUpdateWithoutUsersInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  district: z.lazy(() => DistrictUpdateOneWithoutLocationsNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutUsersInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutUsersInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  districtId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const RoleUpsertWithoutUsersInputSchema: z.ZodType<Prisma.RoleUpsertWithoutUsersInput> = z.object({
  update: z.union([ z.lazy(() => RoleUpdateWithoutUsersInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutUsersInputSchema) ]),
  create: z.union([ z.lazy(() => RoleCreateWithoutUsersInputSchema),z.lazy(() => RoleUncheckedCreateWithoutUsersInputSchema) ]),
  where: z.lazy(() => RoleWhereInputSchema).optional()
}).strict();

export const RoleUpdateToOneWithWhereWithoutUsersInputSchema: z.ZodType<Prisma.RoleUpdateToOneWithWhereWithoutUsersInput> = z.object({
  where: z.lazy(() => RoleWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => RoleUpdateWithoutUsersInputSchema),z.lazy(() => RoleUncheckedUpdateWithoutUsersInputSchema) ]),
}).strict();

export const RoleUpdateWithoutUsersInputSchema: z.ZodType<Prisma.RoleUpdateWithoutUsersInput> = z.object({
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdmin: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RoleUncheckedUpdateWithoutUsersInputSchema: z.ZodType<Prisma.RoleUncheckedUpdateWithoutUsersInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdmin: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingHistoryUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => BookingHistoryUpdateWithoutUserInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => BookingHistoryCreateWithoutUserInputSchema),z.lazy(() => BookingHistoryUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const BookingHistoryUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => BookingHistoryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => BookingHistoryUpdateWithoutUserInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const BookingHistoryUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => BookingHistoryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => BookingHistoryUpdateManyMutationInputSchema),z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const StatementUpsertWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementUpsertWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => StatementWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => StatementUpdateWithoutCreatedByInputSchema),z.lazy(() => StatementUncheckedUpdateWithoutCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => StatementCreateWithoutCreatedByInputSchema),z.lazy(() => StatementUncheckedCreateWithoutCreatedByInputSchema) ]),
}).strict();

export const StatementUpdateWithWhereUniqueWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementUpdateWithWhereUniqueWithoutCreatedByInput> = z.object({
  where: z.lazy(() => StatementWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => StatementUpdateWithoutCreatedByInputSchema),z.lazy(() => StatementUncheckedUpdateWithoutCreatedByInputSchema) ]),
}).strict();

export const StatementUpdateManyWithWhereWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementUpdateManyWithWhereWithoutCreatedByInput> = z.object({
  where: z.lazy(() => StatementScalarWhereInputSchema),
  data: z.union([ z.lazy(() => StatementUpdateManyMutationInputSchema),z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByInputSchema) ]),
}).strict();

export const BookingCreateManyBaggageInputSchema: z.ZodType<Prisma.BookingCreateManyBaggageInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BookingUpdateWithoutBaggageInputSchema: z.ZodType<Prisma.BookingUpdateWithoutBaggageInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutBaggageInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutBaggageInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutBaggageInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutBaggageInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingCommentCreateManyBookingInputSchema: z.ZodType<Prisma.BookingCommentCreateManyBookingInput> = z.object({
  id: z.number().int().optional(),
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int().optional().nullable()
}).strict();

export const BookingDeliveryCreateManyBookingInputSchema: z.ZodType<Prisma.BookingDeliveryCreateManyBookingInput> = z.object({
  id: z.number().int().optional(),
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveryId: z.number().int()
}).strict();

export const BookingItemCreateManyBookingInputSchema: z.ZodType<Prisma.BookingItemCreateManyBookingInput> = z.object({
  id: z.number().int().optional(),
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  productTypeId: z.number().int()
}).strict();

export const BookingHistoryCreateManyBookingInputSchema: z.ZodType<Prisma.BookingHistoryCreateManyBookingInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  userId: z.number().int().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean()
}).strict();

export const BookingCommentUpdateWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentUpdateWithoutBookingInput> = z.object({
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBy: z.lazy(() => UserUpdateOneWithoutBookingCommentsNestedInputSchema).optional()
}).strict();

export const BookingCommentUncheckedUpdateWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentUncheckedUpdateWithoutBookingInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingCommentUncheckedUpdateManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingCommentUncheckedUpdateManyWithoutBookingInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingDeliveryUpdateWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateWithoutBookingInput> = z.object({
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  delivery: z.lazy(() => DeliveryUpdateOneRequiredWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingDeliveryUncheckedUpdateWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedUpdateWithoutBookingInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveryId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingDeliveryUncheckedUpdateManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedUpdateManyWithoutBookingInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveryId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingItemUpdateWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemUpdateWithoutBookingInput> = z.object({
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  productType: z.lazy(() => ProductTypeUpdateOneRequiredWithoutBookingItemsNestedInputSchema).optional()
}).strict();

export const BookingItemUncheckedUpdateWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemUncheckedUpdateWithoutBookingInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  productTypeId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingItemUncheckedUpdateManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingItemUncheckedUpdateManyWithoutBookingInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  productTypeId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUpdateWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryUpdateWithoutBookingInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutBookingHistoryNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneWithoutBookingHistoryNestedInputSchema).optional()
}).strict();

export const BookingHistoryUncheckedUpdateWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateWithoutBookingInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  userId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUncheckedUpdateManyWithoutBookingInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateManyWithoutBookingInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  userId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingCreateManyClientInputSchema: z.ZodType<Prisma.BookingCreateManyClientInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const StatementCreateManyClientInputSchema: z.ZodType<Prisma.StatementCreateManyClientInput> = z.object({
  id: z.number().int().optional(),
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int()
}).strict();

export const UserCreateManyClientInputSchema: z.ZodType<Prisma.UserCreateManyClientInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  roleId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable()
}).strict();

export const BookingUpdateWithoutClientInputSchema: z.ZodType<Prisma.BookingUpdateWithoutClientInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutClientInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutClientInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutClientInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutClientInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const StatementUpdateWithoutClientInputSchema: z.ZodType<Prisma.StatementUpdateWithoutClientInput> = z.object({
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUpdateManyWithoutStatementNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutStatementNestedInputSchema).optional()
}).strict();

export const StatementUncheckedUpdateWithoutClientInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateWithoutClientInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutStatementNestedInputSchema).optional()
}).strict();

export const StatementUncheckedUpdateManyWithoutClientInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateManyWithoutClientInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const UserUpdateWithoutClientInputSchema: z.ZodType<Prisma.UserUpdateWithoutClientInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutClientInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutClientInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateManyWithoutClientInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutClientInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingDeliveryCreateManyDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryCreateManyDeliveryInput> = z.object({
  id: z.number().int().optional(),
  status: z.lazy(() => BookingDeliveryStatusSchema).optional(),
  comment: z.string().optional().nullable(),
  deliveredAt: z.coerce.date().optional().nullable(),
  receivedBy: z.string().optional().nullable(),
  podPath: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int()
}).strict();

export const BookingDeliveryUpdateWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryUpdateWithoutDeliveryInput> = z.object({
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  booking: z.lazy(() => BookingUpdateOneRequiredWithoutDeliveriesNestedInputSchema).optional()
}).strict();

export const BookingDeliveryUncheckedUpdateWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedUpdateWithoutDeliveryInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingDeliveryUncheckedUpdateManyWithoutDeliveryInputSchema: z.ZodType<Prisma.BookingDeliveryUncheckedUpdateManyWithoutDeliveryInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingDeliveryStatusSchema),z.lazy(() => EnumBookingDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  podPath: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const LocationCreateManyDistrictInputSchema: z.ZodType<Prisma.LocationCreateManyDistrictInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  code: z.string(),
  contactName: z.string(),
  contactNo: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const LocationUpdateWithoutDistrictInputSchema: z.ZodType<Prisma.LocationUpdateWithoutDistrictInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateWithoutDistrictInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateWithoutDistrictInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  currentBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  currentBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  clients: z.lazy(() => ClientUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  currentManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentLocationNestedInputSchema).optional(),
  originManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  originRates: z.lazy(() => RateUncheckedUpdateManyWithoutOriginNestedInputSchema).optional(),
  destinationRates: z.lazy(() => RateUncheckedUpdateManyWithoutDestinationNestedInputSchema).optional(),
  subLocations: z.lazy(() => SubLocationUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  deliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutLocationNestedInputSchema).optional()
}).strict();

export const LocationUncheckedUpdateManyWithoutDistrictInputSchema: z.ZodType<Prisma.LocationUncheckedUpdateManyWithoutDistrictInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  code: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BaggageCreateManyOriginInputSchema: z.ZodType<Prisma.BaggageCreateManyOriginInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BaggageCreateManyDestinationInputSchema: z.ZodType<Prisma.BaggageCreateManyDestinationInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BookingCreateManyCurrentLocationInputSchema: z.ZodType<Prisma.BookingCreateManyCurrentLocationInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable()
}).strict();

export const BaggageCreateManyCurrentLocationInputSchema: z.ZodType<Prisma.BaggageCreateManyCurrentLocationInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int()
}).strict();

export const BookingCreateManyOriginInputSchema: z.ZodType<Prisma.BookingCreateManyOriginInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BookingCreateManyDestinationInputSchema: z.ZodType<Prisma.BookingCreateManyDestinationInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const ClientCreateManyLocationInputSchema: z.ZodType<Prisma.ClientCreateManyLocationInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int()
}).strict();

export const ManifestCreateManyCurrentLocationInputSchema: z.ZodType<Prisma.ManifestCreateManyCurrentLocationInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const ManifestCreateManyOriginInputSchema: z.ZodType<Prisma.ManifestCreateManyOriginInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const ManifestCreateManyDestinationInputSchema: z.ZodType<Prisma.ManifestCreateManyDestinationInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const MasterManifestCreateManyOriginInputSchema: z.ZodType<Prisma.MasterManifestCreateManyOriginInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const MasterManifestCreateManyDestinationInputSchema: z.ZodType<Prisma.MasterManifestCreateManyDestinationInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const RateCreateManyOriginInputSchema: z.ZodType<Prisma.RateCreateManyOriginInput> = z.object({
  id: z.number().int().optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  destinationId: z.number().int().optional().nullable()
}).strict();

export const RateCreateManyDestinationInputSchema: z.ZodType<Prisma.RateCreateManyDestinationInput> = z.object({
  id: z.number().int().optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int().optional().nullable()
}).strict();

export const SubLocationCreateManyLocationInputSchema: z.ZodType<Prisma.SubLocationCreateManyLocationInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable()
}).strict();

export const UserCreateManyLocationInputSchema: z.ZodType<Prisma.UserCreateManyLocationInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  roleId: z.number().int().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable()
}).strict();

export const DeliveryCreateManyLocationInputSchema: z.ZodType<Prisma.DeliveryCreateManyLocationInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveredById: z.number().int(),
  createdById: z.number().int()
}).strict();

export const BookingHistoryCreateManyLocationInputSchema: z.ZodType<Prisma.BookingHistoryCreateManyLocationInput> = z.object({
  id: z.number().int().optional(),
  bookingId: z.number().int(),
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  userId: z.number().int().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean()
}).strict();

export const BaggageUpdateWithoutOriginInputSchema: z.ZodType<Prisma.BaggageUpdateWithoutOriginInput> = z.object({
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBaggagesNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBaggagesNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBaggagesNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBaggagesNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateWithoutOriginInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateManyWithoutOriginInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BaggageUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageUpdateWithoutDestinationInput> = z.object({
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBaggagesNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBaggagesNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBaggagesNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBaggagesNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateManyWithoutDestinationInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingUpdateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingUpdateWithoutCurrentLocationInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutCurrentLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutCurrentLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BaggageUpdateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageUpdateWithoutCurrentLocationInput> = z.object({
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBaggagesNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBaggagesNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBaggagesNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBaggagesNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateWithoutCurrentLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutCurrentLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingUpdateWithoutOriginInputSchema: z.ZodType<Prisma.BookingUpdateWithoutOriginInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutOriginInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutOriginInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.BookingUpdateWithoutDestinationInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutDestinationInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ClientUpdateWithoutLocationInputSchema: z.ZodType<Prisma.ClientUpdateWithoutLocationInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUpdateManyWithoutClientNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedClientsNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientUncheckedUpdateWithoutLocationInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUncheckedUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientUncheckedUpdateManyWithoutLocationInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateManyWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const ManifestUpdateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutCurrentLocationInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutCurrentLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateManyWithoutCurrentLocationInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutCurrentLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ManifestUpdateWithoutOriginInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutOriginInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutOriginInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateManyWithoutOriginInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ManifestUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutDestinationInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateManyWithoutDestinationInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestUpdateWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithoutOriginInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUpdateManyWithoutMasterManifestNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedMasterManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutOriginInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithoutDestinationInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUpdateManyWithoutMasterManifestNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedMasterManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutDestinationInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RateUpdateWithoutOriginInputSchema: z.ZodType<Prisma.RateUpdateWithoutOriginInput> = z.object({
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destination: z.lazy(() => LocationUpdateOneWithoutDestinationRatesNestedInputSchema).optional()
}).strict();

export const RateUncheckedUpdateWithoutOriginInputSchema: z.ZodType<Prisma.RateUncheckedUpdateWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RateUncheckedUpdateManyWithoutOriginInputSchema: z.ZodType<Prisma.RateUncheckedUpdateManyWithoutOriginInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  destinationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RateUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.RateUpdateWithoutDestinationInput> = z.object({
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  origin: z.lazy(() => LocationUpdateOneWithoutOriginRatesNestedInputSchema).optional()
}).strict();

export const RateUncheckedUpdateWithoutDestinationInputSchema: z.ZodType<Prisma.RateUncheckedUpdateWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const RateUncheckedUpdateManyWithoutDestinationInputSchema: z.ZodType<Prisma.RateUncheckedUpdateManyWithoutDestinationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SubLocationUpdateWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationUpdateWithoutLocationInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SubLocationUncheckedUpdateWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationUncheckedUpdateWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SubLocationUncheckedUpdateManyWithoutLocationInputSchema: z.ZodType<Prisma.SubLocationUncheckedUpdateManyWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const UserUpdateWithoutLocationInputSchema: z.ZodType<Prisma.UserUpdateWithoutLocationInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  role: z.lazy(() => RoleUpdateOneWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutLocationInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateManyWithoutLocationInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  roleId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DeliveryUpdateWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryUpdateWithoutLocationInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingDeliveryUpdateManyWithoutDeliveryNestedInputSchema).optional(),
  deliveredBy: z.lazy(() => UserUpdateOneRequiredWithoutDeliveredDeliveriesNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedDeliveriesNestedInputSchema).optional()
}).strict();

export const DeliveryUncheckedUpdateWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutDeliveryNestedInputSchema).optional()
}).strict();

export const DeliveryUncheckedUpdateManyWithoutLocationInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateManyWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUpdateWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryUpdateWithoutLocationInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  booking: z.lazy(() => BookingUpdateOneRequiredWithoutHistoryNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneWithoutBookingHistoryNestedInputSchema).optional()
}).strict();

export const BookingHistoryUncheckedUpdateWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  userId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUncheckedUpdateManyWithoutLocationInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateManyWithoutLocationInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  userId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BaggageCreateManyManifestInputSchema: z.ZodType<Prisma.BaggageCreateManyManifestInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BookingCreateManyManifestInputSchema: z.ZodType<Prisma.BookingCreateManyManifestInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const MasterManifestDetailCreateManyManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateManyManifestInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  masterManifestId: z.number().int()
}).strict();

export const BaggageUpdateWithoutManifestInputSchema: z.ZodType<Prisma.BaggageUpdateWithoutManifestInput> = z.object({
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBaggagesNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBaggagesNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBaggagesNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBaggagesNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateWithoutManifestInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateWithoutManifestInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateManyWithoutManifestInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutManifestInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingUpdateWithoutManifestInputSchema: z.ZodType<Prisma.BookingUpdateWithoutManifestInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutManifestInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutManifestInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutManifestInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutManifestInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestDetailUpdateWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateWithoutManifestInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifest: z.lazy(() => MasterManifestUpdateOneRequiredWithoutDetailsNestedInputSchema).optional()
}).strict();

export const MasterManifestDetailUncheckedUpdateWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedUpdateWithoutManifestInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const MasterManifestDetailUncheckedUpdateManyWithoutManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedUpdateManyWithoutManifestInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const ManifestCreateManyCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestCreateManyCurrentMasterManifestInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const MasterManifestDetailCreateManyMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailCreateManyMasterManifestInput> = z.object({
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  manifestId: z.number().int()
}).strict();

export const ManifestUpdateWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutCurrentMasterManifestInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutCurrentMasterManifestInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateManyWithoutCurrentMasterManifestInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutCurrentMasterManifestInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestDetailUpdateWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUpdateWithoutMasterManifestInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  manifest: z.lazy(() => ManifestUpdateOneRequiredWithoutMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestDetailUncheckedUpdateWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedUpdateWithoutMasterManifestInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestInputSchema: z.ZodType<Prisma.MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestInput> = z.object({
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  manifestId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingItemCreateManyProductTypeInputSchema: z.ZodType<Prisma.BookingItemCreateManyProductTypeInput> = z.object({
  id: z.number().int().optional(),
  desc: z.string(),
  piece: z.number().int(),
  weight: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  unit: z.lazy(() => UnitSchema).optional(),
  rate: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  total: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int().optional().nullable()
}).strict();

export const BookingItemUpdateWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemUpdateWithoutProductTypeInput> = z.object({
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  booking: z.lazy(() => BookingUpdateOneWithoutItemsNestedInputSchema).optional()
}).strict();

export const BookingItemUncheckedUpdateWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemUncheckedUpdateWithoutProductTypeInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingItemUncheckedUpdateManyWithoutProductTypeInputSchema: z.ZodType<Prisma.BookingItemUncheckedUpdateManyWithoutProductTypeInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  desc: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  piece: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  weight: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  total: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const UserCreateManyRoleInputSchema: z.ZodType<Prisma.UserCreateManyRoleInput> = z.object({
  id: z.number().int().optional(),
  firstName: z.string().optional().nullable(),
  middleName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string(),
  phone: z.string(),
  userName: z.string(),
  password: z.string().optional().nullable(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  passwordToken: z.string().optional().nullable(),
  passwordTokenExpiration: z.coerce.date().optional().nullable()
}).strict();

export const UserUpdateWithoutRoleInputSchema: z.ZodType<Prisma.UserUpdateWithoutRoleInput> = z.object({
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutUserNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutUsersNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutRoleInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutRoleInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdBaggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingComments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  verifiedBookings: z.lazy(() => BookingUncheckedUpdateManyWithoutVerifiedByNestedInputSchema).optional(),
  createdClients: z.lazy(() => ClientUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  deliveredDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutDeliveredByNestedInputSchema).optional(),
  createdDeliveries: z.lazy(() => DeliveryUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  createdManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedManifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  dispatchedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutDispatchedByNestedInputSchema).optional(),
  receivedMasterManifests: z.lazy(() => MasterManifestUncheckedUpdateManyWithoutReceivedByNestedInputSchema).optional(),
  createdProductTypes: z.lazy(() => ProductTypeUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional(),
  bookingHistory: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Statement: z.lazy(() => StatementUncheckedUpdateManyWithoutCreatedByNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateManyWithoutRoleInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutRoleInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  middleName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingCreateManyStatementInputSchema: z.ZodType<Prisma.BookingCreateManyStatementInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BookingUpdateWithoutStatementInputSchema: z.ZodType<Prisma.BookingUpdateWithoutStatementInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutStatementInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutStatementInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutStatementInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutStatementInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BaggageCreateManyCreatedByInputSchema: z.ZodType<Prisma.BaggageCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  baggageNo: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BookingCommentCreateManyCreatedByInputSchema: z.ZodType<Prisma.BookingCommentCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  comment: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  bookingId: z.number().int()
}).strict();

export const BookingCreateManyCreatedByInputSchema: z.ZodType<Prisma.BookingCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedById: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const BookingCreateManyVerifiedByInputSchema: z.ZodType<Prisma.BookingCreateManyVerifiedByInput> = z.object({
  id: z.number().int().optional(),
  bookingDate: z.coerce.date().optional(),
  consignmentNo: z.string(),
  senderName: z.string(),
  senderContactNo: z.string().optional().nullable(),
  senderEmail: z.string().optional().nullable(),
  senderPan: z.string().optional().nullable(),
  receiverName: z.string(),
  receiverContactNo: z.string().optional().nullable(),
  receiverAddr: z.string().optional().nullable(),
  receiverPan: z.string().optional().nullable(),
  serviceType: z.lazy(() => ServiceTypeSchema),
  deliveryMode: z.lazy(() => DeliveryModeSchema).optional().nullable(),
  declaredValue: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  refNo: z.string().optional().nullable(),
  invoiceDate: z.coerce.date().optional().nullable(),
  instruction: z.string().optional().nullable(),
  paymentMode: z.lazy(() => PaymentModeSchema).optional(),
  otherCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  tax: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  stCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  totAmt: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  status: z.lazy(() => BookingStatusSchema),
  comment: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int(),
  clientId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  baggageId: z.number().int().optional().nullable(),
  manifestId: z.number().int().optional().nullable(),
  verifiedAt: z.coerce.date().optional().nullable(),
  cod: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional().nullable(),
  laborCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  pickupCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  deliveryCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  volumeCharge: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  discount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }).optional(),
  statementId: z.number().int().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable()
}).strict();

export const ClientCreateManyCreatedByInputSchema: z.ZodType<Prisma.ClientCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  contactPerson: z.string(),
  contactNo: z.string(),
  email: z.string(),
  address: z.string(),
  pan: z.string(),
  status: z.boolean(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  locationId: z.number().int().optional().nullable()
}).strict();

export const DeliveryCreateManyDeliveredByInputSchema: z.ZodType<Prisma.DeliveryCreateManyDeliveredByInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  locationId: z.number().int(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  createdById: z.number().int()
}).strict();

export const DeliveryCreateManyCreatedByInputSchema: z.ZodType<Prisma.DeliveryCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  date: z.coerce.date().optional(),
  locationId: z.number().int(),
  status: z.lazy(() => DeliveryStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  deliveredById: z.number().int()
}).strict();

export const ManifestCreateManyCreatedByInputSchema: z.ZodType<Prisma.ManifestCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const ManifestCreateManyDispatchedByInputSchema: z.ZodType<Prisma.ManifestCreateManyDispatchedByInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const ManifestCreateManyReceivedByInputSchema: z.ZodType<Prisma.ManifestCreateManyReceivedByInput> = z.object({
  id: z.number().int().optional(),
  manifestNo: z.string(),
  date: z.coerce.date().optional(),
  status: z.lazy(() => ManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  currentLocationId: z.number().int().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  masterManifestId: z.number().int().optional().nullable(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable()
}).strict();

export const MasterManifestCreateManyCreatedByInputSchema: z.ZodType<Prisma.MasterManifestCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  dispatchedById: z.number().int().optional().nullable(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const MasterManifestCreateManyDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestCreateManyDispatchedByInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  receivedById: z.number().int().optional().nullable()
}).strict();

export const MasterManifestCreateManyReceivedByInputSchema: z.ZodType<Prisma.MasterManifestCreateManyReceivedByInput> = z.object({
  id: z.number().int().optional(),
  masterManifestNo: z.string(),
  date: z.coerce.date().optional(),
  vehicleNo: z.string(),
  driverName: z.string(),
  mobileNo: z.string(),
  freight: z.number(),
  advance: z.number().optional(),
  balance: z.number().optional(),
  status: z.lazy(() => MasterManifestStatusSchema),
  checkedBy: z.string().optional().nullable(),
  dispatchedAt: z.coerce.date().optional().nullable(),
  receivedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  originId: z.number().int(),
  destinationId: z.number().int(),
  createdById: z.number().int(),
  dispatchedById: z.number().int().optional().nullable()
}).strict();

export const ProductTypeCreateManyCreatedByInputSchema: z.ZodType<Prisma.ProductTypeCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  status: z.boolean().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  unit: z.lazy(() => UnitSchema).optional()
}).strict();

export const BookingHistoryCreateManyUserInputSchema: z.ZodType<Prisma.BookingHistoryCreateManyUserInput> = z.object({
  id: z.number().int().optional(),
  bookingId: z.number().int(),
  date: z.coerce.date().optional(),
  action: z.lazy(() => BookingActionSchema),
  refId: z.number().int().optional().nullable(),
  refNo: z.string().optional().nullable(),
  locationId: z.number().int().optional().nullable(),
  comment: z.string().optional().nullable(),
  customer: z.boolean()
}).strict();

export const StatementCreateManyCreatedByInputSchema: z.ZodType<Prisma.StatementCreateManyCreatedByInput> = z.object({
  id: z.number().int().optional(),
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  amount: z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional().nullable(),
  clientId: z.number().int()
}).strict();

export const BaggageUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageUpdateWithoutCreatedByInput> = z.object({
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBaggagesNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBaggagesNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBaggagesNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBaggagesNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutBaggageNestedInputSchema).optional()
}).strict();

export const BaggageUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BaggageUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingCommentUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentUpdateWithoutCreatedByInput> = z.object({
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  booking: z.lazy(() => BookingUpdateOneRequiredWithoutCommentsNestedInputSchema).optional()
}).strict();

export const BookingCommentUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingCommentUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingCommentUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingUpdateWithoutCreatedByInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  verifiedBy: z.lazy(() => UserUpdateOneWithoutVerifiedBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BookingUpdateWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingUpdateWithoutVerifiedByInput> = z.object({
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  comments: z.lazy(() => BookingCommentUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUpdateManyWithoutBookingNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedBookingsNestedInputSchema).optional(),
  client: z.lazy(() => ClientUpdateOneWithoutBookingsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginBookingsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationBookingsNestedInputSchema).optional(),
  baggage: z.lazy(() => BaggageUpdateOneWithoutBookingsNestedInputSchema).optional(),
  manifest: z.lazy(() => ManifestUpdateOneWithoutBookingsNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentBookingsNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUpdateManyWithoutBookingNestedInputSchema).optional(),
  statement: z.lazy(() => StatementUpdateOneWithoutBookingsNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateWithoutVerifiedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comments: z.lazy(() => BookingCommentUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  deliveries: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  items: z.lazy(() => BookingItemUncheckedUpdateManyWithoutBookingNestedInputSchema).optional(),
  history: z.lazy(() => BookingHistoryUncheckedUpdateManyWithoutBookingNestedInputSchema).optional()
}).strict();

export const BookingUncheckedUpdateManyWithoutVerifiedByInputSchema: z.ZodType<Prisma.BookingUncheckedUpdateManyWithoutVerifiedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  consignmentNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderEmail: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  senderPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverContactNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverAddr: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receiverPan: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  serviceType: z.union([ z.lazy(() => ServiceTypeSchema),z.lazy(() => EnumServiceTypeFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryMode: z.union([ z.lazy(() => DeliveryModeSchema),z.lazy(() => NullableEnumDeliveryModeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  declaredValue: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  invoiceDate: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  instruction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  paymentMode: z.union([ z.lazy(() => PaymentModeSchema),z.lazy(() => EnumPaymentModeFieldUpdateOperationsInputSchema) ]).optional(),
  otherCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  tax: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  stCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  totAmt: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => BookingStatusSchema),z.lazy(() => EnumBookingStatusFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  clientId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  baggageId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  verifiedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cod: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => NullableDecimalFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  laborCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  pickupCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  deliveryCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  volumeCharge: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  discount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  statementId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ClientUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientUpdateWithoutCreatedByInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUpdateManyWithoutClientNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneWithoutClientsNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  statements: z.lazy(() => StatementUncheckedUpdateManyWithoutClientNestedInputSchema).optional(),
  user: z.lazy(() => UserUncheckedUpdateManyWithoutClientNestedInputSchema).optional()
}).strict();

export const ClientUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ClientUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactPerson: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contactNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pan: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DeliveryUpdateWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryUpdateWithoutDeliveredByInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingDeliveryUpdateManyWithoutDeliveryNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutDeliveriesNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedDeliveriesNestedInputSchema).optional()
}).strict();

export const DeliveryUncheckedUpdateWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateWithoutDeliveredByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutDeliveryNestedInputSchema).optional()
}).strict();

export const DeliveryUncheckedUpdateManyWithoutDeliveredByInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateManyWithoutDeliveredByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DeliveryUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryUpdateWithoutCreatedByInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bookings: z.lazy(() => BookingDeliveryUpdateManyWithoutDeliveryNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneRequiredWithoutDeliveriesNestedInputSchema).optional(),
  deliveredBy: z.lazy(() => UserUpdateOneRequiredWithoutDeliveredDeliveriesNestedInputSchema).optional()
}).strict();

export const DeliveryUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingDeliveryUncheckedUpdateManyWithoutDeliveryNestedInputSchema).optional()
}).strict();

export const DeliveryUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.DeliveryUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  locationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => DeliveryStatusSchema),z.lazy(() => EnumDeliveryStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deliveredById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const ManifestUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutCreatedByInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ManifestUpdateWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutDispatchedByInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutDispatchedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateManyWithoutDispatchedByInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutDispatchedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ManifestUpdateWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestUpdateWithoutReceivedByInput> = z.object({
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutManifestNestedInputSchema).optional(),
  currentLocation: z.lazy(() => LocationUpdateOneWithoutCurrentManifestsNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationManifestsNestedInputSchema).optional(),
  currentMasterManifest: z.lazy(() => MasterManifestUpdateOneWithoutManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedManifestsNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateWithoutReceivedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  baggages: z.lazy(() => BaggageUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutManifestNestedInputSchema).optional(),
  masterManifests: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutManifestNestedInputSchema).optional()
}).strict();

export const ManifestUncheckedUpdateManyWithoutReceivedByInputSchema: z.ZodType<Prisma.ManifestUncheckedUpdateManyWithoutReceivedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  manifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => ManifestStatusSchema),z.lazy(() => EnumManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  currentLocationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithoutCreatedByInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUpdateManyWithoutMasterManifestNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedMasterManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestUpdateWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithoutDispatchedByInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUpdateManyWithoutMasterManifestNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInputSchema).optional(),
  receivedBy: z.lazy(() => UserUpdateOneWithoutReceivedMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateWithoutDispatchedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutDispatchedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutDispatchedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  receivedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const MasterManifestUpdateWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestUpdateWithoutReceivedByInput> = z.object({
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUpdateManyWithoutMasterManifestNestedInputSchema).optional(),
  origin: z.lazy(() => LocationUpdateOneRequiredWithoutOriginMasterManifestsNestedInputSchema).optional(),
  destination: z.lazy(() => LocationUpdateOneRequiredWithoutDestinationMasterManifestsNestedInputSchema).optional(),
  createdBy: z.lazy(() => UserUpdateOneRequiredWithoutCreatedMasterManifestsNestedInputSchema).optional(),
  dispatchedBy: z.lazy(() => UserUpdateOneWithoutDispatchedMasterManifestsNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateWithoutReceivedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  manifests: z.lazy(() => ManifestUncheckedUpdateManyWithoutCurrentMasterManifestNestedInputSchema).optional(),
  details: z.lazy(() => MasterManifestDetailUncheckedUpdateManyWithoutMasterManifestNestedInputSchema).optional()
}).strict();

export const MasterManifestUncheckedUpdateManyWithoutReceivedByInputSchema: z.ZodType<Prisma.MasterManifestUncheckedUpdateManyWithoutReceivedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  masterManifestNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  vehicleNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  driverName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mobileNo: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  freight: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  advance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  balance: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => MasterManifestStatusSchema),z.lazy(() => EnumMasterManifestStatusFieldUpdateOperationsInputSchema) ]).optional(),
  checkedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  dispatchedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  receivedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  originId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  destinationId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdById: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  dispatchedById: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ProductTypeUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeUpdateWithoutCreatedByInput> = z.object({
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  bookingItems: z.lazy(() => BookingItemUpdateManyWithoutProductTypeNestedInputSchema).optional()
}).strict();

export const ProductTypeUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
  bookingItems: z.lazy(() => BookingItemUncheckedUpdateManyWithoutProductTypeNestedInputSchema).optional()
}).strict();

export const ProductTypeUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.ProductTypeUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  unit: z.union([ z.lazy(() => UnitSchema),z.lazy(() => EnumUnitFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUpdateWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryUpdateWithoutUserInput> = z.object({
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  booking: z.lazy(() => BookingUpdateOneRequiredWithoutHistoryNestedInputSchema).optional(),
  location: z.lazy(() => LocationUpdateOneWithoutBookingHistoryNestedInputSchema).optional()
}).strict();

export const BookingHistoryUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BookingHistoryUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.BookingHistoryUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookingId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  date: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  action: z.union([ z.lazy(() => BookingActionSchema),z.lazy(() => EnumBookingActionFieldUpdateOperationsInputSchema) ]).optional(),
  refId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  refNo: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  locationId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  comment: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  customer: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const StatementUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementUpdateWithoutCreatedByInput> = z.object({
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  client: z.lazy(() => ClientUpdateOneRequiredWithoutStatementsNestedInputSchema).optional(),
  bookings: z.lazy(() => BookingUpdateManyWithoutStatementNestedInputSchema).optional()
}).strict();

export const StatementUncheckedUpdateWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  bookings: z.lazy(() => BookingUncheckedUpdateManyWithoutStatementNestedInputSchema).optional()
}).strict();

export const StatementUncheckedUpdateManyWithoutCreatedByInputSchema: z.ZodType<Prisma.StatementUncheckedUpdateManyWithoutCreatedByInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  fromDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  toDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.union([z.number(),z.string(),z.instanceof(Prisma.Decimal),DecimalJsLikeSchema,]).refine((v) => isValidDecimalInput(v), { message: 'Must be a Decimal' }),z.lazy(() => DecimalFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  clientId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

/////////////////////////////////////////
// ARGS
/////////////////////////////////////////

export const BaggageFindFirstArgsSchema: z.ZodType<Prisma.BaggageFindFirstArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  where: BaggageWhereInputSchema.optional(),
  orderBy: z.union([ BaggageOrderByWithRelationInputSchema.array(),BaggageOrderByWithRelationInputSchema ]).optional(),
  cursor: BaggageWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BaggageScalarFieldEnumSchema,BaggageScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BaggageFindFirstOrThrowArgsSchema: z.ZodType<Prisma.BaggageFindFirstOrThrowArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  where: BaggageWhereInputSchema.optional(),
  orderBy: z.union([ BaggageOrderByWithRelationInputSchema.array(),BaggageOrderByWithRelationInputSchema ]).optional(),
  cursor: BaggageWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BaggageScalarFieldEnumSchema,BaggageScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BaggageFindManyArgsSchema: z.ZodType<Prisma.BaggageFindManyArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  where: BaggageWhereInputSchema.optional(),
  orderBy: z.union([ BaggageOrderByWithRelationInputSchema.array(),BaggageOrderByWithRelationInputSchema ]).optional(),
  cursor: BaggageWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BaggageScalarFieldEnumSchema,BaggageScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BaggageAggregateArgsSchema: z.ZodType<Prisma.BaggageAggregateArgs> = z.object({
  where: BaggageWhereInputSchema.optional(),
  orderBy: z.union([ BaggageOrderByWithRelationInputSchema.array(),BaggageOrderByWithRelationInputSchema ]).optional(),
  cursor: BaggageWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BaggageGroupByArgsSchema: z.ZodType<Prisma.BaggageGroupByArgs> = z.object({
  where: BaggageWhereInputSchema.optional(),
  orderBy: z.union([ BaggageOrderByWithAggregationInputSchema.array(),BaggageOrderByWithAggregationInputSchema ]).optional(),
  by: BaggageScalarFieldEnumSchema.array(),
  having: BaggageScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BaggageFindUniqueArgsSchema: z.ZodType<Prisma.BaggageFindUniqueArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  where: BaggageWhereUniqueInputSchema,
}).strict() ;

export const BaggageFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.BaggageFindUniqueOrThrowArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  where: BaggageWhereUniqueInputSchema,
}).strict() ;

export const BookingCommentFindFirstArgsSchema: z.ZodType<Prisma.BookingCommentFindFirstArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  where: BookingCommentWhereInputSchema.optional(),
  orderBy: z.union([ BookingCommentOrderByWithRelationInputSchema.array(),BookingCommentOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingCommentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingCommentScalarFieldEnumSchema,BookingCommentScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingCommentFindFirstOrThrowArgsSchema: z.ZodType<Prisma.BookingCommentFindFirstOrThrowArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  where: BookingCommentWhereInputSchema.optional(),
  orderBy: z.union([ BookingCommentOrderByWithRelationInputSchema.array(),BookingCommentOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingCommentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingCommentScalarFieldEnumSchema,BookingCommentScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingCommentFindManyArgsSchema: z.ZodType<Prisma.BookingCommentFindManyArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  where: BookingCommentWhereInputSchema.optional(),
  orderBy: z.union([ BookingCommentOrderByWithRelationInputSchema.array(),BookingCommentOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingCommentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingCommentScalarFieldEnumSchema,BookingCommentScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingCommentAggregateArgsSchema: z.ZodType<Prisma.BookingCommentAggregateArgs> = z.object({
  where: BookingCommentWhereInputSchema.optional(),
  orderBy: z.union([ BookingCommentOrderByWithRelationInputSchema.array(),BookingCommentOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingCommentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingCommentGroupByArgsSchema: z.ZodType<Prisma.BookingCommentGroupByArgs> = z.object({
  where: BookingCommentWhereInputSchema.optional(),
  orderBy: z.union([ BookingCommentOrderByWithAggregationInputSchema.array(),BookingCommentOrderByWithAggregationInputSchema ]).optional(),
  by: BookingCommentScalarFieldEnumSchema.array(),
  having: BookingCommentScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingCommentFindUniqueArgsSchema: z.ZodType<Prisma.BookingCommentFindUniqueArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  where: BookingCommentWhereUniqueInputSchema,
}).strict() ;

export const BookingCommentFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.BookingCommentFindUniqueOrThrowArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  where: BookingCommentWhereUniqueInputSchema,
}).strict() ;

export const BookingDeliveryFindFirstArgsSchema: z.ZodType<Prisma.BookingDeliveryFindFirstArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  where: BookingDeliveryWhereInputSchema.optional(),
  orderBy: z.union([ BookingDeliveryOrderByWithRelationInputSchema.array(),BookingDeliveryOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingDeliveryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingDeliveryScalarFieldEnumSchema,BookingDeliveryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingDeliveryFindFirstOrThrowArgsSchema: z.ZodType<Prisma.BookingDeliveryFindFirstOrThrowArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  where: BookingDeliveryWhereInputSchema.optional(),
  orderBy: z.union([ BookingDeliveryOrderByWithRelationInputSchema.array(),BookingDeliveryOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingDeliveryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingDeliveryScalarFieldEnumSchema,BookingDeliveryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingDeliveryFindManyArgsSchema: z.ZodType<Prisma.BookingDeliveryFindManyArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  where: BookingDeliveryWhereInputSchema.optional(),
  orderBy: z.union([ BookingDeliveryOrderByWithRelationInputSchema.array(),BookingDeliveryOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingDeliveryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingDeliveryScalarFieldEnumSchema,BookingDeliveryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingDeliveryAggregateArgsSchema: z.ZodType<Prisma.BookingDeliveryAggregateArgs> = z.object({
  where: BookingDeliveryWhereInputSchema.optional(),
  orderBy: z.union([ BookingDeliveryOrderByWithRelationInputSchema.array(),BookingDeliveryOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingDeliveryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingDeliveryGroupByArgsSchema: z.ZodType<Prisma.BookingDeliveryGroupByArgs> = z.object({
  where: BookingDeliveryWhereInputSchema.optional(),
  orderBy: z.union([ BookingDeliveryOrderByWithAggregationInputSchema.array(),BookingDeliveryOrderByWithAggregationInputSchema ]).optional(),
  by: BookingDeliveryScalarFieldEnumSchema.array(),
  having: BookingDeliveryScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingDeliveryFindUniqueArgsSchema: z.ZodType<Prisma.BookingDeliveryFindUniqueArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  where: BookingDeliveryWhereUniqueInputSchema,
}).strict() ;

export const BookingDeliveryFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.BookingDeliveryFindUniqueOrThrowArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  where: BookingDeliveryWhereUniqueInputSchema,
}).strict() ;

export const BookingHistoryFindFirstArgsSchema: z.ZodType<Prisma.BookingHistoryFindFirstArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  where: BookingHistoryWhereInputSchema.optional(),
  orderBy: z.union([ BookingHistoryOrderByWithRelationInputSchema.array(),BookingHistoryOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingHistoryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingHistoryScalarFieldEnumSchema,BookingHistoryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingHistoryFindFirstOrThrowArgsSchema: z.ZodType<Prisma.BookingHistoryFindFirstOrThrowArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  where: BookingHistoryWhereInputSchema.optional(),
  orderBy: z.union([ BookingHistoryOrderByWithRelationInputSchema.array(),BookingHistoryOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingHistoryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingHistoryScalarFieldEnumSchema,BookingHistoryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingHistoryFindManyArgsSchema: z.ZodType<Prisma.BookingHistoryFindManyArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  where: BookingHistoryWhereInputSchema.optional(),
  orderBy: z.union([ BookingHistoryOrderByWithRelationInputSchema.array(),BookingHistoryOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingHistoryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingHistoryScalarFieldEnumSchema,BookingHistoryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingHistoryAggregateArgsSchema: z.ZodType<Prisma.BookingHistoryAggregateArgs> = z.object({
  where: BookingHistoryWhereInputSchema.optional(),
  orderBy: z.union([ BookingHistoryOrderByWithRelationInputSchema.array(),BookingHistoryOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingHistoryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingHistoryGroupByArgsSchema: z.ZodType<Prisma.BookingHistoryGroupByArgs> = z.object({
  where: BookingHistoryWhereInputSchema.optional(),
  orderBy: z.union([ BookingHistoryOrderByWithAggregationInputSchema.array(),BookingHistoryOrderByWithAggregationInputSchema ]).optional(),
  by: BookingHistoryScalarFieldEnumSchema.array(),
  having: BookingHistoryScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingHistoryFindUniqueArgsSchema: z.ZodType<Prisma.BookingHistoryFindUniqueArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  where: BookingHistoryWhereUniqueInputSchema,
}).strict() ;

export const BookingHistoryFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.BookingHistoryFindUniqueOrThrowArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  where: BookingHistoryWhereUniqueInputSchema,
}).strict() ;

export const BookingItemFindFirstArgsSchema: z.ZodType<Prisma.BookingItemFindFirstArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  where: BookingItemWhereInputSchema.optional(),
  orderBy: z.union([ BookingItemOrderByWithRelationInputSchema.array(),BookingItemOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingItemScalarFieldEnumSchema,BookingItemScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingItemFindFirstOrThrowArgsSchema: z.ZodType<Prisma.BookingItemFindFirstOrThrowArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  where: BookingItemWhereInputSchema.optional(),
  orderBy: z.union([ BookingItemOrderByWithRelationInputSchema.array(),BookingItemOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingItemScalarFieldEnumSchema,BookingItemScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingItemFindManyArgsSchema: z.ZodType<Prisma.BookingItemFindManyArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  where: BookingItemWhereInputSchema.optional(),
  orderBy: z.union([ BookingItemOrderByWithRelationInputSchema.array(),BookingItemOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingItemScalarFieldEnumSchema,BookingItemScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingItemAggregateArgsSchema: z.ZodType<Prisma.BookingItemAggregateArgs> = z.object({
  where: BookingItemWhereInputSchema.optional(),
  orderBy: z.union([ BookingItemOrderByWithRelationInputSchema.array(),BookingItemOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingItemGroupByArgsSchema: z.ZodType<Prisma.BookingItemGroupByArgs> = z.object({
  where: BookingItemWhereInputSchema.optional(),
  orderBy: z.union([ BookingItemOrderByWithAggregationInputSchema.array(),BookingItemOrderByWithAggregationInputSchema ]).optional(),
  by: BookingItemScalarFieldEnumSchema.array(),
  having: BookingItemScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingItemFindUniqueArgsSchema: z.ZodType<Prisma.BookingItemFindUniqueArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  where: BookingItemWhereUniqueInputSchema,
}).strict() ;

export const BookingItemFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.BookingItemFindUniqueOrThrowArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  where: BookingItemWhereUniqueInputSchema,
}).strict() ;

export const BookingFindFirstArgsSchema: z.ZodType<Prisma.BookingFindFirstArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  where: BookingWhereInputSchema.optional(),
  orderBy: z.union([ BookingOrderByWithRelationInputSchema.array(),BookingOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingScalarFieldEnumSchema,BookingScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingFindFirstOrThrowArgsSchema: z.ZodType<Prisma.BookingFindFirstOrThrowArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  where: BookingWhereInputSchema.optional(),
  orderBy: z.union([ BookingOrderByWithRelationInputSchema.array(),BookingOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingScalarFieldEnumSchema,BookingScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingFindManyArgsSchema: z.ZodType<Prisma.BookingFindManyArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  where: BookingWhereInputSchema.optional(),
  orderBy: z.union([ BookingOrderByWithRelationInputSchema.array(),BookingOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BookingScalarFieldEnumSchema,BookingScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BookingAggregateArgsSchema: z.ZodType<Prisma.BookingAggregateArgs> = z.object({
  where: BookingWhereInputSchema.optional(),
  orderBy: z.union([ BookingOrderByWithRelationInputSchema.array(),BookingOrderByWithRelationInputSchema ]).optional(),
  cursor: BookingWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingGroupByArgsSchema: z.ZodType<Prisma.BookingGroupByArgs> = z.object({
  where: BookingWhereInputSchema.optional(),
  orderBy: z.union([ BookingOrderByWithAggregationInputSchema.array(),BookingOrderByWithAggregationInputSchema ]).optional(),
  by: BookingScalarFieldEnumSchema.array(),
  having: BookingScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BookingFindUniqueArgsSchema: z.ZodType<Prisma.BookingFindUniqueArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  where: BookingWhereUniqueInputSchema,
}).strict() ;

export const BookingFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.BookingFindUniqueOrThrowArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  where: BookingWhereUniqueInputSchema,
}).strict() ;

export const ClientFindFirstArgsSchema: z.ZodType<Prisma.ClientFindFirstArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  where: ClientWhereInputSchema.optional(),
  orderBy: z.union([ ClientOrderByWithRelationInputSchema.array(),ClientOrderByWithRelationInputSchema ]).optional(),
  cursor: ClientWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ClientScalarFieldEnumSchema,ClientScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ClientFindFirstOrThrowArgsSchema: z.ZodType<Prisma.ClientFindFirstOrThrowArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  where: ClientWhereInputSchema.optional(),
  orderBy: z.union([ ClientOrderByWithRelationInputSchema.array(),ClientOrderByWithRelationInputSchema ]).optional(),
  cursor: ClientWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ClientScalarFieldEnumSchema,ClientScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ClientFindManyArgsSchema: z.ZodType<Prisma.ClientFindManyArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  where: ClientWhereInputSchema.optional(),
  orderBy: z.union([ ClientOrderByWithRelationInputSchema.array(),ClientOrderByWithRelationInputSchema ]).optional(),
  cursor: ClientWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ClientScalarFieldEnumSchema,ClientScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ClientAggregateArgsSchema: z.ZodType<Prisma.ClientAggregateArgs> = z.object({
  where: ClientWhereInputSchema.optional(),
  orderBy: z.union([ ClientOrderByWithRelationInputSchema.array(),ClientOrderByWithRelationInputSchema ]).optional(),
  cursor: ClientWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const ClientGroupByArgsSchema: z.ZodType<Prisma.ClientGroupByArgs> = z.object({
  where: ClientWhereInputSchema.optional(),
  orderBy: z.union([ ClientOrderByWithAggregationInputSchema.array(),ClientOrderByWithAggregationInputSchema ]).optional(),
  by: ClientScalarFieldEnumSchema.array(),
  having: ClientScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const ClientFindUniqueArgsSchema: z.ZodType<Prisma.ClientFindUniqueArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  where: ClientWhereUniqueInputSchema,
}).strict() ;

export const ClientFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.ClientFindUniqueOrThrowArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  where: ClientWhereUniqueInputSchema,
}).strict() ;

export const DeliveryFindFirstArgsSchema: z.ZodType<Prisma.DeliveryFindFirstArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  where: DeliveryWhereInputSchema.optional(),
  orderBy: z.union([ DeliveryOrderByWithRelationInputSchema.array(),DeliveryOrderByWithRelationInputSchema ]).optional(),
  cursor: DeliveryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DeliveryScalarFieldEnumSchema,DeliveryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const DeliveryFindFirstOrThrowArgsSchema: z.ZodType<Prisma.DeliveryFindFirstOrThrowArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  where: DeliveryWhereInputSchema.optional(),
  orderBy: z.union([ DeliveryOrderByWithRelationInputSchema.array(),DeliveryOrderByWithRelationInputSchema ]).optional(),
  cursor: DeliveryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DeliveryScalarFieldEnumSchema,DeliveryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const DeliveryFindManyArgsSchema: z.ZodType<Prisma.DeliveryFindManyArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  where: DeliveryWhereInputSchema.optional(),
  orderBy: z.union([ DeliveryOrderByWithRelationInputSchema.array(),DeliveryOrderByWithRelationInputSchema ]).optional(),
  cursor: DeliveryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DeliveryScalarFieldEnumSchema,DeliveryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const DeliveryAggregateArgsSchema: z.ZodType<Prisma.DeliveryAggregateArgs> = z.object({
  where: DeliveryWhereInputSchema.optional(),
  orderBy: z.union([ DeliveryOrderByWithRelationInputSchema.array(),DeliveryOrderByWithRelationInputSchema ]).optional(),
  cursor: DeliveryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const DeliveryGroupByArgsSchema: z.ZodType<Prisma.DeliveryGroupByArgs> = z.object({
  where: DeliveryWhereInputSchema.optional(),
  orderBy: z.union([ DeliveryOrderByWithAggregationInputSchema.array(),DeliveryOrderByWithAggregationInputSchema ]).optional(),
  by: DeliveryScalarFieldEnumSchema.array(),
  having: DeliveryScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const DeliveryFindUniqueArgsSchema: z.ZodType<Prisma.DeliveryFindUniqueArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  where: DeliveryWhereUniqueInputSchema,
}).strict() ;

export const DeliveryFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.DeliveryFindUniqueOrThrowArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  where: DeliveryWhereUniqueInputSchema,
}).strict() ;

export const DistrictFindFirstArgsSchema: z.ZodType<Prisma.DistrictFindFirstArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  where: DistrictWhereInputSchema.optional(),
  orderBy: z.union([ DistrictOrderByWithRelationInputSchema.array(),DistrictOrderByWithRelationInputSchema ]).optional(),
  cursor: DistrictWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DistrictScalarFieldEnumSchema,DistrictScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const DistrictFindFirstOrThrowArgsSchema: z.ZodType<Prisma.DistrictFindFirstOrThrowArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  where: DistrictWhereInputSchema.optional(),
  orderBy: z.union([ DistrictOrderByWithRelationInputSchema.array(),DistrictOrderByWithRelationInputSchema ]).optional(),
  cursor: DistrictWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DistrictScalarFieldEnumSchema,DistrictScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const DistrictFindManyArgsSchema: z.ZodType<Prisma.DistrictFindManyArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  where: DistrictWhereInputSchema.optional(),
  orderBy: z.union([ DistrictOrderByWithRelationInputSchema.array(),DistrictOrderByWithRelationInputSchema ]).optional(),
  cursor: DistrictWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ DistrictScalarFieldEnumSchema,DistrictScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const DistrictAggregateArgsSchema: z.ZodType<Prisma.DistrictAggregateArgs> = z.object({
  where: DistrictWhereInputSchema.optional(),
  orderBy: z.union([ DistrictOrderByWithRelationInputSchema.array(),DistrictOrderByWithRelationInputSchema ]).optional(),
  cursor: DistrictWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const DistrictGroupByArgsSchema: z.ZodType<Prisma.DistrictGroupByArgs> = z.object({
  where: DistrictWhereInputSchema.optional(),
  orderBy: z.union([ DistrictOrderByWithAggregationInputSchema.array(),DistrictOrderByWithAggregationInputSchema ]).optional(),
  by: DistrictScalarFieldEnumSchema.array(),
  having: DistrictScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const DistrictFindUniqueArgsSchema: z.ZodType<Prisma.DistrictFindUniqueArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  where: DistrictWhereUniqueInputSchema,
}).strict() ;

export const DistrictFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.DistrictFindUniqueOrThrowArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  where: DistrictWhereUniqueInputSchema,
}).strict() ;

export const FiscalYearFindFirstArgsSchema: z.ZodType<Prisma.FiscalYearFindFirstArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  where: FiscalYearWhereInputSchema.optional(),
  orderBy: z.union([ FiscalYearOrderByWithRelationInputSchema.array(),FiscalYearOrderByWithRelationInputSchema ]).optional(),
  cursor: FiscalYearWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ FiscalYearScalarFieldEnumSchema,FiscalYearScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const FiscalYearFindFirstOrThrowArgsSchema: z.ZodType<Prisma.FiscalYearFindFirstOrThrowArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  where: FiscalYearWhereInputSchema.optional(),
  orderBy: z.union([ FiscalYearOrderByWithRelationInputSchema.array(),FiscalYearOrderByWithRelationInputSchema ]).optional(),
  cursor: FiscalYearWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ FiscalYearScalarFieldEnumSchema,FiscalYearScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const FiscalYearFindManyArgsSchema: z.ZodType<Prisma.FiscalYearFindManyArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  where: FiscalYearWhereInputSchema.optional(),
  orderBy: z.union([ FiscalYearOrderByWithRelationInputSchema.array(),FiscalYearOrderByWithRelationInputSchema ]).optional(),
  cursor: FiscalYearWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ FiscalYearScalarFieldEnumSchema,FiscalYearScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const FiscalYearAggregateArgsSchema: z.ZodType<Prisma.FiscalYearAggregateArgs> = z.object({
  where: FiscalYearWhereInputSchema.optional(),
  orderBy: z.union([ FiscalYearOrderByWithRelationInputSchema.array(),FiscalYearOrderByWithRelationInputSchema ]).optional(),
  cursor: FiscalYearWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const FiscalYearGroupByArgsSchema: z.ZodType<Prisma.FiscalYearGroupByArgs> = z.object({
  where: FiscalYearWhereInputSchema.optional(),
  orderBy: z.union([ FiscalYearOrderByWithAggregationInputSchema.array(),FiscalYearOrderByWithAggregationInputSchema ]).optional(),
  by: FiscalYearScalarFieldEnumSchema.array(),
  having: FiscalYearScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const FiscalYearFindUniqueArgsSchema: z.ZodType<Prisma.FiscalYearFindUniqueArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  where: FiscalYearWhereUniqueInputSchema,
}).strict() ;

export const FiscalYearFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.FiscalYearFindUniqueOrThrowArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  where: FiscalYearWhereUniqueInputSchema,
}).strict() ;

export const GenBookingBarcodeFindFirstArgsSchema: z.ZodType<Prisma.GenBookingBarcodeFindFirstArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  where: GenBookingBarcodeWhereInputSchema.optional(),
  orderBy: z.union([ GenBookingBarcodeOrderByWithRelationInputSchema.array(),GenBookingBarcodeOrderByWithRelationInputSchema ]).optional(),
  cursor: GenBookingBarcodeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ GenBookingBarcodeScalarFieldEnumSchema,GenBookingBarcodeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const GenBookingBarcodeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.GenBookingBarcodeFindFirstOrThrowArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  where: GenBookingBarcodeWhereInputSchema.optional(),
  orderBy: z.union([ GenBookingBarcodeOrderByWithRelationInputSchema.array(),GenBookingBarcodeOrderByWithRelationInputSchema ]).optional(),
  cursor: GenBookingBarcodeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ GenBookingBarcodeScalarFieldEnumSchema,GenBookingBarcodeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const GenBookingBarcodeFindManyArgsSchema: z.ZodType<Prisma.GenBookingBarcodeFindManyArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  where: GenBookingBarcodeWhereInputSchema.optional(),
  orderBy: z.union([ GenBookingBarcodeOrderByWithRelationInputSchema.array(),GenBookingBarcodeOrderByWithRelationInputSchema ]).optional(),
  cursor: GenBookingBarcodeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ GenBookingBarcodeScalarFieldEnumSchema,GenBookingBarcodeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const GenBookingBarcodeAggregateArgsSchema: z.ZodType<Prisma.GenBookingBarcodeAggregateArgs> = z.object({
  where: GenBookingBarcodeWhereInputSchema.optional(),
  orderBy: z.union([ GenBookingBarcodeOrderByWithRelationInputSchema.array(),GenBookingBarcodeOrderByWithRelationInputSchema ]).optional(),
  cursor: GenBookingBarcodeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const GenBookingBarcodeGroupByArgsSchema: z.ZodType<Prisma.GenBookingBarcodeGroupByArgs> = z.object({
  where: GenBookingBarcodeWhereInputSchema.optional(),
  orderBy: z.union([ GenBookingBarcodeOrderByWithAggregationInputSchema.array(),GenBookingBarcodeOrderByWithAggregationInputSchema ]).optional(),
  by: GenBookingBarcodeScalarFieldEnumSchema.array(),
  having: GenBookingBarcodeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const GenBookingBarcodeFindUniqueArgsSchema: z.ZodType<Prisma.GenBookingBarcodeFindUniqueArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  where: GenBookingBarcodeWhereUniqueInputSchema,
}).strict() ;

export const GenBookingBarcodeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.GenBookingBarcodeFindUniqueOrThrowArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  where: GenBookingBarcodeWhereUniqueInputSchema,
}).strict() ;

export const LocationFindFirstArgsSchema: z.ZodType<Prisma.LocationFindFirstArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  where: LocationWhereInputSchema.optional(),
  orderBy: z.union([ LocationOrderByWithRelationInputSchema.array(),LocationOrderByWithRelationInputSchema ]).optional(),
  cursor: LocationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ LocationScalarFieldEnumSchema,LocationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const LocationFindFirstOrThrowArgsSchema: z.ZodType<Prisma.LocationFindFirstOrThrowArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  where: LocationWhereInputSchema.optional(),
  orderBy: z.union([ LocationOrderByWithRelationInputSchema.array(),LocationOrderByWithRelationInputSchema ]).optional(),
  cursor: LocationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ LocationScalarFieldEnumSchema,LocationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const LocationFindManyArgsSchema: z.ZodType<Prisma.LocationFindManyArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  where: LocationWhereInputSchema.optional(),
  orderBy: z.union([ LocationOrderByWithRelationInputSchema.array(),LocationOrderByWithRelationInputSchema ]).optional(),
  cursor: LocationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ LocationScalarFieldEnumSchema,LocationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const LocationAggregateArgsSchema: z.ZodType<Prisma.LocationAggregateArgs> = z.object({
  where: LocationWhereInputSchema.optional(),
  orderBy: z.union([ LocationOrderByWithRelationInputSchema.array(),LocationOrderByWithRelationInputSchema ]).optional(),
  cursor: LocationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const LocationGroupByArgsSchema: z.ZodType<Prisma.LocationGroupByArgs> = z.object({
  where: LocationWhereInputSchema.optional(),
  orderBy: z.union([ LocationOrderByWithAggregationInputSchema.array(),LocationOrderByWithAggregationInputSchema ]).optional(),
  by: LocationScalarFieldEnumSchema.array(),
  having: LocationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const LocationFindUniqueArgsSchema: z.ZodType<Prisma.LocationFindUniqueArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  where: LocationWhereUniqueInputSchema,
}).strict() ;

export const LocationFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.LocationFindUniqueOrThrowArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  where: LocationWhereUniqueInputSchema,
}).strict() ;

export const ManifestFindFirstArgsSchema: z.ZodType<Prisma.ManifestFindFirstArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  where: ManifestWhereInputSchema.optional(),
  orderBy: z.union([ ManifestOrderByWithRelationInputSchema.array(),ManifestOrderByWithRelationInputSchema ]).optional(),
  cursor: ManifestWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ManifestScalarFieldEnumSchema,ManifestScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ManifestFindFirstOrThrowArgsSchema: z.ZodType<Prisma.ManifestFindFirstOrThrowArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  where: ManifestWhereInputSchema.optional(),
  orderBy: z.union([ ManifestOrderByWithRelationInputSchema.array(),ManifestOrderByWithRelationInputSchema ]).optional(),
  cursor: ManifestWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ManifestScalarFieldEnumSchema,ManifestScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ManifestFindManyArgsSchema: z.ZodType<Prisma.ManifestFindManyArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  where: ManifestWhereInputSchema.optional(),
  orderBy: z.union([ ManifestOrderByWithRelationInputSchema.array(),ManifestOrderByWithRelationInputSchema ]).optional(),
  cursor: ManifestWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ManifestScalarFieldEnumSchema,ManifestScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ManifestAggregateArgsSchema: z.ZodType<Prisma.ManifestAggregateArgs> = z.object({
  where: ManifestWhereInputSchema.optional(),
  orderBy: z.union([ ManifestOrderByWithRelationInputSchema.array(),ManifestOrderByWithRelationInputSchema ]).optional(),
  cursor: ManifestWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const ManifestGroupByArgsSchema: z.ZodType<Prisma.ManifestGroupByArgs> = z.object({
  where: ManifestWhereInputSchema.optional(),
  orderBy: z.union([ ManifestOrderByWithAggregationInputSchema.array(),ManifestOrderByWithAggregationInputSchema ]).optional(),
  by: ManifestScalarFieldEnumSchema.array(),
  having: ManifestScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const ManifestFindUniqueArgsSchema: z.ZodType<Prisma.ManifestFindUniqueArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  where: ManifestWhereUniqueInputSchema,
}).strict() ;

export const ManifestFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.ManifestFindUniqueOrThrowArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  where: ManifestWhereUniqueInputSchema,
}).strict() ;

export const MasterManifestDetailFindFirstArgsSchema: z.ZodType<Prisma.MasterManifestDetailFindFirstArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  where: MasterManifestDetailWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestDetailOrderByWithRelationInputSchema.array(),MasterManifestDetailOrderByWithRelationInputSchema ]).optional(),
  cursor: MasterManifestDetailWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MasterManifestDetailScalarFieldEnumSchema,MasterManifestDetailScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MasterManifestDetailFindFirstOrThrowArgsSchema: z.ZodType<Prisma.MasterManifestDetailFindFirstOrThrowArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  where: MasterManifestDetailWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestDetailOrderByWithRelationInputSchema.array(),MasterManifestDetailOrderByWithRelationInputSchema ]).optional(),
  cursor: MasterManifestDetailWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MasterManifestDetailScalarFieldEnumSchema,MasterManifestDetailScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MasterManifestDetailFindManyArgsSchema: z.ZodType<Prisma.MasterManifestDetailFindManyArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  where: MasterManifestDetailWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestDetailOrderByWithRelationInputSchema.array(),MasterManifestDetailOrderByWithRelationInputSchema ]).optional(),
  cursor: MasterManifestDetailWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MasterManifestDetailScalarFieldEnumSchema,MasterManifestDetailScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MasterManifestDetailAggregateArgsSchema: z.ZodType<Prisma.MasterManifestDetailAggregateArgs> = z.object({
  where: MasterManifestDetailWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestDetailOrderByWithRelationInputSchema.array(),MasterManifestDetailOrderByWithRelationInputSchema ]).optional(),
  cursor: MasterManifestDetailWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const MasterManifestDetailGroupByArgsSchema: z.ZodType<Prisma.MasterManifestDetailGroupByArgs> = z.object({
  where: MasterManifestDetailWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestDetailOrderByWithAggregationInputSchema.array(),MasterManifestDetailOrderByWithAggregationInputSchema ]).optional(),
  by: MasterManifestDetailScalarFieldEnumSchema.array(),
  having: MasterManifestDetailScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const MasterManifestDetailFindUniqueArgsSchema: z.ZodType<Prisma.MasterManifestDetailFindUniqueArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  where: MasterManifestDetailWhereUniqueInputSchema,
}).strict() ;

export const MasterManifestDetailFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.MasterManifestDetailFindUniqueOrThrowArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  where: MasterManifestDetailWhereUniqueInputSchema,
}).strict() ;

export const MasterManifestFindFirstArgsSchema: z.ZodType<Prisma.MasterManifestFindFirstArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  where: MasterManifestWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestOrderByWithRelationInputSchema.array(),MasterManifestOrderByWithRelationInputSchema ]).optional(),
  cursor: MasterManifestWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MasterManifestScalarFieldEnumSchema,MasterManifestScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MasterManifestFindFirstOrThrowArgsSchema: z.ZodType<Prisma.MasterManifestFindFirstOrThrowArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  where: MasterManifestWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestOrderByWithRelationInputSchema.array(),MasterManifestOrderByWithRelationInputSchema ]).optional(),
  cursor: MasterManifestWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MasterManifestScalarFieldEnumSchema,MasterManifestScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MasterManifestFindManyArgsSchema: z.ZodType<Prisma.MasterManifestFindManyArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  where: MasterManifestWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestOrderByWithRelationInputSchema.array(),MasterManifestOrderByWithRelationInputSchema ]).optional(),
  cursor: MasterManifestWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MasterManifestScalarFieldEnumSchema,MasterManifestScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MasterManifestAggregateArgsSchema: z.ZodType<Prisma.MasterManifestAggregateArgs> = z.object({
  where: MasterManifestWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestOrderByWithRelationInputSchema.array(),MasterManifestOrderByWithRelationInputSchema ]).optional(),
  cursor: MasterManifestWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const MasterManifestGroupByArgsSchema: z.ZodType<Prisma.MasterManifestGroupByArgs> = z.object({
  where: MasterManifestWhereInputSchema.optional(),
  orderBy: z.union([ MasterManifestOrderByWithAggregationInputSchema.array(),MasterManifestOrderByWithAggregationInputSchema ]).optional(),
  by: MasterManifestScalarFieldEnumSchema.array(),
  having: MasterManifestScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const MasterManifestFindUniqueArgsSchema: z.ZodType<Prisma.MasterManifestFindUniqueArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  where: MasterManifestWhereUniqueInputSchema,
}).strict() ;

export const MasterManifestFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.MasterManifestFindUniqueOrThrowArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  where: MasterManifestWhereUniqueInputSchema,
}).strict() ;

export const MonthFindFirstArgsSchema: z.ZodType<Prisma.MonthFindFirstArgs> = z.object({
  select: MonthSelectSchema.optional(),
  where: MonthWhereInputSchema.optional(),
  orderBy: z.union([ MonthOrderByWithRelationInputSchema.array(),MonthOrderByWithRelationInputSchema ]).optional(),
  cursor: MonthWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MonthScalarFieldEnumSchema,MonthScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MonthFindFirstOrThrowArgsSchema: z.ZodType<Prisma.MonthFindFirstOrThrowArgs> = z.object({
  select: MonthSelectSchema.optional(),
  where: MonthWhereInputSchema.optional(),
  orderBy: z.union([ MonthOrderByWithRelationInputSchema.array(),MonthOrderByWithRelationInputSchema ]).optional(),
  cursor: MonthWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MonthScalarFieldEnumSchema,MonthScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MonthFindManyArgsSchema: z.ZodType<Prisma.MonthFindManyArgs> = z.object({
  select: MonthSelectSchema.optional(),
  where: MonthWhereInputSchema.optional(),
  orderBy: z.union([ MonthOrderByWithRelationInputSchema.array(),MonthOrderByWithRelationInputSchema ]).optional(),
  cursor: MonthWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ MonthScalarFieldEnumSchema,MonthScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const MonthAggregateArgsSchema: z.ZodType<Prisma.MonthAggregateArgs> = z.object({
  where: MonthWhereInputSchema.optional(),
  orderBy: z.union([ MonthOrderByWithRelationInputSchema.array(),MonthOrderByWithRelationInputSchema ]).optional(),
  cursor: MonthWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const MonthGroupByArgsSchema: z.ZodType<Prisma.MonthGroupByArgs> = z.object({
  where: MonthWhereInputSchema.optional(),
  orderBy: z.union([ MonthOrderByWithAggregationInputSchema.array(),MonthOrderByWithAggregationInputSchema ]).optional(),
  by: MonthScalarFieldEnumSchema.array(),
  having: MonthScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const MonthFindUniqueArgsSchema: z.ZodType<Prisma.MonthFindUniqueArgs> = z.object({
  select: MonthSelectSchema.optional(),
  where: MonthWhereUniqueInputSchema,
}).strict() ;

export const MonthFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.MonthFindUniqueOrThrowArgs> = z.object({
  select: MonthSelectSchema.optional(),
  where: MonthWhereUniqueInputSchema,
}).strict() ;

export const ProductTypeFindFirstArgsSchema: z.ZodType<Prisma.ProductTypeFindFirstArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  where: ProductTypeWhereInputSchema.optional(),
  orderBy: z.union([ ProductTypeOrderByWithRelationInputSchema.array(),ProductTypeOrderByWithRelationInputSchema ]).optional(),
  cursor: ProductTypeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ProductTypeScalarFieldEnumSchema,ProductTypeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ProductTypeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.ProductTypeFindFirstOrThrowArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  where: ProductTypeWhereInputSchema.optional(),
  orderBy: z.union([ ProductTypeOrderByWithRelationInputSchema.array(),ProductTypeOrderByWithRelationInputSchema ]).optional(),
  cursor: ProductTypeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ProductTypeScalarFieldEnumSchema,ProductTypeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ProductTypeFindManyArgsSchema: z.ZodType<Prisma.ProductTypeFindManyArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  where: ProductTypeWhereInputSchema.optional(),
  orderBy: z.union([ ProductTypeOrderByWithRelationInputSchema.array(),ProductTypeOrderByWithRelationInputSchema ]).optional(),
  cursor: ProductTypeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ProductTypeScalarFieldEnumSchema,ProductTypeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const ProductTypeAggregateArgsSchema: z.ZodType<Prisma.ProductTypeAggregateArgs> = z.object({
  where: ProductTypeWhereInputSchema.optional(),
  orderBy: z.union([ ProductTypeOrderByWithRelationInputSchema.array(),ProductTypeOrderByWithRelationInputSchema ]).optional(),
  cursor: ProductTypeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const ProductTypeGroupByArgsSchema: z.ZodType<Prisma.ProductTypeGroupByArgs> = z.object({
  where: ProductTypeWhereInputSchema.optional(),
  orderBy: z.union([ ProductTypeOrderByWithAggregationInputSchema.array(),ProductTypeOrderByWithAggregationInputSchema ]).optional(),
  by: ProductTypeScalarFieldEnumSchema.array(),
  having: ProductTypeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const ProductTypeFindUniqueArgsSchema: z.ZodType<Prisma.ProductTypeFindUniqueArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  where: ProductTypeWhereUniqueInputSchema,
}).strict() ;

export const ProductTypeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.ProductTypeFindUniqueOrThrowArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  where: ProductTypeWhereUniqueInputSchema,
}).strict() ;

export const RateFindFirstArgsSchema: z.ZodType<Prisma.RateFindFirstArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  where: RateWhereInputSchema.optional(),
  orderBy: z.union([ RateOrderByWithRelationInputSchema.array(),RateOrderByWithRelationInputSchema ]).optional(),
  cursor: RateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ RateScalarFieldEnumSchema,RateScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const RateFindFirstOrThrowArgsSchema: z.ZodType<Prisma.RateFindFirstOrThrowArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  where: RateWhereInputSchema.optional(),
  orderBy: z.union([ RateOrderByWithRelationInputSchema.array(),RateOrderByWithRelationInputSchema ]).optional(),
  cursor: RateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ RateScalarFieldEnumSchema,RateScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const RateFindManyArgsSchema: z.ZodType<Prisma.RateFindManyArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  where: RateWhereInputSchema.optional(),
  orderBy: z.union([ RateOrderByWithRelationInputSchema.array(),RateOrderByWithRelationInputSchema ]).optional(),
  cursor: RateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ RateScalarFieldEnumSchema,RateScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const RateAggregateArgsSchema: z.ZodType<Prisma.RateAggregateArgs> = z.object({
  where: RateWhereInputSchema.optional(),
  orderBy: z.union([ RateOrderByWithRelationInputSchema.array(),RateOrderByWithRelationInputSchema ]).optional(),
  cursor: RateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const RateGroupByArgsSchema: z.ZodType<Prisma.RateGroupByArgs> = z.object({
  where: RateWhereInputSchema.optional(),
  orderBy: z.union([ RateOrderByWithAggregationInputSchema.array(),RateOrderByWithAggregationInputSchema ]).optional(),
  by: RateScalarFieldEnumSchema.array(),
  having: RateScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const RateFindUniqueArgsSchema: z.ZodType<Prisma.RateFindUniqueArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  where: RateWhereUniqueInputSchema,
}).strict() ;

export const RateFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.RateFindUniqueOrThrowArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  where: RateWhereUniqueInputSchema,
}).strict() ;

export const RoleFindFirstArgsSchema: z.ZodType<Prisma.RoleFindFirstArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  where: RoleWhereInputSchema.optional(),
  orderBy: z.union([ RoleOrderByWithRelationInputSchema.array(),RoleOrderByWithRelationInputSchema ]).optional(),
  cursor: RoleWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ RoleScalarFieldEnumSchema,RoleScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const RoleFindFirstOrThrowArgsSchema: z.ZodType<Prisma.RoleFindFirstOrThrowArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  where: RoleWhereInputSchema.optional(),
  orderBy: z.union([ RoleOrderByWithRelationInputSchema.array(),RoleOrderByWithRelationInputSchema ]).optional(),
  cursor: RoleWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ RoleScalarFieldEnumSchema,RoleScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const RoleFindManyArgsSchema: z.ZodType<Prisma.RoleFindManyArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  where: RoleWhereInputSchema.optional(),
  orderBy: z.union([ RoleOrderByWithRelationInputSchema.array(),RoleOrderByWithRelationInputSchema ]).optional(),
  cursor: RoleWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ RoleScalarFieldEnumSchema,RoleScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const RoleAggregateArgsSchema: z.ZodType<Prisma.RoleAggregateArgs> = z.object({
  where: RoleWhereInputSchema.optional(),
  orderBy: z.union([ RoleOrderByWithRelationInputSchema.array(),RoleOrderByWithRelationInputSchema ]).optional(),
  cursor: RoleWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const RoleGroupByArgsSchema: z.ZodType<Prisma.RoleGroupByArgs> = z.object({
  where: RoleWhereInputSchema.optional(),
  orderBy: z.union([ RoleOrderByWithAggregationInputSchema.array(),RoleOrderByWithAggregationInputSchema ]).optional(),
  by: RoleScalarFieldEnumSchema.array(),
  having: RoleScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const RoleFindUniqueArgsSchema: z.ZodType<Prisma.RoleFindUniqueArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  where: RoleWhereUniqueInputSchema,
}).strict() ;

export const RoleFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.RoleFindUniqueOrThrowArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  where: RoleWhereUniqueInputSchema,
}).strict() ;

export const StatementFindFirstArgsSchema: z.ZodType<Prisma.StatementFindFirstArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  where: StatementWhereInputSchema.optional(),
  orderBy: z.union([ StatementOrderByWithRelationInputSchema.array(),StatementOrderByWithRelationInputSchema ]).optional(),
  cursor: StatementWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ StatementScalarFieldEnumSchema,StatementScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const StatementFindFirstOrThrowArgsSchema: z.ZodType<Prisma.StatementFindFirstOrThrowArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  where: StatementWhereInputSchema.optional(),
  orderBy: z.union([ StatementOrderByWithRelationInputSchema.array(),StatementOrderByWithRelationInputSchema ]).optional(),
  cursor: StatementWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ StatementScalarFieldEnumSchema,StatementScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const StatementFindManyArgsSchema: z.ZodType<Prisma.StatementFindManyArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  where: StatementWhereInputSchema.optional(),
  orderBy: z.union([ StatementOrderByWithRelationInputSchema.array(),StatementOrderByWithRelationInputSchema ]).optional(),
  cursor: StatementWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ StatementScalarFieldEnumSchema,StatementScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const StatementAggregateArgsSchema: z.ZodType<Prisma.StatementAggregateArgs> = z.object({
  where: StatementWhereInputSchema.optional(),
  orderBy: z.union([ StatementOrderByWithRelationInputSchema.array(),StatementOrderByWithRelationInputSchema ]).optional(),
  cursor: StatementWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const StatementGroupByArgsSchema: z.ZodType<Prisma.StatementGroupByArgs> = z.object({
  where: StatementWhereInputSchema.optional(),
  orderBy: z.union([ StatementOrderByWithAggregationInputSchema.array(),StatementOrderByWithAggregationInputSchema ]).optional(),
  by: StatementScalarFieldEnumSchema.array(),
  having: StatementScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const StatementFindUniqueArgsSchema: z.ZodType<Prisma.StatementFindUniqueArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  where: StatementWhereUniqueInputSchema,
}).strict() ;

export const StatementFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.StatementFindUniqueOrThrowArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  where: StatementWhereUniqueInputSchema,
}).strict() ;

export const SubLocationFindFirstArgsSchema: z.ZodType<Prisma.SubLocationFindFirstArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  where: SubLocationWhereInputSchema.optional(),
  orderBy: z.union([ SubLocationOrderByWithRelationInputSchema.array(),SubLocationOrderByWithRelationInputSchema ]).optional(),
  cursor: SubLocationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SubLocationScalarFieldEnumSchema,SubLocationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SubLocationFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SubLocationFindFirstOrThrowArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  where: SubLocationWhereInputSchema.optional(),
  orderBy: z.union([ SubLocationOrderByWithRelationInputSchema.array(),SubLocationOrderByWithRelationInputSchema ]).optional(),
  cursor: SubLocationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SubLocationScalarFieldEnumSchema,SubLocationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SubLocationFindManyArgsSchema: z.ZodType<Prisma.SubLocationFindManyArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  where: SubLocationWhereInputSchema.optional(),
  orderBy: z.union([ SubLocationOrderByWithRelationInputSchema.array(),SubLocationOrderByWithRelationInputSchema ]).optional(),
  cursor: SubLocationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SubLocationScalarFieldEnumSchema,SubLocationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SubLocationAggregateArgsSchema: z.ZodType<Prisma.SubLocationAggregateArgs> = z.object({
  where: SubLocationWhereInputSchema.optional(),
  orderBy: z.union([ SubLocationOrderByWithRelationInputSchema.array(),SubLocationOrderByWithRelationInputSchema ]).optional(),
  cursor: SubLocationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SubLocationGroupByArgsSchema: z.ZodType<Prisma.SubLocationGroupByArgs> = z.object({
  where: SubLocationWhereInputSchema.optional(),
  orderBy: z.union([ SubLocationOrderByWithAggregationInputSchema.array(),SubLocationOrderByWithAggregationInputSchema ]).optional(),
  by: SubLocationScalarFieldEnumSchema.array(),
  having: SubLocationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SubLocationFindUniqueArgsSchema: z.ZodType<Prisma.SubLocationFindUniqueArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  where: SubLocationWhereUniqueInputSchema,
}).strict() ;

export const SubLocationFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SubLocationFindUniqueOrThrowArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  where: SubLocationWhereUniqueInputSchema,
}).strict() ;

export const UserFindFirstArgsSchema: z.ZodType<Prisma.UserFindFirstArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserFindFirstOrThrowArgsSchema: z.ZodType<Prisma.UserFindFirstOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserFindManyArgsSchema: z.ZodType<Prisma.UserFindManyArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserAggregateArgsSchema: z.ZodType<Prisma.UserAggregateArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const UserGroupByArgsSchema: z.ZodType<Prisma.UserGroupByArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithAggregationInputSchema.array(),UserOrderByWithAggregationInputSchema ]).optional(),
  by: UserScalarFieldEnumSchema.array(),
  having: UserScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const UserFindUniqueArgsSchema: z.ZodType<Prisma.UserFindUniqueArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.UserFindUniqueOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const WardFindFirstArgsSchema: z.ZodType<Prisma.WardFindFirstArgs> = z.object({
  select: WardSelectSchema.optional(),
  where: WardWhereInputSchema.optional(),
  orderBy: z.union([ WardOrderByWithRelationInputSchema.array(),WardOrderByWithRelationInputSchema ]).optional(),
  cursor: WardWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ WardScalarFieldEnumSchema,WardScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const WardFindFirstOrThrowArgsSchema: z.ZodType<Prisma.WardFindFirstOrThrowArgs> = z.object({
  select: WardSelectSchema.optional(),
  where: WardWhereInputSchema.optional(),
  orderBy: z.union([ WardOrderByWithRelationInputSchema.array(),WardOrderByWithRelationInputSchema ]).optional(),
  cursor: WardWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ WardScalarFieldEnumSchema,WardScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const WardFindManyArgsSchema: z.ZodType<Prisma.WardFindManyArgs> = z.object({
  select: WardSelectSchema.optional(),
  where: WardWhereInputSchema.optional(),
  orderBy: z.union([ WardOrderByWithRelationInputSchema.array(),WardOrderByWithRelationInputSchema ]).optional(),
  cursor: WardWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ WardScalarFieldEnumSchema,WardScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const WardAggregateArgsSchema: z.ZodType<Prisma.WardAggregateArgs> = z.object({
  where: WardWhereInputSchema.optional(),
  orderBy: z.union([ WardOrderByWithRelationInputSchema.array(),WardOrderByWithRelationInputSchema ]).optional(),
  cursor: WardWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const WardGroupByArgsSchema: z.ZodType<Prisma.WardGroupByArgs> = z.object({
  where: WardWhereInputSchema.optional(),
  orderBy: z.union([ WardOrderByWithAggregationInputSchema.array(),WardOrderByWithAggregationInputSchema ]).optional(),
  by: WardScalarFieldEnumSchema.array(),
  having: WardScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const WardFindUniqueArgsSchema: z.ZodType<Prisma.WardFindUniqueArgs> = z.object({
  select: WardSelectSchema.optional(),
  where: WardWhereUniqueInputSchema,
}).strict() ;

export const WardFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.WardFindUniqueOrThrowArgs> = z.object({
  select: WardSelectSchema.optional(),
  where: WardWhereUniqueInputSchema,
}).strict() ;

export const BaggageCreateArgsSchema: z.ZodType<Prisma.BaggageCreateArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  data: z.union([ BaggageCreateInputSchema,BaggageUncheckedCreateInputSchema ]),
}).strict() ;

export const BaggageUpsertArgsSchema: z.ZodType<Prisma.BaggageUpsertArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  where: BaggageWhereUniqueInputSchema,
  create: z.union([ BaggageCreateInputSchema,BaggageUncheckedCreateInputSchema ]),
  update: z.union([ BaggageUpdateInputSchema,BaggageUncheckedUpdateInputSchema ]),
}).strict() ;

export const BaggageCreateManyArgsSchema: z.ZodType<Prisma.BaggageCreateManyArgs> = z.object({
  data: z.union([ BaggageCreateManyInputSchema,BaggageCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const BaggageDeleteArgsSchema: z.ZodType<Prisma.BaggageDeleteArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  where: BaggageWhereUniqueInputSchema,
}).strict() ;

export const BaggageUpdateArgsSchema: z.ZodType<Prisma.BaggageUpdateArgs> = z.object({
  select: BaggageSelectSchema.optional(),
  include: BaggageIncludeSchema.optional(),
  data: z.union([ BaggageUpdateInputSchema,BaggageUncheckedUpdateInputSchema ]),
  where: BaggageWhereUniqueInputSchema,
}).strict() ;

export const BaggageUpdateManyArgsSchema: z.ZodType<Prisma.BaggageUpdateManyArgs> = z.object({
  data: z.union([ BaggageUpdateManyMutationInputSchema,BaggageUncheckedUpdateManyInputSchema ]),
  where: BaggageWhereInputSchema.optional(),
}).strict() ;

export const BaggageDeleteManyArgsSchema: z.ZodType<Prisma.BaggageDeleteManyArgs> = z.object({
  where: BaggageWhereInputSchema.optional(),
}).strict() ;

export const BookingCommentCreateArgsSchema: z.ZodType<Prisma.BookingCommentCreateArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  data: z.union([ BookingCommentCreateInputSchema,BookingCommentUncheckedCreateInputSchema ]),
}).strict() ;

export const BookingCommentUpsertArgsSchema: z.ZodType<Prisma.BookingCommentUpsertArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  where: BookingCommentWhereUniqueInputSchema,
  create: z.union([ BookingCommentCreateInputSchema,BookingCommentUncheckedCreateInputSchema ]),
  update: z.union([ BookingCommentUpdateInputSchema,BookingCommentUncheckedUpdateInputSchema ]),
}).strict() ;

export const BookingCommentCreateManyArgsSchema: z.ZodType<Prisma.BookingCommentCreateManyArgs> = z.object({
  data: z.union([ BookingCommentCreateManyInputSchema,BookingCommentCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const BookingCommentDeleteArgsSchema: z.ZodType<Prisma.BookingCommentDeleteArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  where: BookingCommentWhereUniqueInputSchema,
}).strict() ;

export const BookingCommentUpdateArgsSchema: z.ZodType<Prisma.BookingCommentUpdateArgs> = z.object({
  select: BookingCommentSelectSchema.optional(),
  include: BookingCommentIncludeSchema.optional(),
  data: z.union([ BookingCommentUpdateInputSchema,BookingCommentUncheckedUpdateInputSchema ]),
  where: BookingCommentWhereUniqueInputSchema,
}).strict() ;

export const BookingCommentUpdateManyArgsSchema: z.ZodType<Prisma.BookingCommentUpdateManyArgs> = z.object({
  data: z.union([ BookingCommentUpdateManyMutationInputSchema,BookingCommentUncheckedUpdateManyInputSchema ]),
  where: BookingCommentWhereInputSchema.optional(),
}).strict() ;

export const BookingCommentDeleteManyArgsSchema: z.ZodType<Prisma.BookingCommentDeleteManyArgs> = z.object({
  where: BookingCommentWhereInputSchema.optional(),
}).strict() ;

export const BookingDeliveryCreateArgsSchema: z.ZodType<Prisma.BookingDeliveryCreateArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  data: z.union([ BookingDeliveryCreateInputSchema,BookingDeliveryUncheckedCreateInputSchema ]),
}).strict() ;

export const BookingDeliveryUpsertArgsSchema: z.ZodType<Prisma.BookingDeliveryUpsertArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  where: BookingDeliveryWhereUniqueInputSchema,
  create: z.union([ BookingDeliveryCreateInputSchema,BookingDeliveryUncheckedCreateInputSchema ]),
  update: z.union([ BookingDeliveryUpdateInputSchema,BookingDeliveryUncheckedUpdateInputSchema ]),
}).strict() ;

export const BookingDeliveryCreateManyArgsSchema: z.ZodType<Prisma.BookingDeliveryCreateManyArgs> = z.object({
  data: z.union([ BookingDeliveryCreateManyInputSchema,BookingDeliveryCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const BookingDeliveryDeleteArgsSchema: z.ZodType<Prisma.BookingDeliveryDeleteArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  where: BookingDeliveryWhereUniqueInputSchema,
}).strict() ;

export const BookingDeliveryUpdateArgsSchema: z.ZodType<Prisma.BookingDeliveryUpdateArgs> = z.object({
  select: BookingDeliverySelectSchema.optional(),
  include: BookingDeliveryIncludeSchema.optional(),
  data: z.union([ BookingDeliveryUpdateInputSchema,BookingDeliveryUncheckedUpdateInputSchema ]),
  where: BookingDeliveryWhereUniqueInputSchema,
}).strict() ;

export const BookingDeliveryUpdateManyArgsSchema: z.ZodType<Prisma.BookingDeliveryUpdateManyArgs> = z.object({
  data: z.union([ BookingDeliveryUpdateManyMutationInputSchema,BookingDeliveryUncheckedUpdateManyInputSchema ]),
  where: BookingDeliveryWhereInputSchema.optional(),
}).strict() ;

export const BookingDeliveryDeleteManyArgsSchema: z.ZodType<Prisma.BookingDeliveryDeleteManyArgs> = z.object({
  where: BookingDeliveryWhereInputSchema.optional(),
}).strict() ;

export const BookingHistoryCreateArgsSchema: z.ZodType<Prisma.BookingHistoryCreateArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  data: z.union([ BookingHistoryCreateInputSchema,BookingHistoryUncheckedCreateInputSchema ]),
}).strict() ;

export const BookingHistoryUpsertArgsSchema: z.ZodType<Prisma.BookingHistoryUpsertArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  where: BookingHistoryWhereUniqueInputSchema,
  create: z.union([ BookingHistoryCreateInputSchema,BookingHistoryUncheckedCreateInputSchema ]),
  update: z.union([ BookingHistoryUpdateInputSchema,BookingHistoryUncheckedUpdateInputSchema ]),
}).strict() ;

export const BookingHistoryCreateManyArgsSchema: z.ZodType<Prisma.BookingHistoryCreateManyArgs> = z.object({
  data: z.union([ BookingHistoryCreateManyInputSchema,BookingHistoryCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const BookingHistoryDeleteArgsSchema: z.ZodType<Prisma.BookingHistoryDeleteArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  where: BookingHistoryWhereUniqueInputSchema,
}).strict() ;

export const BookingHistoryUpdateArgsSchema: z.ZodType<Prisma.BookingHistoryUpdateArgs> = z.object({
  select: BookingHistorySelectSchema.optional(),
  include: BookingHistoryIncludeSchema.optional(),
  data: z.union([ BookingHistoryUpdateInputSchema,BookingHistoryUncheckedUpdateInputSchema ]),
  where: BookingHistoryWhereUniqueInputSchema,
}).strict() ;

export const BookingHistoryUpdateManyArgsSchema: z.ZodType<Prisma.BookingHistoryUpdateManyArgs> = z.object({
  data: z.union([ BookingHistoryUpdateManyMutationInputSchema,BookingHistoryUncheckedUpdateManyInputSchema ]),
  where: BookingHistoryWhereInputSchema.optional(),
}).strict() ;

export const BookingHistoryDeleteManyArgsSchema: z.ZodType<Prisma.BookingHistoryDeleteManyArgs> = z.object({
  where: BookingHistoryWhereInputSchema.optional(),
}).strict() ;

export const BookingItemCreateArgsSchema: z.ZodType<Prisma.BookingItemCreateArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  data: z.union([ BookingItemCreateInputSchema,BookingItemUncheckedCreateInputSchema ]),
}).strict() ;

export const BookingItemUpsertArgsSchema: z.ZodType<Prisma.BookingItemUpsertArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  where: BookingItemWhereUniqueInputSchema,
  create: z.union([ BookingItemCreateInputSchema,BookingItemUncheckedCreateInputSchema ]),
  update: z.union([ BookingItemUpdateInputSchema,BookingItemUncheckedUpdateInputSchema ]),
}).strict() ;

export const BookingItemCreateManyArgsSchema: z.ZodType<Prisma.BookingItemCreateManyArgs> = z.object({
  data: z.union([ BookingItemCreateManyInputSchema,BookingItemCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const BookingItemDeleteArgsSchema: z.ZodType<Prisma.BookingItemDeleteArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  where: BookingItemWhereUniqueInputSchema,
}).strict() ;

export const BookingItemUpdateArgsSchema: z.ZodType<Prisma.BookingItemUpdateArgs> = z.object({
  select: BookingItemSelectSchema.optional(),
  include: BookingItemIncludeSchema.optional(),
  data: z.union([ BookingItemUpdateInputSchema,BookingItemUncheckedUpdateInputSchema ]),
  where: BookingItemWhereUniqueInputSchema,
}).strict() ;

export const BookingItemUpdateManyArgsSchema: z.ZodType<Prisma.BookingItemUpdateManyArgs> = z.object({
  data: z.union([ BookingItemUpdateManyMutationInputSchema,BookingItemUncheckedUpdateManyInputSchema ]),
  where: BookingItemWhereInputSchema.optional(),
}).strict() ;

export const BookingItemDeleteManyArgsSchema: z.ZodType<Prisma.BookingItemDeleteManyArgs> = z.object({
  where: BookingItemWhereInputSchema.optional(),
}).strict() ;

export const BookingCreateArgsSchema: z.ZodType<Prisma.BookingCreateArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  data: z.union([ BookingCreateInputSchema,BookingUncheckedCreateInputSchema ]),
}).strict() ;

export const BookingUpsertArgsSchema: z.ZodType<Prisma.BookingUpsertArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  where: BookingWhereUniqueInputSchema,
  create: z.union([ BookingCreateInputSchema,BookingUncheckedCreateInputSchema ]),
  update: z.union([ BookingUpdateInputSchema,BookingUncheckedUpdateInputSchema ]),
}).strict() ;

export const BookingCreateManyArgsSchema: z.ZodType<Prisma.BookingCreateManyArgs> = z.object({
  data: z.union([ BookingCreateManyInputSchema,BookingCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const BookingDeleteArgsSchema: z.ZodType<Prisma.BookingDeleteArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  where: BookingWhereUniqueInputSchema,
}).strict() ;

export const BookingUpdateArgsSchema: z.ZodType<Prisma.BookingUpdateArgs> = z.object({
  select: BookingSelectSchema.optional(),
  include: BookingIncludeSchema.optional(),
  data: z.union([ BookingUpdateInputSchema,BookingUncheckedUpdateInputSchema ]),
  where: BookingWhereUniqueInputSchema,
}).strict() ;

export const BookingUpdateManyArgsSchema: z.ZodType<Prisma.BookingUpdateManyArgs> = z.object({
  data: z.union([ BookingUpdateManyMutationInputSchema,BookingUncheckedUpdateManyInputSchema ]),
  where: BookingWhereInputSchema.optional(),
}).strict() ;

export const BookingDeleteManyArgsSchema: z.ZodType<Prisma.BookingDeleteManyArgs> = z.object({
  where: BookingWhereInputSchema.optional(),
}).strict() ;

export const ClientCreateArgsSchema: z.ZodType<Prisma.ClientCreateArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  data: z.union([ ClientCreateInputSchema,ClientUncheckedCreateInputSchema ]),
}).strict() ;

export const ClientUpsertArgsSchema: z.ZodType<Prisma.ClientUpsertArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  where: ClientWhereUniqueInputSchema,
  create: z.union([ ClientCreateInputSchema,ClientUncheckedCreateInputSchema ]),
  update: z.union([ ClientUpdateInputSchema,ClientUncheckedUpdateInputSchema ]),
}).strict() ;

export const ClientCreateManyArgsSchema: z.ZodType<Prisma.ClientCreateManyArgs> = z.object({
  data: z.union([ ClientCreateManyInputSchema,ClientCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const ClientDeleteArgsSchema: z.ZodType<Prisma.ClientDeleteArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  where: ClientWhereUniqueInputSchema,
}).strict() ;

export const ClientUpdateArgsSchema: z.ZodType<Prisma.ClientUpdateArgs> = z.object({
  select: ClientSelectSchema.optional(),
  include: ClientIncludeSchema.optional(),
  data: z.union([ ClientUpdateInputSchema,ClientUncheckedUpdateInputSchema ]),
  where: ClientWhereUniqueInputSchema,
}).strict() ;

export const ClientUpdateManyArgsSchema: z.ZodType<Prisma.ClientUpdateManyArgs> = z.object({
  data: z.union([ ClientUpdateManyMutationInputSchema,ClientUncheckedUpdateManyInputSchema ]),
  where: ClientWhereInputSchema.optional(),
}).strict() ;

export const ClientDeleteManyArgsSchema: z.ZodType<Prisma.ClientDeleteManyArgs> = z.object({
  where: ClientWhereInputSchema.optional(),
}).strict() ;

export const DeliveryCreateArgsSchema: z.ZodType<Prisma.DeliveryCreateArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  data: z.union([ DeliveryCreateInputSchema,DeliveryUncheckedCreateInputSchema ]),
}).strict() ;

export const DeliveryUpsertArgsSchema: z.ZodType<Prisma.DeliveryUpsertArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  where: DeliveryWhereUniqueInputSchema,
  create: z.union([ DeliveryCreateInputSchema,DeliveryUncheckedCreateInputSchema ]),
  update: z.union([ DeliveryUpdateInputSchema,DeliveryUncheckedUpdateInputSchema ]),
}).strict() ;

export const DeliveryCreateManyArgsSchema: z.ZodType<Prisma.DeliveryCreateManyArgs> = z.object({
  data: z.union([ DeliveryCreateManyInputSchema,DeliveryCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const DeliveryDeleteArgsSchema: z.ZodType<Prisma.DeliveryDeleteArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  where: DeliveryWhereUniqueInputSchema,
}).strict() ;

export const DeliveryUpdateArgsSchema: z.ZodType<Prisma.DeliveryUpdateArgs> = z.object({
  select: DeliverySelectSchema.optional(),
  include: DeliveryIncludeSchema.optional(),
  data: z.union([ DeliveryUpdateInputSchema,DeliveryUncheckedUpdateInputSchema ]),
  where: DeliveryWhereUniqueInputSchema,
}).strict() ;

export const DeliveryUpdateManyArgsSchema: z.ZodType<Prisma.DeliveryUpdateManyArgs> = z.object({
  data: z.union([ DeliveryUpdateManyMutationInputSchema,DeliveryUncheckedUpdateManyInputSchema ]),
  where: DeliveryWhereInputSchema.optional(),
}).strict() ;

export const DeliveryDeleteManyArgsSchema: z.ZodType<Prisma.DeliveryDeleteManyArgs> = z.object({
  where: DeliveryWhereInputSchema.optional(),
}).strict() ;

export const DistrictCreateArgsSchema: z.ZodType<Prisma.DistrictCreateArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  data: z.union([ DistrictCreateInputSchema,DistrictUncheckedCreateInputSchema ]),
}).strict() ;

export const DistrictUpsertArgsSchema: z.ZodType<Prisma.DistrictUpsertArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  where: DistrictWhereUniqueInputSchema,
  create: z.union([ DistrictCreateInputSchema,DistrictUncheckedCreateInputSchema ]),
  update: z.union([ DistrictUpdateInputSchema,DistrictUncheckedUpdateInputSchema ]),
}).strict() ;

export const DistrictCreateManyArgsSchema: z.ZodType<Prisma.DistrictCreateManyArgs> = z.object({
  data: z.union([ DistrictCreateManyInputSchema,DistrictCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const DistrictDeleteArgsSchema: z.ZodType<Prisma.DistrictDeleteArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  where: DistrictWhereUniqueInputSchema,
}).strict() ;

export const DistrictUpdateArgsSchema: z.ZodType<Prisma.DistrictUpdateArgs> = z.object({
  select: DistrictSelectSchema.optional(),
  include: DistrictIncludeSchema.optional(),
  data: z.union([ DistrictUpdateInputSchema,DistrictUncheckedUpdateInputSchema ]),
  where: DistrictWhereUniqueInputSchema,
}).strict() ;

export const DistrictUpdateManyArgsSchema: z.ZodType<Prisma.DistrictUpdateManyArgs> = z.object({
  data: z.union([ DistrictUpdateManyMutationInputSchema,DistrictUncheckedUpdateManyInputSchema ]),
  where: DistrictWhereInputSchema.optional(),
}).strict() ;

export const DistrictDeleteManyArgsSchema: z.ZodType<Prisma.DistrictDeleteManyArgs> = z.object({
  where: DistrictWhereInputSchema.optional(),
}).strict() ;

export const FiscalYearCreateArgsSchema: z.ZodType<Prisma.FiscalYearCreateArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  data: z.union([ FiscalYearCreateInputSchema,FiscalYearUncheckedCreateInputSchema ]),
}).strict() ;

export const FiscalYearUpsertArgsSchema: z.ZodType<Prisma.FiscalYearUpsertArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  where: FiscalYearWhereUniqueInputSchema,
  create: z.union([ FiscalYearCreateInputSchema,FiscalYearUncheckedCreateInputSchema ]),
  update: z.union([ FiscalYearUpdateInputSchema,FiscalYearUncheckedUpdateInputSchema ]),
}).strict() ;

export const FiscalYearCreateManyArgsSchema: z.ZodType<Prisma.FiscalYearCreateManyArgs> = z.object({
  data: z.union([ FiscalYearCreateManyInputSchema,FiscalYearCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const FiscalYearDeleteArgsSchema: z.ZodType<Prisma.FiscalYearDeleteArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  where: FiscalYearWhereUniqueInputSchema,
}).strict() ;

export const FiscalYearUpdateArgsSchema: z.ZodType<Prisma.FiscalYearUpdateArgs> = z.object({
  select: FiscalYearSelectSchema.optional(),
  data: z.union([ FiscalYearUpdateInputSchema,FiscalYearUncheckedUpdateInputSchema ]),
  where: FiscalYearWhereUniqueInputSchema,
}).strict() ;

export const FiscalYearUpdateManyArgsSchema: z.ZodType<Prisma.FiscalYearUpdateManyArgs> = z.object({
  data: z.union([ FiscalYearUpdateManyMutationInputSchema,FiscalYearUncheckedUpdateManyInputSchema ]),
  where: FiscalYearWhereInputSchema.optional(),
}).strict() ;

export const FiscalYearDeleteManyArgsSchema: z.ZodType<Prisma.FiscalYearDeleteManyArgs> = z.object({
  where: FiscalYearWhereInputSchema.optional(),
}).strict() ;

export const GenBookingBarcodeCreateArgsSchema: z.ZodType<Prisma.GenBookingBarcodeCreateArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  data: z.union([ GenBookingBarcodeCreateInputSchema,GenBookingBarcodeUncheckedCreateInputSchema ]),
}).strict() ;

export const GenBookingBarcodeUpsertArgsSchema: z.ZodType<Prisma.GenBookingBarcodeUpsertArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  where: GenBookingBarcodeWhereUniqueInputSchema,
  create: z.union([ GenBookingBarcodeCreateInputSchema,GenBookingBarcodeUncheckedCreateInputSchema ]),
  update: z.union([ GenBookingBarcodeUpdateInputSchema,GenBookingBarcodeUncheckedUpdateInputSchema ]),
}).strict() ;

export const GenBookingBarcodeCreateManyArgsSchema: z.ZodType<Prisma.GenBookingBarcodeCreateManyArgs> = z.object({
  data: z.union([ GenBookingBarcodeCreateManyInputSchema,GenBookingBarcodeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const GenBookingBarcodeDeleteArgsSchema: z.ZodType<Prisma.GenBookingBarcodeDeleteArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  where: GenBookingBarcodeWhereUniqueInputSchema,
}).strict() ;

export const GenBookingBarcodeUpdateArgsSchema: z.ZodType<Prisma.GenBookingBarcodeUpdateArgs> = z.object({
  select: GenBookingBarcodeSelectSchema.optional(),
  data: z.union([ GenBookingBarcodeUpdateInputSchema,GenBookingBarcodeUncheckedUpdateInputSchema ]),
  where: GenBookingBarcodeWhereUniqueInputSchema,
}).strict() ;

export const GenBookingBarcodeUpdateManyArgsSchema: z.ZodType<Prisma.GenBookingBarcodeUpdateManyArgs> = z.object({
  data: z.union([ GenBookingBarcodeUpdateManyMutationInputSchema,GenBookingBarcodeUncheckedUpdateManyInputSchema ]),
  where: GenBookingBarcodeWhereInputSchema.optional(),
}).strict() ;

export const GenBookingBarcodeDeleteManyArgsSchema: z.ZodType<Prisma.GenBookingBarcodeDeleteManyArgs> = z.object({
  where: GenBookingBarcodeWhereInputSchema.optional(),
}).strict() ;

export const LocationCreateArgsSchema: z.ZodType<Prisma.LocationCreateArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  data: z.union([ LocationCreateInputSchema,LocationUncheckedCreateInputSchema ]),
}).strict() ;

export const LocationUpsertArgsSchema: z.ZodType<Prisma.LocationUpsertArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  where: LocationWhereUniqueInputSchema,
  create: z.union([ LocationCreateInputSchema,LocationUncheckedCreateInputSchema ]),
  update: z.union([ LocationUpdateInputSchema,LocationUncheckedUpdateInputSchema ]),
}).strict() ;

export const LocationCreateManyArgsSchema: z.ZodType<Prisma.LocationCreateManyArgs> = z.object({
  data: z.union([ LocationCreateManyInputSchema,LocationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const LocationDeleteArgsSchema: z.ZodType<Prisma.LocationDeleteArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  where: LocationWhereUniqueInputSchema,
}).strict() ;

export const LocationUpdateArgsSchema: z.ZodType<Prisma.LocationUpdateArgs> = z.object({
  select: LocationSelectSchema.optional(),
  include: LocationIncludeSchema.optional(),
  data: z.union([ LocationUpdateInputSchema,LocationUncheckedUpdateInputSchema ]),
  where: LocationWhereUniqueInputSchema,
}).strict() ;

export const LocationUpdateManyArgsSchema: z.ZodType<Prisma.LocationUpdateManyArgs> = z.object({
  data: z.union([ LocationUpdateManyMutationInputSchema,LocationUncheckedUpdateManyInputSchema ]),
  where: LocationWhereInputSchema.optional(),
}).strict() ;

export const LocationDeleteManyArgsSchema: z.ZodType<Prisma.LocationDeleteManyArgs> = z.object({
  where: LocationWhereInputSchema.optional(),
}).strict() ;

export const ManifestCreateArgsSchema: z.ZodType<Prisma.ManifestCreateArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  data: z.union([ ManifestCreateInputSchema,ManifestUncheckedCreateInputSchema ]),
}).strict() ;

export const ManifestUpsertArgsSchema: z.ZodType<Prisma.ManifestUpsertArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  where: ManifestWhereUniqueInputSchema,
  create: z.union([ ManifestCreateInputSchema,ManifestUncheckedCreateInputSchema ]),
  update: z.union([ ManifestUpdateInputSchema,ManifestUncheckedUpdateInputSchema ]),
}).strict() ;

export const ManifestCreateManyArgsSchema: z.ZodType<Prisma.ManifestCreateManyArgs> = z.object({
  data: z.union([ ManifestCreateManyInputSchema,ManifestCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const ManifestDeleteArgsSchema: z.ZodType<Prisma.ManifestDeleteArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  where: ManifestWhereUniqueInputSchema,
}).strict() ;

export const ManifestUpdateArgsSchema: z.ZodType<Prisma.ManifestUpdateArgs> = z.object({
  select: ManifestSelectSchema.optional(),
  include: ManifestIncludeSchema.optional(),
  data: z.union([ ManifestUpdateInputSchema,ManifestUncheckedUpdateInputSchema ]),
  where: ManifestWhereUniqueInputSchema,
}).strict() ;

export const ManifestUpdateManyArgsSchema: z.ZodType<Prisma.ManifestUpdateManyArgs> = z.object({
  data: z.union([ ManifestUpdateManyMutationInputSchema,ManifestUncheckedUpdateManyInputSchema ]),
  where: ManifestWhereInputSchema.optional(),
}).strict() ;

export const ManifestDeleteManyArgsSchema: z.ZodType<Prisma.ManifestDeleteManyArgs> = z.object({
  where: ManifestWhereInputSchema.optional(),
}).strict() ;

export const MasterManifestDetailCreateArgsSchema: z.ZodType<Prisma.MasterManifestDetailCreateArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  data: z.union([ MasterManifestDetailCreateInputSchema,MasterManifestDetailUncheckedCreateInputSchema ]),
}).strict() ;

export const MasterManifestDetailUpsertArgsSchema: z.ZodType<Prisma.MasterManifestDetailUpsertArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  where: MasterManifestDetailWhereUniqueInputSchema,
  create: z.union([ MasterManifestDetailCreateInputSchema,MasterManifestDetailUncheckedCreateInputSchema ]),
  update: z.union([ MasterManifestDetailUpdateInputSchema,MasterManifestDetailUncheckedUpdateInputSchema ]),
}).strict() ;

export const MasterManifestDetailCreateManyArgsSchema: z.ZodType<Prisma.MasterManifestDetailCreateManyArgs> = z.object({
  data: z.union([ MasterManifestDetailCreateManyInputSchema,MasterManifestDetailCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const MasterManifestDetailDeleteArgsSchema: z.ZodType<Prisma.MasterManifestDetailDeleteArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  where: MasterManifestDetailWhereUniqueInputSchema,
}).strict() ;

export const MasterManifestDetailUpdateArgsSchema: z.ZodType<Prisma.MasterManifestDetailUpdateArgs> = z.object({
  select: MasterManifestDetailSelectSchema.optional(),
  include: MasterManifestDetailIncludeSchema.optional(),
  data: z.union([ MasterManifestDetailUpdateInputSchema,MasterManifestDetailUncheckedUpdateInputSchema ]),
  where: MasterManifestDetailWhereUniqueInputSchema,
}).strict() ;

export const MasterManifestDetailUpdateManyArgsSchema: z.ZodType<Prisma.MasterManifestDetailUpdateManyArgs> = z.object({
  data: z.union([ MasterManifestDetailUpdateManyMutationInputSchema,MasterManifestDetailUncheckedUpdateManyInputSchema ]),
  where: MasterManifestDetailWhereInputSchema.optional(),
}).strict() ;

export const MasterManifestDetailDeleteManyArgsSchema: z.ZodType<Prisma.MasterManifestDetailDeleteManyArgs> = z.object({
  where: MasterManifestDetailWhereInputSchema.optional(),
}).strict() ;

export const MasterManifestCreateArgsSchema: z.ZodType<Prisma.MasterManifestCreateArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  data: z.union([ MasterManifestCreateInputSchema,MasterManifestUncheckedCreateInputSchema ]),
}).strict() ;

export const MasterManifestUpsertArgsSchema: z.ZodType<Prisma.MasterManifestUpsertArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  where: MasterManifestWhereUniqueInputSchema,
  create: z.union([ MasterManifestCreateInputSchema,MasterManifestUncheckedCreateInputSchema ]),
  update: z.union([ MasterManifestUpdateInputSchema,MasterManifestUncheckedUpdateInputSchema ]),
}).strict() ;

export const MasterManifestCreateManyArgsSchema: z.ZodType<Prisma.MasterManifestCreateManyArgs> = z.object({
  data: z.union([ MasterManifestCreateManyInputSchema,MasterManifestCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const MasterManifestDeleteArgsSchema: z.ZodType<Prisma.MasterManifestDeleteArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  where: MasterManifestWhereUniqueInputSchema,
}).strict() ;

export const MasterManifestUpdateArgsSchema: z.ZodType<Prisma.MasterManifestUpdateArgs> = z.object({
  select: MasterManifestSelectSchema.optional(),
  include: MasterManifestIncludeSchema.optional(),
  data: z.union([ MasterManifestUpdateInputSchema,MasterManifestUncheckedUpdateInputSchema ]),
  where: MasterManifestWhereUniqueInputSchema,
}).strict() ;

export const MasterManifestUpdateManyArgsSchema: z.ZodType<Prisma.MasterManifestUpdateManyArgs> = z.object({
  data: z.union([ MasterManifestUpdateManyMutationInputSchema,MasterManifestUncheckedUpdateManyInputSchema ]),
  where: MasterManifestWhereInputSchema.optional(),
}).strict() ;

export const MasterManifestDeleteManyArgsSchema: z.ZodType<Prisma.MasterManifestDeleteManyArgs> = z.object({
  where: MasterManifestWhereInputSchema.optional(),
}).strict() ;

export const MonthCreateArgsSchema: z.ZodType<Prisma.MonthCreateArgs> = z.object({
  select: MonthSelectSchema.optional(),
  data: z.union([ MonthCreateInputSchema,MonthUncheckedCreateInputSchema ]),
}).strict() ;

export const MonthUpsertArgsSchema: z.ZodType<Prisma.MonthUpsertArgs> = z.object({
  select: MonthSelectSchema.optional(),
  where: MonthWhereUniqueInputSchema,
  create: z.union([ MonthCreateInputSchema,MonthUncheckedCreateInputSchema ]),
  update: z.union([ MonthUpdateInputSchema,MonthUncheckedUpdateInputSchema ]),
}).strict() ;

export const MonthCreateManyArgsSchema: z.ZodType<Prisma.MonthCreateManyArgs> = z.object({
  data: z.union([ MonthCreateManyInputSchema,MonthCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const MonthDeleteArgsSchema: z.ZodType<Prisma.MonthDeleteArgs> = z.object({
  select: MonthSelectSchema.optional(),
  where: MonthWhereUniqueInputSchema,
}).strict() ;

export const MonthUpdateArgsSchema: z.ZodType<Prisma.MonthUpdateArgs> = z.object({
  select: MonthSelectSchema.optional(),
  data: z.union([ MonthUpdateInputSchema,MonthUncheckedUpdateInputSchema ]),
  where: MonthWhereUniqueInputSchema,
}).strict() ;

export const MonthUpdateManyArgsSchema: z.ZodType<Prisma.MonthUpdateManyArgs> = z.object({
  data: z.union([ MonthUpdateManyMutationInputSchema,MonthUncheckedUpdateManyInputSchema ]),
  where: MonthWhereInputSchema.optional(),
}).strict() ;

export const MonthDeleteManyArgsSchema: z.ZodType<Prisma.MonthDeleteManyArgs> = z.object({
  where: MonthWhereInputSchema.optional(),
}).strict() ;

export const ProductTypeCreateArgsSchema: z.ZodType<Prisma.ProductTypeCreateArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  data: z.union([ ProductTypeCreateInputSchema,ProductTypeUncheckedCreateInputSchema ]),
}).strict() ;

export const ProductTypeUpsertArgsSchema: z.ZodType<Prisma.ProductTypeUpsertArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  where: ProductTypeWhereUniqueInputSchema,
  create: z.union([ ProductTypeCreateInputSchema,ProductTypeUncheckedCreateInputSchema ]),
  update: z.union([ ProductTypeUpdateInputSchema,ProductTypeUncheckedUpdateInputSchema ]),
}).strict() ;

export const ProductTypeCreateManyArgsSchema: z.ZodType<Prisma.ProductTypeCreateManyArgs> = z.object({
  data: z.union([ ProductTypeCreateManyInputSchema,ProductTypeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const ProductTypeDeleteArgsSchema: z.ZodType<Prisma.ProductTypeDeleteArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  where: ProductTypeWhereUniqueInputSchema,
}).strict() ;

export const ProductTypeUpdateArgsSchema: z.ZodType<Prisma.ProductTypeUpdateArgs> = z.object({
  select: ProductTypeSelectSchema.optional(),
  include: ProductTypeIncludeSchema.optional(),
  data: z.union([ ProductTypeUpdateInputSchema,ProductTypeUncheckedUpdateInputSchema ]),
  where: ProductTypeWhereUniqueInputSchema,
}).strict() ;

export const ProductTypeUpdateManyArgsSchema: z.ZodType<Prisma.ProductTypeUpdateManyArgs> = z.object({
  data: z.union([ ProductTypeUpdateManyMutationInputSchema,ProductTypeUncheckedUpdateManyInputSchema ]),
  where: ProductTypeWhereInputSchema.optional(),
}).strict() ;

export const ProductTypeDeleteManyArgsSchema: z.ZodType<Prisma.ProductTypeDeleteManyArgs> = z.object({
  where: ProductTypeWhereInputSchema.optional(),
}).strict() ;

export const RateCreateArgsSchema: z.ZodType<Prisma.RateCreateArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  data: z.union([ RateCreateInputSchema,RateUncheckedCreateInputSchema ]),
}).strict() ;

export const RateUpsertArgsSchema: z.ZodType<Prisma.RateUpsertArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  where: RateWhereUniqueInputSchema,
  create: z.union([ RateCreateInputSchema,RateUncheckedCreateInputSchema ]),
  update: z.union([ RateUpdateInputSchema,RateUncheckedUpdateInputSchema ]),
}).strict() ;

export const RateCreateManyArgsSchema: z.ZodType<Prisma.RateCreateManyArgs> = z.object({
  data: z.union([ RateCreateManyInputSchema,RateCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const RateDeleteArgsSchema: z.ZodType<Prisma.RateDeleteArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  where: RateWhereUniqueInputSchema,
}).strict() ;

export const RateUpdateArgsSchema: z.ZodType<Prisma.RateUpdateArgs> = z.object({
  select: RateSelectSchema.optional(),
  include: RateIncludeSchema.optional(),
  data: z.union([ RateUpdateInputSchema,RateUncheckedUpdateInputSchema ]),
  where: RateWhereUniqueInputSchema,
}).strict() ;

export const RateUpdateManyArgsSchema: z.ZodType<Prisma.RateUpdateManyArgs> = z.object({
  data: z.union([ RateUpdateManyMutationInputSchema,RateUncheckedUpdateManyInputSchema ]),
  where: RateWhereInputSchema.optional(),
}).strict() ;

export const RateDeleteManyArgsSchema: z.ZodType<Prisma.RateDeleteManyArgs> = z.object({
  where: RateWhereInputSchema.optional(),
}).strict() ;

export const RoleCreateArgsSchema: z.ZodType<Prisma.RoleCreateArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  data: z.union([ RoleCreateInputSchema,RoleUncheckedCreateInputSchema ]),
}).strict() ;

export const RoleUpsertArgsSchema: z.ZodType<Prisma.RoleUpsertArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  where: RoleWhereUniqueInputSchema,
  create: z.union([ RoleCreateInputSchema,RoleUncheckedCreateInputSchema ]),
  update: z.union([ RoleUpdateInputSchema,RoleUncheckedUpdateInputSchema ]),
}).strict() ;

export const RoleCreateManyArgsSchema: z.ZodType<Prisma.RoleCreateManyArgs> = z.object({
  data: z.union([ RoleCreateManyInputSchema,RoleCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const RoleDeleteArgsSchema: z.ZodType<Prisma.RoleDeleteArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  where: RoleWhereUniqueInputSchema,
}).strict() ;

export const RoleUpdateArgsSchema: z.ZodType<Prisma.RoleUpdateArgs> = z.object({
  select: RoleSelectSchema.optional(),
  include: RoleIncludeSchema.optional(),
  data: z.union([ RoleUpdateInputSchema,RoleUncheckedUpdateInputSchema ]),
  where: RoleWhereUniqueInputSchema,
}).strict() ;

export const RoleUpdateManyArgsSchema: z.ZodType<Prisma.RoleUpdateManyArgs> = z.object({
  data: z.union([ RoleUpdateManyMutationInputSchema,RoleUncheckedUpdateManyInputSchema ]),
  where: RoleWhereInputSchema.optional(),
}).strict() ;

export const RoleDeleteManyArgsSchema: z.ZodType<Prisma.RoleDeleteManyArgs> = z.object({
  where: RoleWhereInputSchema.optional(),
}).strict() ;

export const StatementCreateArgsSchema: z.ZodType<Prisma.StatementCreateArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  data: z.union([ StatementCreateInputSchema,StatementUncheckedCreateInputSchema ]),
}).strict() ;

export const StatementUpsertArgsSchema: z.ZodType<Prisma.StatementUpsertArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  where: StatementWhereUniqueInputSchema,
  create: z.union([ StatementCreateInputSchema,StatementUncheckedCreateInputSchema ]),
  update: z.union([ StatementUpdateInputSchema,StatementUncheckedUpdateInputSchema ]),
}).strict() ;

export const StatementCreateManyArgsSchema: z.ZodType<Prisma.StatementCreateManyArgs> = z.object({
  data: z.union([ StatementCreateManyInputSchema,StatementCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const StatementDeleteArgsSchema: z.ZodType<Prisma.StatementDeleteArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  where: StatementWhereUniqueInputSchema,
}).strict() ;

export const StatementUpdateArgsSchema: z.ZodType<Prisma.StatementUpdateArgs> = z.object({
  select: StatementSelectSchema.optional(),
  include: StatementIncludeSchema.optional(),
  data: z.union([ StatementUpdateInputSchema,StatementUncheckedUpdateInputSchema ]),
  where: StatementWhereUniqueInputSchema,
}).strict() ;

export const StatementUpdateManyArgsSchema: z.ZodType<Prisma.StatementUpdateManyArgs> = z.object({
  data: z.union([ StatementUpdateManyMutationInputSchema,StatementUncheckedUpdateManyInputSchema ]),
  where: StatementWhereInputSchema.optional(),
}).strict() ;

export const StatementDeleteManyArgsSchema: z.ZodType<Prisma.StatementDeleteManyArgs> = z.object({
  where: StatementWhereInputSchema.optional(),
}).strict() ;

export const SubLocationCreateArgsSchema: z.ZodType<Prisma.SubLocationCreateArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  data: z.union([ SubLocationCreateInputSchema,SubLocationUncheckedCreateInputSchema ]),
}).strict() ;

export const SubLocationUpsertArgsSchema: z.ZodType<Prisma.SubLocationUpsertArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  where: SubLocationWhereUniqueInputSchema,
  create: z.union([ SubLocationCreateInputSchema,SubLocationUncheckedCreateInputSchema ]),
  update: z.union([ SubLocationUpdateInputSchema,SubLocationUncheckedUpdateInputSchema ]),
}).strict() ;

export const SubLocationCreateManyArgsSchema: z.ZodType<Prisma.SubLocationCreateManyArgs> = z.object({
  data: z.union([ SubLocationCreateManyInputSchema,SubLocationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SubLocationDeleteArgsSchema: z.ZodType<Prisma.SubLocationDeleteArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  where: SubLocationWhereUniqueInputSchema,
}).strict() ;

export const SubLocationUpdateArgsSchema: z.ZodType<Prisma.SubLocationUpdateArgs> = z.object({
  select: SubLocationSelectSchema.optional(),
  include: SubLocationIncludeSchema.optional(),
  data: z.union([ SubLocationUpdateInputSchema,SubLocationUncheckedUpdateInputSchema ]),
  where: SubLocationWhereUniqueInputSchema,
}).strict() ;

export const SubLocationUpdateManyArgsSchema: z.ZodType<Prisma.SubLocationUpdateManyArgs> = z.object({
  data: z.union([ SubLocationUpdateManyMutationInputSchema,SubLocationUncheckedUpdateManyInputSchema ]),
  where: SubLocationWhereInputSchema.optional(),
}).strict() ;

export const SubLocationDeleteManyArgsSchema: z.ZodType<Prisma.SubLocationDeleteManyArgs> = z.object({
  where: SubLocationWhereInputSchema.optional(),
}).strict() ;

export const UserCreateArgsSchema: z.ZodType<Prisma.UserCreateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
}).strict() ;

export const UserUpsertArgsSchema: z.ZodType<Prisma.UserUpsertArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
  create: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
  update: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
}).strict() ;

export const UserCreateManyArgsSchema: z.ZodType<Prisma.UserCreateManyArgs> = z.object({
  data: z.union([ UserCreateManyInputSchema,UserCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const UserDeleteArgsSchema: z.ZodType<Prisma.UserDeleteArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserUpdateArgsSchema: z.ZodType<Prisma.UserUpdateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserUpdateManyArgsSchema: z.ZodType<Prisma.UserUpdateManyArgs> = z.object({
  data: z.union([ UserUpdateManyMutationInputSchema,UserUncheckedUpdateManyInputSchema ]),
  where: UserWhereInputSchema.optional(),
}).strict() ;

export const UserDeleteManyArgsSchema: z.ZodType<Prisma.UserDeleteManyArgs> = z.object({
  where: UserWhereInputSchema.optional(),
}).strict() ;

export const WardCreateArgsSchema: z.ZodType<Prisma.WardCreateArgs> = z.object({
  select: WardSelectSchema.optional(),
  data: z.union([ WardCreateInputSchema,WardUncheckedCreateInputSchema ]),
}).strict() ;

export const WardUpsertArgsSchema: z.ZodType<Prisma.WardUpsertArgs> = z.object({
  select: WardSelectSchema.optional(),
  where: WardWhereUniqueInputSchema,
  create: z.union([ WardCreateInputSchema,WardUncheckedCreateInputSchema ]),
  update: z.union([ WardUpdateInputSchema,WardUncheckedUpdateInputSchema ]),
}).strict() ;

export const WardCreateManyArgsSchema: z.ZodType<Prisma.WardCreateManyArgs> = z.object({
  data: z.union([ WardCreateManyInputSchema,WardCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const WardDeleteArgsSchema: z.ZodType<Prisma.WardDeleteArgs> = z.object({
  select: WardSelectSchema.optional(),
  where: WardWhereUniqueInputSchema,
}).strict() ;

export const WardUpdateArgsSchema: z.ZodType<Prisma.WardUpdateArgs> = z.object({
  select: WardSelectSchema.optional(),
  data: z.union([ WardUpdateInputSchema,WardUncheckedUpdateInputSchema ]),
  where: WardWhereUniqueInputSchema,
}).strict() ;

export const WardUpdateManyArgsSchema: z.ZodType<Prisma.WardUpdateManyArgs> = z.object({
  data: z.union([ WardUpdateManyMutationInputSchema,WardUncheckedUpdateManyInputSchema ]),
  where: WardWhereInputSchema.optional(),
}).strict() ;

export const WardDeleteManyArgsSchema: z.ZodType<Prisma.WardDeleteManyArgs> = z.object({
  where: WardWhereInputSchema.optional(),
}).strict() ;