import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { BaggageSchema, BaggageUpdateInputSchema } from '@/zod';
import { BaggageCreateDTOSchema } from './BaggageModel';
import { baggageController } from './baggageController';

extendZodWithOpenApi(z);

export const baggageRegistry = new OpenAPIRegistry();
export const baggageRouter: Router = express.Router();

baggageRegistry.register('Baggage', BaggageSchema);

baggageRegistry.registerPath({
    method: 'get',
    path: '/api/baggages',
    tags: ['Baggage'],
    responses: createApiResponse(z.array(BaggageSchema), 'Success'),
});

baggageRouter.get('/', isAuthenticated, baggageController.getAll);

const GetBaggageBookingOptionsSchema = z.object({
    query: z.object({
        originId: commonValidations.id,
        destinationId: commonValidations.id,
    }),
});

baggageRouter.get(
    '/booking-options',
    isAuthenticated,
    validateRequest(GetBaggageBookingOptionsSchema),
    baggageController.getBookingOptions,
);

baggageRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    baggageController.getById,
);

const CreateSchema = z.object({
    body: BaggageCreateDTOSchema,
});

baggageRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    baggageController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: BaggageUpdateInputSchema,
});

baggageRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    baggageController.update,
);
