import type { BaseModel } from '@/common/models/commonModel';
import type { Prisma } from '@prisma/client';

export class LocationListViewModel {
    readonly id: number;
    readonly name: string;
    readonly districtId: number | null;
    readonly district: BaseModel | null;
    readonly contactName: string;
    readonly contactNo: string;
    readonly status: boolean;
    constructor(
        location: Prisma.LocationGetPayload<{
            include: {
                district: true;
            };
        }>,
    ) {
        this.id = location.id;
        this.name = location.name;
        this.districtId = location.districtId;
        this.district = location.district
            ? {
                  id: location.district.id,
                  name: location.district.name,
              }
            : null;
        this.contactName = location.contactName;
        this.contactNo = location.contactNo;
        this.status = location.status;
    }
}
