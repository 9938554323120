import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { StatementSchema, StatementUpdateInputSchema } from '@/zod';
import { statementController } from './statementController';
import { StatementCreateDTOSchema } from './statementModel';

extendZodWithOpenApi(z);

export const statementRegistry = new OpenAPIRegistry();
export const statementRouter: Router = express.Router();

statementRegistry.register('Statement', StatementSchema);

statementRegistry.registerPath({
    method: 'get',
    path: '/api/statements',
    tags: ['Statement'],
    responses: createApiResponse(z.array(StatementSchema), 'Success'),
});

statementRouter.get('/', isAuthenticated, statementController.getAll);

statementRouter.get(
    '/:id/print',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    statementController.print,
);

statementRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    statementController.getById,
);

const CreateSchema = z.object({
    body: StatementCreateDTOSchema,
});

statementRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    statementController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: StatementUpdateInputSchema,
});

statementRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    statementController.update,
);
