import type { NextFunction, Request, Response } from 'express';
import { StatusCodes } from 'http-status-codes';
import type { ZodError, ZodSchema } from 'zod';

import { ServiceResponse } from '@/common/models/serviceResponse';

export function handleServiceResponse<T>(
    serviceResponse: ServiceResponse<T>,
    response: Response,
) {
    return response.status(serviceResponse.statusCode).send(serviceResponse);
}

export const validateRequest =
    (schema: ZodSchema) =>
    (req: Request, res: Response, next: NextFunction) => {
        try {
            schema.parse({
                body: req.body,
                query: req.query,
                params: req.params,
                file: req.file,
            });
            next();
        } catch (err) {
            const errors = (err as ZodError).errors;
            const errorMessage = errors
                .map((e) => {
                    return `Field: "${e.path.slice(1).join('.')}", Error: "${e.message}"`;
                })
                .join('\n');
            const statusCode = StatusCodes.BAD_REQUEST;
            const serviceResponse = ServiceResponse.failure(
                errorMessage,
                null,
                statusCode,
                errors,
            );
            handleServiceResponse(serviceResponse, res);
        }
    };
