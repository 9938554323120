import { z } from 'zod';

export const commonValidations = {
    id: z
        .string()
        .refine(
            (data) => !Number.isNaN(Number(data)),
            'ID must be a numeric value',
        )
        .transform(Number)
        .refine((num) => num > 0, 'ID must be a positive number'),
    contact: z.string().regex(/^[-+ 0-9]{5,15}$/, {
        message: 'Invalid format for Contact No',
    }),
    pan: z.string().regex(/^[1-9][0-9]{8}$/),
    name: z.string().max(150, {
        message: 'Name cannot be longer than 150 characters',
    }),
    address: z.string().max(500, {
        message: 'Address cannot be longer than 500 characters',
    }),
    amount: z.number().refine((num) => num >= 0),
    date: z.string().transform((str) => new Date(str)),
    dateNullable: z
        .string()
        .nullable()
        .optional()
        .transform((str) => (str ? new Date(str) : undefined)),
};
