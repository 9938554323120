import { StatusCodes } from 'http-status-codes';

import { ServiceResponse } from '@/common/models/serviceResponse';
import { logger } from '@/server';
import { DeliveryMode, PaymentMode, ServiceType, Unit } from '@prisma/client';
import { Lookup, type LookupViewModel } from './lookupModel';

export class LookupService {
    async getUnits(): Promise<ServiceResponse<LookupViewModel[] | null>> {
        try {
            const list = Object.values(Unit).map((value) => ({
                value,
                label: Lookup.unit[value],
            }));

            return ServiceResponse.success<LookupViewModel[]>(
                'Units found',
                list,
            );
        } catch (ex) {
            const errorMessage = `Error getting lookups: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving lookups.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async getPaymentModes(): Promise<
        ServiceResponse<LookupViewModel[] | null>
    > {
        try {
            const list = Object.values(PaymentMode).map((value) => ({
                ...Lookup.paymentMode[value],
                value,
            }));

            return ServiceResponse.success<LookupViewModel[]>(
                'Payment Modes found',
                list,
            );
        } catch (ex) {
            const errorMessage = `Error getting lookups: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving lookups.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async getServiceTypes(): Promise<
        ServiceResponse<LookupViewModel[] | null>
    > {
        try {
            const list = Object.values(ServiceType).map((value) => ({
                value,
                label: Lookup.serviceType[value],
            }));

            return ServiceResponse.success<LookupViewModel[]>(
                'Service Types found',
                list,
            );
        } catch (ex) {
            const errorMessage = `Error getting lookups: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving lookups.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }

    async getDeliveryModes(): Promise<
        ServiceResponse<LookupViewModel[] | null>
    > {
        try {
            const list = Object.values(DeliveryMode).map((value) => ({
                value,
                label: Lookup.deliveryMode[value],
            }));

            return ServiceResponse.success<LookupViewModel[]>(
                'Delivery Modes found',
                list,
            );
        } catch (ex) {
            const errorMessage = `Error getting lookups: $${(ex as Error).message}`;
            logger.error(errorMessage);
            return ServiceResponse.failure(
                'An error occurred while retrieving lookups.',
                null,
                StatusCodes.INTERNAL_SERVER_ERROR,
            );
        }
    }
}

export const lookupService = new LookupService();
