import {
    OpenAPIRegistry,
    extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import express, { type Router } from 'express';
import { z } from 'zod';

import { createApiResponse } from '@/api-docs/openAPIResponseBuilders';
import isAuthenticated from '@/common/middleware/isAuthenticated';
import upload from '@/common/middleware/upload';
import { GetByIdSchema } from '@/common/models/commonModel';
import { commonValidations } from '@/common/utils/commonValidation';
import { validateRequest } from '@/common/utils/httpHandlers';
import { DeliverySchema, DeliveryUpdateInputSchema } from '@/zod';
import { deliveryController } from './deliveryController';
import {
    ACCEPTED_IMAGE_TYPES,
    DeliveryCreateDTOSchema,
    DeliveryDeliverDTOSchema,
    DeliveryReturnDTOSchema,
    MAX_IMAGE_SIZE,
} from './deliveryModel';

extendZodWithOpenApi(z);

export const deliveryRegistry = new OpenAPIRegistry();
export const deliveryRouter: Router = express.Router();

deliveryRegistry.register('Delivery', DeliverySchema);

deliveryRegistry.registerPath({
    method: 'get',
    path: '/api/deliveries',
    tags: ['Delivery'],
    responses: createApiResponse(z.array(DeliverySchema), 'Success'),
});

deliveryRouter.get('/', isAuthenticated, deliveryController.getAll);

deliveryRouter.get(
    '/booking-options',
    isAuthenticated,
    deliveryController.getBookingOptions,
);

deliveryRouter.get(
    '/:id',
    isAuthenticated,
    validateRequest(GetByIdSchema),
    deliveryController.getById,
);

const CreateSchema = z.object({
    body: DeliveryCreateDTOSchema,
});

deliveryRouter.post(
    '/',
    isAuthenticated,
    validateRequest(CreateSchema),
    deliveryController.create,
);

const UpdateSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: DeliveryUpdateInputSchema,
});

deliveryRouter.put(
    '/:id',
    isAuthenticated,
    validateRequest(UpdateSchema),
    deliveryController.update,
);

const DeliverSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: DeliveryDeliverDTOSchema,
    file: z
        .any()
        .refine((file) => !!file, 'POD is required.')
        .refine((file) => file?.size <= MAX_IMAGE_SIZE, 'Max file size is 5MB.')
        .refine(
            (file) => ACCEPTED_IMAGE_TYPES.includes(file?.mimetype),
            '.jpg, .jpeg, .png and .webp files are accepted.',
        ),
});

deliveryRouter.post(
    '/:id/deliver',
    isAuthenticated,
    upload.single('pod'),
    validateRequest(DeliverSchema),
    deliveryController.deliver,
);

const ReturnSchema = z.object({
    params: z.object({ id: commonValidations.id }),
    body: DeliveryReturnDTOSchema,
});

deliveryRouter.post(
    '/:id/return',
    isAuthenticated,
    validateRequest(ReturnSchema),
    deliveryController.return,
);
